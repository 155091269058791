import { useTranslation } from "react-i18next";
import { Wrapper } from "..";
import { Row, Col } from "antd";
import ActivitiesMost from "./content/activities-most";
import ActivitiesTime from "./content/activities-time";

const ActivitiesDashboard = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]} className="h-full w-full">
        <Col span={24}>{t("property.activities", { ns: "menu" })}</Col>
        <Col md={24} lg={24} xl={8}>
          <ActivitiesMost />
        </Col>
        <Col md={24} lg={24} xl={16}>
          <ActivitiesTime />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ActivitiesDashboard;
