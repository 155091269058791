import { FC } from "react";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetPropertyAdministrators } from "service/property/administrator";
import { useTranslation } from "react-i18next";
import { IUserParams } from "service/administrator/interface";

export interface CSelectAdminPropertyProps extends CSelectProps, IUserParams {
  valueKey?: string;
  queryParams?: Record<string, any>;
}

export const CSelectAdminProperty: FC<CSelectAdminPropertyProps> = ({
  enabled,
  queryParams,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={props.placeholder ? props.placeholder : t("creator")}
      useQuery={useScrollGetPropertyAdministrators}
      valueKey={props?.valueKey ? props?.valueKey : "uuid"}
      labelKey="first-last-name"
      searchKey="name"
      queryParams={{
        enabled,
        isActive: true,
        ...queryParams,
      }}
    />
  );
};
