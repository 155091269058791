import { FC } from "react";
import { Row, Col, Statistic, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { ICardStatus, colorTheme, theme } from "../interface";
import { ErrorDashboard, WrapperBG } from "pages/overview";
import React from "react";
import { ITaskStatus } from "service/dashboard/property/task/interface";
import { useGetDashboardTask } from "service/dashboard/central/task";
import { useOverview } from "providers/overview";

const DivStyle = styled(Col)`
  height: calc(100vh - 75vh) !important;
`;

const WorkCard = () => {
  const { property } = useOverview();
  const { data, isFetching, isError, error } = useGetDashboardTask({
    property,
  });

  const overDue = data?.overdue;
  const earlyWaning = data?.early;
  const inprogress = data?.inprogress;

  if (isError) {
    return (
      <DivStyle span={24}>
        <WrapperBG className="h-full w-full">
          <ErrorDashboard error={error} height="calc(100vh - 75vh)" />
        </WrapperBG>
      </DivStyle>
    );
  }

  return (
    <React.Fragment>
      <DivStyle md={24} lg={24} xl={15}>
        <WrapperBG className="h-full">
          <CardSide isFetching={isFetching} />
        </WrapperBG>
      </DivStyle>
      <DivStyle md={24} lg={24} xl={9} className="h-full">
        <Row gutter={[20, 0]} className="h-full">
          <CardStatus type="overdue" text={overDue} loading={isFetching} />
          <CardStatus
            type="early-warning"
            text={earlyWaning}
            loading={isFetching}
          />
          <CardStatus type="on-due" text={inprogress} loading={isFetching} />
        </Row>
      </DivStyle>
    </React.Fragment>
  );
};

interface IProps {
  data?: ITaskStatus;
  isFetching: boolean;
}

const CardSide = ({ data, isFetching }: IProps) => {
  const { t } = useTranslation();

  const today = data?.todayTask || 0;
  const manage = data?.manageTask || 0;
  const all = data?.allTask || 0;
  const unRoutine = data?.impermanentTask || 0;

  return (
    <Row className="h-full center ">
      <ItemSide
        title={t("work-to-do-today")}
        count={today}
        border
        isFetching={isFetching}
      />
      <ItemSide
        title={t("all-work-planned")}
        count={manage}
        border
        isFetching={isFetching}
      />
      <ItemSide
        title={t("routine")}
        count={all}
        border
        isFetching={isFetching}
      />
      <ItemSide
        title={t("unroutine")}
        count={unRoutine}
        isFetching={isFetching}
      />
    </Row>
  );
};

interface ItemSideProps {
  title: string;
  count: number;
  border?: boolean;
  isFetching: boolean;
}

const StyleItemSide = styled(Col)<{ $border?: boolean }>`
  ${tw`text-center h-[15vh] flex flex-col items-center justify-center`}
  border-right:${({ $border }) =>
    $border ? "1.5px solid rgba(5, 5, 5, 0.06)" : ""};
  .text-title {
    color: #58595b;
  }
`;

const ItemSide: FC<ItemSideProps> = ({
  title,
  count,
  border = false,
  isFetching,
}) => {
  const { t } = useTranslation();
  return (
    <StyleItemSide span={6} $border={border}>
      <Spin spinning={isFetching} className="h-full center ">
        <div className="text-title">{title}</div>
        <div className="text-[#44955B] text-[24px] font-bold">{count}</div>
        <div className="text-[#276347] text-[16px] ">{t("work")}</div>
      </Spin>
    </StyleItemSide>
  );
};

const CardStyle = styled.div<{ themeColor: colorTheme }>`
  ${tw`rounded-app !h-full`}
  background-color: ${({ themeColor }) => themeColor.bg};
  .ant-statistic .ant-statistic-title {
    color: ${({ themeColor }) => themeColor.color};
  }
  .ant-statistic .ant-statistic-content {
    color: ${({ themeColor }) => themeColor.color};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CardStatus: FC<ICardStatus> = ({ text, type, loading }) => {
  const { t } = useTranslation();

  const status = t(type);
  const themeColor = theme[type];

  return (
    <Col span={8}>
      <CardStyle themeColor={themeColor} className="center">
        <Statistic title={status} value={text || 0} loading={loading} />
      </CardStyle>
    </Col>
  );
};

export default WorkCard;
