import React from "react";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetRepairsByUnit } from "service/property/house-care-service";

export interface CSelectRepairServiceReferenceNumberProps extends CSelectProps {
  queryParams: Record<string, any>;
}

export const CSelectRepairServiceReferenceNumber: React.FC<
  CSelectRepairServiceReferenceNumberProps
> = ({ placeholder, queryParams, ...props }) => {
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder}
      useQuery={useScrollGetRepairsByUnit}
      valueKey="reference"
      labelKey="code"
      searchKey="code"
      queryParams={{ ...queryParams }}
    />
  );
};
