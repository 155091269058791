import React from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectStatusNews: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("status")}
      valueOption={{
        values: [
          { value: "DRAFT", title: t("draft"), disabled: true },
          {
            value: "SELECT_PROJECT",
            title: t("select-a-project"),
            disabled: true,
          },
          {
            value: "PENDING_APPROVAL",
            title: t("pending-approval"),
            disabled: true,
          },
          { value: "PUBLISH", title: t("publish") },
          { value: "DISAPPROVED", title: t("disapproved") },
          { value: "APPROVED_SOME", title: t("published-some-property") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
