import { Spin } from "antd";
import { CIndicator } from "components";
import React from "react";
import styled, { CSSProperties } from "styled-components";
import { PageFilter, PageFilterProps } from "../page-filter";
import { PageHeader, PageHeaderProps } from "../page-header";

interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
  padding?: number;
  pageFilter?: PageFilterProps;
  pageHeader?: PageHeaderProps;
  style?: CSSProperties;
  loading?: boolean;
}

const PageContainerWrapper = styled.div``;

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  className = "",
  padding = 5,
  pageFilter,
  pageHeader,
  style,
  loading = false,
}) => {
  return (
    <PageContainerWrapper
      className={`!p-${padding} ${className} `}
      style={style}
    >
      {pageHeader ? <PageHeader className="mb-3" {...pageHeader} /> : null}

      {pageFilter ? <PageFilter className="mb-5" {...pageFilter} /> : null}
      <Spin spinning={loading} indicator={<CIndicator />}>
        {children}
      </Spin>
    </PageContainerWrapper>
  );
};
