import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  IRepairService,
  IRepairServiceParams,
  IRepairServiceTimeline,
  IRepairStatusCount,
} from "./interface";
import { IResponse } from "config/axios/interface";
import { axiosProperty, throwResponse } from "config/axios";
import { IMediaObject } from "service/media-object/interface";
import { postPropertyMediaObject } from "../media-object";

export const useGetPropertyRepairStatusCount = (): UseQueryResult<
  IResponse<IRepairStatusCount>,
  Error
> => {
  return useQuery(
    ["repairServiceStatusCount", "count"],
    async () => {
      const res = await axiosProperty.get("/repairing/count-all-status");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPropertyRepairs = (
  params?: IRepairServiceParams
): UseQueryResult<IResponse<IRepairService[]>, Error> => {
  return useQuery(
    ["repairService", "all", params],
    async () => {
      const res = await axiosProperty.get("/repairing", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPropertyRepair = (
  id?: number | string
): UseQueryResult<IRepairService, Error> => {
  return useQuery(
    ["repairService", "id", id],
    async () => {
      const res = await axiosProperty.get(`/repairing/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetPropertyRepairCalendar = (
  id?: number | string,
  enabled?: boolean
): UseQueryResult<IRepairService, Error> => {
  return useQuery(
    ["repairService", "id", id],
    async () => {
      const res = await axiosProperty.get(`/repairing/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: enabled }
  );
};

export const usePostPropertyRepair = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/repairing`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchPropertyRepair = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    data.beforeRepairingImages = await Promise.all(
      data.beforeRepairingImages.map(
        async (e: File | { media: IMediaObject }) => {
          if ("media" in e) return e.media?.id;
          const { id } = await postPropertyMediaObject(e);
          return id;
        }
      )
    );
    data.afterRepairingImages = await Promise.all(
      data.afterRepairingImages.map(
        async (e: File | { media: IMediaObject }) => {
          if ("media" in e) return e.media?.id;
          const { id } = await postPropertyMediaObject(e);
          return id;
        }
      )
    );
    const res = await axiosProperty.patch(`/repairing/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyRepair = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/repairing/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyRepairBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/repairing/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetRepairsByUnit = (
  params?: IRepairServiceParams
): UseInfiniteQueryResult<IResponse<IRepairService[]>, Error> => {
  return useInfiniteQuery(
    ["repairService", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get(
        `/repairing/find-by-unit/${params?.unitId}`,
        {
          params: { ...params, ...pageParam },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.unitId }
  );
};

export const useGetRepairServiceReference = (
  reference?: string
): UseQueryResult<IRepairService[], Error> => {
  return useQuery(
    ["repairService", "reference", reference],
    async () => {
      const res = await axiosProperty.get(
        `/repairing/find-reference/${reference}`
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!reference }
  );
};

export const useGetRepairServiceHistory = (
  id?: string
): UseQueryResult<IRepairServiceTimeline[], Error> => {
  return useQuery(
    ["repairService", "history", id],
    async () => {
      const res = await axiosProperty.get(`/repairing/history/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};
