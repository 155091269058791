import { LoadingDashboard, Wrapper } from "pages/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderOverview, IRenderBarChart, RenderBarChart } from "components";
import { useOverview } from "providers/overview";
import { Form } from "antd";

const colors = [
  "#7986CB",
  "#3F51B5",
  "#4285F4",
  "#33B679",
  "#0B8043",
  "#C0CA33",
  "#F6BF26",
  "#F09300",
  "#EF6C00",
  "#D50000",
  "#D81B60",
  "#E67C73",
  "#7986CB",
  "#3F51B5",
];

interface IDataMenu {
  title: string;
  count: number;
}

const dataMenu: IDataMenu[] = [
  {
    title: "property.news-and-announcement",
    count: 5,
  },
  {
    title: "property.house-care-service",
    count: 44,
  },
  {
    title: "property.parcel",
    count: 85,
  },
  {
    title: "property.facility",
    count: 22,
  },
  {
    title: "user-management",
    count: 30,
  },
  {
    title: "property.residents",
    count: 82,
  },
  {
    title: "property.unit",
    count: 70,
  },
  {
    title: "property.complaint",
    count: 22,
  },
  {
    title: "property.survey",
    count: 50,
  },
  {
    title: "property.work-manage",
    count: 80,
  },
  {
    title: "property.sell-and-rent",
    count: 40,
  },
  {
    title: "property.voting",
    count: 40,
  },
  {
    title: "property.work-space",
    count: 10,
  },
  {
    title: "property.activities",
    count: 1,
  },
];

const ListMenus = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const listMenusDate = Form.useWatch("listMenusDate");
  const date = findDate(listMenusDate);

  const data: IDataMenu[] = dataMenu.map((e) => {
    return { ...e, title: t(e.title, { ns: "menu" }) };
  });

  const dataBar: IRenderBarChart = {
    data: data,
    dataKeyX: "title",
    dataKeyY: "count",
    color: "#F6BF26",
    colorsBar: colors,
  };

  return (
    <Wrapper className="h-[400px]">
      <LoadingDashboard loading={false}>
        <HeaderOverview title={t("menu-item")} name="listMenusDate" />
        <RenderBarChart {...dataBar} />
      </LoadingDashboard>
    </Wrapper>
  );
};

export default ListMenus;
