import { Radio, RadioProps } from "antd";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { CRadioAccept } from "./radio-accept";
import { CRadioGroup, CRadioGroupProps } from "./radio-group";
import { CRadioNationality } from "./radio-nationality";
import { CRadioPriority } from "./radio-priority";
import { CRadioRepairArea } from "./radio-repair-area";
import { CRadioSurvey } from "./radio-survey";

export const StyledRadio = styled(Radio)`
  ${tw`rounded-app flex items-center`};
  height: 45px;
  .ant-radio-inner {
    background-color: #fff;
    width: 23px;
    height: 23px;
  }

  .ant-radio-inner::after {
    width: 35px;
    height: 35px;
    background-color: #467700;
    // inset-block-start: -30%;
    // inset-inline-start: -30%;
    inset-block-start: -7px;
    inset-inline-start: -7px;
    margin-block-start: 0px;
    margin-inline-start: 0px;
    transform: scale(0.375) !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #467700;
    background-color: #fff;
  }
`;

type NotationTypes = {
  Group: React.FC<CRadioGroupProps>;
  Nationality: React.FC<CRadioGroupProps>;
  Accept: React.FC<CRadioGroupProps>;
  RepairArea: React.FC<CRadioGroupProps>;
  Priority: React.FC<CRadioGroupProps>;
  Survey: React.FC<CRadioGroupProps>;
};

export const CRadio: React.FC<RadioProps> & NotationTypes = (props) => {
  return <StyledRadio {...props} />;
};

CRadio.Group = CRadioGroup;
CRadio.Nationality = CRadioNationality;
CRadio.Accept = CRadioAccept;
CRadio.RepairArea = CRadioRepairArea;
CRadio.Priority = CRadioPriority;
CRadio.Survey = CRadioSurvey;
