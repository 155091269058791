import { InputNumber, InputNumberProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledInputNumber = styled(InputNumber)<{ bgcolor?: number }>`
  ${tw`!rounded-app`}
  // border-color: ${({ bgcolor }) => (bgcolor ? "white" : "")}!important;
  width: 100%;
  height: 45px;
  input,
  .ant-input-number-input-wrap {
    height: 100%;
  }
`;

export interface CInputNumberProps extends InputNumberProps {
  bgcolor?: boolean;
}

export const CInputNumber: FC<CInputNumberProps> = ({
  bgcolor = true,
  ...props
}) => {
  return (
    <StyledInputNumber precision={0} bgcolor={bgcolor ? 1 : 0} {...props} />
  );
};
