import { useTranslation } from "react-i18next";
import { CRadio, StyledRadio } from ".";
import { CRadioGroupProps } from "./radio-group";
import React from "react";
import { Row, Col, Form } from "antd";
import { CInputNumber } from "../c-input";

export const CRadioSurvey: React.FC<CRadioGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const OptionElement = () => {
    return (
      <Row justify="space-between" align="top">
        <Col span={4}>
          <StyledRadio value={"RANDOM"}>
            <span> {t("random")}</span>
          </StyledRadio>
        </Col>
        <Col>
          <Form.Item
            rules={[
              {
                required: props.state === "RANDOM" && true,
              },
            ]}
            name="displayRound"
          >
            <CInputNumber
              placeholder={t("x-rounded")}
              className={props.state === "UNRANDOM" ? "hidden" : ""}
              min={0}
              disabled={props.disable}
            />
          </Form.Item>
        </Col>
        <Col>
          <StyledRadio value={"UNRANDOM"}>
            <span> {t("no-random")}</span>
          </StyledRadio>
        </Col>
      </Row>
    );
  };

  return <CRadio.Group {...props} OptionElement={OptionElement} />;
};
