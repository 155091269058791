import React from "react";
import { useTranslation } from "react-i18next";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "../..";
import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import { useOverview } from "providers/overview";
import { useGetPropertyDashboardAppliancesCount } from "service/dashboard/property/appliances";
import { Form } from "antd";
import { useAppLanguage } from "providers/app-language";

const COLORS = ["#276347", "#F8931D", "#D2D3D5"];

const MostUsedItems = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();
  const { translationObject } = useAppLanguage();

  const workSpaceTopDate = Form.useWatch("workSpaceTopDate");
  const date = findDate(workSpaceTopDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardAppliancesCount({
      ...date,
    });

  const getData =
    data?.map((item) => {
      const { name } = translationObject({ object: item, keys: ["name"] });
      return { name: name, count: item.count };
    }) || [];

  const dataPie: IRenderPieChart = {
    data: getData,
    dataKey: "count",
    dataName: "name",
    color: COLORS,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("the-most-used-items")}
          name="workSpaceTopDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default MostUsedItems;
