import { Divider } from "antd";
import { LogoImage } from "assets/images";
import { CImage } from "components/input/c-image";
import React from "react";
import styled from "styled-components";
const SiderLogoWrapper = styled.div`
  height: 70px;
`;

const SiderLogo = () => {
  return (
    <React.Fragment>
      <SiderLogoWrapper className='center'>
        <div className='h-full w-[75px]'>
          <CImage src={LogoImage} style={{ objectFit: "contain" }} />
        </div>
      </SiderLogoWrapper>
      <Divider className='!m-0' />
    </React.Fragment>
  );
};

export default SiderLogo;
