import { Radio, RadioGroupProps, Space } from "antd";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { StyledRadio } from ".";

export const StyledRadioGroup = styled(Radio.Group)<{
  height?: string;
  $readonly?: boolean;
}>`
  ${tw`rounded-app flex items-center`};
  height: ${({ height }) => height};
  .ant-radio-button-wrapper {
    height: ${({ height }) => height};
  }
  .ant-radio-button-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    svg * {
      fill: black;
    }
  }
  .ant-radio-button-wrapper {
    span: not(.ant-radio-button) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .ant-radio-wrapper {
    .ant-radio {
      margin-right: 5px;
    }
  }
`;

export interface CRadioGroupProps extends RadioGroupProps {
  height?: string;
  disable?: boolean;
  vertical?: boolean;
  spaceSize?: number;
  OptionElement?: () => JSX.Element;
  state?: string;
  readonly?: boolean;
}

export const CRadioGroup: React.FC<CRadioGroupProps> = ({
  optionType = "default",
  buttonStyle = "solid",
  height = "45px",
  vertical = false,
  spaceSize = 0,
  options,
  OptionElement,
  disable,
  readonly,
  ...props
}) => {
  const Option = () => {
    return (
      <React.Fragment>
        {options?.map((item, index) => {
          if (typeof item === "number" || typeof item === "string") return null;
          return (
            <StyledRadio
              disabled={item.disabled}
              key={index}
              value={item.value}
            >
              {item.label}
            </StyledRadio>
          );
        })}
      </React.Fragment>
    );
  };

  if (vertical) {
    const { ...rest } = props;
    return (
      <StyledRadioGroup
        {...rest}
        optionType={optionType}
        buttonStyle={buttonStyle}
        height={height}
      >
        <Space direction="vertical" size={spaceSize}>
          <Option />
        </Space>
      </StyledRadioGroup>
    );
  }

  return (
    <StyledRadioGroup
      {...props}
      optionType={optionType}
      size="large"
      height={height}
    >
      {OptionElement ? <OptionElement /> : <Option />}
    </StyledRadioGroup>
  );
};
