import React from "react";
import { HeaderOverview } from "components";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useGetDashboardVehicle } from "service/dashboard/central/vehicle";

const VehicleUse = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const mostUsedVehicleDate = Form.useWatch("mostUsedVehicleDate");
  const date = findDate(mostUsedVehicleDate);

  const { data, isFetching, isError, error } = useGetDashboardVehicle({
    ...date,
    property,
  });

  const getType = () => {
    if (!data) return "";
    switch (data?.types) {
      case "CAR":
        return t("car");
      case "MOTORCYCLE":
        return t("motorcycle");
      case "BICYCLE":
        return t("bicycle");
      case "OTHER":
        return t("other");
    }
  };

  const type = getType();

  return (
    <WrapperBG className="h-full">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("most-used-vehicle-types")}
          name="mostUsedVehicleDate"
        />

        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || !data.types ? (
          <NoDataDashboard height="250px" />
        ) : (
          <div className="h-[250px] center flex-col">
            <div className="text-[2.5rem] text-[#44955B]">{type}</div>
          </div>
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default VehicleUse;
