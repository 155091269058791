import { CopyFilled, DeleteFilled } from "@ant-design/icons";
import { Badge, Col, Row, Typography } from "antd";
import { SearchIcon } from "assets/images/icon";
import { CButton } from "components";
import { MenuTypes } from "menus/interface";
import { useAppPageContainer } from "providers/app-page-container";
import { useAppPopup } from "providers/app-popup";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MenuPath } from "routes/interface";
import styled from "styled-components";
import PageAction, { IActionProps } from "../page-action";
import PageExportAction, { IExcelOptions } from "../page-export";
import { useAppActionRole } from "providers/action-role";

export interface PageHeaderProps {
  title: MenuTypes;
  pathTitle?: MenuPath;
  button?: { to?: string; label?: string };
  className?: string;
  action?: IActionProps | boolean;
  breadcrumb?: IBreadcrumb | boolean;
  showTitle?: boolean;
  onButtonAdd?: () => void;
  onDelete?: () => void;
  onLastItemInPage?: () => void;
  onDuplicate?: () => void;
  ExtraButton?: JSX.Element;
  actionButton?: IButtonHeader;
  dataLength?: number;
  excelOptions?: IExcelOptions;
  selectedItems?: number;
  customSelectedItems?: boolean;
}
interface IButtonHeader {
  filter?: boolean;
  add?: boolean;
  deletes?: boolean;
  export?: boolean;
  duplicate?: boolean;
}

interface IBreadcrumb {
  create: string;
  edit: string;
}

const StyleBreadcrumb = styled.div`
  .text {
    color: #5c7cff;
  }
`;

const StyleBadge = styled.div`
  .ant-badge-count {
    top: 10px;
  }
`;

const PageHeaderWrapper = styled.div``;

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  button,
  pathTitle,
  className,
  action = false,
  breadcrumb = false,
  showTitle = true,
  onButtonAdd,
  onDelete,
  onLastItemInPage,
  onDuplicate,
  ExtraButton,
  dataLength,
  selectedItems = 0,
  customSelectedItems,
  actionButton = {
    filter: true,
    add: true,
    deletes: true,
    export: true,
    duplicate: false,
  },
  excelOptions,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id, propertyId } = useParams();
  const { actionMenu } = useAppActionRole();
  const {
    toggleCollapseFilter,
    onMultiRecordDelete,
    selectRowsKey,
    onMultiRecordDuplicate,
  } = useAppPageContainer();
  const { confirmDelete, confirmDuplicate } = useAppPopup();
  const options = typeof action === "boolean" ? {} : action;

  const onAdd = () => {
    if (onButtonAdd) {
      return onButtonAdd();
    }
    if (button?.to) {
      return navigate(button.to);
    }
    if (propertyId) {
      return navigate(`/property/${propertyId}/${pathTitle}/create`);
    }
    return navigate(`/${pathTitle}/create`);
  };

  const breadcrumbText = () => {
    if (!breadcrumb) return "";

    if (typeof breadcrumb === "boolean") {
      if ((id && propertyId) || (id && !propertyId)) {
        return t("edit") + t(title, { ns: "menu" });
      }
      return t("add") + t(title, { ns: "menu" });
    }

    if (propertyId && pathname.includes(`/property/${propertyId}`)) {
      const splitPath = pathname.split("/");
      if (splitPath.length === 3) {
        return t(breadcrumb.edit, { ns: "menu" });
      }
    }

    if (!id) {
      return t(breadcrumb.create, { ns: "menu" });
    }
    return t(breadcrumb.edit, { ns: "menu" });
  };

  const _onDelete = async () => {
    const ids = (selectRowsKey || []) as number[];
    const confirm = await confirmDelete();
    if (!confirm) return;
    if (onDelete) return onDelete();
    onMultiRecordDelete(ids, onLastItemInPage, dataLength);
  };

  const _onDuplicate = async () => {
    const ids = (selectRowsKey || []) as string[];
    const confirm = await confirmDuplicate();
    if (!confirm) return;
    if (onDelete) return onDelete();
    onMultiRecordDuplicate(ids);
  };

  const text = breadcrumbText();
  const deletes =
    actionButton.deletes === undefined ? false : !actionButton.deletes;
  const filter =
    actionButton.filter === undefined ? false : !actionButton.filter;
  const add = actionButton.add === undefined ? false : !actionButton.add;
  const exportExp =
    actionButton.export === undefined ? false : !actionButton.export;
  const duplicate =
    actionButton.duplicate === undefined ? true : !actionButton.duplicate;

  return (
    <PageHeaderWrapper className={className} style={{ overflowX: "hidden" }}>
      <Row gutter={12} style={{ height: 40 }} align="middle">
        <Col>
          <Typography.Title level={4}>
            {showTitle ? t(title, { ns: "menu" }) : null}
          </Typography.Title>
        </Col>
        <Col className="ml-auto"></Col>
        <Col className="ml-auto" hidden={duplicate || !actionMenu?.action}>
          <CButton
            color="blue"
            sizeFor="icon"
            onClick={_onDuplicate}
            icon={<CopyFilled />}
          />
        </Col>
        <Col hidden={deletes || !actionMenu?.action}>
          <StyleBadge>
            <Badge
              count={
                customSelectedItems ? selectedItems : selectRowsKey?.length
              }
              overflowCount={99}
            >
              <CButton
                color="light-orange"
                sizeFor="icon"
                onClick={_onDelete}
                icon={<DeleteFilled />}
              />
            </Badge>
          </StyleBadge>
        </Col>
        <Col hidden={exportExp}>
          <PageExportAction excelOptions={excelOptions} />
        </Col>
        <Col hidden={filter}>
          <CButton
            onClick={toggleCollapseFilter}
            color="gray"
            sizeFor="icon"
            icon={<SearchIcon width={40} height={40} />}
            className="!border-hidden"
          />
        </Col>
        {ExtraButton ? ExtraButton : null}
        <Col xs={4} md={3} hidden={add || !actionMenu?.action}>
          <CButton fullWidth onClick={onAdd}>
            {button?.label || t("add")}
          </CButton>
        </Col>
        {action ? <PageAction {...options} /> : null}
      </Row>
      <StyleBreadcrumb hidden={!breadcrumb}>
        <span className="title">{t(title, { ns: "menu" })}</span>
        <span className="text">
          {" / "}
          {text}
        </span>
      </StyleBreadcrumb>
    </PageHeaderWrapper>
  );
};
