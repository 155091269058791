import { useTranslation } from "react-i18next";
import { CRadio } from ".";
import { CRadioGroupProps } from "./radio-group";
import React from "react";

export const CRadioNationality: React.FC<CRadioGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const options = [
    { label: t("thai-nationality"), value: true },
    { label: t("foreign"), value: false },
  ];
  return <CRadio.Group {...props} options={options} />;
};
