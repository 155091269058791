import { Switch, SwitchProps } from "antd";
import React from "react";
import styled from "styled-components";

const StyleSwitch = styled(Switch)`
  :where(.css-dev-only-do-not-override-sxw3l).ant-switch.ant-switch-checked:hover:not(
      .ant-switch-disabled
    ) {
    background: #276347;
  }
  :where(.css-dev-only-do-not-override-sxw3l).ant-switch.ant-switch-checked {
    background: #276347;
  }
`;

export interface CSwitchProps extends SwitchProps {
  //
}

export const CSwitch: React.FC<CSwitchProps> = ({ ...props }) => {
  return <StyleSwitch {...props} />;
};
