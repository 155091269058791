import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ITagSign, ITagSignParams } from "./interface";

export const usePropertyGetTagSigns = (
  params?: ITagSignParams
): UseQueryResult<IResponse<ITagSign[]>, Error> => {
  return useQuery(
    ["tag-sign", "all", params],
    async () => {
      const res = await axiosProperty.get("/tag-sign", { params });
      if (res.status === 200) {
        return res.data;
      }

      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyGetTagSign = (
  id?: string | number
): UseQueryResult<ITagSign, Error> => {
  return useQuery(
    ["tag-sign", "id", id],
    async () => {
      const res = await axiosProperty.get(`/tag-sign/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }

      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePropertyGetTagSignsScroll = (
  params?: ITagSignParams
): UseInfiniteQueryResult<IResponse<ITagSign[]>, Error> => {
  return useInfiniteQuery(
    ["tag-sign", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/tag-sign", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyPostTagSign = (): UseMutationResult => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post("/tag-sign", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyPatchTagSign = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/tag-sign/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyDeleteTagSign = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/tag-sign/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
