import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IDashboardGraph, IDashboardParams } from "../../interface";
import { axiosProperty, throwResponse } from "config/axios";

export const useGetPropertyDashboardResident = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-property-resident", "count-increased-per-month", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/residents/count-increased-per-month",
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
