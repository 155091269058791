import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Col, FormInstance } from "antd";
import { CButton } from "components/button/c-button";
import { fireNotification } from "components/popup/notification";
import { useAppActionRole } from "providers/action-role";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MenuPath } from "routes/interface";

type FunctionType = (v?: any) => any;

export interface IActionProps {
  form?: FormInstance;
  mutate?: {
    mutation?: UseMutationResult;
    invalidateQueries?: string[];
    onSave?: FunctionType | boolean;
    onSaveAndContinue?: FunctionType | boolean;
    onCancel?: FunctionType | boolean | MenuPath;
    onBeforeUpdate?: FunctionType;
    validates?: boolean;
  };
  loading?: {
    save?: boolean;
    saveAndContinue?: boolean;
    cancel?: boolean;
  };
  saveLabel?: string | boolean;
}

const PageAction: FC<IActionProps> = ({ form, mutate, loading, saveLabel }) => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { actionMenu } = useAppActionRole();

  const {
    mutation,
    invalidateQueries = [],
    onSave,
    onCancel,
    onSaveAndContinue,
    onBeforeUpdate,
    validates = true,
  } = mutate || {};

  const _onSave = async () => {
    if (validates) {
      await form?.validateFields();
    }
    let values = await form?.getFieldsValue();

    if (typeof onSave === "function") {
      return onSave(values);
    }

    if (onBeforeUpdate) {
      values = await onBeforeUpdate({ ...values });
    }

    mutation?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
        _onCanCel();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
        });
      },
    });
  };

  const _onSaveAndContinue = async () => {
    if (validates) {
      await form?.validateFields();
    }
    let values = await form?.getFieldsValue();

    if (typeof onSaveAndContinue === "function") {
      return onSaveAndContinue(values);
    }

    if (onBeforeUpdate) {
      values = await onBeforeUpdate({ ...values });
    }

    mutation?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
        form?.resetFields();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
        });
      },
    });
  };

  const _onCanCel = () => {
    if (typeof onCancel === "function") {
      return onCancel();
    }

    if (typeof onCancel === "boolean") return;

    if (propertyId && onCancel !== "property") {
      return navigate(`/property/${propertyId}/${onCancel}`);
    }

    return navigate(`/${onCancel}`);
  };

  return (
    <React.Fragment>
      <Col xs={4} md={3} hidden={onCancel === false}>
        <CButton
          color="gray"
          fullWidth
          onClick={_onCanCel}
          loading={loading?.cancel}
        >
          {t("cancel")}
        </CButton>
      </Col>
      <Col
        xs={4}
        md={3}
        hidden={onSaveAndContinue === false || !actionMenu?.action}
      >
        <CButton
          color="orange"
          fullWidth
          onClick={_onSaveAndContinue}
          loading={loading?.saveAndContinue}
        >
          {t("save-and-continue")}
        </CButton>
      </Col>
      <Col xs={4} md={3} hidden={onSave === false || !actionMenu?.action}>
        <CButton fullWidth onClick={_onSave} loading={loading?.save}>
          {saveLabel ? saveLabel : t("save")}
        </CButton>
      </Col>
    </React.Fragment>
  );
};

export default PageAction;
