import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosCentral } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IPropertyCategory, IPropertyCategoryParams } from "./interface";

export const usePropertyPostEstateCategory = (): UseMutationResult => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post("/property-category", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyPatchEstateCategory = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(`/property-category/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyScrollGetEstateCategorys = (
  params?: IPropertyCategoryParams
): UseInfiniteQueryResult<IResponse<IPropertyCategory[]>, Error> => {
  return useInfiniteQuery(
    ["property-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/property-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};


export const usePropertyGetEstateCategory = (
  id?: number | string
): UseQueryResult<IPropertyCategory, Error> => {
  return useQuery(
    ["property-category", "id", id],
    async () => {
      const res = await axiosCentral.get(`/property-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePropertyGetEstateCategorys = (
  params?: IPropertyCategoryParams
): UseQueryResult<IResponse<IPropertyCategory[]>, Error> => {
  return useQuery(
    ["property-category", "all", params],
    async () => {
      const res = await axiosCentral.get("/property-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyDeleteEstateCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/property-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

