import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_th from "./common/th.json";
import common_en from "./common/en.json";

import menu_th from "./menu/th.json";
import menu_en from "./menu/en.json";

import message_th from "./message/th.json";
import message_en from "./message/en.json";

import form_th from "./form/th.json";
import form_en from "./form/en.json";

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem("@lpp-language") || "th",
  debug: false,
  ns: ["translation", "menu", "message", "form"],
  resources: {
    en: {
      translation: common_en,
      menu: menu_en,
      message: message_en,
      form: form_en,
    },
    th: {
      translation: common_th,
      menu: menu_th,
      message: message_th,
      form: form_th,
    },
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
