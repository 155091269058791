import { UseMutationResult } from "@tanstack/react-query";
import { Col, Divider, Form, FormInstance, Modal, ModalProps, Row } from "antd";
import { fireNotification } from "components";
import { CButton } from "components/button/c-button";
import { CRangePicker } from "components/input/c-date";
import dayjs from "config/dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { downloadBlobFile } from "tools/file";

export interface CModalExportProps extends ModalProps {
  mutation?: UseMutationResult<Blob>;
  onCancel?: () => void;
  form?: FormInstance;
  top?: number;
  extraFilter?: React.ReactNode;
  params?: Record<string, any>;
}

const StyledModal = styled(Modal)`
  .anticon-close > svg {
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
  }
`;

export const CModalExport: FC<CModalExportProps> = ({
  title,
  mutation,
  footer = null,
  open,
  form,
  onCancel,
  closable = false,
  top = 200,
  extraFilter,
  params,
  ...props
}) => {
  const { t } = useTranslation();

  const onExport = (values: any) => {
    const startDate = dayjs(values.date[0]).startOf("day").format();
    const endDate = dayjs(values.date[1]).endOf("day").format();

    values = { ...values, startDate, endDate, ...params };
    delete values.date;

    mutation?.mutate(values, {
      onSuccess: (res) => {
        fireNotification({ type: "success" });
        downloadBlobFile(res, t(`${title}`, { ns: "menu" }));
        onCancel?.();
      },
      onError: () => fireNotification({ type: "error" }),
    });
  };

  return (
    <StyledModal
      style={{
        top: top,
      }}
      title={title}
      open={open}
      closable={!closable}
      onCancel={onCancel}
      footer={footer}
      {...props}
    >
      <Form form={form} onFinish={onExport} layout="vertical">
        <Divider />
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("period")}
              name="date"
              rules={[{ required: true }]}
            >
              <CRangePicker />
            </Form.Item>
            {extraFilter}
          </Col>
        </Row>
        <Divider />
        <Row justify="center" align="middle">
          <Col>
            <CButton color="outline-green" className="mr-5" onClick={onCancel}>
              {t("cancel")}
            </CButton>
          </Col>
          <Col>
            <CButton
              color="green"
              htmlType="submit"
              loading={mutation?.isLoading}
            >
              {t("export")}
            </CButton>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};
