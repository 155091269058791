import { FC, useEffect, useRef, useState } from "react";
import { numFormat } from "tools/string";
import { YAxis, CartesianGrid, Tooltip } from "recharts";
import { BarChart, Bar, XAxis, Cell, Label } from "recharts";

export interface IRenderBarChart {
  data: any[];
  dataKeyX: string;
  dataKeyY: string;
  colorsBar?: string[];
  color?: string;
  labelX?: string;
  labelY?: string;
  height?: number;
}

export const RenderBarChart: FC<IRenderBarChart> = ({
  data,
  dataKeyX,
  dataKeyY,
  colorsBar,
  color = "#80BC28",
  labelX,
  labelY,
  height = 300,
}) => {
  const ref = useRef<any>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    //get size page width
    if (ref?.current === null) return;
    setWidth(ref?.current?.offsetWidth);
    const getwidth = () => {
      setWidth(ref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  const tickFormatter = (value: string, index: number) => {
    const number = numFormat(Number(value));
    if (!number) return "0";
    return number?.toString();
  };

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const textAnchor = "middle";

    let tickValue = payload.value;
    if (tickValue.length >= 10) {
      tickValue = tickValue.slice(0, 12) + "...";
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor={textAnchor}>
          {tickValue}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-5 rounded-app">
          <p className="label">{`${label} : ${payload[0].value.toLocaleString()}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div ref={ref} className=" overflow-auto !overflow-x-hidden">
      <BarChart
        width={width}
        height={height}
        data={data}
        barSize={50}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" vertical={false} />
        <XAxis
          dataKey={dataKeyX}
          label={
            labelX ? (
              <Label value={labelX} offset={0} position="insideBottom" />
            ) : undefined
          }
          interval={0}
          tickSize={0}
          tickMargin={10}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          tickFormatter={tickFormatter}
          domain={[0, "dataMax"]}
          label={
            labelY
              ? { value: labelY, angle: -90, position: "center" }
              : undefined
          }
        />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey={dataKeyY} fill="#80BC28" barSize={50}>
          {data.map((entry, index) => {
            const fill = colorsBar ? colorsBar[index % 20] : color;
            return <Cell key={`cell-${index}`} fill={fill} />;
          })}
        </Bar>
      </BarChart>
    </div>
  );
};
