import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { axiosProperty, throwResponse } from "config/axios";
import {
  IPropertyPhoneCategory,
  IPropertyPhoneCategoryParams,
} from "./interface";
import { IRoleParams } from "service/role/interface";

export const useScrollGetPropertyPhoneCategories = (
  params?: IRoleParams
): UseInfiniteQueryResult<IResponse<IPropertyPhoneCategory[]>, Error> => {
  return useInfiniteQuery(
    ["property-phone-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/phone-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetPropertyPhoneCategories = (
  params?: IPropertyPhoneCategoryParams
): UseQueryResult<IResponse<IPropertyPhoneCategory[]>, Error> => {
  return useQuery(
    ["property-phone-category", "all", params],
    async () => {
      const res = await axiosProperty.get("/phone-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPropertyPhoneCategory = (
  id?: number | string
): UseQueryResult<IPropertyPhoneCategory, Error> => {
  return useQuery(
    ["property-phone-category", "id", id],
    async () => {
      const res = await axiosProperty.get(`/phone-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostPropertyPhoneCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/phone-category`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchPropertyPhoneCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/phone-category/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyPhoneCategory = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/phone-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyPhoneCategoryBulk = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/phone-category/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExcelImportPropertyPhoneCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post(
      "/import-excel/phone-category/",
      params,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyPhoneCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post(
      "/export-excel/template-phone-category/",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
