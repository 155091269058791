export interface ICardStatus {
  type: "overdue" | "early-warning" | "on-due";
  text?: number;
  loading: boolean;
}

export interface colorTheme {
  color: string;
  bg: string;
}

export const theme: {
  [K in "overdue" | "early-warning" | "on-due"]: colorTheme;
} = {
  overdue: {
    color: "#F52A53",
    bg: "#FEEAEE",
  },
  "early-warning": { color: "#F8931D", bg: "#FFF4E8" },
  "on-due": {
    color: "#21AA00",
    bg: "#E9F7E6",
  },
};
