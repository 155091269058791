import { Form, Typography } from "antd";
import { HeaderOverview } from "components";
import { WrapperBG } from "pages/overview";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import { useGetPropertyDashboardFacility } from "service/dashboard/property/facility";
import { ErrorDashboard, LoadingDashboard, NoDataDashboard } from "../..";

const FacilityUsed = () => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();
  const { findDate } = useOverview();

  const mostUsedFacilitiesDate = Form.useWatch("mostUsedFacilitiesDate");
  const date = findDate(mostUsedFacilitiesDate);

  const { data, isFetching, isError, error } = useGetPropertyDashboardFacility({
    ...date,
  });

  const name = data
    ? translationObject({ object: data[0], keys: ["name"] }).name
    : "";

  return (
    <WrapperBG className="h-full">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("the-most-used-facilities")}
          name="mostUsedFacilitiesDate"
        />

        <div className="p-5 h-[250px] center flex-col text-center">
          {isError ? (
            <ErrorDashboard error={error} />
          ) : !data || data.length <= 0 ? (
            <NoDataDashboard />
          ) : (
            <Typography.Title
              className="!text-[#44955B] w-full"
              ellipsis={{ tooltip: true }}
            >
              {name}
            </Typography.Title>
          )}
        </div>
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default FacilityUsed;
