import { axiosProperty, throwResponse } from "config/axios";
import {
  IPropertyTaskCategory,
  IPropertyTaskCategoryParams,
} from "./interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { IResponseWork } from "config/axios/interface";

export const useGetPropertyTaskCategorys = (
  params?: IPropertyTaskCategoryParams
): UseQueryResult<IResponseWork<IPropertyTaskCategory[]>, Error> => {
  return useQuery(
    ["property-task-category", "all", params],
    async () => {
      const res = await axiosProperty.get("/tasks-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetPropertyTaskCategory = (
  params?: IPropertyTaskCategoryParams
): UseInfiniteQueryResult<IResponseWork<IPropertyTaskCategory[]>, Error> => {
  return useInfiniteQuery(
    ["property-task-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/tasks-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.mainId || params?.enabled }
  );
};

export const useGetPropertyTaskCategory = (
  params?: IPropertyTaskCategoryParams
): UseQueryResult<IPropertyTaskCategory, Error> => {
  return useQuery(
    ["task-category", "id", params],
    async () => {
      const { id, type } = params || {};
      const res = await axiosProperty.get(`/tasks-category/${id}?type=${type}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const usePostPropertyTaskCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post("/tasks-category", data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const usePatchPropertyTaskCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/tasks-category/${id}`, data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyTaskCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.delete(`/tasks-category/${id}`, {
      data: { ...data },
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyTaskCategoryBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/tasks-category/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelPropertyTaskCategoryMain =
  (): UseMutationResult<Blob> => {
    return useMutation(async (data) => {
      const res = await axiosProperty.post(
        "/export-excel/task-main-category",
        data,
        {
          responseType: "blob",
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };

export const useExportExcelPropertyTaskCategorySub =
  (): UseMutationResult<Blob> => {
    return useMutation(async (data) => {
      const res = await axiosProperty.post(
        "/export-excel/task-sub-category",
        data,
        {
          responseType: "blob",
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };
