import React, { FC } from "react";
import { IMenuItem } from ".";
import { Col, Row, Space, Typography } from "antd";
import {
  IConRate,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
} from "assets/images/icon/survey";
import styled from "styled-components";
import tw from "twin.macro";

export interface IItemSurvey {
  item: IMenuItem;
}

const customIconsArr: {
  color: string;
  Icon: (props?: IConRate) => JSX.Element;
}[] = [
  { color: "#FF0019", Icon: Icon1 },
  { color: "#FF8109", Icon: Icon2 },
  { color: "#FFC600", Icon: Icon3 },
  { color: "#93DB2A", Icon: Icon4 },
  { color: "#3BB0DB", Icon: Icon5 },
];

const EmoticonStyle = styled.div<{ color: string; $active?: boolean }>`
  svg {
    width: ${({ $active }) => ($active ? "40px" : "30px")};
    height: ${({ $active }) => ($active ? "40px" : "30px")};
  }
`;

const IconMenu = styled.div`
  svg * {
    ${tw`fill-primary`};
  }
`;

const WrapperItem = styled.div`
  ${tw`bg-white rounded-app p-5 w-full h-[200px]`}
`;

const NumberBox = styled.div<{ color?: string }>`
  ${tw`rounded-full w-[30px] h-[30px]`}
  background-color:${({ color }) => color};
  color: white;
`;

const ItemSurvey: FC<IItemSurvey> = ({ item }) => {
  const { icon, title } = item;
  const Icon = icon as React.ComponentType<React.SVGProps<SVGSVGElement>>;

  let activeColor = "";
  switch (item.score) {
    case 1:
      activeColor = "#FF0019";
      break;
    case 2:
      activeColor = "#FF8109";
      break;
    case 3:
      activeColor = "#FFC600";
      break;
    case 4:
      activeColor = "#93DB2A";
      break;
    case 5:
      activeColor = "#3BB0DB";
      break;
  }

  return (
    <WrapperItem>
      <Row>
        <Col span={2}>
          <IconMenu>
            <Icon height={18} width={18} />
          </IconMenu>
        </Col>
        <Col span={22}>
          <Typography.Text ellipsis={{ tooltip: title }}>
            {title}
          </Typography.Text>
        </Col>
      </Row>
      <div className="center flex-col h-full">
        <Space direction="horizontal" size="middle">
          {customIconsArr.map((item, index) => {
            const { Icon } = item;
            return (
              <EmoticonStyle
                color={item.color}
                key={index}
                $active={activeColor === item.color}
              >
                <Icon
                  fillActive={
                    activeColor === item.color ? item.color : undefined
                  }
                />
              </EmoticonStyle>
            );
          })}
        </Space>
        <br />
        <Space
          direction="horizontal"
          size="middle"
          className="bg-[#ECECEC] rounded-full "
        >
          {customIconsArr.map((item, index) => {
            return (
              <NumberBox
                key={index}
                color={activeColor === item.color ? item.color : "#C8C8C8"}
                className="center"
              >
                {index + 1}
              </NumberBox>
            );
          })}
        </Space>
      </div>
    </WrapperItem>
  );
};

export default ItemSurvey;
