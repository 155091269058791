import { Dayjs } from "dayjs";

export type AppLanguageTypes = "en" | "th";
export type DateFormatType =
  | "normal"
  | "full-month"
  | "normal-time"
  | "full-month-time"
  | "time"
  | "full-month-full-time"
  | "nomal-full-time"
  | "full-month-full-time"
  | "full-time"
  | "month-year"
  | "year";
export const DATE_FORMATS: {
  [K in DateFormatType]: { en: string; th: string };
} = {
  normal: {
    en: "DD/MMM/YYYY",
    th: "DD/MMM/BBBB",
  },
  "full-month": {
    en: "DD/MMMM/YYYY",
    th: "DD/MMMM/BBBB",
  },
  "normal-time": {
    en: "DD/MMM/YYYY HH:mm",
    th: "DD/MMM/BBBB HH:mm",
  },
  "full-month-time": {
    en: "DD/MMMM/YYYY HH:mm",
    th: "DD/MMMM/BBBB HH:mm",
  },
  time: {
    en: "HH:mm",
    th: "HH:mm",
  },
  "full-month-full-time": {
    en: "DD/MMMM/YYYY HH:mm:ss",
    th: "DD/MMMM/BBBB HH:mm:ss",
  },
  "nomal-full-time": {
    en: "DD/MMM/YYYY HH:mm:ss",
    th: "DD/MMM/BBBB HH:mm:ss",
  },
  "full-time": {
    en: "HH:mm:ss",
    th: "HH:mm:ss",
  },
  "month-year": {
    en: "MMMM/YYYY",
    th: "MMMM/BBBB",
  },
  year: {
    en: "YYYY",
    th: "BBBB",
  },
};

export interface IAppAntdConfigContext {
  lang: AppLanguageTypes;
  changeLanguage: (v: AppLanguageTypes) => void;
  translationObject: (v: { object: any; keys: string[] }) => Record<string, any>;
  formatDate: (v: {
    date?: Dayjs | string;
    format?: DateFormatType;
    keySeparator?: string;
  }) => string;
}
