import { CSelectPagination } from "./pagination";
import { useScrollGetPhoneCategories } from "service/phone-category";

export const CSelectPhoneCategory = ({ ...props }) => {
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetPhoneCategories}
      valueKey='id'
      labelKey='name'
      searchKey='name'
      translation
      queryParams={{
        isActive: true,
      }}
    />
  );
};
