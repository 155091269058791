import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetParcelSettingStorageZones } from "service/parcel-setting-storage-zone";
import { CSelectPagination } from "./pagination";
import { CSelectProps } from ".";

export interface ISelectParcelStorageZone extends CSelectProps {
  queryParams?: Record<string, any>;
}

export const CSelectParcelStorageZone: React.FC<ISelectParcelStorageZone> = ({
  queryParams,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={t("parcel-storage-zone")}
      useQuery={useScrollGetParcelSettingStorageZones}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      translation
      queryParams={{ ...queryParams, isActive: true }}
    />
  );
};
