import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosCentral } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IRole, IRoleParams } from "./interface";

export const useGetRoles = (
  params?: IRoleParams
): UseQueryResult<IResponse<IRole[]>, Error> => {
  return useQuery(
    ["role", "all", params],
    async () => {
      const res = await axiosCentral.get("/role", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useScrollGetRoles = (
  params?: IRoleParams
): UseInfiniteQueryResult<IResponse<IRole[]>, Error> => {
  return useInfiniteQuery(
    ["role", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/role", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useScrollGetRolesPermission = (
  params?: IRoleParams
): UseInfiniteQueryResult<IResponse<IRole[]>, Error> => {
  return useInfiniteQuery(
    ["role", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/role/without-permission", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetRole = (
  id?: number | string
): UseQueryResult<IRole, Error> => {
  return useQuery(
    ["role", "id", id],
    async () => {
      const res = await axiosCentral.get(`/role/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostRole = (): UseMutationResult => {
  return useMutation(async ({ roleMenus, ...data }: any) => {
    const res = await axiosCentral.post("/role", data);
    if (res.status !== 200) {
      throwResponse(res);
    }
    const id = res.data.data.id;
    const rRole = await axiosCentral.patch(
      `/role/update-status/${id}`,
      roleMenus
    );
    if (rRole.status === 200) {
      return;
    }
    throwResponse(rRole);
  });
};

export const usePatchRole = (): UseMutationResult => {
  return useMutation(async ({ id, roleMenus, ...data }: any) => {
    const rRole = await axiosCentral.patch(
      `/role/update-status/${id}`,
      roleMenus
    );
    if (rRole.status !== 200) {
      throwResponse(rRole);
    }
    const res = await axiosCentral.patch(`/role/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteRole = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/role/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
