import { Form } from "antd";
import { TopBox } from "components";
import { LoadingDashboard } from "../..";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { useGetPropertyDashboardNewsCountMostReader } from "service/dashboard/property/news-and-announcement";

const MostReaderNews = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const listNewsDate = Form.useWatch("listNewsDate");
  const date = findDate(listNewsDate);

  const query = useGetPropertyDashboardNewsCountMostReader({ ...date });

  return (
    <LoadingDashboard loading={query.isFetching}>
      <TopBox
        query={query}
        nameDate="listNewsDate"
        title={t("top-5-most-read-news")}
        translation
        keyTitle="title"
        height={330}
      />
    </LoadingDashboard>
  );
};

export default MostReaderNews;
