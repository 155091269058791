import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectComplaintCategory: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      valueOption={{
        values: [
          { label: t("complaint"), value: "APPEAL" },
          { label: t("feedback"), value: "FEEDBACK" },
          { label: t("suggest"), value: "SUGGEST" },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
