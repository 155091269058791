import { Form } from "antd";
import { TopBox } from "components";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { useScrollGetPropertyDashboardParcelUnit } from "service/dashboard/property/parcel";
import { LoadingDashboard } from "../..";

const UnitParcel = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const unitParcelDate = Form.useWatch("unitParcelDate");
  const date = findDate(unitParcelDate);

  const mutate = useScrollGetPropertyDashboardParcelUnit({ ...date });

  return (
    <LoadingDashboard loading={mutate.isFetching}>
      <TopBox
        mutate={mutate}
        keyTitle="unitNumber"
        nameDate="unitParcelDate"
        classNameWrapper="h-[300px]"
        title={t("number-of-unit-with-30-parcels")}
      />
    </LoadingDashboard>
  );
};

export default UnitParcel;
