import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { CIndicator } from "components";
import { IInfiniteScroll, IResponse } from "config/axios/interface";
import { useDebounce } from "hooks/useDebounc";
import React, { FC } from "react";
import { useState } from "react";
import { CSelectProps } from ".";
import { CSelect } from "./c-select";

export interface CSelectPaginationProps
  extends Omit<CSelectProps, "valueOptions"> {
  useQuery: (params?: any) => UseInfiniteQueryResult<IResponse<any[]>>;
  labelKey: string | "first-last-name";
  valueKey: string;
  searchKey?: string;
  onSelectCallback?: (v: any) => void;
  translation?: boolean;
  queryParams?: Record<string, any>;
  notValueKey?: boolean;
  overview?: boolean;
  dataDisabled?: { key: string; data: any[] };
}

export const CSelectPagination: FC<CSelectPaginationProps> = ({
  useQuery,
  labelKey,
  valueKey,
  onChange,
  onSelectCallback,
  searchKey,
  translation,
  queryParams,
  notValueKey = false,
  dataDisabled,
  ...props
}) => {
  const [search, setSearch] = useState<string>();
  const dSearch = useDebounce(search, 500);
  const mutation = useQuery(
    searchKey ? { [searchKey]: dSearch, ...queryParams } : queryParams
  );

  const entireData = (): IInfiniteScroll<any> => {
    const { pages } = mutation.data || {};
    if (!pages?.[0])
      return { data: [], hasMore: false, page: 1, undefinded: true };

    const idx = (pages?.length || 0) - 1;
    const page = pages?.[idx]?.page || 1;
    const pageCount = pages?.[idx]?.pageCount || 0;
    const data =
      pages
        ?.map((item) => {
          return item?.data;
        })
        .flat() || [];

    const dataFilter = data.filter((e) => {
      const find = dataDisabled?.data?.some((c: any) => {
        return e[dataDisabled?.key] === c[dataDisabled?.key];
      });
      return !find;
    });

    return {
      data: !!dataDisabled ? dataFilter : data,
      hasMore: pageCount > page,
      page: page,
    };
  };

  const { page, hasMore, data } = entireData();

  const fetchNext = () => {
    mutation.fetchNextPage({ pageParam: { page: page + 1 } });
  };
  const fetching = mutation.isFetchingNextPage || mutation.isFetching;

  const _onChange = (e: any, i: any) => {
    onChange?.(e, i);
    const find = data.find((c: any) => c.id === e);
    onSelectCallback?.(find);
  };

  const onSearch = (e: string) => {
    if (!searchKey) return;
    setSearch(e);
  };

  return (
    <CSelect
      {...props}
      valueOption={{
        values: data,
        valueKey,
        labelKey,
        translation,
        notValueKey,
      }}
      dropdownRender={(menu) => {
        const { isFetching, isFetchingNextPage } = mutation;
        const isFetchingSearch = isFetching && !isFetchingNextPage;

        return (
          <React.Fragment>
            <div hidden={isFetchingSearch}>
              {menu}
              <div
                hidden={!isFetchingNextPage}
                style={{ transition: "0.3s" }}
                className="relative bg-white"
              >
                <CIndicator
                  style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                  height={30}
                  size={25}
                />
              </div>
            </div>
          </React.Fragment>
        );
      }}
      loading={mutation.isFetching}
      onPopupScroll={(e) => {
        const { target } = e as any;
        const scrolled = target.scrollTop + target.offsetHeight;
        const reach = scrolled >= target.scrollHeight - 10;
        if (reach && hasMore && !fetching) {
          fetchNext();
        }
      }}
      onChange={_onChange}
      onSearch={searchKey ? onSearch : undefined}
      showSearch={!!searchKey}
      filterOption={() => true}
    />
  );
};
