import { Row, Col } from "antd";
import { CButton, CButtonProps } from "components";
import { useAppActionRole } from "providers/action-role";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface CModalHeaderProps {
  submit?: CButtonProps;
  cancel?: CButtonProps;
}

const CModalHeader: FC<CModalHeaderProps> = ({ submit, cancel }) => {
  const { t } = useTranslation();
  const { actionMenu } = useAppActionRole();
  const disabled = !actionMenu?.action;
  return (
    <Row gutter={12} justify="end" className="py-1">
      <Col span={12}>
        <CButton
          style={{ height: 40 }}
          className="w-full"
          color="gray"
          {...cancel}
        >
          {cancel?.children || t("cancel")}
        </CButton>
      </Col>
      <Col span={12} hidden={disabled}>
        <CButton
          style={{ height: 40 }}
          className="w-full"
          color="green"
          {...submit}
        >
          {submit?.children || t("save")}
        </CButton>
      </Col>
    </Row>
  );
};

export default CModalHeader;
