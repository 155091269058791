import { Col, Row } from "antd";
import React from "react";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import DailyParcelVolume from "./content/daily-parcel-volume";
import AnalyzeTrendsParcels from "./content/analyze-trends-parcels";
import ParcelStill from "./content/parcel-still";
import ParcelType from "./content/parcel-type";
import AllParcelsItem from "./content/all-parcel-item";
import AveragePieces from "./content/average-pieces";
import UnitParcel from "./content/unit-parcel";

const ParCel = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.parcel", { ns: "menu" })}</Col>
        <Col md={24} lg={24} xl={12}>
          <ParcelStill />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <ParcelType />
        </Col>
        <Col md={24} lg={24} xl={8}>
          <AllParcelsItem />
        </Col>
        <Col md={24} lg={24} xl={8}>
          <AveragePieces />
        </Col>
        <Col md={24} lg={24} xl={8}>
          <UnitParcel />
        </Col>
        <Col span={24}>
          <DailyParcelVolume />
        </Col>
        <Col span={24}>
          <AnalyzeTrendsParcels />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ParCel;
