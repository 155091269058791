export interface IConRate extends React.SVGProps<SVGSVGElement> {
  fillActive?: string;
}

export const Icon1 = (props?: IConRate) => {
  const { fillActive } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 32 \u2013 7"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={22}
      viewBox="0 0 38.77 38.77"
      {...props}
    >
      <path
        fill={fillActive ? fillActive : "#bfbfbf"}
        d="M30.142 38.766H8.628A8.628 8.628 0 0 1 0 30.142V8.628A8.628 8.628 0 0 1 8.628 0h21.514a8.628 8.628 0 0 1 8.628 8.628v21.514a8.628 8.628 0 0 1-8.628 8.628"
        data-name="Path 70718"
      />
      <path
        fill="#fff"
        d="M13.975 19.652c-.061 0-.123 0-.185-.007a2.5 2.5 0 0 1-2.277-1.2.713.713 0 1 1 1.218-.742 1.074 1.074 0 0 0 1.013.516.634.634 0 0 1 .138 0 .843.843 0 0 0 .844-.462.713.713 0 0 1 1.28.629 2.268 2.268 0 0 1-2.031 1.264"
        data-name="Path 70719"
      />
      <path
        fill="#fff"
        d="M24.779 19.652c-.061 0-.123 0-.184-.007a2.493 2.493 0 0 1-2.278-1.2.713.713 0 1 1 1.218-.742 1.079 1.079 0 0 0 1.013.516.634.634 0 0 1 .138 0 .841.841 0 0 0 .844-.462.713.713 0 0 1 1.28.63 2.268 2.268 0 0 1-2.031 1.264"
        data-name="Path 70720"
      />
      <path
        fill="#fff"
        d="M24.062 24.673a.714.714 0 0 1-.62-.359 4.888 4.888 0 0 0-4.337-2.014c-3.115 0-4.2 1.913-4.249 1.995a.713.713 0 0 1-1.258-.672c.059-.113 1.5-2.749 5.507-2.749a6.267 6.267 0 0 1 5.579 2.733.713.713 0 0 1-.621 1.063"
        data-name="Path 70721"
      />
      <path
        fill="#fff"
        d="M15.972 16.822a.71.71 0 0 1-.389-.116l-2.555-1.666a.715.715 0 0 1 .779-1.2l2.555 1.666a.713.713 0 0 1-.39 1.311"
        data-name="Path 70722"
      />
      <path
        fill="#fff"
        d="M22.139 16.822a.713.713 0 0 1-.39-1.311l2.831-1.846a.715.715 0 0 1 .779 1.2l-2.833 1.847a.709.709 0 0 1-.389.116"
        data-name="Path 70723"
      />
    </svg>
  );
};

export const Icon2 = (props?: IConRate) => {
  const { fillActive } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 33 \u2013 7"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={22}
      viewBox="0 0 38.77 38.77"
      {...props}
    >
      <path
        fill={fillActive ? fillActive : "#bfbfbf"}
        d="M30.145 38.766H8.628A8.628 8.628 0 0 1 0 30.138V8.628A8.628 8.628 0 0 1 8.628 0h21.516a8.628 8.628 0 0 1 8.628 8.628v21.514a8.628 8.628 0 0 1-8.628 8.628"
        data-name="Path 70724"
      />
      <path
        fill="#fff"
        d="M13.978 17.98c-.061 0-.123 0-.185-.008a2.493 2.493 0 0 1-2.276-1.2.713.713 0 0 1 1.219-.741 1.072 1.072 0 0 0 1.011.515.642.642 0 0 1 .138 0 .835.835 0 0 0 .844-.461.713.713 0 0 1 1.28.629 2.266 2.266 0 0 1-2.03 1.264"
        data-name="Path 70725"
      />
      <path
        fill="#fff"
        d="M24.782 17.98c-.061 0-.123 0-.185-.008a2.5 2.5 0 0 1-2.277-1.2.713.713 0 1 1 1.218-.742 1.069 1.069 0 0 0 1.012.516.648.648 0 0 1 .139 0 .832.832 0 0 0 .844-.461.713.713 0 0 1 1.28.629 2.268 2.268 0 0 1-2.03 1.264"
        data-name="Path 70726"
      />
      <path
        fill="#fff"
        d="M22.991 23.732a.712.712 0 0 1-.657-.436c-.034-.074-.7-1.483-3.062-1.483s-2.965 1.4-2.989 1.465a.716.716 0 0 1-.922.4.709.709 0 0 1-.41-.909c.036-.1.931-2.38 4.321-2.38 3.374 0 4.339 2.265 4.378 2.361a.714.714 0 0 1-.66.983"
        data-name="Path 70727"
      />
    </svg>
  );
};

export const Icon3 = (props?: IConRate) => {
  const { fillActive } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 34 \u2013 7"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={22}
      viewBox="0 0 38.77 38.77"
      {...props}
    >
      <path
        fill={fillActive ? fillActive : "#bfbfbf"}
        d="M30.142 38.766H8.628a8.628 8.628 0 0 1-8.624-8.628V8.628A8.628 8.628 0 0 1 8.628 0h21.513a8.628 8.628 0 0 1 8.628 8.628v21.514a8.628 8.628 0 0 1-8.628 8.628"
        data-name="Path 70710"
      />
      <path
        fill="#fff"
        d="M23.312 23.224h-6.99a.713.713 0 0 1 0-1.426h6.985a.713.713 0 1 1 0 1.426"
        data-name="Path 70711"
      />
      <path
        fill="#fff"
        d="M15.728 17.49a1.088 1.088 0 1 1-1.088-1.089 1.088 1.088 0 0 1 1.088 1.088"
        data-name="Path 70712"
      />
      <path
        fill="#fff"
        d="M25.602 17.49a1.088 1.088 0 1 1-1.088-1.089 1.088 1.088 0 0 1 1.088 1.088"
        data-name="Path 70713"
      />
    </svg>
  );
};

export const Icon4 = (props?: IConRate) => {
  const { fillActive } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 35 \u2013 7"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={22}
      viewBox="0 0 38.77 38.77"
      {...props}
    >
      <path
        fill={fillActive ? fillActive : "#bfbfbf"}
        d="M30.145 38.766H8.628A8.628 8.628 0 0 1 0 30.138V8.625A8.628 8.628 0 0 1 8.628 0h21.517a8.628 8.628 0 0 1 8.628 8.625v21.517a8.628 8.628 0 0 1-8.628 8.628"
        data-name="Path 70706"
      />
      <path
        fill="#fff"
        d="M16.026 17.826a.71.71 0 0 1-.526-.232l-.066-.065a1.467 1.467 0 0 0-2.072.071.713.713 0 0 1-1.042-.974 2.9 2.9 0 0 1 4.089-.138c.051.047.1.095.143.144a.713.713 0 0 1-.526 1.194"
        data-name="Path 70707"
      />
      <path
        fill="#fff"
        d="M26.072 17.826a.71.71 0 0 1-.521-.227l-.071-.071a1.467 1.467 0 0 0-2.072.071.713.713 0 0 1-1.042-.974 2.9 2.9 0 0 1 4.089-.138c.048.046.095.091.138.138a.713.713 0 0 1-.521 1.2"
        data-name="Path 70708"
      />
      <path
        fill="#fff"
        d="M19.407 23.378c-3.374 0-4.34-2.265-4.38-2.361a.713.713 0 0 1 1.317-.548c.034.075.7 1.483 3.063 1.483s2.964-1.4 2.989-1.465a.718.718 0 0 1 .923-.4.709.709 0 0 1 .41.909c-.037.1-.932 2.381-4.321 2.381"
        data-name="Path 70709"
      />
    </svg>
  );
};

export const Icon5 = (props?: IConRate) => {
  const { fillActive } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={22}
      viewBox="0 0 38.77 38.77"
      {...props}
    >
      <g data-name="Component 36 \u2013 7">
        <path
          fill={fillActive ? fillActive : "#bfbfbf"}
          d="M30.145 38.766H8.628A8.628 8.628 0 0 1 0 30.138V8.628A8.628 8.628 0 0 1 8.628 0h21.517a8.628 8.628 0 0 1 8.628 8.628v21.514a8.628 8.628 0 0 1-8.628 8.628"
          data-name="Path 70714"
        />
        <path
          fill="#fff"
          d="M16.025 15.687a.71.71 0 0 1-.526-.232l-.066-.065a1.467 1.467 0 0 0-2.072.071.713.713 0 0 1-1.042-.974 2.9 2.9 0 0 1 4.089-.138c.051.047.1.095.143.144a.713.713 0 0 1-.526 1.194"
          data-name="Path 70715"
        />
        <path
          fill="#fff"
          d="M26.07 15.687a.71.71 0 0 1-.526-.232l-.066-.065a1.467 1.467 0 0 0-2.072.071.713.713 0 0 1-1.042-.974 2.9 2.9 0 0 1 4.089-.138c.051.047.1.095.143.144a.713.713 0 0 1-.526 1.194"
          data-name="Path 70716"
        />
        <path
          fill="#fff"
          d="M19.455 23.822a5.776 5.776 0 0 0 5.719-4.961.713.713 0 0 0-.6-.809.666.666 0 0 0-.106-.008H14.443a.712.712 0 0 0-.715.71.656.656 0 0 0 .007.106 5.777 5.777 0 0 0 5.72 4.96"
          data-name="Path 70717"
        />
      </g>
    </svg>
  );
};
