import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  Wrapper,
  WrapperBG,
} from "..";
import { useTranslation } from "react-i18next";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import { useGetPropertyDashboardJuristic } from "service/dashboard/property/administrator";
import { useAppLanguage } from "providers/app-language";
import { IData } from "service/dashboard/interface";

const Juristic = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();
  const { formatDate } = useAppLanguage();

  const administratorsDate = Form.useWatch("administratorsDate");
  const date = findDate(administratorsDate, "year");

  const { data, isFetching, isError, error } = useGetPropertyDashboardJuristic({
    ...date,
  });

  if (isFetching) {
    return (
      <Wrapper>
        <LoadingDashboard loading={isFetching}>
          <div>{t("user-management", { ns: "menu" })}</div>
          <WrapperBG className="mt-2">
            <HeaderOverview
              title={t("administrators-added-to-system")}
              name="administratorsDate"
              formatDate="year"
            />
            <div className="h-[300px]"></div>
          </WrapperBG>
        </LoadingDashboard>
      </Wrapper>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
  };

  return (
    <Wrapper>
      <LoadingDashboard loading={isFetching}>
        <div>{t("user-management", { ns: "menu" })}</div>
        <WrapperBG className="mt-2">
          <HeaderOverview
            title={t("administrators-added-to-system")}
            name="administratorsDate"
            formatDate="year"
          />
          {isError ? (
            <ErrorDashboard error={error} height="300px" />
          ) : !data || data.length <= 0 ? (
            <NoDataDashboard height="300px" />
          ) : (
            <RenderLineChart {...dataLine} />
          )}
        </WrapperBG>
      </LoadingDashboard>
    </Wrapper>
  );
};

export default Juristic;
