import { DeleteFilled, EditFilled, PlusSquareFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CButton } from "components";
import React from "react";

export interface ActionButtonGroupProps {
  onAdd?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  hide?: {
    add?: boolean;
    edit?: boolean;
    del?: boolean;
  };
  disabled?: {
    add?: boolean;
    edit?: boolean;
    del?: boolean;
  };
  loading?: {
    delLoad?: boolean;
  };
}

export const ActionButtonGroup: React.FC<ActionButtonGroupProps> = ({
  onAdd,
  onEdit,
  onDelete,
  hide,
  loading,
  disabled,
}) => {
  const { add, edit, del } = hide || {};
  const { delLoad } = loading || {};
  return (
    <Row gutter={6}>
      <Col hidden={add}>
        <CButton
          disabled={disabled?.add}
          loading={delLoad}
          onClick={onAdd}
          style={{ height: 35, width: 70 }}
          icon={<PlusSquareFilled />}
        />
      </Col>
      <Col hidden={edit}>
        <CButton
          disabled={disabled?.edit}
          loading={delLoad}
          onClick={onEdit}
          style={{ height: 35, width: 40 }}
          icon={<EditFilled />}
          color="dark-green"
        />
      </Col>
      <Col hidden={del}>
        <CButton
          disabled={disabled?.del}
          loading={delLoad}
          onClick={onDelete}
          style={{ height: 35, width: 40 }}
          icon={<DeleteFilled />}
          color="orange"
        />
      </Col>
    </Row>
  );
};
