import { Form } from "antd";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useGetDashboardActivitiesTime } from "service/dashboard/central/activities";
import { IData } from "service/dashboard/interface";

const ActivitiesTime = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { findDate, property } = useOverview();

  const activitiesDate = Form.useWatch("activitiesDate");
  const date = findDate(activitiesDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardActivitiesTime({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("activities-times/year")}
            name="activitiesDate"
            formatDate="year"
          />
          <div className="h-[230px]"></div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    height: 230,
  };

  return (
    <WrapperBG className="h-full">
      <HeaderOverview
        title={t("activities-times/year")}
        name="activitiesDate"
        formatDate="year"
      />
      {isError ? (
        <ErrorDashboard error={error} height="230px" />
      ) : !data || data.length <= 0 ? (
        <NoDataDashboard height="230px" />
      ) : (
        <RenderLineChart {...dataLine} />
      )}
    </WrapperBG>
  );
};

export default ActivitiesTime;
