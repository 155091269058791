import { Form } from "antd";
import { HeaderOverview, IRenderBarChart, RenderBarChart } from "components";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboardFacilityType } from "service/dashboard/central/facility";
import { IData } from "service/dashboard/interface";

const colors = [
  "#7986CB",
  "#3F51B5",
  "#4285F4",
  "#33B679",
  "#0B8043",
  "#C0CA33",
  "#F6BF26",
  "#F09300",
  "#EF6C00",
  "#D50000",
  "#D81B60",
  "#E67C73",
  "#7986CB",
  "#3F51B5",
];

const FacilityType = () => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();
  const { findDate, property } = useOverview();

  const facilityTypeDate = Form.useWatch("facilityTypeDate");
  const date = findDate(facilityTypeDate);

  const { data, isFetching, isError, error } = useGetDashboardFacilityType({
    ...date,
    property,
  });

  const getData: IData[] =
    data?.map((e) => {
      const title = translationObject({ object: e, keys: ["name"] }).name;
      return { count: e.count, title };
    }) || [];

  const findNoCount = getData.some((e) => e.count > 0);

  const dataBar: IRenderBarChart = {
    data: getData,
    dataKeyX: "title",
    dataKeyY: "count",
    color: "#F6BF26",
    colorsBar: colors,
  };

  return (
    <WrapperBG className="h-[400px]">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("usage-statistics-by-facility-type")}
          name="facilityTypeDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="300px" />
        ) : !data || data.length <= 0 || !findNoCount ? (
          <NoDataDashboard height="300px" />
        ) : (
          <RenderBarChart {...dataBar} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default FacilityType;
