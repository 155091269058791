import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ButtonActionThemeTypes, BUTTON_ACTION_THEME as theme } from "./theme";

const StyleButtonAction = styled.div<{
  color: ButtonActionThemeTypes;
  $active?: boolean;
  $fullWidth: boolean;
  width?: string;
}>`
  ${tw`flex justify-center items-center rounded-app`};
  cursor: pointer;
  height: 45px;
  width: ${({ width }) => (width ? width : "100%")};
  ${({ $fullWidth }) => ($fullWidth ? tw`w-full` : "")};
  .ant-typography {
    color: ${({ color, $active }) =>
      $active ? theme[color].actionColor : theme[color].color} !important;
  }
  background-color: ${({ color, $active }) =>
    $active ? theme[color].actionBg : theme[color].bg} !important;
  border-color: ${({ color, $active }) =>
    $active ? theme[color].actionBorder : theme[color].border} !important;

  &:hover,
  &:active,
  &:focus {
    .ant-typography {
      color: ${({ color }) => theme[color].actionColor} !important;
    }
    background-color: ${({ color }) => theme[color].actionBg} !important;
    border-color: ${({ color }) => theme[color].actionBorder} !important;
  }
`;

export interface IActionProps {
  label: string;
  action: boolean;
  fullWidth?: boolean;
  color: ButtonActionThemeTypes;
  onClick: () => void;
  width?: string;
  className?: string;
}

export const CButtonAction: React.FC<IActionProps> = ({
  label = "",
  action = false,
  color = "orange",
  fullWidth = false,
  width = "100%",
  className = "",
  onClick,
  ...props
}) => {
  return (
    <StyleButtonAction
      {...props}
      $fullWidth={fullWidth}
      color={color}
      $active={action}
      width={width}
      className={className}
      onClick={onClick}
    >
      <Typography.Text ellipsis={{ tooltip: label }}>{label}</Typography.Text>
    </StyleButtonAction>
  );
};
