import { Row, Col, Typography, Popover } from "antd";
import { MenuTypes } from "menus/interface";
import { useAppActionRole } from "providers/action-role";
import { useActivitySidebar } from "providers/active-sidebar";
import { useNotification } from "providers/app-notification";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setMenuCode } from "service/auth";
import styled from "styled-components";
import tw from "twin.macro";

export interface SiderMenuItemProps {
  key: MenuTypes;
  title: MenuTypes;
  icon?: React.ComponentType;
  to: string;
  children?: Omit<SiderMenuItemProps, "icon" | "children">[];
  code: string;
  slug?: string;
}

const SiderMenuItemWrapper = styled.div<{ $active: boolean }>`
  ${tw`cursor-pointer transition-all pl-6`};
  svg * {
    ${({ $active }) => ($active ? tw`fill-primary` : "")};
  }
  .ant-typography {
    color: ${({ $active }) => ($active ? "black" : "#5b5b5b")};
    font-weight: ${({ $active }) => ($active ? "500" : "")};
  }
  background-color: ${({ $active }) => ($active ? "#E8F2EB" : "")};
  &:hover {
    .ant-typography {
      color: black;
      font-weight: 500;
    }
  }
`;

const NotificationItem = styled.div`
  width: 35px;
  border-radius: 20px;
  color: #fff;
  background-color: #f52a53;
`;

const SiderMenuItem: React.FC<{ item: SiderMenuItemProps }> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setMenu } = useAppActionRole();
  const { propertyId } = useParams<{ propertyId?: string }>();
  const { to, title, children, icon, ...rest } = item;

  const Icon = icon as React.ComponentType<React.SVGProps<SVGSVGElement>>;

  const { sidebarNoti, dispatchNoti } = useNotification();
  const count = sidebarNoti[rest.key];

  const { setActiveKey } = useActivitySidebar();

  const dispatchPage = () => {
    if (count && count.length > 0) {
      if (rest.key) {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: [rest.key],
        });
      }
    }
  };

  const onClick = () => {
    return dispatchPage();
  };

  const onNavigate = () => {
    setMenu(item);
    setMenuCode(item.code);
    setActiveKey?.(undefined);

    if (children) {
      return;
    }
    if (propertyId) {
      return navigate(to.replace(":propertyId", propertyId), { replace: true });
    }

    onClick();

    return navigate(to, { replace: true });
  };

  const getActive = () => {
    if (pathname.includes("/property/")) {
      if (!to.includes("/property/:propertyId/")) {
        const splitPath = pathname.split("/");
        const path = splitPath[1];
        const splitTo = to.split("/");
        const _to = splitTo[1];
        return _to === path;
      }

      const splitPath = pathname.split("/");
      const path = splitPath[3];

      const splitTo = to.split("/");
      const _to = splitTo[splitTo.length - 1];

      return _to === path;
    }

    if (pathname.includes(to) && !pathname.includes("/property/")) {
      const splitPath = pathname.split("/");
      const path = splitPath[1];
      const splitTo = to.split("/");
      const _to = splitTo[1];
      return _to === path;
    }

    return to === pathname;
  };

  const active = getActive();

  const menu = (
    <SiderMenuItemWrapper $active={active}>
      <Row onClick={onNavigate}>
        <Col span={4} className="center">
          <Icon height={18} width={18} />
        </Col>
        <Col span={15} className="py-4 flex items-center">
          <Typography.Text ellipsis={{ tooltip: t(title, { ns: "menu" }) }}>
            {t(title, { ns: "menu" })}
          </Typography.Text>
        </Col>
        {count && count.length > 0 && (
          <Col span={5} className="center">
            <NotificationItem className="center">
              {Number(count.length)}
            </NotificationItem>
          </Col>
        )}
      </Row>
    </SiderMenuItemWrapper>
  );

  if (children && children.length) {
    return (
      <Popover
        overlayClassName="menu-popover"
        trigger={["click"]}
        showArrow={false}
        placement="rightTop"
        overlayInnerStyle={{ width: 280 }}
        content={
          <React.Fragment>
            {children.map((children) => {
              const active = children.to === pathname;
              const count = sidebarNoti[children?.key];

              const onNavigateChildren = () => {
                setMenuCode(children.code);
                setMenu(children);
                setActiveKey?.(undefined);

                if (propertyId) {
                  return navigate(
                    children.to.replace(":propertyId", propertyId),
                    {
                      replace: true,
                    }
                  );
                }

                onClick();
                return navigate(children.to, { replace: true });
              };

              const onClick = () => {
                if (count && count.length > 0) {
                  if (children.key) {
                    dispatchNoti?.({
                      action: "clear",
                      type: "sidebar",
                      key: [children.key],
                    });
                  }
                }
              };

              return (
                <SiderMenuItemWrapper
                  key={children.key}
                  className="bg-gray"
                  $active={active}
                  // onClick={onClick}
                >
                  <Row
                    onClick={() => {
                      onNavigateChildren();
                      // navigate(item.to, { replace: true });
                    }}
                  >
                    <Col span={20} className="py-3 px-4">
                      <Typography.Text
                        ellipsis={{
                          tooltip: t(children.title, { ns: "menu" }),
                        }}
                      >
                        {t(children.title, { ns: "menu" })}
                      </Typography.Text>
                    </Col>
                    {count && count.length > 0 && (
                      <Col span={4} className="center">
                        <NotificationItem className="center">
                          {Number(count.length)}
                        </NotificationItem>
                      </Col>
                    )}
                  </Row>
                </SiderMenuItemWrapper>
              );
            })}
          </React.Fragment>
        }
      >
        {menu}
      </Popover>
    );
  }
  return menu;
};

export default SiderMenuItem;
