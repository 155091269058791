import React, { FC, ReactNode, createContext, useState } from "react";
import { IActiveSidebarContext } from "./interface";

const Context = createContext<IActiveSidebarContext>(
  {} as IActiveSidebarContext
);

const AppActivitySidebarProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeKey, setActiveKey] = useState<string>();

  return (
    <Context.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </Context.Provider>
  );
};

export const useActivitySidebar = () => React.useContext(Context);
export default AppActivitySidebarProvider;
