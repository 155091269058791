import { Col, Row, Typography } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

export interface ITextItem {
  title: string;
  count: number;
  colorDot?: string;
  text?: string;
  colorCount?: string;
}

const StyleText = styled(Row)<{ color: string }>`
  ${tw``}

  .title {
    .ant-typography {
      color: #6c6c6c;
    }
  }
  .count {
    color: ${({ color }) => color};
  }

  .text {
    color: #898989;
  }
`;

const DotStyle = styled.div<{ color?: string }>`
  ${tw`w-[15px] h-[15px] rounded-full`}
  background-color: ${({ color }) => color};
`;

export const TextItem: FC<ITextItem> = ({
  title,
  count,
  colorDot,
  text,
  colorCount = "#2061e7",
}) => {
  const { t } = useTranslation();
  return (
    <StyleText gutter={[6, 6]} color={colorCount}>
      <Col span={colorDot ? 3 : 0}>
        <DotStyle color={colorDot} />
      </Col>
      <Col span={colorDot ? 15 : 18} className="title">
        <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>
      </Col>
      <Col span={4} className="count text-end">
        {Number(count).toLocaleString()}
      </Col>
      <Col span={2} className="text text-end">
        {text ? text : t("times")}
      </Col>
    </StyleText>
  );
};
