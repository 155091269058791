import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";
import { PROPERTY_MENUS } from "menus/property";

export interface CSelectMenuProps extends CSelectProps {}

export const CSelectMenuProperty: FC<CSelectMenuProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const propertyMenu = PROPERTY_MENUS.map((e) => {
    const title = t(e.title, { ns: "menu" });
    return {
      code: e.code,
      title: title,
    };
  });

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("display-type")}
      valueOption={{
        values: propertyMenu,
        valueKey: "code",
        labelKey: "title",
      }}
    />
  );
};
