import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectVehicleSticker: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("sticker")}
      valueOption={{
        values: [
          { value: "OWN", title: t("have-sticker") },
          { value: "WITHOUT", title: t("no-sticker") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
