import { SearchOutlined } from "@ant-design/icons";
import { useDebounceWithCallback } from "hooks/useDebounc";
import { FC, useState } from "react";
import { CInputProps, StyledInput } from "./c-input";

export interface CInputDebounceProps extends Omit<CInputProps, "onChange"> {
  debounceDelay?: number;
  onChange?: (v: string) => void;
}

export const CInputDebounce: FC<CInputDebounceProps> = ({
  debounceDelay = 500,
  search,
  value,
  onChange,
  bgcolor = true,
  ...rest
}) => {
  const [internal, setInternal] = useState<string>();
  useDebounceWithCallback(internal, debounceDelay, onChange);

  return (
    <StyledInput
      suffix={
        search ? (
          <SearchOutlined style={{ fontSize: 20, color: "#F0F0F0" }} />
        ) : undefined
      }
      onChange={(e) => {
        setInternal(e.target.value);
      }}
      value={internal}
      $bgcolor={bgcolor ? 1 : 0}
      {...rest}
      autoComplete="off"
    />
  );
};
