import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosCentral } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ISettingSurvey, ISettingSurveyParams } from "./interface";

export const useScrollGetSettingSurveys = (
  params?: ISettingSurveyParams
): UseInfiniteQueryResult<IResponse<ISettingSurvey[]>, Error> => {
  return useInfiniteQuery(
    ["setting-surveys", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/satisfaction-survey-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetSettingSurveys = (
  params?: ISettingSurveyParams
): UseQueryResult<IResponse<ISettingSurvey[]>, Error> => {
  return useQuery(
    ["setting-surveys", "all", params],
    async () => {
      const res = await axiosCentral.get("/satisfaction-survey-category", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetSettingSurvey = (
  id?: number | string
): UseQueryResult<ISettingSurvey, Error> => {
  return useQuery(
    ["setting-surveys", "id", id],
    async () => {
      const res = await axiosCentral.get(`/satisfaction-survey-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostSettingSurvey = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post(`/satisfaction-survey-category`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchSettingSurvey = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(
      `/satisfaction-survey-category/${id}`,
      data
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteSettingSurvey = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(
      `/satisfaction-survey-category/${id}`
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteSettingSurveyBulk = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axiosCentral.delete(
      `/satisfaction-survey-category/bulk`,
      { data }
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
