import { FC } from "react";
import { CSelect, CSelectProps } from ".";
import { useTranslation } from "react-i18next";

export const CSelectApprove: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("status")}
      valueOption={{
        values: [
          { value: "PENDING", label: t("pending-approval") },
          { value: "APPROVE", label: t("approve") },
          { value: "DISAPPROVAL", label: t("disapproved") },
          { value: "CANCEL", label: t("cancel") },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
