import { fireNotification } from "components";
import { FC, useMemo, useRef } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postMediaObject } from "service/media-object";
import styled from "styled-components";

const Delta = Quill.import("delta");

const ReactQuillStyle = styled(ReactQuill)`
  .ql-toolbar.ql-snow {
    background-color: #fff;
    // border: 1px solid #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 10px;
    // margin-bottom: 3px;
  }
  .ql-editor {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
  }
  .ql-container.ql-snow {
    border-radius: 0px 0px 5px 5px;
    // border: 1px solid #fff;
  }
`;

export interface CReactQuillProps extends ReactQuillProps {
  value?: any;
  onChange?: () => void;
}

export const CReactQuill: FC<CReactQuillProps> = ({
  value,
  onChange,
  ...props
}) => {
  const ref = useRef<ReactQuill>(null);

  const imageHandler = (e: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files![0];

      if (/^image\//.test(file?.type)) {
        try {
          const asRef = ref.current as any;
          const range = asRef.getEditorSelection();
          const res = await postMediaObject(file);

          ref.current?.getEditor().insertEmbed(range.index, "image", res?.url);
        } catch (error: any) {
          return fireNotification({ type: "error", message: error.message });
        }
      } else {
        return fireNotification({ type: "error" });
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [
            {
              color: [
                "red",
                "green",
                "yellow",
                "blue",
                "orange",
                "violet",
                "#d0d1d2",
                "black",
              ],
            },
          ],
          ["bold", "italic", "underline", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchers: [
          [
            Node.ELEMENT_NODE,
            function (node: any, delta: any) {
              return delta.compose(
                new Delta().retain(delta.length(), {
                  color: false,
                  background: false,
                  // bold: false,
                  // strike: false,
                  // underline: false,
                })
              );
            },
          ],
        ],
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ReactQuillStyle
      {...props}
      theme="snow"
      value={value || ""}
      modules={modules}
      onChange={onChange}
      className="h-[300px] pb-8"
      ref={ref}
    />
  );
};
