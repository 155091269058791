import { Col, Form, Row } from "antd";
import React from "react";
import { ErrorDashboard, LoadingDashboard, Wrapper } from "..";
import { HeaderOverview } from "components";
import { useTranslation } from "react-i18next";
import { PROPERTY_MENUS } from "menus/property";
import ItemSurvey from "./item";
import { useOverview } from "providers/overview";
import { useGetDashboardSurvey } from "service/dashboard/central/survey";
import { dataMenuCode } from "service/dashboard/central/survey/interface";
export interface IMenuItem {
  key: string;
  title: string;
  icon?: React.ComponentType;
  code: string;
  slug?: string;
  score: number;
}

const Survey = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const surveyDate = Form.useWatch("surveyDate");
  const date = findDate(surveyDate);

  const { data, isFetching, isError, error } = useGetDashboardSurvey({
    ...date,
    property,
  });

  const propertyMenu = () => {
    const filterMenu = PROPERTY_MENUS.filter((e) => {
      return dataMenuCode.some((m) => m === e.code);
    });

    return filterMenu.map((e) => {
      const findData = data?.find((d) => d.code === e.code);
      const title = t(e?.title, { ns: "menu" });
      return {
        ...e,
        score: findData?.score || 0,
        title,
      };
    });
  };

  const menus: IMenuItem[] = propertyMenu() || [];

  return (
    <Wrapper>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("property.survey", { ns: "menu" })}
          name="surveyDate"
        />
        <Row gutter={[14, 14]} className="h-[400px] overflow-auto">
          {isError ? (
            <ErrorDashboard error={error} />
          ) : (
            menus.map((item) => {
              return (
                <Col key={item.title} sm={24} md={12} lg={8} xl={8} xxl={6}>
                  <ItemSurvey item={item} />
                </Col>
              );
            })
          )}
        </Row>
      </LoadingDashboard>
    </Wrapper>
  );
};

export default Survey;
