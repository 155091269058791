import { Image, ImageProps } from "antd";
import { FallbackImage } from "assets/images";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { fileToDataUrl } from "tools/file";
import tw from "twin.macro";
import { NullHolderImage } from "assets/images";

export type AcceptFileTypes =
  | ".pdf"
  | ".jpeg"
  | ".jpg"
  | ".png"
  | ".xls"
  | ".doc"
  | ".docx"
  | ".xlsx"
  | "video/*";

const StyledImage = styled(Image)<{ $rounded: boolean }>`
  ${({ $rounded }) => ($rounded ? tw`rounded-app` : "")};
`;

interface CImageProps extends Omit<ImageProps, "src"> {
  rounded?: boolean;
  src?: string | File;
}

export const CImage = ({
  preview = false,
  rounded = true,
  src,
  ...props
}: CImageProps) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const initial = async () => {
    if (!src) return;

    if (typeof src === "string") {
      return setUrl(src);
    }
    const _src = await fileToDataUrl(src);
    if (typeof _src === "string") {
      return setUrl(_src);
    }
  };

  return (
    <StyledImage
      $rounded={rounded}
      style={{ objectFit: "cover" }}
      height="100%"
      width="100%"
      placeholder={<Image src={NullHolderImage} preview={false} />}
      src={url || NullHolderImage}
      {...props}
      preview={preview}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = FallbackImage;
      }}
    />
  );
};
