import { WrapperListNews } from "..";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import { useGetPropertyDashboardNewsCountPerMonth } from "service/dashboard/property/news-and-announcement";
import { ErrorDashboard, LoadingDashboard, NoDataDashboard } from "../..";
import { useAppLanguage } from "providers/app-language";
import { IData } from "service/dashboard/interface";

const CountPerMonth = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { findDate } = useOverview();

  const NewsDate = Form.useWatch("NewsDate");
  const date = findDate(NewsDate, "year");

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardNewsCountPerMonth({ ...date });

  if (isFetching) {
    return (
      <WrapperListNews>
        <LoadingDashboard loading={isFetching}>
          <div className="px-4 pt-4">
            <HeaderOverview
              title={t("news-times-year")}
              name="NewsDate"
              formatDate="year"
            />
          </div>
          <div className="h-[230px]"></div>
        </LoadingDashboard>
      </WrapperListNews>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    height: 230,
  };

  return (
    <WrapperListNews>
      <div className="px-4 pt-4">
        <HeaderOverview
          title={t("news-times-year")}
          name="NewsDate"
          formatDate="year"
        />
      </div>
      {isError ? (
        <ErrorDashboard error={error} height="230px" />
      ) : !data || data.length <= 0 ? (
        <NoDataDashboard height="230px" />
      ) : (
        <RenderLineChart {...dataLine} />
      )}
    </WrapperListNews>
  );
};

export default CountPerMonth;
