import { createContext, useContext, useState } from "react";
import { IFindDate, IOverviewContext } from "./interface";
import { Form } from "antd";
import { DATA_PAGE_OVERVIEW } from "./data-page-overview";
import { useParams } from "react-router-dom";
import { DATA_PAGE_OVERVIEW_PROPERTY } from "./data-page-overview-property";
import dayjs, { Dayjs } from "config/dayjs";

const Context = createContext<IOverviewContext>({} as IOverviewContext);

const OverviewProviderApp: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [form] = Form.useForm();
  const [menusView, setMenusView] = useState<JSX.Element[]>([]);
  const [menus, setMenus] = useState<string[]>([]);
  const [property, setProperty] = useState();
  const { propertyId } = useParams();

  const setInitialValues = () => {
    const now = dayjs();
    const initialData = {
      ...getStatisticData(now),
      ...getWorkPlanData(now),
      ...getSurveyData(now),
      ...getNewsData(now),
      ...getHomeCareData(now),
      ...getParcelData(now),
      ...getComplaintData(now),
      ...getFacilityData(now),
      ...getVehicleData(now),
      ...getSellAndRentData(now),
      ...getVotingData(now),
      ...getActivitiesData(now),
      ...getWorkSpaceData(now),
      administratorsDate: now,
      residentsDate: now,
      backOfficeDate: now,
    };

    form.setFieldsValue(initialData);
  };

  const onClearProperty = () => {
    form.resetFields();
    form.submit();
  };

  const onClearMenu = () => {
    form.setFieldValue("menu", undefined);
    setMenusView([]);
  };

  const onSearchMenu = () => {
    const values = form.getFieldValue("menu");

    if (values) {
      const filter: JSX.Element[] =
        values?.map((e: string) => {
          if (propertyId) return DATA_PAGE_OVERVIEW_PROPERTY[e];
          return DATA_PAGE_OVERVIEW[e];
        }) || [];
      setMenusView(filter);
      setMenus(values);
    }
  };

  const findDate = (date: any, type?: "month" | "year"): IFindDate => {
    const startDate = dayjs(date)
      .startOf(type || "month")
      .toISOString();
    const endDate = dayjs(date)
      .endOf(type || "month")
      .toISOString();

    return { startDate, endDate };
  };

  const onSearchProperty = () => {
    const propertyUuid = form.getFieldValue("property");
    setProperty(propertyUuid);
  };

  return (
    <Context.Provider
      value={{
        form,
        onClearProperty,
        onClearMenu,
        onSearchMenu,
        onSearchProperty,
        property,
        menusView,
        setMenusView,
        menus,
        findDate,
        setInitialValues,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const getStatisticData = (now: Dayjs) => ({
  groupDate: now,
  applicationDate: now,
  listMenusDate: now,
  backOfficeDate: now,
});

const getWorkPlanData = (now: Dayjs) => ({
  workRemainingDate: now,
  workloadDate: now,
});

const getSurveyData = (now: Dayjs) => ({
  surveyDate: now,
});

const getNewsData = (now: Dayjs) => ({
  listNewsDate: now,
  newsDate: now,
});

const getHomeCareData = (now: Dayjs) => ({
  repairDate: now,
  unitRepairDate: now,
  repairTypesDate: now,
  budgetsDate: now,
  dailyWorkloadDate: now,
});

const getParcelData = (now: Dayjs) => ({
  parcelsStillDate: now,
  typeParcelDate: now,
  allStatusDate: now,
  unitParcelDate: now,
  dailyParcelDate: now,
  analyzeTrendsParcelsDate: now,
});

const getComplaintData = (now: Dayjs) => ({
  numberComplaintsDate: now,
  volumeComplaintsDate: now,
});

const getFacilityData = (now: Dayjs) => ({
  mostUsedFacilitiesDate: now,
  facilityTypeDate: now,
  facilityTopDate: now,
  facilitiesUsedEachMonthDate: now,
});

const getVehicleData = (now: Dayjs) => ({
  mostUsedVehicleDate: now,
  vehicleTopDate: now,
});

const getSellAndRentData = (now: Dayjs) => ({
  saleAndRentDate: now,
});

const getVotingData = (now: Dayjs) => ({
  votingDate: now,
});

const getActivitiesData = (now: Dayjs) => ({
  listActivitiesDate: now,
  activitiesDate: now,
});

const getWorkSpaceData = (now: Dayjs) => ({
  workSpaceDate: now,
  workSpaceTopDate: now,
});

export const useOverview = () => useContext(Context);
export default OverviewProviderApp;
