import React from "react";
import { LoadingDashboard } from "../..";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import { useGetPropertyDashboardRepairingCategory } from "service/dashboard/property/homecare";
import { TopBox } from "components";
import { useTranslation } from "react-i18next";

const TypeBox = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const repairTypesDate = Form.useWatch("repairTypesDate");
  const date = findDate(repairTypesDate);

  const query = useGetPropertyDashboardRepairingCategory({ ...date });

  return (
    <LoadingDashboard loading={query.isFetching}>
      <TopBox
        query={query}
        nameDate="repairTypesDate"
        title={t("statistics-type-5-types")}
        translation
        keyTitle="title"
      />
    </LoadingDashboard>
  );
};

export default TypeBox;
