import { FC } from "react";
import { LoadingDashboard, WrapperBG } from "../..";
import { Col, Form, Row, Space, Typography } from "antd";
import { HeaderOverview } from "components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { useGetPropertyDashboardRepairingByStatus } from "service/dashboard/property/homecare";
import { useOverview } from "providers/overview";

const Repair = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const allStatusDate = Form.useWatch("repairDate");
  const date = findDate(allStatusDate, "year");

  const { data, isFetching } = useGetPropertyDashboardRepairingByStatus({
    ...date,
  });

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <HeaderOverview
              formatDate="year"
              title={t("central-repair-work")}
              name="repairDate"
            />
          </Col>
          <Col sm={24} xl={8}>
            <Box
              title={t("number-of-repair-requests")}
              count={data?.all || 0}
              color={{
                bg: "#EAF5FF",
                colorText: "#2061E7",
              }}
            />
          </Col>
          <Col sm={24} xl={8}>
            <Box
              title={t("number-of-pending-tasks")}
              count={data?.pending || 0}
              color={{
                bg: "#FEEAEE",
                colorText: "#F52A53",
              }}
            />
          </Col>
          <Col sm={24} xl={8}>
            <Box
              title={t("number-of-tasks-completed")}
              count={data?.complete || 0}
              color={{
                bg: "#E9F7E6",
                colorText: "#21AA00",
              }}
            />
          </Col>
        </Row>
      </LoadingDashboard>
    </WrapperBG>
  );
};

interface IBox {
  title: string;
  count: number;
  color: { bg: string; colorText: string };
}

const BoxStyle = styled.div<{ bg: string; color: string }>`
  ${tw`h-[150px] rounded-app`}
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};

  .ant-typography {
    color: ${({ color }) => color};
  }
`;

const Box: FC<IBox> = ({ title, color, count }) => {
  const { t } = useTranslation();
  return (
    <BoxStyle bg={color.bg} color={color.colorText} className="center">
      <Space direction="vertical" className="center">
        <div>{title}</div>
        <Typography.Title level={3}>{count.toLocaleString()}</Typography.Title>
        <div>{t("work")}</div>
      </Space>
    </BoxStyle>
  );
};

export default Repair;
