import React from "react";
import { Wrapper } from "..";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import VehicleUse from "./content/left";
import VehicleTop from "./content/right";

const Vehicle = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.vehicle", { ns: "menu" })}</Col>
        <Col md={24} lg={24} xl={12}>
          <VehicleUse />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <VehicleTop />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Vehicle;
