export type ColorThemeTypes = "green" | "orange" | "white" | "red" | "blue";

interface ThmemOption {
  bg: string;
  colorText: string;
}

export const COLOR_THEME: { [K in ColorThemeTypes]: ThmemOption } = {
  green: {
    bg: "#E9F7E6",
    colorText: "#21AA00",
  },
  orange: {
    bg: "#FFF4E8",
    colorText: "#F8931D",
  },
  white: {
    bg: "white",
    colorText: "#081221",
  },
  blue: {
    bg: "#EAF5FF",
    colorText: "#2061E7",
  },
  red: {
    bg: "#FEEAEE",
    colorText: "#F52A53",
  },
};
