import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { HeaderOverview, IRenderBarChart, RenderBarChart } from "components";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useGetDashboardTaskProgress } from "service/dashboard/central/task";
import { IData } from "service/dashboard/interface";

const RemainingWorkloadCard = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { formatDate } = useAppLanguage();

  const workRemainingDate = Form.useWatch("workRemainingDate");
  const date = findDate(workRemainingDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardTaskProgress({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("remaining-workload-statistics")}
            name="workRemainingDate"
            formatDate="year"
          />
          <div className="h-[300px]"></div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const findCount = getData.some((e) => e.count > 0);

  const dataBar: IRenderBarChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    color: "#419A70",
  };

  return (
    <WrapperBG>
      <HeaderOverview
        title={t("remaining-workload-statistics")}
        name="workRemainingDate"
        formatDate="year"
      />
      {isError ? (
        <ErrorDashboard error={error} height="300px" />
      ) : !data || data.length <= 0 || !findCount ? (
        <NoDataDashboard height="300px" />
      ) : (
        <RenderBarChart {...dataBar} />
      )}
    </WrapperBG>
  );
};

export default RemainingWorkloadCard;
