import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IComplaint, IComplaintGraph } from "./interface";
import { axiosCentral, throwResponse } from "config/axios";
import { IDashboardGraph, IDashboardParams } from "../../interface";

export const useGetDashBoardComplaint = (
  params?: IDashboardParams
): UseQueryResult<IComplaint, Error> => {
  return useQuery(["dashboard-complaint", "count-on-status"], async () => {
    const res = await axiosCentral.get("/dashboard/feedback/count-on-status", {
      params,
    });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useGetDashBoardComplaintGraph = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-complaint", "graph-count-on-month", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/feedback/graph-count-on-month",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    }
  );
};

export const useGetDashBoardComplaintGraphSettingType = (
  params?: IDashboardParams
): UseQueryResult<IComplaintGraph[], Error> => {
  return useQuery(
    ["dashboard-complaint", "graph-status-by-setting-type", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/feedback/graph-status-by-setting-type",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    }
  );
};
