import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IPropertyUnit,
  IPropertyUnitParams,
  IPropertyUnitRepairing,
  IPropertyUnitTimeline,
  IPropertyUnitTimeLineParams,
  IResidentInUnit,
} from "./interface";
import { IResident } from "../residents/interface";

export const useGetPropertyUnits = (
  params?: IPropertyUnitParams
): UseQueryResult<IResponse<IPropertyUnit[]>> => {
  return useQuery(
    ["property-units", "all", params],
    async () => {
      const res = await axiosProperty.get(`/property-unit`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useScrollGetPropertyUnits = (
  params?: IPropertyUnitParams
): UseInfiniteQueryResult<IResponse<IPropertyUnit[]>, Error> => {
  return useInfiniteQuery(
    ["property-units", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/property-unit", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetPropertyUnit = (
  id?: number | string
): UseQueryResult<IPropertyUnit, Error> => {
  return useQuery(
    ["property-units", "id", id],
    async () => {
      const res = await axiosProperty.get(`/property-unit/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostPropertyUnits = (): UseMutationResult => {
  return useMutation(async ({ propertyId, ...data }: any) => {
    const res = await axiosProperty.post(`/property-unit`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchPropertyUnits = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/property-unit/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyUnit = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/property-unit/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyUnitBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data: any) => {
    const res = await axiosProperty.delete(`/property-unit/bulk`, { data });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetResidentInUnits = (
  params?: IPropertyUnitParams
): UseQueryResult<IResponse<IResidentInUnit[]>> => {
  return useQuery(
    ["residentInUnit", "all", params],
    async () => {
      const res = await axiosProperty.get(
        `/property-unit/get-residents-in-unit/${params?.propertyUnitUuid}`,
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.propertyUnitUuid }
  );
};

export const useGetResidentNotInUnits = (
  params?: IPropertyUnitParams
): UseQueryResult<IResponse<IResident[]>> => {
  return useQuery(
    ["residentNotInUnit", "all", params],
    async () => {
      const res = await axiosProperty.get(
        `/property-unit/get-residents-not-in-unit/${params?.propertyUnitUuid}`,
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.propertyUnitUuid }
  );
};

export const useGetTimelineInUnit = (
  params?: IPropertyUnitParams
): UseQueryResult<IResponse<IResidentInUnit[]>> => {
  return useQuery(
    ["timelineInUnit", "all", params],
    async () => {
      const res = await axiosProperty.get(
        `/property-unit/timeline-residents-in-unit/${params?.propertyUnitId}`,
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.propertyUnitId }
  );
};

export const useScrollGetTimelineInUnit = (
  params?: IPropertyUnitParams
): UseInfiniteQueryResult<IResponse<IResidentInUnit[]>, Error> => {
  return useInfiniteQuery(
    ["timelineInUnit", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get(
        `/property-unit/timeline-residents-in-unit/${params?.propertyUnitId}`,
        {
          params: { ...params, ...pageParam },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.propertyUnitId }
  );
};

export const useDeleteResidentInGroup = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.delete(
      `/property-unit/removed-residents-in-unit/${id}`,
      {
        data,
      }
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchAddResidentInToUnit = (): UseMutationResult => {
  return useMutation(async ({ dataFind }: any) => {
    const res = await axiosProperty.patch(
      `/property-unit/add-residents-into-unit`,
      dataFind
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchUpdateResidents = (): UseMutationResult => {
  return useMutation(async ({ id, value }: any) => {
    const res = await axiosProperty.patch(
      `/property-unit/update-residents/${id}`,
      value
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetPropertyUnitRepairing = (
  params?: IPropertyUnitParams
): UseQueryResult<IResponse<IPropertyUnitRepairing[]>> => {
  return useQuery(
    ["property-units-repairing", "all", params],
    async () => {
      const res = await axiosProperty.get(
        `/property-unit/${params?.uuid}/repairing`,
        { params }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useExportExcelPropertyUnit = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/property-unit", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetPropertyUnitHistoryTimeline = (
  id?: string
): UseQueryResult<IPropertyUnitTimeline[], Error> => {
  return useQuery(
    ["PropertyUnit", "history", id],
    async () => {
      const res = await axiosProperty.get(
        `/property-unit/${id}/timeline-of-property-unit`
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useScrollGetPropertyUnitHistoryTimeline = (
  params?: IPropertyUnitTimeLineParams
): UseInfiniteQueryResult<IResponse<IPropertyUnitTimeline[]>, Error> => {
  return useInfiniteQuery(
    ["PropertyUnit", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get(
        `/property-unit/${params?.id}/timeline-of-property-unit`,
        {
          params: { ...params, ...pageParam },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useImportPropertyUnit = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post(
      "/import-excel/property-unit/",
      params,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyUnit = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post(
      "/export-excel/template-property-unit/",
      params,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
