import React from "react";
import { Wrapper } from ".";
import { Row, Col, Form } from "antd";
import { Action, CSelect } from "components";
import { useTranslation } from "react-i18next";

const SearchProperty = () => {
  const { t } = useTranslation();
  return (
    <Wrapper className="!pb-0">
      <Row gutter={[12, 0]}>
        <Col span={20}>
          <Form.Item label={t("property")} name="property">
            <CSelect.Property
              placeholder={t("property")}
              overview
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Action searchType="property" />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SearchProperty;
