import React from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";

export const CSelectRepeat: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("repeat")}
      valueOption={{
        values: [
          { value: "NONE", title: t("not-repeat") },
          // { value: "WEEKLY", title: t("weekly") },
          // { value: "MONTHLY", title: t("monthly") },
          { value: "CUSTOM", title: t("custom") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
