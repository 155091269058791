import { FacilityBookingStatus } from "service/facility-booking/interface";
import { FacilityListBookingType } from "service/facility-list/interface";
import { TStatusNews } from "service/news-announcement/interface";
import {
  TRepairStatus,
  TRepairingZone,
} from "service/property/house-care-service/interface";
import { TParcelStatus } from "service/property/parcel/interface";
import { TStatusVehicle } from "service/property/vehicle/interface";
import { TSurveyStatus } from "service/survey/interface";
import { VotingStatusType } from "service/voting/interface";

export const cardThemes = [
  "blue",
  "green",
  "orange",
  "red",
  "purple",
  "lime",
  "drabGreen",
  "sky",
  "violet",
  "gray",
  "black",
  "lightGray",
];

export type CardThemeTypes =
  | "green"
  | "gray"
  | "orange"
  | "purple"
  | "blue"
  | "red"
  | "lime"
  | "drabGreen"
  | "sky"
  | "black"
  | "violet"
  | "lightGray";

export interface ThemeOption {
  bg?: string;
  color?: string;
  hoverColor?: string;
  hoverBg?: string;
  border?: string;
}

export const CARD_THEME: { [K in CardThemeTypes]: ThemeOption } = {
  green: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#276347",
    hoverColor: "#0A1430",
    hoverBg: "#E8F2EB",
  },
  orange: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#FF8E00",
    hoverColor: "#0A1430",
    hoverBg: "#FEF4E8",
  },
  gray: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#E5E5E5",
    hoverColor: "#0A1430",
    hoverBg: "#d1d1d1",
  },
  purple: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#D146FF",
    hoverColor: "#0A1430",
    hoverBg: "#F9EEFF",
  },
  blue: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#2061E7",
    hoverColor: "#0A1430",
    hoverBg: "#E8EFFD",
  },
  red: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#F52A53",
    hoverColor: "#0A1430",
    hoverBg: "#FFEFEF",
  },
  lime: {
    bg: "#F8F8F8",
    color: "#939498",
    border: "#2FB09D",
    hoverColor: "#0A1430",
    hoverBg: "rgb(47,176,144,0.1)",
  },
  drabGreen: {
    bg: "#B0B02F",
    color: "#939498",
    border: "#B0B02F",
    hoverColor: "#0A1430",
    hoverBg: "#B0B02F1A",
  },
  sky: {
    bg: "#0093FF",
    color: "#939498",
    border: "#0093FF",
    hoverColor: "#0A1430",
    hoverBg: "#0093FF1A",
  },
  black: {
    bg: "#0093FF",
    color: "#939498",
    border: "#535D6F",
    hoverColor: "#0A1430",
    hoverBg: "#535D6F1A",
  },
  violet: {
    bg: "#0093FF",
    color: "#939498",
    border: "#854DD6",
    hoverColor: "#0A1430",
    hoverBg: "#854DD61a",
  },
  lightGray: {
    bg: "#0093FF",
    color: "#939498",
    border: "#AFBAC7",
    hoverColor: "#AFBAC7",
    hoverBg: "#AFBAC71a",
  },
};

export type StatusCardTypes =
  | "active-inActive"
  | "online-office"
  | "parcel"
  | "none"
  | "news"
  | "vehicle"
  | "voting"
  | "facility-list"
  | "facility-booking"
  | "survey"
  | "satisfaction-survey"
  | "house-care-service"
  | "repair-area";

export type ActiveTypes =
  | "active"
  | "inActive"
  | "online"
  | "office"
  | "none"
  | TStatusNews
  | TParcelStatus
  | TStatusVehicle
  | VotingStatusType
  | FacilityListBookingType
  | FacilityBookingStatus
  | TSurveyStatus
  | TRepairStatus
  | TRepairingZone;

export interface StatusOption {
  title: string;
  color: string;
  theme?: CardThemeTypes;
}

export const STATUS_CARD: {
  [T in StatusCardTypes]: { [K in any]: StatusOption };
} = {
  voting: {
    DRAFT: { title: "draft", color: "#44955B", theme: "orange" },
    PENDING: { title: "pending", color: "#44955B", theme: "lime" },
    CANCEL: { title: "cancel", color: "#44955B", theme: "red" },
    COMPLETE: { title: "complete", color: "#44955B", theme: "green" },
    INPROGRESS: { title: "in-progress", color: "#44955B", theme: "violet" },
  },
  "active-inActive": {
    active: { title: "active", color: "#44955B", theme: "green" },
    inActive: { title: "in-active", color: "#C1061B", theme: "red" },
  },
  "online-office": {
    online: { title: "active", color: "#44955B", theme: "green" },
    office: { title: "in-active", color: "#C1061B", theme: "red" },
  },
  "satisfaction-survey": {
    DRAFT: { title: "draft", color: "#C1061B", theme: "red" },
    PUBLISH: { title: "publish", color: "#44955B", theme: "green" },
    PENDING_APPROVE: {
      title: "pending-approval",
      color: "#0031B5",
      theme: "blue",
    },
    DISAPPROVE: { title: "disapproved", color: "#C1061B", theme: "red" },
  },
  none: {
    none: { title: " ", color: "" },
  },
  parcel: {
    RECEIVED: { title: "parcel-received", color: "" },
    NOT_RECEIVED: { title: "not-received-parcel", color: "" },
    OVER_TIME: { title: "parcel-over-time", color: "" },
    SEND_BACK: { title: "parcel-overdue", color: "" },
    CANCEL: { title: "cancel", color: "" },
  },
  news: {
    PUBLISH: { title: "publish", color: "#21AA00", theme: "green" },
    DRAFT: { title: "draft", color: "#F8931D", theme: "orange" },
    PENDING_APPROVAL: {
      title: "pending-approval",
      color: "#0031B5",
      theme: "blue",
    },
    DISAPPROVED: { title: "disapproved", color: "#C1061B", theme: "red" },
    SELECT_PROJECT: {
      title: "select-a-project",
      color: "#854DD6",
      theme: "purple",
    },
    APPROVED_SOME: {
      title: "published-some-property",
      color: "rgb(47,176,144,0.1)",
      theme: "lime",
    },
  },
  vehicle: {
    APPROVE: { title: "approved", color: "#21AA00", theme: "green" },
    PENDING: { title: "pending-approval", color: "#F8931D", theme: "orange" },
    INACTIVE: { title: "inactive", color: "#0031B5", theme: "blue" },
    REJECT: { title: "disapproved", color: "#C1061B", theme: "red" },
    EXPIRE: { title: "expire", color: "#C1061B", theme: "purple" },
  },
  "facility-list": {
    REQUIRED: {
      title: "advance-reservation-required",
      color: "#F8931D",
      theme: "orange",
    },
    NOT_REQUIRED: {
      title: "no-advance-reservation-required",
      color: "#0031B5",
      theme: "blue",
    },
  },
  "facility-booking": {
    PENDING: {
      title: "pending",
      color: "#21AA00",
      theme: "green",
    },
    CHECK_IN: {
      title: "check-in",
      color: "#F8931D",
      theme: "orange",
    },
    CHECK_OUT: {
      title: "check-out",
      color: "#C1061B",
      theme: "red",
    },
    CANCEL: {
      title: "cancel",
      color: "#854DD6",
      theme: "purple",
    },
  },
  survey: {
    DRAFT: {
      title: "draft",
      color: "#F8931D",
      theme: "orange",
    },
    PUBLISH: {
      title: "publish",
      color: "#21AA00",
      theme: "green",
    },
    PENDING_APPROVE: {
      title: "pending-approval",
      color: "#0031B5",
      theme: "blue",
    },
    DISAPPROVE: {
      title: "disapproved",
      color: "#C1061B",
      theme: "red",
    },
    SELECT_PROPERTY: {
      title: "select-a-project",
      color: "#854DD6",
      theme: "purple",
    },
    PUBLISH_SOME_PROPERTY: {
      title: "published-some-property",
      color: "rgb(47,176,144,0.1)",
      theme: "lime",
    },
  },
  "house-care-service": {
    WAITING_CONFIRM_APPOINTMENT: {
      title: "waiting-confirm-appointment",
      color: "#21AA00",
      theme: "green",
    },
    PENDING: {
      title: "pending",
      color: "#F8931D",
      theme: "orange",
    },
    COMPLETE: {
      title: "complete",
      color: "#44955B",
      theme: "lime",
    },
    CANCEL: {
      title: "cancel",
      color: "#C1061B",
      theme: "red",
    },
    PUT_OFF_APPOINTMENT: {
      title: "put-off-appointment",
      color: "#854DD6",
      theme: "purple",
    },
  },
  "repair-area": {
    PRIVATE: { title: "private-area", color: "#0031B5", theme: "blue" },
    MIDDLE: { title: "middle-area", color: "#F8931D", theme: "orange" },
  },
};
