import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)<{ bgcolor?: number }>`
  ${tw`!rounded-app`}
`;

export interface CTextAreaProps extends TextAreaProps {
  bgcolor?: boolean;
}

export const CTextArea: FC<CTextAreaProps> = ({ bgcolor = true, ...props }) => {
  return <StyledTextArea {...props} bgcolor={bgcolor ? 1 : 0} />;
};
