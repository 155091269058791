import { useScrollGetProperties } from "service/property";
import { CSelectPagination } from "./pagination";

export const CSelectPropertySuggestion = ({ ...props }) => {
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetProperties}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      translation
      queryParams={{
        isActive: true,
      }}
    />
  );
};
