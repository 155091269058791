import { FileExcelFilled } from "@ant-design/icons";
import { MutateOptions, UseMutationResult } from "@tanstack/react-query";
import { Col, Form, Popover, Row } from "antd";
import { CButton } from "components/button/c-button";
import { CModal } from "components/display/c-modal";
import { fireNotification } from "components/popup/notification";
import { useAppImport } from "providers/import-provider";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { downloadBlobFile } from "tools/file";

const StylePopover = styled(Popover)`
  .ant-popover {
    position: fixed;
  }
`;

export interface IExcelOptions {
  title?: string;
  import?: (value?: any[]) => void;
  export?: UseMutationResult<Blob>;
  template?: UseMutationResult<unknown, Error, unknown, unknown> | (() => void);
  params?: Record<string, any>;
  extraFilter?: React.ReactNode;
}

const PageExportAction: FC<{ excelOptions?: IExcelOptions }> = ({
  excelOptions,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isPopOpen, setIsPopOpen] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { isModalImportOpen, setIsModalImportOpen } = useAppImport();

  const action: MutateOptions<unknown, Error, unknown, unknown> = {
    onSuccess: (res) => {
      fireNotification({ type: "success" });
      downloadBlobFile(
        res as Blob,
        t(`${excelOptions?.title}`, { ns: "menu" })
      );
    },
    onError: ({ message }) => {
      fireNotification({ type: "error", description: message });
    },
  };

  const exportToggle = () => {
    if (isPopOpen) setIsPopOpen(!isPopOpen);
    setIsModalOpen(!isModalOpen);
  };

  const onImport = () => {
    if (isPopOpen) setIsPopOpen(!isPopOpen);
    setIsModalImportOpen(true);
  };

  const onTemplate = () => {
    if (typeof excelOptions?.template === "function")
      return excelOptions.template();
    return excelOptions?.template?.mutate(
      {},
      {
        ...action,
      }
    );
  };

  const excelOptionsContent = (
    <Row className="w-[150px]" gutter={[0, 8]}>
      {excelOptions?.export && (
        <Col span={24}>
          <CButton className="w-full" onClick={exportToggle}>
            {t(`export`)}
          </CButton>
        </Col>
      )}
      {excelOptions?.import && (
        <Col span={24}>
          <CButton className="w-full" onClick={onImport}>
            {t(`import`)}
          </CButton>
        </Col>
      )}
      {excelOptions?.template && (
        <Col span={24}>
          <CButton className="w-full" onClick={onTemplate}>
            {t(`download-template`)}
          </CButton>
        </Col>
      )}
    </Row>
  );

  const onOpenChange = (visible: boolean) => {
    if (!excelOptions) return;
    setIsPopOpen(visible);
  };

  return (
    <React.Fragment>
      <StylePopover
        arrow={false}
        trigger="click"
        open={isPopOpen}
        placement="rightBottom"
        onOpenChange={onOpenChange}
        getPopupContainer={(e) => e}
        content={excelOptionsContent}
      >
        <CButton
          color="green"
          sizeFor="icon"
          display={false}
          icon={<FileExcelFilled />}
        />
      </StylePopover>
      <CModal.CModalExport
        title={t("export-excel")}
        open={isModalOpen}
        form={form}
        onCancel={exportToggle}
        mutation={excelOptions?.export}
        extraFilter={excelOptions?.extraFilter}
        params={excelOptions?.params}
      />
      <CModal.CModalImport
        title={t("import-excel")}
        open={isModalImportOpen}
        mutation={excelOptions?.import}
      />
    </React.Fragment>
  );
};

export default PageExportAction;
