import { useTranslation } from "react-i18next";
import { usePropertyGetTagSignsScroll } from "service/property/tag-sign";
import { CSelectPagination, CSelectProps } from ".";

export const CSelectTagsSign: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder ? placeholder : t("tags")}
      useQuery={usePropertyGetTagSignsScroll}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      translation
      queryParams={{
        isActive: true,
      }}
    />
  );
};
