import { Col, Divider, FormInstance, Row, Typography } from "antd";
import { NullHolderImage } from "assets/images";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IMediaObject, TMediaObject } from "service/media-object/interface";
import styled from "styled-components";
import tw from "twin.macro";
import { CImage } from "..";
import { CImageModal } from "./image-modal";
import { CImageModalProperty } from "./image-modal-property";
import { useAppActionRole } from "providers/action-role";

export interface CUploadCaptureProps {
  onChange?: (v?: IMediaObject) => void;
  value?: IMediaObject;
  width?: string;
  height?: string;
  disabled?: boolean;
  label: string;
  type?: TMediaObject;
  required?: boolean;
  form?: FormInstance;
  name?: string;
}

const CUploadCaptureWrapper = styled.div<{
  width: string;
  height: string;
  errorColor?: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
  background-color: white;
  border: ${({ errorColor }) =>
    errorColor ? `1px solid ${errorColor}` : "none"};
  ${tw`inline-flex items-center justify-center`};
  ${tw`rounded-app`};
`;

export const CUploadAndSelect: React.FC<CUploadCaptureProps> = ({
  value,
  onChange,
  width = "300px",
  height = "300px",
  disabled = false,
  label = "",
  type = "image",
  required = false,
  form,
  name,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<undefined | string>();
  const { propertyId } = useParams();
  const { actionMenu } = useAppActionRole();

  const disable = disabled || !actionMenu?.action;

  useEffect(() => {
    setImageUrl(value?.url);
  }, [value]);

  const toggle = () => {
    if (disable) return;
    setVisible(!visible);
  };

  const onSave = (value?: IMediaObject | IMediaObject[]) => {
    onChange?.(value as IMediaObject);
    toggle();
  };

  const onDelete = () => {
    if (disable) return;
    onChange?.(undefined);
    setImageUrl(undefined);
  };

  const error = form?.getFieldError(name || "");

  return (
    <React.Fragment>
      <div className="mb-4">
        <div className="p-4">
          <Typography.Text>
            {required ? <span className="!text-[#80BC28] pr-1">*</span> : <></>}
            {label}
          </Typography.Text>
        </div>
        {propertyId ? (
          <CImageModalProperty
            visible={visible}
            toggle={toggle}
            onSave={onSave}
            type={type}
          />
        ) : (
          <CImageModal
            visible={visible}
            toggle={toggle}
            onSave={onSave}
            type={type}
          />
        )}

        <Divider className="!m-0" />
        <Row className="p-4 center">
          <CUploadCaptureWrapper
            width={width}
            height={height}
            errorColor={(error?.length || 0) > 0 ? "red" : undefined}
          >
            <CImage
              className="!h-full !w-full"
              src={imageUrl || NullHolderImage}
              preview={false}
              style={{ borderColor: "red" }}
            />
          </CUploadCaptureWrapper>
        </Row>
        {(error?.length || 0) > 0 ? (
          <Row className="text-[red] px-4 pb-4">
            {form?.getFieldError(name || "")}
          </Row>
        ) : (
          <></>
        )}
        <Divider className="!m-0" />
        <Row className="w-full" justify="space-around">
          <Col
            span={11}
            className={`center p-4 ${
              !disable ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={toggle}
          >
            <Typography.Text>{t("select-img")}</Typography.Text>
          </Col>
          <Col className="center">
            <Divider className="!m-0 h-full" type="vertical" />
          </Col>
          <Col
            span={11}
            className={`center p-4 ${
              !disable ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={onDelete}
          >
            <Typography.Text>{t("delete-img")}</Typography.Text>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
