import { Col, Row } from "antd";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import MostReaderNews from "./content/most-reader";
import CountPerMonth from "./content/count-reader-per-month";

export const WrapperListNews = styled.div`
  ${tw`bg-white h-full rounded-app p-3`}
`;

const NewsAndAnnouncement = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>
          {t("property.news-and-announcement", { ns: "menu" })}
        </Col>
        <Col md={24} lg={24} xl={8}>
          <MostReaderNews />
        </Col>
        <Col md={24} lg={24} xl={16}>
          <CountPerMonth />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default NewsAndAnnouncement;
