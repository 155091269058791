import { useQueryClient } from "@tanstack/react-query";
import { useAppAuth } from "providers/app-auth";
import { useAppPopup } from "providers/app-popup";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { IAppPageContainerContext, IAppPageContainerProps } from "./interface";
import { Form } from "antd";

const Context = React.createContext<IAppPageContainerContext>(
  {} as IAppPageContainerContext
);

const withAppPageContainer = (Component: React.ComponentType) => {
  return ({
    invalidatesQuery = [],
    useMultiRecordDelete,
    useRecordDelete,
    useMultiRecordDuplicate,
  }: IAppPageContainerProps) => {
    const [form] = Form.useForm();

    const [collapseFilter, setCollapseFilter] = useState<boolean>(false);
    const [selectRowsKey, setSelectRowsKey] = useState<React.Key[]>([]);
    const [statusExtra, setStatusExtra] = useState<any>(undefined);

    const recordDelete = useRecordDelete?.();
    const multiRecordDelete = useMultiRecordDelete?.();
    const multiRecordDuplicate = useMultiRecordDuplicate?.();
    const qClient = useQueryClient();
    const { propertyId } = useParams();
    const { userMe } = useAppAuth();

    const { fireNotification } = useAppPopup();

    const toggleCollapseFilter = () => {
      setCollapseFilter(!collapseFilter);
    };

    const onRecordDelete = (
      id: number | string,
      onLastItemInPage?: () => void,
      dataLength?: number
    ) => {
      recordDelete?.mutate(
        { id },
        {
          onSuccess: () => {
            fireNotification({ type: "success" });
            invalidatesQuery?.forEach((k) => qClient.invalidateQueries([k]));
            // qClient.invalidateQueries(invalidatesQuery);
            if (onLastItemInPage && dataLength === 1) {
              onLastItemInPage();
            }
          },
          onError: ({ message }) => {
            fireNotification({ type: "error", message: message });
          },
        }
      );
    };

    const onMultiRecordDelete = (
      ids: string[] | number[],
      onLastItemInPage?: () => void,
      dataLength?: number
    ) => {
      multiRecordDelete?.mutate(
        { ids },
        {
          onSuccess: () => {
            fireNotification({ type: "success" });
            setSelectRowsKey([]);
            invalidatesQuery?.forEach((k) => qClient.invalidateQueries([k]));
            // qClient.invalidateQueries(invalidatesQuery);
            if (onLastItemInPage && dataLength === ids.length) {
              onLastItemInPage();
            }
          },
          onError: ({ message }) => {
            fireNotification({ type: "error", message: message });
          },
        }
      );
    };

    const onMultiRecordDuplicate = (ids: string[] | number[]) => {
      const data = propertyId ? { ids, uuidAdmin: userMe?.uuid } : { ids };
      multiRecordDuplicate?.mutate(
        { ...data },
        {
          onSuccess: () => {
            fireNotification({ type: "success" });
            setSelectRowsKey([]);
            invalidatesQuery?.forEach((k) => qClient.invalidateQueries([k]));
            // qClient.invalidateQueries(invalidatesQuery);
          },
          onError: ({ message }) => {
            fireNotification({ type: "error", message: message });
          },
        }
      );
    };

    return (
      <Context.Provider
        value={{
          invalidatesQuery,
          selectRowsKey,
          collapseFilter,
          statusExtra,
          setSelectRowsKey,
          toggleCollapseFilter,
          setStatusExtra,
          onRecordDelete,
          onMultiRecordDelete,
          onMultiRecordDuplicate,
          form,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useAppPageContainer = () => React.useContext(Context);
export default withAppPageContainer;
