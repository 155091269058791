import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  ActiveTypes,
  CardThemeTypes,
  CARD_THEME,
  StatusCardTypes,
  STATUS_CARD,
  ThemeOption,
} from "./theme";

const StyleCard = styled.div<{ themeColor: ThemeOption }>`
  background-color: ${({ themeColor }) => themeColor.hoverBg};
  height: 40px;
  width: 130px;
  .value {
    color: ${({ themeColor }) => themeColor.border};
  }
`;

export interface ICard {
  isActive?: ActiveTypes;
  theme?: CardThemeTypes;
  statusType?: StatusCardTypes;
  className?: string;
}
export const CCardStatus: React.FC<ICard> = ({
  isActive,
  theme,
  statusType,
  className,
}) => {
  const { t } = useTranslation();
  if (!isActive) return <div className="center">-</div>;

  const statusCard = STATUS_CARD[statusType || "active-inActive"];
  const status = statusCard[isActive || "active"];
  const themeColor = CARD_THEME[status?.theme || theme || "green"];

  return (
    <StyleCard
      themeColor={themeColor}
      className={`rounded-app center px-5 !w-full ${className}`}
    >
      <Typography.Text
        ellipsis={{ tooltip: status?.title ? t(status.title) : "-" }}
        className="value center"
      >
        {status?.title ? t(status.title) : "-"}
      </Typography.Text>
    </StyleCard>
  );
};
