import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetParcelSettingStoragePlaces } from "service/parcel-setting-storage-place";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { IParcelSettingStoragePlaceParams } from "service/parcel-setting-storage-place/interface";

export interface ISelectParcelStorage extends CSelectProps {
  params?: IParcelSettingStoragePlaceParams;
}

export const CSelectParcelStoragePlace: React.FC<ISelectParcelStorage> = ({
  params,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={t("parcel-storage-floor")}
      useQuery={useScrollGetParcelSettingStoragePlaces}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      translation
      queryParams={{ ...params, isActive: true }}
    />
  );
};
