import {
  CaretRightFilled,
  EyeFilled,
  FileExclamationFilled,
} from "@ant-design/icons";
import { Col, Divider, Row, Typography, Image } from "antd";
import { Clear, MockUp } from "assets/images";
import { CModal } from "components";
import { CImage } from "components/input/c-image";
import { useAppLanguage } from "providers/app-language";
import React from "react";
import { INewsAnnouncement } from "service/news-announcement/interface";
import styled from "styled-components";
import ReactShadowScroll from "react-shadow-scroll";
import HTMLReactParser, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";
import { IMediaObject } from "service/media-object/interface";
import ReactPlayer from "react-player";
import { ExcelIcon, PDFIcon, WordIcon } from "assets/images/icon/gallery";

const ImageStyle = styled.div`
  width: 100%;
  height: 20vh;
`;

const CImageStyle = styled(CImage)`
  border-radius: 0px;
`;

const StyleDiv = styled.div`
  position: absolute;
  left: 0;
  top: 105px;
  bottom: 0px;
`;

export interface IPreviewMobile {
  visible: boolean;
  toggle: () => void;
  data?: INewsAnnouncement;
}

export const PreviewMobile: React.FC<IPreviewMobile> = ({
  visible,
  toggle,
  data,
}) => {
  const { translationObject, formatDate } = useAppLanguage();
  const { title, description } = translationObject({
    object: data,
    keys: ["title", "description"],
  });
  const date = formatDate({ date: "2023/01/01", format: "full-month" });

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (
        domNode instanceof Element &&
        domNode.attribs &&
        domNode.attribs.class === "remove"
      ) {
        return <></>;
      }
    },
    htmlparser2: {
      xmlMode: true,
    },
  };

  return (
    <CModal width={368} open={visible} maskClosable onCancel={toggle} closable>
      <CImage src={MockUp} width={320} height={665} preview={false} />
      <StyleDiv className="pr-9 pl-[2.35rem]">
        <div className="!w-[293px] !h-[550px]">
          <ReactShadowScroll isShadow={false} scrollWidth={0}>
            <div className="!w-full !h-[550px]">
              <ImageStyle className="mr-1">
                <CImageStyle src={data?.cover?.url || ""} width={293} />
              </ImageStyle>
              <Row className="px-4 pt-2">
                <Col span={24}>
                  <Typography.Title level={5} className="!text-[#8EC641]">
                    {title}
                  </Typography.Title>
                </Col>
                <Col span={12}>{date}</Col>
                <Col span={12} className="text-end">
                  <EyeFilled /> 164
                </Col>
                <Divider className="!m-0 !my-2 !mb-3" />
                <Col span={24} hidden={!description}>
                  {/* <div dangerouslySetInnerHTML={{ __html: description }} /> */}
                  <div>
                    {description && HTMLReactParser(description, options)}
                  </div>
                </Col>
                <Col span={24} hidden={!data?.media}>
                  <VideoGrid media={data?.media} />
                </Col>
                <Col span={24} hidden={!data?.file}>
                  {data?.file
                    ? data.file.map((item) => {
                        return <FileGrid media={item} key={item?.id} />;
                      })
                    : null}
                </Col>
              </Row>
            </div>
          </ReactShadowScroll>
        </div>
      </StyleDiv>
    </CModal>
  );
};

type TFileTypes = "pdf" | "sheet" | "doc";

const files: { [K in TFileTypes]: { icon: any; bg: string } } = {
  pdf: { icon: PDFIcon, bg: "#FFEFEF" },
  sheet: { icon: ExcelIcon, bg: "#EDF5F1" },
  doc: { icon: WordIcon, bg: "#E7EFFE" },
};

const FileGrid = ({ media }: { media?: IMediaObject }) => {
  const typesFile = ["pdf", "sheet", "doc"];
  const type = typesFile.find((e) =>
    media?.mimeType?.includes(e)
  ) as TFileTypes;
  const Icon = files[type]?.icon || FileExclamationFilled;

  return (
    <Row key={media?.id} className="my-2">
      <Col span={3}>
        <Icon width={25} height={25} />
      </Col>
      <Col span={21} className="flex items-center">
        <Typography.Text
          ellipsis={{ tooltip: media?.originalFilename || "-" }}
          style={{ width: 230 }}
        >
          {media?.originalFilename || "-"}
        </Typography.Text>
      </Col>
    </Row>
  );
};

const VideoMask = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  color: white;
`;

const VideoGrid = ({ media }: { media?: IMediaObject }) => {
  return (
    <div className=" my-2">
      <div
        className="absolute rounded-app w-full !h-[142px]"
        style={{ background: "#FEF4E8" }}
      >
        <VideoMask>
          <div className="h-full flex justify-center items-center">
            <Row
              justify="center"
              style={{ background: "rgb(39, 99, 71, 0.6)" }}
              className="w-[30px] h-[30px] rounded-full"
            >
              <CaretRightFilled className="text-[20px] pl-[2px]" />
            </Row>
          </div>
        </VideoMask>
        <ReactPlayer width={"100%"} height={"100%"} url={media?.url} />
      </div>
      <Image
        src={Clear}
        preview={false}
        id={`${media?.id}`}
        className="!h-[132px] w-full"
      />
    </div>
  );
};
