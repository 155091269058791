import { UseQueryResult } from "@tanstack/react-query";
import { Col, Row, Spin, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { IResponse } from "config/axios/interface";
import { IPagination, PaginationPage } from "../c-table/pagination";
import { CEmpty } from "../c-empty";

const TabStyle = styled(Col)<{ $active?: boolean }>`
  ${tw`rounded-app`}
  cursor: pointer;
  background-color: ${({ $active }) => ($active ? "#44955B" : "#E9EFEC")};
  .ant-typography {
    color: ${({ $active }) => ($active ? "white" : "#276347")};
  }
  height: 35px;
  padding: 20px;
`;
interface ICardContent {
  data?: any[];
  title?: string;
  tabHeader?: ITabHeader[];
  content: (v?: any[]) => React.ReactNode;
  useQueryResult?: UseQueryResult<IResponse<any[]>>;
  pagination?: IPagination;
  loading?: boolean;
}
interface ITabHeader {
  text?: string;
  active?: boolean;
  onClick?: () => void;
}

export const CCardContent = ({
  title,
  tabHeader,
  content,
  pagination,
  data,
  loading = false,
}: ICardContent) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row gutter={[16, 16]} className="mb-4">
        <Col className="center mr-5">
          <Typography.Title level={4}>{t(title || "")}</Typography.Title>
        </Col>
        {tabHeader ? (
          <>
            {tabHeader?.map((item, index) => {
              return (
                <TabStyle
                  key={index}
                  className="center mx-1"
                  span={3}
                  onClick={item.onClick}
                  $active={item?.active}
                >
                  <Typography.Text ellipsis={{ tooltip: item?.text }}>
                    {t(item?.text || "")}
                  </Typography.Text>
                </TabStyle>
              );
            })}
          </>
        ) : null}
      </Row>

      {loading ? (
        <Spin spinning={loading}>
          <div style={{ height: "calc(100vh - 570px)" }}></div>
        </Spin>
      ) : (
        <Row gutter={[16, 16]}>{content()}</Row>
      )}
      <Col
        span={24}
        hidden={!!data?.length}
        style={{ height: "calc(100vh - 570px)" }}
        className="flex items-center justify-center"
      >
        <CEmpty />
      </Col>
      <Row justify="end">
        <PaginationPage {...pagination} />
      </Row>
    </React.Fragment>
  );
};
