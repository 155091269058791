import { SiderMenuItemProps } from "layouts/menu/item";
import { MAIN_MENUS } from "menus/main";
import { PROPERTY_MENUS } from "menus/property";
import { useAppAuth } from "providers/app-auth";
import React, { ReactNode, useEffect, useState } from "react";
import { IRoleMenu } from "service/role-menu/interface";
import { IAppRoleMenuContext } from "./interface";
import { useParams } from "react-router-dom";
import { useGetPropertyMenu } from "service/property-menu";
import { IPropertyMenu } from "service/property-menu/interface";
import { APPLICATION_MENUS } from "menus/application";

type MenuType = SiderMenuItemProps[];

const Context = React.createContext<IAppRoleMenuContext>(
  {} as IAppRoleMenuContext
);
const AppRoleMenuProvider = ({ children }: { children: ReactNode }) => {
  const { userMe } = useAppAuth();
  const { propertyId } = useParams<{ propertyId?: string }>();

  const { data } = useGetPropertyMenu(propertyId);

  const [propertyMenu, setPropertyMenu] = useState<MenuType>(PROPERTY_MENUS);
  const [mainMenu, setMainMenu] = useState<MenuType>(MAIN_MENUS);

  useEffect(() => {
    if (userMe) {
      initMenu(userMe.role?.roleMenus || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMe, data]);

  const initMenu = (roleMenus: IRoleMenu[]) => {
    let property = getViewAbleMenu(PROPERTY_MENUS, roleMenus);
    if (propertyId && data) {
      property = getViewAbleMenu(PROPERTY_MENUS, roleMenus, data);
    }

    const main = getViewAbleMenu(MAIN_MENUS, roleMenus);

    setMainMenu(main);
    setPropertyMenu(property);
  };

  const getViewAbleMenu = (
    base: MenuType,
    roleMenus: IRoleMenu[],
    propertyMenu?: IPropertyMenu[]
  ): MenuType => {
    return base
      .map((item) => {
        return item;
      })
      .filter((item) => {
        const find = roleMenus.find((e) => e.menu?.code === item.code);
        const findProperty = propertyMenu?.find(
          (e) => e.menu?.code === item.code
        );
        const view = propertyMenu
          ? find?.viewable && findProperty?.viewableBackoffice
          : find?.viewable || false;
        return view;
      })
      .map((item) => {
        const find = roleMenus.find((e) => e.menu?.code === item.code);
        return { ...item, slug: find?.slug };
      });
  };

  return (
    <Context.Provider
      value={{
        baseMainMenus: MAIN_MENUS,
        basePropertyMenus: PROPERTY_MENUS,
        baseApplicationMenus: APPLICATION_MENUS,
        mainMenu: mainMenu,
        propertyMenu: propertyMenu,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppRoleMenu = () => React.useContext(Context);
export default AppRoleMenuProvider;
