import {
  NewsAnnouncementIcon,
  PhoneBookIcon,
  ResidentsIcon,
  ActivitiesIcon,
  ChatIcon,
  HouseCareServiceIcon,
  ParcelIcon,
  FacilityIcon,
  VehicleIcon,
  ComplaintIcon,
  SaleAndRentIcon,
  VotingIcon,
  PaymentIcon,
  SurveyIcon,
  UnitIcon,
} from "assets/images/icon/menu";
import { SiderMenuItemProps } from "layouts/menu/item";

export const APPLICATION_MENUS: SiderMenuItemProps[] = [
  {
    key: "property.house-care-service",
    title: "property.house-care-service",
    to: "/property/:propertyId/house-care-service",
    icon: HouseCareServiceIcon,
    code: "2300",
  },
  {
    key: "property.parcel",
    title: "property.parcel",
    to: "/property/:propertyId/parcel",
    icon: ParcelIcon,
    code: "2400",
  },
  {
    key: "property.facility",
    title: "property.facility",
    to: "/property/:propertyId/facility",
    icon: FacilityIcon,
    code: "2500",
  },
  {
    key: "property.phone-book",
    title: "property.phone-book",
    to: "/property/:propertyId/phone-book",
    icon: PhoneBookIcon,
    code: "2600",
  },
  {
    key: "property.residents",
    title: "property.residents",
    to: "/property/:propertyId/residents",
    icon: ResidentsIcon,
    code: "2700",
  },
  {
    key: "property.vehicle",
    title: "property.vehicle",
    to: "/property/:propertyId/vehicle",
    icon: VehicleIcon,
    code: "2800",
  },
  {
    key: "property.activities",
    title: "property.activities",
    to: "/property/:propertyId/activities",
    icon: ActivitiesIcon,
    code: "3900",
  },
  {
    key: "property.sell-and-rent",
    title: "property.sell-and-rent",
    to: "/property/:propertyId/sell-and-rent",
    icon: SaleAndRentIcon,
    code: "3100",
  },
  {
    key: "property.complaint",
    title: "property.complaint",
    to: "/property/:propertyId/complaint",
    icon: ComplaintIcon,
    code: "2900",
  },
  {
    key: "property.voting",
    title: "property.voting",
    to: "/property/:propertyId/voting",
    icon: VotingIcon,
    code: "3300",
  },
  {
    key: "property.survey",
    title: "property.survey",
    to: "/property/:propertyId/survey",
    icon: SurveyIcon,
    code: "4100",
  },
  {
    key: "property.unit",
    title: "property.unit",
    to: "/property/:propertyId/unit",
    icon: UnitIcon,
    code: "3000",
  },
  {
    key: "property.payment",
    title: "property.payment",
    to: "/property/:propertyId/payment",
    icon: PaymentIcon,
    code: "4000",
  },
  {
    key: "property.news-and-announcement",
    title: "property.news-and-announcement",
    to: "/property/:propertyId/news-and-announcement",
    icon: NewsAnnouncementIcon,
    code: "2100",
  },
  {
    key: "property.chat",
    title: "property.chat",
    to: "/property/:propertyId/chat",
    icon: ChatIcon,
    code: "2200",
  },
];
