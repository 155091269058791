import React from "react";
import { useTranslation } from "react-i18next";
import { SpaceStyle } from "..";
import { HeaderOverview } from "components";
import { LoadingDashboard, Wrapper } from "../..";

const BackOffice = () => {
  const { t } = useTranslation();
  const count = (2485).toLocaleString();
  return (
    <Wrapper className="h-[300px]">
      <LoadingDashboard loading={false}>
        <HeaderOverview
          title={t("back-office-and-all-application")}
          name="backOfficeDate"
        />
        <SpaceStyle className="center " direction="vertical">
          <div className="count">{count}</div>
          <div className="people">{t("people")}</div>
        </SpaceStyle>
      </LoadingDashboard>
    </Wrapper>
  );
};

export default BackOffice;
