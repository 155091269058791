import React from "react";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetRepairServiceCategories } from "service/property/house-care-service-setting";

export const CSelectRepairServiceCategory: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder}
      useQuery={useScrollGetRepairServiceCategories}
      valueKey="id"
      labelKey="title"
      searchKey="title"
      translation
      queryParams={{ isActive: true }}
    />
  );
};
