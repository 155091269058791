import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  IDashboardGraph,
  IDashboardParams,
  IDashboardTitle,
} from "../../interface";
import { axiosProperty, throwResponse } from "config/axios";

export const useGetPropertyDashboardActivities = (
  params?: IDashboardParams
): UseQueryResult<IDashboardTitle[], Error> => {
  return useQuery(
    ["dashboard-property-activity", "count-most-reader", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/activity/count-most-reader",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetPropertyDashboardActivitiesTime = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-property-activity", "count-reader-per-mont", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/activity/count-reader-per-month",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
