import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { ITaskStatus } from "./interface";
import {
  IDashboardGraph,
  IDashboardParams,
  IDashboardTitle,
} from "../../interface";

export const useGetPropertyDashboardTask = (): UseQueryResult<
  ITaskStatus,
  Error
> => {
  return useQuery(
    ["dashboard-property-task", "work-detail"],
    async () => {
      const res = await axiosProperty.get("/dashboard/task/work-detail");
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPropertyDashboardTaskCategory = (
  params?: IDashboardParams
): UseQueryResult<IDashboardTitle[], Error> => {
  return useQuery(
    ["dashboard-property-task", "graph-by-category", params],
    async () => {
      const res = await axiosProperty.get("/dashboard/task/graph-by-category", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPropertyDashboardTaskProgress = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-property-task", "graph-progress-monthly", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/task/graph-progress-monthly",
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};
