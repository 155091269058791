import { Select, SelectProps } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppLanguage } from "providers/app-language";
import { CSelectOption } from ".";

const StyledSelect = styled(Select)<{
  bgcolor?: number;
}>`
  width: 100%;
`;

export interface CSelectTagsProps extends SelectProps {
  valueOption?: CSelectOption;
  render?: (v: any[]) => ReactNode;
  mode?: "multiple" | "tags";
  bgcolor?: boolean;
}

export const CSelectTags: React.FC<CSelectTagsProps> = ({
  valueOption,
  options,
  bgcolor = true,
  mode = "tags",
  ...props
}) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const getOptions = () => {
    if (options && options.length) return options;
    if (!valueOption) return [];

    const {
      valueKey,
      labelKey,
      values,
      translation,
      translationI18n,
      notValueKey,
    } = valueOption;

    return values?.map((item) => {
      if (notValueKey) {
        // select object
        let label = item[labelKey];
        if (labelKey === "first-last-name") {
          label = `${item["firstName"]} ${item["lastName"]}`;
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });
          label = value?.[labelKey];
        }

        if (translationI18n) {
          label = t(item[labelKey]);
        }

        const value = JSON.stringify(item);
        return { ...item, value: value, label: label };
      } else {
        // select by valueKey
        if (labelKey === "first-last-name") {
          return {
            ...item,
            value: item[valueKey],
            label: `${item["firstName"]} ${item["lastName"]}`,
          };
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });

          return { ...item, value: item[valueKey], label: value?.[labelKey] };
        }

        if (translationI18n) {
          return { ...item, value: item[valueKey], label: t(item[labelKey]) };
        }

        return { ...item, value: item[valueKey], label: item[labelKey] };
      }
    });
  };

  const opt = getOptions();
  return (
    <StyledSelect
      {...(props as any)}
      options={opt}
      bgcolor={bgcolor ? 1 : 0}
      getPopupContainer={(e) => e}
      mode={mode}
    />
  );
};
