import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Col,
  Form,
  Modal,
  ModalProps,
  Row,
  Table,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { CButton } from "components/button/c-button";
import { useAppImport } from "providers/import-provider";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const StyledModal = styled(Modal)`
  .anticon-close > svg {
    margin: auto;
    display: block;
    width: 20px;
    height: 20px;
  }
`;
const { Dragger } = Upload;

export interface CModalImportProps extends ModalProps {
  top?: number;
  mutation?: (value?: any[]) => void;
}

export const CModalImportExcel: React.FC<CModalImportProps> = ({
  title,
  footer = null,
  open,

  closable = false,
  top = 200,
  mutation,

  ...props
}) => {
  const { onCancel } = useAppImport();
  return (
    <StyledModal
      style={{
        top: top,
      }}
      title={title}
      open={open}
      className="!w-[1000px]"
      onCancel={onCancel}
      closable={!closable}
      footer={footer}
      {...props}
      forceRender={false}
    >
      <ImportForm mutation={mutation} />
    </StyledModal>
  );
};

const ImportForm = ({ mutation }: { mutation?: (value?: any[]) => void }) => {
  const { t } = useTranslation();

  const {
    importForm,
    openExcel,
    column,
    onCancel,
    onImport,
    onOK,
    isDataValid,
    tableInvalid,
  } = useAppImport();

  const props: UploadProps = {
    name: "file",
    maxCount: 1,
    accept: ".xlsx",
    showUploadList: false,
    multiple: false,

    onChange(info: any) {
      onImport(info.file);
    },
  };

  return (
    <React.Fragment>
      <Form form={importForm}>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <Row align="stretch">
            <Col span={24}>
              <Form.Item name="import-file">
                {!openExcel ? (
                  <Dragger
                    {...props}
                    className="w-full"
                    customRequest={({ onSuccess }) => onSuccess}
                  >
                    <p className="ant-upload-drag-icon">
                      <PlusOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {t("click this area to upload")}
                    </p>
                  </Dragger>
                ) : (
                  <Table
                    className="!text-sm"
                    dataSource={openExcel || []}
                    columns={
                      (column &&
                        tableInvalid && [
                          {
                            title: (
                              <Typography.Title
                                level={5}
                                style={{
                                  fontSize: 12,
                                  margin: 0,
                                }}
                                ellipsis={{ tooltip: true }}
                              >
                                {t("import")}
                              </Typography.Title>
                            ),
                            width: "5%",
                            align: "center",
                            render: (_, __, index) => {
                              return (
                                <>
                                  {!tableInvalid[index].length ? (
                                    <CheckCircleTwoTone
                                      twoToneColor="#52c41a"
                                      key={index}
                                    />
                                  ) : (
                                    <Typography.Text key={index}>
                                      <CloseCircleTwoTone
                                        twoToneColor={"#FF0000"}
                                      />
                                    </Typography.Text>
                                  )}
                                </>
                              );
                            },
                          },
                          ...column,
                        ]) ||
                      []
                    }
                    scroll={{
                      x: 1200,
                    }}
                    size="large"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                    pagination={false}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              <CButton
                className="mr-5"
                onClick={onCancel}
                color="outline-green"
              >
                {t("cancel")}
              </CButton>
            </Col>
            <Col>
              <CButton
                onClick={() => onOK(mutation)}
                color="orange"
                disabled={!isDataValid}
              >
                {t("import")}
              </CButton>
            </Col>
          </Row>
        </div>
      </Form>
    </React.Fragment>
  );
};
