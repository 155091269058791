import { Wrapper } from "pages/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { SpaceStyle } from "..";
import { Col, Row } from "antd";
import { HeaderOverview } from "components";

const Group = () => {
  const { t } = useTranslation();

  const count = (2485).toLocaleString();

  return (
    <Wrapper className="h-[300px]">
      <HeaderOverview
        title={t("user-group")}
        formatDate="year"
        name="groupDate"
      />

      <Row>
        <Col span={8}>
          <SpaceStyle
            className="center  border-r-[2px] border-[#E5E5E5]"
            direction="vertical"
          >
            <div className="title">{t("administrator")}</div>
            <div className="count">{count}</div>
            <div className="people">{t("people")}</div>
          </SpaceStyle>
        </Col>
        <Col span={8}>
          <SpaceStyle
            className="center  border-r-[2px] border-[#E5E5E5]"
            direction="vertical"
          >
            <div className="title">{t("general-users")}</div>
            <div className="count">{count}</div>
            <div className="people">{t("people")}</div>
          </SpaceStyle>
        </Col>
        <Col span={8}>
          <SpaceStyle className="center  " direction="vertical">
            <div className="title">{t("condominium-officers")}</div>
            <div className="count">{count}</div>
            <div className="people">{t("people")}</div>
          </SpaceStyle>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Group;
