import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

interface IPieChart {
  series: number[];
  colors?: string[];
  height?: number;
  labels: string[];
}

export const CPieChart = ({
  labels,
  series,
  colors,
  height = 150,
}: IPieChart) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      fontFamily: "kanit-regular",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    labels: labels,
    legend: {
      show: false,
    },
    tooltip: {
    //   enabled: false,
    },
    colors: colors,
  };

  return (
    <Chart height={height} type="donut" options={options} series={series} />
  );
};
