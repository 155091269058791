import React from "react";
import { useTranslation } from "react-i18next";
import { IUserParams } from "service/administrator/interface";
import { useScrollGetPropertyAdministrators } from "service/property/administrator";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export interface CSelectPropertyUserProps extends CSelectProps {
  valueKey?: string;
  queryParams?: IUserParams;
}

export const CSelectPropertyUser: React.FC<CSelectPropertyUserProps> = ({
  queryParams,
  placeholder,
  valueKey,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      placeholder={placeholder ? placeholder : t("person")}
      useQuery={useScrollGetPropertyAdministrators}
      valueKey={valueKey ? valueKey : "id"}
      labelKey="first-last-name"
      searchKey="name"
      queryParams={{
        ...queryParams,
        isActive: true,
      }}
      {...props}
    />
  );
};
