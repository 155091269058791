import { SearchOutlined } from "@ant-design/icons";
import { Input, InputProps } from "antd";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

export interface CInputProps extends InputProps {
  search?: boolean;
  bgcolor?: boolean;
  phoneNumber?: boolean;
}
export const StyledInput = styled(Input)<{
  $bgcolor?: number;
  $phoneNumber?: number;
}>`
  ${tw`!rounded-app`}
  // border-color: ${({ $bgcolor }) => ($bgcolor ? "white" : "")}!important;
  height: 45px;
  .ant-input-group {
    height: 45px;
  }
  .ant-input-group .ant-input {
    height: 45px;
  }
  ${({ $phoneNumber }) =>
    $phoneNumber
      ? `.ant-input-group .ant-input-group-addon {
     background-color: #44955b; 
    }`
      : ``}
`;
export const CInput: React.FC<CInputProps> = ({
  search = false,
  bgcolor = true,
  phoneNumber = false,
  ...props
}) => {
  return (
    <StyledInput
      {...props}
      suffix={
        search ? (
          <SearchOutlined style={{ fontSize: 20, color: "#F0F0F0" }} />
        ) : undefined
      }
      $bgcolor={bgcolor ? 1 : 0}
      $phoneNumber={phoneNumber ? 1 : 0}
    />
  );
};
