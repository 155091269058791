import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App, ModalFuncProps } from "antd";
import { IconType } from "antd/es/notification/interface";
import React from "react";
import { useTranslation } from "react-i18next";
import { IAppPopupContext, NotificationProps } from "./interface";

const Context = React.createContext<IAppPopupContext>({} as IAppPopupContext);

const AppPopupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();

  const confirmDelete = (props?: ModalFuncProps): Promise<boolean> => {
    return new Promise((resolve) => {
      modal.confirm({
        title: t("are-you-sure", { ns: "message" }),
        icon: <ExclamationCircleOutlined />,
        content: t("this-action-is-irreversible", { ns: "message" }),
        // okButtonProps: { className: "!bg-primary" },
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        transitionName: "",
        centered: true,
        maskClosable: true,
        zIndex: 9999,
        ...props,
      });
    });
  };

  const confirmDuplicate = (props?: ModalFuncProps): Promise<boolean> => {
    return new Promise((resolve) => {
      modal.confirm({
        title: t("are-you-sure", { ns: "message" }),
        icon: <ExclamationCircleOutlined />,
        content: t("this-action-is-irreversible", { ns: "message" }),
        // okButtonProps: { className: "!bg-primary" },
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        transitionName: "",
        centered: true,
        maskClosable: true,
        zIndex: 9999,
        ...props,
      });
    });
  };

  const confirmDeleteGallery = (props?: ModalFuncProps): Promise<boolean> => {
    return new Promise((resolve) => {
      modal.confirm({
        icon: <ExclamationCircleOutlined />,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        transitionName: "",
        centered: true,
        maskClosable: true,
        zIndex: 9999,
        ...props,
      });
    });
  };

  const fireNotification = ({
    type,
    message,
    menu,
    description,
  }: NotificationProps) => {
    const messages: { [K in IconType]: string } = {
      success: t("action-completed", { ns: "message" }),
      info: t("warning", { ns: "message" }),
      warning: t("warning", { ns: "message" }),
      error: t("error-occurred", { ns: "message" }),
    };
    const msg = message || messages[type];

    return notification[type]({
      message: msg,
      duration: 3.5,
      className: "ronded-app",
      description: description,
    });
  };

  return (
    <Context.Provider
      value={{
        confirmDelete,
        confirmDuplicate,
        confirmDeleteGallery,
        fireNotification,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppPopup = () => React.useContext(Context);
export default AppPopupProvider;
