import { Form, Row, Col } from "antd";
import { CButton } from "components/button/c-button";
import { CDatePickerProps } from "components/input/c-date/c-date-picker";
import { useOverview } from "providers/overview";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IAction {
  searchType: "property" | "menu";
}

export const Action: FC<IAction> = ({ searchType }) => {
  const { t } = useTranslation();
  const { onSearchProperty } = useOverview();
  const { onClearProperty, onClearMenu, onSearchMenu } = useOverview();

  const onClear = searchType === "property" ? onClearProperty : onClearMenu;
  const onSearch = searchType === "menu" ? onSearchMenu : onSearchProperty;

  return (
    <Form.Item label=" ">
      <Row gutter={12}>
        <Col span={12}>
          <CButton
            fullWidth
            className="h-[45px] center"
            color="gray"
            onClick={onClear}
          >
            {t("clear-data")}
          </CButton>
        </Col>
        <Col span={12}>
          <CButton
            fullWidth
            className="h-[45px] center"
            color="orange"
            htmlType="submit"
            onClick={onSearch}
          >
            {t("search")}
          </CButton>
        </Col>
      </Row>
    </Form.Item>
  );
};
interface IPropsDate {
  year?: boolean;
}
export const propsDate = (props?: IPropsDate): CDatePickerProps => {
  const { year } = props || {};
  if (year)
    return {
      allowClear: false,
      notShowDate: true,
      picker: "year",
      className: "date-overview",
      formatDate: "year",
    };

  return {
    allowClear: false,
    notShowDate: true,
    picker: "month",
    className: "date-overview",
  };
};

export { RenderBarChart, type IRenderBarChart } from "./render-bar-chart";
export {
  CSelectPropertyMenus,
  type CSelectPropertyMenuProps,
} from "./select-menu-overview";
export { HeaderOverview, type IHeaderOverview } from "./header";
export { TextItem, type ITextItem } from "./text-item";
export { RenderLineChart, type IRenderLineChart } from "./render-line-chart";
export { SvgDot, type ISvgDot } from "./svg-dot";
export { TopBox, type ITopBox } from "./top-box";
export { RenderPieChart, type IRenderPieChart } from "./render-pie-chart";
export { Box, type IBox } from "./box-color";
export {
  RenderStackedBarChart,
  type IRenderStackedBarChart,
} from "./stacked-bar-char";
export { BoxText, type IBoxText } from "./box-text";
