import { Col, Collapse, Form, FormItemProps, Row } from "antd";
import {
  CButton,
  CDatePicker,
  CInput,
  CRangePicker,
  CSelect,
  CSelectComplaintStatus,
  CSelectComplaintType,
  CSelectMainCategory,
  CSelectPhoneCategory,
  CSelectPropertyPhoneCategory,
  CSelectPropertyUnit,
  CSelectPropertyWithSaleAndRent,
  // CSelectPropertyPhoneCategory,
  // CSelectPropertyUnit,
  CSelectRole,
  CSelectStatusActive,
  CSelectStatusSellAndRent,
  CSelectSubCategory,
  CSelectTypeSellAndRent,
} from "components";
import { CInputRangeSelling } from "components/input/c-input/input-selling-range";
import { CInputRangeRent } from "components/input/c-input/input-rent-range";
import { CSelectAdminProperty } from "components/input/c-select/c-select-admin-property";
import { CSelectFacilityItem } from "components/input/c-select/c-select-facility";
import { CSelectPropertyUnitWithUUID } from "components/input/c-select/c-select-property-unit-with-uuid";
import { CSelectPropertySuggestion } from "components/input/c-select/c-select-property-with-suggestion";
import { CSelectRepairServiceCategory } from "components/input/c-select/c-select-repair-service-category";
import { CSelectUser } from "components/input/c-select/c-select-user";
import { CSelectPropertyUser } from "components/input/c-select/c-select-user-property";
import { CSelectVehicleBrand } from "components/input/c-select/c-select-vehicle-brand";
import { CSelectVehicleType } from "components/input/c-select/c-select-vehicle-type";
import { CSelectRoleResident } from "components/input/c-select/select-role-resident";
import { useAppPageContainer } from "providers/app-page-container";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

export type PageFilterInputTypes =
  | "INPUT"
  | "SELECT"
  | "SELECT_STATUS_ACTIVE"
  | "SELECT_ROLE"
  | "SELECT_PHONE_CATEGORY"
  | "SELECT_PROPERTY_PHONE_CATEGORY"
  | "DATE_PICKER"
  | "RANGE_PICKER"
  | "SELECT_VEHICLE_TYPE"
  | "SELECT_VEHICLE_BRAND"
  | "SELECT_PROPERTY_UNIT"
  | "SELECT_PROPERTY_UNIT_WITH_UUID"
  | "SELECT_NEWS_TYPE"
  | "SELECT_ROLE_RESIDENT"
  | "SELECT_TYPE_SELL_RENT"
  | "SELECT_STATUS_SELL_RENT"
  | "INPUT_RANGE_SELLING"
  | "INPUT_RANGE_RENT"
  | "SELECT_PARCEL_STORAGE_ZONE"
  | "SELECT_DELIVERY_COMPANY"
  | "SELECT_HAVE_STICKER"
  | "SELECT_VEHICLE_STATUS"
  | "SELECT_STATUS_ACTION"
  | "SELECT_PROPERTY"
  | "SELECT_PROPERTY_SALE_AND_RENT"
  | "SELECT_USER"
  | "SELECT_MAIN_CATEGORY"
  | "SELECT_SUB_CATEGORY"
  | "SELECT_ADMIN_PROPERTY"
  | "SELECT_TYPE_COMPLAINT"
  | "SELECT_PROPERTY_SUGGESTION"
  | "SELECT_APPROVE_STATUS"
  | "SELECT_PARCEL_TRANSPORT"
  | "SELECT_PROPERTY_USER"
  | "SELECT_REPAIR_SERVICE_CATEGORY"
  | "SELECT_FACILITY";

export interface PageFilterItemProps {
  name: string;
  label: string;
  form?: FormItemProps;
  input: {
    type: PageFilterInputTypes;
    props?: Record<string, any>;
  };
}
export interface PageFilterProps {
  bgcolor?: boolean;
  className?: string;
  items: PageFilterItemProps[];
  onSearch: (v: any) => void;
  resetFields?: boolean;
}

const PageFilterWrapper = styled.div<{ $collapse: boolean }>`
  ${tw`bg-gray shadow `};
`;

export const PageFilter: React.FC<PageFilterProps> = ({
  bgcolor = true,
  className = "",
  items,
  onSearch,
  resetFields = false,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { collapseFilter } = useAppPageContainer();

  useEffect(() => {
    if (resetFields) onClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFields]);

  const getInput = (type: PageFilterInputTypes) => {
    switch (type) {
      case "INPUT":
        return CInput;
      case "SELECT":
        return CSelect;
      case "SELECT_STATUS_ACTIVE":
        return CSelectStatusActive;
      case "SELECT_ROLE":
        return CSelectRole;
      case "SELECT_PHONE_CATEGORY":
        return CSelectPhoneCategory;
      case "SELECT_PROPERTY_PHONE_CATEGORY":
        return CSelectPropertyPhoneCategory;
      case "DATE_PICKER":
        return CDatePicker;
      case "RANGE_PICKER":
        return CRangePicker;
      case "SELECT_VEHICLE_TYPE":
        return CSelectVehicleType;
      case "SELECT_VEHICLE_BRAND":
        return CSelectVehicleBrand;
      case "SELECT_PROPERTY_UNIT":
        return CSelectPropertyUnit;
      case "SELECT_PROPERTY_UNIT_WITH_UUID":
        return CSelectPropertyUnitWithUUID;
      case "SELECT_NEWS_TYPE":
        return CSelect.NewsType;
      case "SELECT_ROLE_RESIDENT":
        return CSelectRoleResident;
      case "SELECT_TYPE_SELL_RENT":
        return CSelectTypeSellAndRent;
      case "SELECT_STATUS_SELL_RENT":
        return CSelectStatusSellAndRent;
      case "INPUT_RANGE_SELLING":
        return CInputRangeSelling;
      case "INPUT_RANGE_RENT":
        return CInputRangeRent;
      case "SELECT_PARCEL_STORAGE_ZONE":
        return CSelect.ParcelStorageZone;
      case "SELECT_DELIVERY_COMPANY":
        return CSelect.DeliveryCompany;
      case "SELECT_HAVE_STICKER":
        return CSelect.VehicleSticker;
      case "SELECT_VEHICLE_STATUS":
        return CSelect.VehicleStatus;
      case "SELECT_STATUS_ACTION":
        return CSelectComplaintStatus;
      case "SELECT_PROPERTY":
        return CSelect.Property;
      case "SELECT_PROPERTY_SALE_AND_RENT":
        return CSelectPropertyWithSaleAndRent;
      case "SELECT_USER":
        return CSelectUser;
      case "SELECT_MAIN_CATEGORY":
        return CSelectMainCategory;
      case "SELECT_SUB_CATEGORY":
        return CSelectSubCategory;
      case "SELECT_ADMIN_PROPERTY":
        return CSelectAdminProperty;
      case "SELECT_TYPE_COMPLAINT":
        return CSelectComplaintType;
      case "SELECT_PROPERTY_SUGGESTION":
        return CSelectPropertySuggestion;
      case "SELECT_APPROVE_STATUS":
        return CSelect.ApproveStatus;
      case "SELECT_PROPERTY_USER":
        return CSelectPropertyUser;
      case "SELECT_REPAIR_SERVICE_CATEGORY":
        return CSelectRepairServiceCategory;
      case "SELECT_FACILITY":
        return CSelectFacilityItem;
    }
  };

  const getPlaceHolder = (item: PageFilterItemProps) => {
    const { input, label } = item;
    if (input.type === "INPUT" || input.type === "SELECT") {
      return { placeholder: label };
    }
    return {};
  };

  const onClear = () => {
    form.resetFields();
    form.submit();
  };

  const onFinish = () => {
    const value = form.getFieldsValue();
    onSearch(value);
  };

  return (
    <Collapse
      className="remove-padding-collapse"
      activeKey={collapseFilter ? [] : ["main"]}
      ghost
    >
      <Collapse.Panel showArrow={false} header={null} key="main">
        <PageFilterWrapper
          $collapse={collapseFilter}
          className={`rounded-app ${className}`}
        >
          <Form
            className="!p-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
          >
            <Row gutter={12}>
              <Col span={18}>
                <Row gutter={[12, 0]}>
                  {items.map((item) => {
                    const input = getInput(item.input.type) as any;
                    const holder = getPlaceHolder(item);

                    return (
                      <Col span={8} key={item.name}>
                        <Form.Item
                          name={item.name}
                          label={item.label}
                          {...item.form}
                        >
                          {React.createElement(input, {
                            ...holder,
                            ...item.input.props,
                            bgcolor,
                          })}
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col span={6}>
                <Form.Item label=" ">
                  <Row gutter={12}>
                    <Col span={12}>
                      <CButton fullWidth color="gray" onClick={onClear}>
                        {t("clear-data")}
                      </CButton>
                    </Col>
                    <Col span={12}>
                      <CButton fullWidth color="orange" onClick={onFinish}>
                        {t("search")}
                      </CButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </PageFilterWrapper>
      </Collapse.Panel>
    </Collapse>
  );
};
