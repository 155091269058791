import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosProperty, axiosCentral } from "config/axios";
import { IResponse, IResponseActive } from "config/axios/interface";
import { IUser, IUserParams } from "service/administrator/interface";
import {
  IPropertyGetAdministratorParams,
  IUserJuristicParams,
} from "./interface";

export const useGetPropertyAdministrators = (
  params?: IUserParams
): UseQueryResult<IResponseActive<IUser[]>, Error> => {
  return useQuery(
    ["administrator", "all", params],
    async () => {
      const res = await axiosProperty.get("/administrator/in-property?", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useScrollGetPropertyAdministrators = (
  params?: IUserParams
): UseInfiniteQueryResult<IResponse<IUser[]>, Error> => {
  return useInfiniteQuery(
    ["administrator", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/administrator/in-property?", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetPropertyAdministrator = (
  id?: number | string
): UseQueryResult<IUser> => {
  return useQuery(
    ["administrator", "id", id],
    async () => {
      if (!id) return;
      const res = await axiosProperty.get(`/administrator/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!id }
  );
};

export const usePropertyGetAdministrator = (
  params?: IPropertyGetAdministratorParams
): UseQueryResult<IResponse<IUser[]>, Error> => {
  return useQuery(
    ["property-get-administrator", params],
    async () => {
      const res = await axiosProperty.get("/property/get-administrator", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetAdministratorJuristic = (
  params: IUserJuristicParams
): UseQueryResult<IResponse<IUser[]>, Error> => {
  return useQuery(
    ["administrator-juristic", params],
    async () => {
      const res = await axiosCentral("/administrator/juristic", { params });

      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyPatchJuristic = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...rest }: any) => {
    const res = await axiosCentral.patch(`/property/${id}/add-juristic`, {
      ...rest,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyDeleteAdministrator = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/property/delete-admin/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyDeleteAdministratorBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/property/delete-admin/bulk`, {
      data,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
