import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import { Form } from "antd";
import { IData } from "service/dashboard/interface";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useGetDashboardRepairingMostCategory } from "service/dashboard/central/homecare";

const COLORS = [
  "#0088FE",
  "#8EC641",
  "#FFBB28",
  "#FF8042",
  "#276347",
  "#F8931D",
  "#E5E5E5",
  "#535A6E",
];

const Budgets = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { translationObject } = useAppLanguage();

  const budgetsDate = Form.useWatch("budgetsDate");
  const date = findDate(budgetsDate);

  const { data, isFetching, isError, error } =
    useGetDashboardRepairingMostCategory({ ...date, property });

  const getData: IData[] =
    data?.map((e) => {
      const { title } = translationObject({ object: e, keys: ["title"] });
      return { name: title, count: e.count || 0 };
    }) || [];

  const dataPie: IRenderPieChart = {
    data: getData,
    dataKey: "count",
    dataName: "name",
    color: COLORS,
  };

  return (
    <WrapperBG className="h-[350px]">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("budgets-for-different-categories-of-work")}
          name="budgetsDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default Budgets;
