import { Form } from "antd";
import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import { WrapperBG } from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetPropertyDashboardFacilityMonth } from "service/dashboard/property/facility";
import { ErrorDashboard, LoadingDashboard, NoDataDashboard } from "../..";

const COLORS = ["#F8931D", "#8EC641"];

interface IData {
  name: string;
  count: number;
}

const FacilityMonth = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const facilitiesUsedEachMonthDate = Form.useWatch(
    "facilitiesUsedEachMonthDate"
  );
  const date = findDate(facilitiesUsedEachMonthDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardFacilityMonth({
      ...date,
    });

  const getData: IData[] = [
    {
      name: t("no-advance-reservation-required"),
      count: data?.countDisallowBooking || 0,
    },
    {
      name: t("advance-reservation-required"),
      count: data?.countPreBooking || 0,
    },
  ];

  const dataPie: IRenderPieChart = {
    data: getData,
    dataKey: "count",
    dataName: "name",
    color: COLORS,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("number-of-facilities-used")}
          name="facilitiesUsedEachMonthDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default FacilityMonth;
