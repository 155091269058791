import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectVehicleType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("type")}
      valueOption={{
        values: [
          { value: "MOTORCYCLE", title: t("motorcycle") },
          { value: "CAR", title: t("car") },
          { value: "BICYCLE", title: t("bicycle") },
          { value: "OTHER", title: t("other") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
