import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { IPropertyMenu } from "./interface";
import { axiosCentral, throwResponse } from "config/axios";

export const useGetPropertyMenu = (
  id?: string | number
): UseQueryResult<IPropertyMenu[], Error> => {
  return useQuery(
    ["property-menu", id],
    async () => {
      const res = await axiosCentral.get(`/property-menu/${id}`);

      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePatchPropertyMenu = (): UseMutationResult => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axiosCentral.patch(
      `/property-menu/update-status/${id}`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
