import React from "react";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetRepairServiceSubCategories } from "service/property/house-care-service-setting";

export interface CSelectRepairServiceSubCategoryProps extends CSelectProps {
  queryParams?: Record<string, any>;
}

export const CSelectRepairServiceSubCategory: React.FC<
  CSelectRepairServiceSubCategoryProps
> = ({ placeholder, queryParams, ...props }) => {
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder}
      useQuery={useScrollGetRepairServiceSubCategories}
      valueKey="id"
      labelKey="title"
      searchKey="title"
      translation
      queryParams={{ ...queryParams, isActive: true }}
    />
  );
};
