import { Form, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { ComponentType, createContext, useContext, useState } from "react";
import {
  DataType,
  IAppImportProps,
  IContextImport,
  ImportFile,
} from "./interface";
import * as XLSX from "xlsx";
import { UseMutationResult } from "@tanstack/react-query";
import React from "react";
import { isPhoneNumber, isValidEmail } from "tools/string";
import dayjs from "config/dayjs";

const Context = createContext<IContextImport>({} as IContextImport);

const withAppImportProvider = (Components: ComponentType) => {
  return () => {
    const [importForm] = Form.useForm();
    const [openExcel, setOpenExcel] = useState<Array<any>>();
    const [column, setColumn] = useState<ColumnsType<DataType>>();
    const [isModalImportOpen, setIsModalImportOpen] = useState<boolean>(false);
    const [importOption, setImportOption] = useState<IAppImportProps>();
    const onCancel = () => {
      setIsModalImportOpen(false);
    };

    const onOK = (
      mutation?:
        | UseMutationResult<unknown, Error, unknown, unknown>
        | (() => void)
    ) => {
      if (typeof mutation === "function") {
        setIsModalImportOpen(false);

        return mutation();
      }
      mutation?.mutate({}, {});

      setIsModalImportOpen(false);

      return;
    };

    const ExcelDateToJSDate = (serial: number) => {
      const secondsInDay = 24 * 60 * 60;
      const excelEpoch = new Date(1899, 11, 31);
      const excelEpochAsUnixTimestamp = excelEpoch.getTime();
      const missingLeapYearDay = secondsInDay * 1000;
      const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
      const excelTimestampAsUnixTimestamp = serial * secondsInDay * 1000;
      const parsed = excelTimestampAsUnixTimestamp + delta;

      return parsed;
    };

    const onImport = (event: ImportFile) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target?.result;
        const wb = XLSX.read(bstr, {
          type: "binary",
        });
        const sheetName = wb.SheetNames[0];
        const options = {
          defval: "",
        };
        const rowData: any[] = XLSX.utils.sheet_to_json(
          wb.Sheets[sheetName],
          options
        );

        const tHeader = Object?.keys?.(rowData?.[0] || []);

        setColumn?.(
          tHeader.map((e: any, i) => {
            return {
              title: (
                <Typography.Title
                  key={i}
                  ellipsis={{ tooltip: true }}
                  level={5}
                  style={{
                    fontSize: 12,
                    margin: 0,
                  }}
                >
                  {e}
                </Typography.Title>
              ),
              dataIndex: e,
              key: i,
              align: "center",
              width: e === "#" ? "5%" : "10%",
              ellipsis: {
                showTitle: true,
              },
              render: (rc: any) => {
                return (
                  <Typography.Text
                    ellipsis={{ tooltip: true }}
                    style={{
                      fontSize: 12,
                      color: "#697179",
                    }}
                  >
                    {`${rc}`}
                  </Typography.Text>
                );
              },
            };
          })
        );

        if (tHeader.some((e) => e.includes("เวลา"))) {
          const rowDataWithTime = rowData.map((e) => {
            return Object.keys(e).reduce((p: any, c: any) => {
              const time = c.includes("เวลา");
              if (time)
                return {
                  ...p,
                  [c]: dayjs(ExcelDateToJSDate(e[c])).format("HH:mm"),
                };
              return { ...p, [c]: e[c] };
            }, {});
          });
          return setOpenExcel?.(rowDataWithTime);
        }

        return setOpenExcel?.(rowData);
      };

      reader.readAsBinaryString(event.originFileObj);
    };

    const onFilter = ({
      phoneNumber,
      email,
    }: {
      phoneNumber?: string;
      email?: string;
    }) => {
      let isPhoneValid = false;
      let isEmailValid = false;

      if (phoneNumber) {
        const phone = isPhoneNumber(phoneNumber, "TH");
        if (phone && phoneNumber.length === 10) {
          isPhoneValid = true;
        }
      }

      if (email && isValidEmail(email)) {
        isEmailValid = true;
      }

      if (!email) {
        return isPhoneValid;
      }

      if (!phoneNumber) {
        return isEmailValid;
      }

      if (isPhoneValid && isEmailValid) {
        return true;
      } else {
        return false;
      }
    };

    const result = openExcel?.map((obj) => {
      const updatedObj = {} as any;
      for (const key in obj) {
        if (key.endsWith("*")) {
          updatedObj[key] = obj[key];
        }
      }
      return updatedObj;
    });

    const isDataValid = result?.every((obj) => {
      return Object.values(obj).every(
        (val) => val !== "" && val !== undefined && val !== null
      );
    });

    const tableInvalid = result?.map((e) =>
      Object.values(e).filter((e) => e === "")
    );

    React.useEffect(() => {
      setColumn(undefined);
      setOpenExcel(undefined);
    }, [isModalImportOpen]);
    return (
      <Context.Provider
        value={{
          importForm,
          openExcel,
          setOpenExcel,
          onFilter,
          column,
          setColumn,
          isModalImportOpen,
          setIsModalImportOpen,
          onCancel,
          onOK,
          onImport,
          importOption,
          setImportOption,
          isDataValid,
          tableInvalid,
        }}
      >
        <Components />
      </Context.Provider>
    );
  };
};

export const useAppImport = () => useContext(Context);
export default withAppImportProvider;
