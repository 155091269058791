import { Divider, Row, Typography } from "antd";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  ActiveTypes,
  CARD_THEME,
  CardThemeTypes,
  StatusCardTypes,
  STATUS_CARD,
  ThemeOption,
} from "./theme";

export interface ICCardDefault {
  className?: string;
  style?: CSSProperties;
  hover?: boolean;
  theme?: CardThemeTypes;
  statusType?: StatusCardTypes;
  value?: IValueCard;
  active?: boolean;
  onClick?: () => void;
  hideColon?: boolean;
}

export interface IValueCard {
  title: string;
  text: string;
  data: any;
  status?: ActiveTypes;
}

const CardWrapper = styled.div<{
  $active?: boolean;
  themeColor: ThemeOption;
  hover?: boolean;
  statusColor?: string;
}>`
  &:before {
    content: " ";
    border-left: ${({ $active, themeColor }) =>
      $active ? `5px solid ${themeColor.border}` : ""};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
  }
  background-color: ${({ $active, themeColor, hover }) =>
    !hover ? themeColor.hoverBg : $active ? themeColor.hoverBg : themeColor.bg};
  position: relative;
  cursor: pointer;
  .title {
    color: #0a1430;
  }
  .text {
    color: #7e7e7e;
  }
  .value {
    color: ${({ $active, themeColor }) =>
      $active ? themeColor.hoverColor : themeColor.color};
  }
  .status {
    color: ${({ statusColor }) => statusColor};
  }
  .ant-divider {
    border-block-start: ${({ $active }) => ($active ? "1px solid white" : "")};
  }
  &:hover {
    background-color: ${({ themeColor }) => themeColor.hoverBg};
    &:before {
      content: " ";
      border-left: ${({ themeColor }) => `5px solid ${themeColor.border}`};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      position: absolute;
      left: 0;
      top: 0px;
      bottom: 0px;
    }
    .ant-divider {
      border-block-start: 1px solid white;
    }
    .value {
      color: ${({ themeColor }) => themeColor.hoverColor};
    }
  }
`;

export const CCardDefault: React.FC<ICCardDefault> = ({
  theme,
  hover = true,
  statusType,
  value,
  active,
  onClick,
  className,
  hideColon,
  style,
}) => {
  const { t } = useTranslation();
  const themeColor = CARD_THEME[theme || "green"];
  const statusCard = STATUS_CARD[statusType || "active-inActive"];
  const status = statusCard[value?.status || "active"];
  return (
    <CardWrapper
      onClick={onClick}
      className={`mb-2 rounded-app ${className}`}
      style={style}
      $active={active}
      themeColor={themeColor}
      hover={hover}
      statusColor={status.color}
    >
      <Row className="px-4 py-2 " justify="space-between">
        <Typography.Text className="title" ellipsis={{ tooltip: value?.title }}>
          {value?.title || "-"}
        </Typography.Text>
        <div className="status" hidden={statusType ? false : true}>
          {t(status?.title) || "-"}
        </div>
      </Row>
      <Divider className="!m-0" />
      <div className="px-4 py-6">
        <span className="text">{value?.text || "-"}</span>{" "}
        <span className="px-1" hidden={hideColon}>
          :
        </span>{" "}
        <span className="value"> {value?.data}</span>
      </div>
    </CardWrapper>
  );
};
