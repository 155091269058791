import {
  ActivitiesIcon,
  NewsAnnouncementIcon,
  PhoneBookIcon,
  PropertyIcon,
  ResidentsIcon,
  SurveyIcon,
  UserManagementIcon,
  GalleryIcon,
  SettingIcon,
  OverviewIcon,
  SaleAndRentIcon,
  RoutineIcon,
  ComplaintIcon,
  WorkSpaceIcon,
} from "assets/images/icon/menu";
import { SiderMenuItemProps } from "layouts/menu/item";

export const MAIN_MENUS: SiderMenuItemProps[] = [
  {
    key: "overview",
    title: "overview",
    to: "/",
    icon: OverviewIcon,
    code: "0100",
  },
  {
    key: "property.list",
    title: "property.list",
    to: "/property",
    icon: PropertyIcon,
    code: "0200",
  },
  {
    key: "news-and-announcement",
    title: "news-and-announcement",
    to: "/news-and-announcement",
    icon: NewsAnnouncementIcon,
    code: "0300",
  },
  {
    key: "activities",
    title: "activities",
    to: "/activities",
    icon: ActivitiesIcon,
    code: "0400",
  },
  {
    key: "survey",
    title: "survey",
    to: "/survey",
    icon: SurveyIcon,
    code: "0500",
  },
  {
    key: "user-management",
    title: "user-management",
    to: "/user-management",
    icon: UserManagementIcon,
    code: "0600",
  },
  {
    key: "residents",
    title: "residents",
    to: "/residents",
    icon: ResidentsIcon,
    code: "0700",
  },
  {
    key: "phone-book",
    title: "phone-book",
    to: "/phone-book",
    icon: PhoneBookIcon,
    code: "0800",
    children: [
      {
        key: "phone-book.list",
        title: "phone-book.list",
        to: "/phone-book/list",
        code: "0801",
      },
      {
        key: "phone-book.category-setting",
        title: "phone-book.category-setting",
        to: "/phone-book/category-setting",
        code: "0802",
      },
    ],
  },
  {
    key: "gallery",
    title: "gallery",
    to: "/gallery",
    icon: GalleryIcon,
    code: "0900",
  },
  {
    key: "work-manage",
    title: "work-manage",
    to: "/work-manage",
    icon: RoutineIcon,
    code: "1200",
    children: [
      {
        key: "unroutine",
        title: "unroutine",
        to: "/work-manage/unroutine",
        code: "1201",
      },
      {
        key: "work-calendar",
        title: "work-calendar",
        to: "/work-manage/work-calendar",
        code: "1202",
      },
      {
        key: "work-setting",
        title: "work-setting",
        to: "/work-manage/work-setting",
        code: "1203",
      },
    ],
  },
  {
    key: "work-space",
    title: "work-space",
    to: "/work-space",
    icon: WorkSpaceIcon,
    code: "1400",
    children: [
      {
        key: "work-space-list",
        title: "work-space-list",
        to: "/work-space/work-space-list",
        code: "1401",
      },
      {
        key: "request-list",
        title: "request-list",
        to: "/work-space/request",
        code: "1402",
      },
    ],
  },
  {
    key: "sell-and-rent",
    title: "sell-and-rent",
    to: "/sell-and-rent",
    icon: SaleAndRentIcon,
    code: "1100",
  },
  {
    key: "complaint",
    title: "complaint",
    to: "/complaint",
    icon: ComplaintIcon,
    code: "1300",
    children: [
      {
        key: "complaint.list",
        title: "complaint.list",
        to: "/complaint/list",
        code: "1301",
      },
      {
        key: "complaint.setting",
        title: "complaint.setting",
        to: "/complaint/setting",
        code: "1302",
      },
    ],
  },
  {
    key: "setting",
    title: "setting",
    to: "/setting",
    icon: SettingIcon,
    code: "1000",
    children: [
      {
        key: "setting.role",
        title: "setting.role",
        to: "/setting/role",
        code: "1001",
      },
      {
        key: "setting.menu-display",
        title: "setting.menu-display",
        to: "/setting/menu-display",
        // code: "1005",
        code: "1001",
      },
      {
        key: "setting.terms-and-condition",
        title: "setting.terms-and-condition",
        to: "/setting/terms-and-condition",
        code: "1002",
      },
      {
        key: "setting.privacy",
        title: "setting.privacy",
        to: "/setting/privacy",
        code: "1003",
      },
      {
        key: "setting.pdpa",
        title: "setting.pdpa",
        to: "/setting/pdpa",
        code: "1004",
      },
    ],
  },
];
