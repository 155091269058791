import { FC } from "react";
import { useTranslation } from "react-i18next";
import provinces from "components/data/province.json";
import { CSelect, CSelectProps } from ".";

const CSelectProvince: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const data = provinces.data;

  return (
    <CSelect
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => (option?.value || "").toString().includes(input)}
      placeholder={t("plate-province")}
      {...props}
      valueOption={{
        values: data,
        labelKey: "name",
        valueKey: "nameTh",
        translation: true,
      }}
    />
  );
};

export default CSelectProvince;
