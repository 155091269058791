import { Form } from "antd";
import { HeaderOverview } from "components";
import { WrapperBG } from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetPropertyDashboardParcelByStatus } from "service/dashboard/property/parcel";
import { LoadingDashboard } from "../..";

const ParcelStill = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const parcelsStillDate = Form.useWatch("parcelsStillDate");
  const date = findDate(parcelsStillDate);

  const { data, isFetching } = useGetPropertyDashboardParcelByStatus({
    ...date,
  });

  const count = (data?.overTime || 0).toLocaleString();

  return (
    <WrapperBG className="h-[300px]">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("number-of-parcels-still")}
          name="parcelsStillDate"
        />
        <div className="h-[200px] center flex-col">
          <div className="text-[2.5rem] text-[#44955B]">{count}</div>
          <div className="text-[1.5rem] text-[#276347]">{t("pieces")}</div>
        </div>
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default ParcelStill;
