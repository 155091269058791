import { Modal, ModalProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import { CModalExport, CModalExportProps } from "./modal-export";
import CModalHeader, { CModalHeaderProps } from "./modal-header";
import { CModalImportExcel, CModalImportProps } from "./modal-import";

type NonationTypes = {
  // ConfirmDelete:
  CModalHeader: React.FC<CModalHeaderProps>;
  CModalExport: React.FC<CModalExportProps>;
  CModalImport: React.FC<CModalImportProps>;
};

const StyledModal = styled(Modal)`
  .anticon-close > svg {
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
  }
`;

export interface CModalProps extends ModalProps {}

export const CModal: FC<CModalProps> & NonationTypes = ({
  footer = null,
  closable = false,
  centered = true,
  ...props
}) => {
  return (
    <StyledModal
      centered={centered}
      closable={closable}
      footer={footer}
      {...props}
      transitionName=""
    />
  );
};

CModal.CModalHeader = CModalHeader;
CModal.CModalExport = CModalExport;
CModal.CModalImport = CModalImportExcel;
