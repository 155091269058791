import { Col, Form, Row } from "antd";
import { CDatePicker, propsDate } from "components";
import { FC } from "react";

export interface IHeaderOverview {
  title: string;
  onChange?: (v: any) => void;
  formatDate?: string | "year";
  name: string;
}

export const HeaderOverview: FC<IHeaderOverview> = ({
  title,
  onChange,
  formatDate,
  name,
}) => {
  const props = propsDate({ year: formatDate === "year" });
  return (
    <Row className="h-[60px] ">
      <Col
        span={12}
        // className="!flex !flex-col !justify-center"
      >
        {title}
      </Col>
      <Col span={12} className="grid justify-items-end ">
        <Form.Item noStyle name={name}>
          <CDatePicker {...props} onChange={onChange} />
        </Form.Item>
      </Col>
    </Row>
  );
};
