import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IDashboardParams } from "../../interface";
import { axiosCentral, throwResponse } from "config/axios";
import { IVehicle, IVehicleBrand } from "./interface";

export const useGetDashboardVehicle = (
  params?: IDashboardParams
): UseQueryResult<IVehicle, Error> => {
  return useQuery(
    ["dashboard-vehicle", "most-used-vehicle-types", params],
    async () => {
      const res = await axiosCentral.get(
        "dashboard/vehicle/most-used-vehicle-types",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetDashboardMostVehicle = (
  params?: IDashboardParams
): UseQueryResult<IVehicleBrand[], Error> => {
  return useQuery(
    ["dashboard-vehicle", "graph-most-used-vehicle-brand", params],
    async () => {
      const res = await axiosCentral.get(
        "dashboard/vehicle/graph-most-used-vehicle-brand",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};
