import { Form } from "antd";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { CustomizedDot } from "pages/property/overview";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboardParcelAvgDate } from "service/dashboard/central/parcel";
import { IData } from "service/dashboard/interface";

const AnalyzeTrendsParcels = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { formatDate } = useAppLanguage();

  const analyzeTrendsParcelsDate = Form.useWatch("analyzeTrendsParcelsDate");
  const date = findDate(analyzeTrendsParcelsDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardParcelAvgDate({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("analyze-trends-in-the-number-of-parcels")}
            name="analyzeTrendsParcelsDate"
            formatDate="year"
          />
          <div className="h-[300px]"></div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    CustomizedDot: CustomizedDot,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("analyze-trends-in-the-number-of-parcels")}
          name="analyzeTrendsParcelsDate"
          formatDate="year"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderLineChart {...dataLine} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default AnalyzeTrendsParcels;
