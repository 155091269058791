import { Form } from "antd";
import { TopBox } from "components";
import { LoadingDashboard } from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboardRepairingUnit } from "service/dashboard/central/homecare";

const UnitBox = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const unitRepairDate = Form.useWatch("unitRepairDate");
  const date = findDate(unitRepairDate);

  const query = useGetDashboardRepairingUnit({ ...date, property });

  return (
    <LoadingDashboard loading={query.isFetching}>
      <TopBox
        query={query}
        nameDate="unitRepairDate"
        title={t("top-5-unit-repair")}
        keyTitle="unitNumber"
      />
    </LoadingDashboard>
  );
};

export default UnitBox;
