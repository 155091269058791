import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetParcelSettingTypes } from "service/parcel-setting-type";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export interface CSelectParcelTypeProps extends CSelectProps {
  queryParams?: Record<string, any>;
}

export const CSelectParcelType: React.FC<CSelectParcelTypeProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder ? placeholder : t("property", { ns: "menu" })}
      useQuery={useScrollGetParcelSettingTypes}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      translation
    />
  );
};
