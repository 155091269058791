import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IDashboardParams } from "../../interface";
import { axiosProperty, throwResponse } from "config/axios";
import { IMdpc } from "./interface";

export const useGetPropertyDashboardMDPC = (
  params?: IDashboardParams
): UseQueryResult<IMdpc, Error> => {
  return useQuery(
    ["dashboard-property-mdpc", "count-by-types", params],
    async () => {
      const res = await axiosProperty.get("dashboard/mdpc/count-by-types", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
