import { Wrapper } from "..";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import MostUser from "./content/most-user";
import MostUsedItems from "./content/most-used-item";

const WorkSpaceDashboard = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]} className="h-full w-full">
        <Col span={24}>{t("work-space", { ns: "menu" })}</Col>
        <Col md={24} lg={24} xl={12}>
          <MostUser />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <MostUsedItems />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default WorkSpaceDashboard;
