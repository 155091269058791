import { CaretRightFilled, FileExclamationFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Row, Image, Typography } from "antd";
import { Clear } from "assets/images";
import { ExcelIcon, PDFIcon, WordIcon } from "assets/images/icon/gallery";
import {
  CButton,
  CIndicator,
  EmptyPage,
  ErrorPage,
  fireNotification,
  PaginationPage,
} from "components";
import CDrawer from "components/display/c-drawer";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useGetMediaObjects, usePostMediaObject } from "service/media-object";
import {
  IMediaObject,
  IMediaObjectParams,
  TMediaObject,
} from "service/media-object/interface";
import styled from "styled-components";
import tw from "twin.macro";
import { AcceptFileTypes, CImage } from "..";
import { FilePicker } from "./file-picker";
import { useAppActionRole } from "providers/action-role";

const CImageWrapper = styled.div<{ $active: boolean }>`
  width: 150px;
  height: 150px;
  overflow: hidden;
  background-color: #e8efec;
  border: ${({ $active }) =>
    $active ? "4px solid #7BB54E" : "1px solid #E8EFEC"};
  ${tw`inline-flex items-center justify-center`};
  ${tw`rounded-app`};
  &:hover {
    border: 4px solid #7bb54e;
  }
`;

interface IModalImage {
  visible: boolean;
  toggle: () => void;
  onSave: (v?: IMediaObject | IMediaObject[]) => void;
  type: TMediaObject;
  title?: string;
  accepts?: AcceptFileTypes[];
  resize?: boolean;
  selectMulti?: boolean;
}

export const CImageModal: React.FC<IModalImage> = ({
  visible,
  toggle,
  onSave,
  type = "image",
  accepts,
  resize,
  selectMulti = false,
  ...props
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const qClient = useQueryClient();
  const { actionMenu } = useAppActionRole();
  const [image, setImage] = useState<IMediaObject>();
  const [images, setImages] = useState<IMediaObject[]>([]);
  const [params, setParams] = useState<IMediaObjectParams>({
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    if (selectMulti) return setImages([]);
    return setImage(undefined);
  }, [visible, selectMulti]);

  const { data, isFetching, isError, error } = useGetMediaObjects({
    ...params,
    enabled: visible,
    fileType: type,
  });
  const postMedia = usePostMediaObject();

  const onFilePickerChange = (file: File) => {
    const isLt10M = file.size / 1024 / 1024 <= 10;
    const isLt30M = file.size / 1024 / 1024 <= 30;
    if (file.type.includes("video") && !isLt30M) {
        return fireNotification({
          type: "error",
          description: t("images-cannot-larger-30mb", { ns: "message" }),
        });
    } else 
      if (!file.type.includes("video") && !isLt10M) {
        return fireNotification({
          type: "error",
          description: t("images-cannot-larger-10mb", { ns: "message" }),
        });
      }
    return postMedia.mutate(
      { imageFile: file },
      {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["media-object"]);
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
          });
        },
      }
    );
  };

  const onClickImage = (value: IMediaObject) => {
    if (selectMulti) {
      const find = (images || [])?.find((e) => e === value);
      if (find) {
        const filter = (images || [])?.filter((e) => e !== value);
        return setImages(filter);
      }
      return setImages([...images, value]);
    } else {
      if (value === image) {
        return setImage(undefined);
      }
      return setImage(value as IMediaObject);
    }
  };

  const title =
    type === "application"
      ? t("select-files-gallery")
      : type === "video"
        ? t("select-video-gallery")
        : t("select-image-gallery");

  const button =
    type === "application"
      ? t("upload-files")
      : type === "video"
        ? t("upload-video-files")
        : t("upload-image-files");

  return (
    <CDrawer
      {...props}
      title={title}
      open={visible}
      onSave={() => {
        if (selectMulti) {
          return onSave?.(images);
        }
        return onSave?.(image);
      }}
      onCancel={toggle}
      forceRender={false}
      className="!p-0"
      width="55%"
    >
      <div className="w-full">
        <Row className="w-full p-4">
          <FilePicker
            ref={ref}
            onChange={onFilePickerChange}
            accepts={accepts}
            resize={resize}
          />
          <Col span={18}></Col>
          <Col span={6} hidden={!actionMenu?.action}>
            <CButton
              fullWidth
              loading={postMedia.isLoading}
              onClick={() => {
                return ref.current?.click();
              }}
              style={{ height: 45 }}
            >
              {button}
            </CButton>
          </Col>
        </Row>
        <Divider className="!m-0" />
        {isFetching ? <CIndicator className="center h-full p-4" /> : null}
        {isError && error ? (
          <div className="center h-full p-4">
            <ErrorPage error={error} />{" "}
          </div>
        ) : null}
        {!data?.data || (data?.data.length || 0) < 1 ? (
          <EmptyPage className="p-4 h-full" />
        ) : null}
        {data?.data && data.data.length > 0 && !isFetching ? (
          <Row gutter={[5, 5]} className="w-full p-4">
            {data?.data.map((item) => {
              if (type === "application") {
                const active = images.find((e) => e === item);
                return (
                  <Col key={item.id}>
                    <CImageWrapper
                      $active={!!active}
                      className="cursor-pointer"
                      onClick={() => onClickImage(item)}
                    >
                      <FileGrid media={item} />
                    </CImageWrapper>
                  </Col>
                );
              }
              if (type === "video")
                return (
                  <Col key={item.id}>
                    <CImageWrapper
                      $active={item === image}
                      className="cursor-pointer"
                      onClick={() => onClickImage(item)}
                    >
                      <VideoGrid media={item} />
                    </CImageWrapper>
                  </Col>
                );
              return (
                <Col key={item.id}>
                  <CImageWrapper
                    $active={item === image}
                    className="cursor-pointer"
                    onClick={() => onClickImage(item)}
                  >
                    <CImage src={item.url} rounded={false} />
                  </CImageWrapper>
                </Col>
              );
            })}
            <Col span={24}>
              <PaginationPage
                total={data?.count?.count || 0}
                current={params.page || 1}
                pageSize={params?.limit}
                pageCount={data?.pageCount || 0}
                pageSizeOptions={[20, 40, 80, 160]}
                onChange={(page, limit) => {
                  setParams({ ...params, page, limit });
                }}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    </CDrawer>
  );
};

interface IGrid {
  media: IMediaObject;
}

type TFileTypes = "pdf" | "sheet" | "doc";

const files: { [K in TFileTypes]: { icon: any; bg: string } } = {
  pdf: { icon: PDFIcon, bg: "#FFEFEF" },
  sheet: { icon: ExcelIcon, bg: "#EDF5F1" },
  doc: { icon: WordIcon, bg: "#E7EFFE" },
};

const FileGrid: React.FC<IGrid> = ({ media }) => {
  const typesFile = ["pdf", "sheet", "doc"];
  const type = typesFile.find((e) => media.mimeType?.includes(e)) as TFileTypes;
  const background = files[type]?.bg || "#E5E5E5";
  const Icon = files[type]?.icon || FileExclamationFilled;

  return (
    <React.Fragment>
      <div className="rounded-app absolute" style={{ background }}>
        <div className="flex flex-col justify-end w-[140px] h-[140px]">
          <div className="w-full flex justify-center items-end pb-4 text-[26px] text-[gray]">
            <Icon width={"50%"} height={"50%"} />
          </div>
          <div className="px-5 pb-5">
            <Typography.Text ellipsis={{ tooltip: media?.filename }}>
              {media?.originalFilename}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Image
        src={Clear}
        preview={false}
        id={`${media.id}`}
        className="h-[130px] w-[130px]"
      />
    </React.Fragment>
  );
};

const VideoMask = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  color: white;
`;

const VideoGrid: React.FC<IGrid> = ({ media }) => {
  return (
    <div className="rounded-app">
      <div className="absolute">
        <VideoMask>
          <div className="h-full flex justify-center items-center">
            <Row
              justify="center"
              style={{ background: "rgb(39, 99, 71, 0.6)" }}
              className="w-[30px] h-[30px] rounded-full"
            >
              <CaretRightFilled className="text-[20px] pl-[2px]" />
            </Row>
          </div>
        </VideoMask>
        <ReactPlayer width={140} height={140} url={media.url} />
      </div>
      <Image
        src={Clear}
        preview={false}
        id={`${media.id}`}
        className="!h-[140px] !w-[140px]"
      />
    </div>
  );
};
