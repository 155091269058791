import { Col, Row } from "antd";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import MostReaderNews from "./content/most-reader";
import CountPerMonth from "./content/count-reader-per-month";

const NewsAndAnnouncement = () => {
  const { t } = useTranslation();
  return (
    <Wrapper minHeight={385}>
      <Row gutter={[14, 14]} className="h-full w-full">
        <Col span={24}>
          {t("property.news-and-announcement", { ns: "menu" })}
        </Col>
        <Col md={24} lg={24} xl={8}>
          <MostReaderNews />
        </Col>
        <Col md={24} lg={24} xl={16}>
          <CountPerMonth />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default NewsAndAnnouncement;
