import React from "react";
import { HeaderOverview, IRenderBarChart, RenderBarChart } from "components";
import { useTranslation } from "react-i18next";
import { WrapperBG } from "pages/overview";
import { useGetPropertyDashboardParcelPerDay } from "service/dashboard/property/parcel";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import { ErrorDashboard, LoadingDashboard, NoDataDashboard } from "../..";
import dayjs from "dayjs";
import { IData } from "service/dashboard/interface";

const DailyParcelVolume = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const dailyParcelDate = Form.useWatch("dailyParcelDate");
  const date = findDate(dailyParcelDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardParcelPerDay({
      ...date,
    });

  const getData: IData[] =
    data?.map((item) => {
      const day = dayjs(item.day).date().toString();
      return { name: day, count: item?.count || 0 };
    }) || [];

  const findNoCount = getData.some((e) => e.count > 0);

  const dataBar: IRenderBarChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    color: "#BBD649",
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("daily-parcel-volume")}
          name="dailyParcelDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="300px" />
        ) : !data || data.length <= 0 || !findNoCount ? (
          <NoDataDashboard height="300px" />
        ) : (
          <RenderBarChart {...dataBar} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default DailyParcelVolume;
