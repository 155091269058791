import { FC } from "react";
import { useTranslation } from "react-i18next";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Tag } from "antd";
import styled from "styled-components";
import tw from "twin.macro";
import { CSelect, CSelectProps } from "components/input/c-select";
import { MenuTypes } from "menus/interface";

const TagStyle = styled(Tag)`
  ${tw`p-[5px] mr-[3px]`}
  border-color: #e6f4ff;

  .ant-tag-close-icon {
    color: #91caff;
  }

  &:hover,
  &:focus {
    .ant-tag-close-icon {
      color: #0958d9;
    }
  }
`;

export interface MenuItemProps {
  key: MenuTypes;
  title: MenuTypes;
  code: string;
}

export interface CSelectPropertyMenuProps extends CSelectProps {}

export const CSelectPropertyMenus: FC<CSelectPropertyMenuProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <TagStyle
        className="center"
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3, padding: 5 }}
      >
        {label}
      </TagStyle>
    );
  };

  const menu = dataMenuOverview.map((e) => {
    const title = t(e.title, { ns: "menu" });
    return {
      code: e.code,
      title: title,
    };
  });

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("menu")}
      tagRender={tagRender}
      valueOption={{
        values: menu,
        valueKey: "code",
        labelKey: "title",
      }}
    />
  );
};

export const dataMenuOverview: MenuItemProps[] = [
  {
    key: "property.news-and-announcement",
    title: "property.news-and-announcement",
    code: "2100",
  },
  {
    key: "property.house-care-service",
    title: "property.house-care-service",
    code: "2300",
  },
  {
    key: "property.parcel",
    title: "property.parcel",
    code: "2400",
  },
  {
    key: "property.facility",
    title: "property.facility",
    code: "2500",
  },
  {
    key: "user-management",
    title: "user-management",
    code: "0600",
  },
  {
    key: "property.residents",
    title: "property.residents",
    code: "2700",
  },
  {
    key: "property.complaint",
    title: "property.complaint",
    code: "2900",
  },
  {
    key: "property.survey",
    title: "property.survey",
    code: "4100",
  },
  {
    key: "property.work-manage",
    title: "property.work-manage",
    code: "5000",
  },
  { key: "property.vehicle", title: "property.vehicle", code: "2800" },
  {
    key: "property.sell-and-rent",
    title: "property.sell-and-rent",
    code: "3100",
  },
  { key: "property.voting", title: "property.voting", code: "3300" },
  { key: "property.work-space", title: "property.work-space", code: "3600" },
  { key: "property.activities", title: "property.activities", code: "3900" },
];
