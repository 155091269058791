import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";
import { MAIN_MENUS } from "menus/main";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Tag } from "antd";
import styled from "styled-components";
import tw from "twin.macro";

const TagStyle = styled(Tag)`
  ${tw`p-[5px] mr-[3px]`}
  border-color: #e6f4ff;

  .ant-tag-close-icon {
    color: #91caff;
  }

  &:hover,
  &:focus {
    .ant-tag-close-icon {
      color: #0958d9;
    }
  }
`;

export interface CSelectMainMenuProps extends CSelectProps {}

export const CSelectMainMenus: FC<CSelectMainMenuProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <TagStyle
        className="center"
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3, padding: 5 }}
      >
        {label}
      </TagStyle>
    );
  };

  const menu = MAIN_MENUS.map((e) => {
    const title = t(e.title, { ns: "menu" });
    return {
      code: e.code,
      title: title,
    };
  });

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("menu")}
      tagRender={tagRender}
      valueOption={{
        values: menu,
        valueKey: "code",
        labelKey: "title",
      }}
    />
  );
};
