import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetParcelSettingCompanies } from "service/parcel-setting-company";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { IParcelSettingCompanyParams } from "service/parcel-setting-company/interface";

export interface ISelectDeliveryCompany extends CSelectProps {
  params?: IParcelSettingCompanyParams;
}

export const CSelectDeliveryCompany: React.FC<ISelectDeliveryCompany> = ({
  placeholder,
  params,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder || t("transport-name")}
      useQuery={useScrollGetParcelSettingCompanies}
      valueKey="id"
      labelKey="companyName"
      searchKey="companyName"
      translation
      queryParams={{ ...params, isActive: true }}
    />
  );
};
