import { Form, Space, Spin } from "antd";
import { useOverview } from "providers/overview";
import styled from "styled-components";
import tw from "twin.macro";
import SearchProperty from "./search-property";
import SearchMenus from "./search-menus";
import OverviewProviderApp from "providers/overview";
import { FC, useEffect } from "react";
import Statistics from "./statistics-web";
import { CIndicator, EmptyPage, ErrorPage, SvgDot } from "components";

export const Wrapper = styled.div<{ minHeight?: number }>`
  ${tw`bg-[#F8F8F8] p-4 rounded-app `}
  min-height:${({ minHeight }) => minHeight}px
`;

export const WrapperBG = styled.div`
  ${tw`bg-white p-4 rounded-app`}
`;

const OverviewPage = () => {
  const { form, menusView, setInitialValues } = useOverview();

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-5">
      <Form form={form} layout="vertical">
        <Space direction="vertical" size="middle" className="flex">
          <SearchProperty />
          <SearchMenus />
          <Statistics />
          {menusView.map((item: JSX.Element, index) => {
            return <div key={index}>{item}</div>;
          })}
        </Space>
      </Form>
    </div>
  );
};

interface ILoadingDashboard {
  loading: boolean;
  children?: React.ReactNode;
}

export const LoadingDashboard: FC<ILoadingDashboard> = ({
  loading = false,
  children,
}) => {
  return (
    <Spin spinning={loading} indicator={<CIndicator />}>
      {children}
    </Spin>
  );
};

interface IEmptyDaTa {
  height?: string;
}

export const NoDataDashboard: FC<IEmptyDaTa> = ({ height = "100%" }) => {
  return (
    <div
      className={`h-[${height}] w-full center overflow-auto !overflow-x-hidden px-4`}
    >
      <EmptyPage />
    </div>
  );
};

interface IErrorDashboard {
  error?: Error | null;
  height?: string;
}

export const ErrorDashboard: FC<IErrorDashboard> = ({
  error,
  height = "100%",
}) => {
  return (
    <div
      className={`w-full center overflow-auto !overflow-x-hidden px-4`}
      style={{ height: height }}
    >
      <ErrorPage error={error} />
    </div>
  );
};

export const CustomizedDot = (props: any) => {
  const { cx, cy, value } = props;

  let color = "#F65555";

  if (value >= 45) {
    color = "#44955B";
  } else if (value >= 40) {
    color = "#66BC07";
  } else if (value >= 35) {
    color = "#A2E35A";
  } else if (value >= 25) {
    color = "#FFBD7B";
  } else if (value >= 15) {
    color = "#FD9E40";
  } else if (value >= 10) {
    color = "#FA7C7C";
  }

  return <SvgDot cx={cx} cy={cy} color={color} />;
};

const WrapperPage = () => {
  return (
    <OverviewProviderApp>
      <OverviewPage />
    </OverviewProviderApp>
  );
};

export default WrapperPage;
