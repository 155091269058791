import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { Divider, Typography } from "antd";
import { ErrorPage, EmptyPage, CIndicator } from "components";
import { IInfiniteScroll, IResponse } from "config/axios/interface";
import { ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import tw from "twin.macro";

export interface CInfiniteScrollProps {
  title?: string;
  height?: number | string;
  mutation?: UseInfiniteQueryResult<IResponse<any[]>, Error>;
  render: (v: any[]) => ReactNode;
  className?: string;
  textColor?: string;
  style?: React.CSSProperties;
  renderEmpty?: JSX.Element;
  renderLoading?: JSX.Element;
  renderError?: (v: Error) => JSX.Element;
  hidden?: boolean;
  backgroundColor?: string;
  heightContent?: string;
  loading?: boolean;
}

const Wrapper = styled.div`
  ${tw`bg-white shadow`}
  height:calc(100vh - 151px);
`;

export const CInfiniteScroll: React.FC<CInfiniteScrollProps> = ({
  height,
  mutation,
  render,
  className,
  style,
  hidden,
  backgroundColor = "",
  renderEmpty,
  renderError,
  renderLoading,
  title,
  heightContent = "calc(100vh - 224px)",
  loading = true,
}) => {
  const entireData = (): IInfiniteScroll<any> => {
    const { pages } = mutation?.data || {};
    if (!pages?.[0])
      return { data: [], hasMore: false, page: 1, undefinded: true };

    const idx = (pages?.length || 0) - 1;
    const page = pages?.[idx]?.page || 1;
    const pageCount = pages?.[idx]?.pageCount || 0;
    const data =
      pages
        ?.map((item) => {
          return item?.data;
        })
        .flat() || [];
    return { data, hasMore: pageCount > page, page: page };
  };

  const { page, hasMore, data } = entireData();

  const fetchNext = () => {
    mutation?.fetchNextPage({ pageParam: { page: page + 1 } });
  };

  if (hidden) return null;

  if (mutation?.isLoading && loading) {
    if (renderLoading) {
      return renderLoading;
    }
    return (
      <CIndicator
        height={height}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  }

  if (mutation?.isError) {
    if (renderError) {
      return renderError(mutation?.error);
    }
    return (
      <div
        className="px-5 !w-full center flex-col text-center"
        style={{ height: height, backgroundColor: backgroundColor }}
      >
        <ErrorPage error={mutation?.error} />
      </div>
    );
  }

  if (!data || data.length <= 0) {
    if (renderEmpty) {
      return renderEmpty;
    }
    return (
      <div
        className="p-4"
        style={{ height: height, backgroundColor: backgroundColor }}
      >
        <EmptyPage />
      </div>
    );
  }

  return (
    <InfiniteScroll
      className={className}
      height={height}
      style={{ ...style, backgroundColor: backgroundColor }}
      next={fetchNext}
      hasMore={hasMore}
      dataLength={data.length}
      loader={<CIndicator height={50} />}
    >
      {title ? (
        <Wrapper>
          <Divider className="!m-0" />
          <div className="p-5">
            <Typography.Title
              ellipsis={{ tooltip: title }}
              level={5}
              className="!text-primary-dark"
            >
              {title}
            </Typography.Title>
          </div>
          <Divider className="!m-0" />
          <div
            className="p-5"
            style={{ overflow: "auto", height: heightContent }}
          >
            {render(data)}
          </div>
        </Wrapper>
      ) : (
        render(data)
      )}
    </InfiniteScroll>
  );
};
