import React from "react";
import { ErrorDashboard, LoadingDashboard, NoDataDashboard, Wrapper } from "..";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import { IData } from "service/dashboard/interface";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import { useGetDashboardVoting } from "service/dashboard/central/voting";

const WrapperList = styled.div`
  ${tw`bg-white h-full rounded-app p-3`}
`;

const Voting = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { formatDate } = useAppLanguage();

  const votingDate = Form.useWatch("votingDate");
  const date = findDate(votingDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardVoting({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <Wrapper minHeight={385}>
        <LoadingDashboard loading={isFetching}>
          <Row gutter={[14, 14]} className="h-full w-full">
            <Col span={24}>{t("property.voting", { ns: "menu" })}</Col>
            <Col span={24}>
              <WrapperList>
                <HeaderOverview
                  title={t("number-of-meeting-lists-created")}
                  name="votingDate"
                  formatDate="year"
                />
                <div className="h-[230px]"></div>
              </WrapperList>
            </Col>
          </Row>
        </LoadingDashboard>
      </Wrapper>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    height: 230,
  };

  return (
    <Wrapper minHeight={385}>
      <Row gutter={[14, 14]} className="h-full w-full">
        <Col span={24}>{t("property.voting", { ns: "menu" })}</Col>
        <Col span={24}>
          <WrapperList>
            <HeaderOverview
              title={t("number-of-meeting-lists-created")}
              name="votingDate"
              formatDate="year"
            />
            {isError ? (
              <ErrorDashboard error={error} height="230px" />
            ) : !data || data.length <= 0 ? (
              <NoDataDashboard height="230px" />
            ) : (
              <RenderLineChart {...dataLine} />
            )}
          </WrapperList>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Voting;
