import { Col, Row } from "antd";
import { TopBox } from "components";
import React from "react";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import Repair from "./content/repair";
import Waiting from "./content/waiting";
import SLA from "./content/sla";
import Budgets from "./content/budgets";
import Workload from "./content/workload";
import UnitBox from "./content/unit";
import TypeBox from "./content/type";

const HomeCare = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.house-care-service", { ns: "menu" })}</Col>
        <Col span={24}>
          <Repair />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <UnitBox />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <TypeBox />
        </Col>
        <SLA />
        <Col md={24} lg={24} xl={8}>
          <Waiting />
        </Col>
        <Col md={24} lg={24} xl={16}>
          <Budgets />
        </Col>
        <Col span={24}>
          <Workload />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default HomeCare;
