import { Form } from "antd";
import { TopBox } from "components";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { LoadingDashboard, WrapperBG } from "pages/overview";
import { useGetDashboardActivities } from "service/dashboard/central/activities";

const ActivitiesMost = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const listActivitiesDate = Form.useWatch("listActivitiesDate");
  const date = findDate(listActivitiesDate);

  const query = useGetDashboardActivities({
    ...date,
    property,
  });

  return (
    <WrapperBG className="h-full">
      <LoadingDashboard loading={query.isFetching}>
        <TopBox
          query={query}
          nameDate="listActivitiesDate"
          title={t("the-5-most-attended-activities")}
          translation
          keyTitle="title"
        />
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default ActivitiesMost;
