import { Form } from "antd";
import { TopBox } from "components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { LoadingDashboard } from "../..";
import { useGetPropertyDashboardActivities } from "service/dashboard/property/activities";
import { useOverview } from "providers/overview";

const WrapperList = styled.div`
  ${tw`bg-white h-full rounded-app p-4`}
`;

const ActivitiesMost = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const listActivitiesDate = Form.useWatch("listActivitiesDate");
  const date = findDate(listActivitiesDate);

  const query = useGetPropertyDashboardActivities({
    ...date,
  });

  return (
    <WrapperList>
      <LoadingDashboard loading={query.isFetching}>
        <TopBox
          query={query}
          nameDate="listActivitiesDate"
          title={t("the-5-most-attended-activities")}
          translation
          keyTitle="title"
        />
      </LoadingDashboard>
    </WrapperList>
  );
};

export default ActivitiesMost;
