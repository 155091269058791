import { MenuTypes } from "menus/interface";
import { Dispatch } from "react";

export interface INotiContext {
  sidebarNoti: INotification;
  tableNoti: INotification;
  dispatchNoti?: Dispatch<TNotiAction>;
}
export interface INoti {
  id: number | number[];
  type: MenuTypes;
}

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type INotification = PartialRecord<MenuTypes, number[]>;

export interface INotiState {
  tableNoti: INotification;
  sidebarNoti: INotification;
  headerNoti: INotification;
}

export type TNotiAction = {
  type: "sidebar" | "table" | "all";
  action: "clear" | "add";
  id?: number | number[];
  key: MenuTypes[];
};

export const dataMenuPage = {
  "overview": [],
  "property": [],
  "property.list": [],
  "news-and-announcement": [],
  "activities": [],
  "survey": [],
  "survey.list": [],
  "survey.setting": [],
  "user-management": [],
  "phone-book": [],
  "phone-book.list": [],
  "phone-book.category-setting": [],
  "gallery": [],
  "residents": [],
  "setting": [],
  "setting.role": [],
  "setting.menu-display": [],
  "setting.terms-and-condition": [],
  "setting.privacy": [],
  "setting.pdpa": [],
  "sell-and-rent": [],
  "work-manage": [],
  "unroutine": [],
  "work-calendar": [],
  "work-setting": [],
  "suggestion": [],
  "complaint": [],
  "complaint.list": [],
  "complaint.setting": [],
  "work-space": [],
  "work-space-list": [],
  "request-list": [],
  // ---- property ----
  "property.overview": [],
  "property.news-and-announcement": [],
  "property.chat": [],
  "property.house-care-service": [],
  "property.house-care-service.list": [],
  "property.house-care-service.setting": [],
  "property.house-care-service.calendar": [],
  "property.parcel": [],
  "property.parcel.list": [],
  "property.parcel.setting": [],
  "property.facility": [],
  "property.facility.list": [],
  "property.facility.booking": [],
  "property.facility.calendar": [],
  "property.phone-book": [],
  "property.phone-book.list": [],
  "property.phone-book.category-setting": [],
  "property.residents": [],
  "property.vehicle": [],
  "property.complaint": [],
  "property.complaint.list": [],
  "property.complaint.setting": [],
  "property.unit": [],
  "property.sell-and-rent": [],
  "property.calendar": [],
  "property.voting": [],
  "property.routine": [],
  "property.unroutine": [],
  "property.work-space": [],
  "property.gallery": [],
  "property.request": [],
  "property.activities": [],
  "property.payment": [],
  "property.work-manage": [],
  "property.work-calendar": [],
  "property.work-setting": [],
  "property.survey": [],
  "property.survey.list": [],
  "property.survey.setting": [],
  "property.work-space-list": [],
  "property.request-list": [],
}