import { DownOutlined } from "@ant-design/icons";
import {
  Badge,
  Col,
  Dropdown,
  Layout,
  MenuProps,
  Popover,
  Row,
  Typography,
} from "antd";
import tw from "twin.macro";
import styled from "styled-components";
import { BellIcon } from "assets/images";
import { CAvatar, CSelect } from "components";
import { useTranslation } from "react-i18next";
import { useAppAuth } from "providers/app-auth";
import NotificationContent from "./notification";
import { Logout, getProperty } from "service/auth";
import { useAppLanguage } from "providers/app-language";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const { translationObject } = useAppLanguage();

  const property = getProperty(`lpp-property-${propertyId}`);

  const name = property
    ? translationObject({ object: property, keys: ["name"] }).name
    : undefined;

  const Icon = BellIcon;

  return (
    <Layout.Header style={{ backgroundColor: "#276347", height: 70 }}>
      <Row align="middle" className="w-full !h-full" justify="space-between">
        <Col>
          <Typography.Title
            ellipsis={{ tooltip: name }}
            className="!text-white"
            level={3}
          >
            {name}
          </Typography.Title>
        </Col>
        <Col>
          <Row>
            <Col className="center">
              <Popover
                placement="bottomLeft"
                title={
                  <div className="bg-gray rounded-t-[10px] p-[13px] -mb-[8px]">
                    {t("alert")}
                  </div>
                }
                content={NotificationContent}
                trigger="click"
                overlayInnerStyle={{ padding: 0 }}
                overlayStyle={{ width: "370px" }}
              >
                <Badge count={0} offset={[0, 5]}>
                  <Icon height={30} width={30} className="cursor-pointer" />
                </Badge>
              </Popover>
            </Col>
            <Col className="!h-[70px] center ml-4">
              <TranslationBox />
            </Col>
            <Col className="!h-[70px] center ml-2 ">
              <UserBox />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  );
};

const CSelectStyle = styled(CSelect)`
  ${tw`flex justify-center`}
  height: 35px !important;
  width: 65px !important;
  border-radius: 50px !important;
  .ant-select-selector {
    border-radius: 50px !important;
    background-color: #ffffff2b !important;
    border: 1px solid #ffffff00 !important;
    color: white !important;
  }
  .ant-select-arrow {
    color: white !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    color: white !important;
  }
`;

const TranslationBox = () => {
  const { lang, changeLanguage } = useAppLanguage();
  return (
    <CSelectStyle
      onChange={changeLanguage}
      valueOption={{
        valueKey: "value",
        labelKey: "label",
        values: [
          { value: "en", label: "EN" },
          { value: "th", label: "TH" },
        ],
      }}
      value={lang}
    />
  );
};

const UserBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userMe } = useAppAuth();

  const items: MenuProps["items"] = [
    {
      label: (
        <div onClick={() => navigate(`/user-management/${userMe?.id}`)}>
          {t("profile")}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={() => {
            Logout();
            return <Navigate to="/login" replace />;
          }}
        >
          {t("log-out")}
        </div>
      ),
      key: "0",
    },
  ];

  return (
    <Dropdown
      getPopupContainer={(e) => e}
      overlayStyle={{ width: 200, top: 50 }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
      menu={{ items }}
    >
      <div onClick={(e) => e.preventDefault()}>
        <ProfileBox />
      </div>
    </Dropdown>
  );
};

const ProfileBox = () => {
  const { translationObject } = useAppLanguage();
  const { userMe } = useAppAuth();

  const { title } = translationObject({
    object: userMe,
    keys: ["role.title"],
  });

  return (
    <Row className={` cursor-pointer w-[240px]`}>
      <Col span={6} className="center">
        <CAvatar
          src={userMe?.profilePicture?.url}
          size="large"
          style={{ border: "2px solid white" }}
        />
      </Col>
      <Col span={16} className="flex flex-col justify-center">
        <Typography.Text
          className="!text-white !text-[18px] "
          ellipsis={{ tooltip: `${userMe?.firstName} ${userMe?.lastName}` }}
        >
          {userMe?.firstName} {userMe?.lastName}
          <div className="text-white text-[12px]">{title}</div>
        </Typography.Text>
      </Col>
      <Col span={2}>
        <DownOutlined className="!text-white" style={{ fontSize: 12 }} />
      </Col>
    </Row>
  );
};

export default Header;
