import { TimePicker, TimePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import styled from "styled-components";

dayjs.extend(customParseFormat);

const StyleTimePicker = styled(TimePicker)`
  :where(.css-dev-only-do-not-override-naxx1s).ant-picker {
    height: 45px;
  }
`;

export const CTimePicker: React.FC<TimePickerProps> = ({
  className,
  ...props
}) => {
  const format = "HH:mm";
  return (
    <StyleTimePicker
      className={`w-full ${className}`}
      {...props}
      format={format}
      getPopupContainer={(e) => e}
      style={{ width: "100%", height: "45px" }}
    />
  );
};
