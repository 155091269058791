import { Form } from "antd";
import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboardFacilityMonth } from "service/dashboard/central/facility";
import { IData } from "service/dashboard/interface";

const COLORS = ["#F8931D", "#8EC641"];

const FacilityMonth = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const facilitiesUsedEachMonthDate = Form.useWatch(
    "facilitiesUsedEachMonthDate"
  );
  const date = findDate(facilitiesUsedEachMonthDate);

  const { data, isFetching, isError, error } = useGetDashboardFacilityMonth({
    ...date,
    property,
  });

  const getData: IData[] = [
    {
      name: t("no-advance-reservation-required"),
      count: data?.countDisallowBooking || 0,
    },
    {
      name: t("advance-reservation-required"),
      count: data?.countPreBooking || 0,
    },
  ];

  const dataPie: IRenderPieChart = {
    data: getData,
    dataKey: "count",
    dataName: "name",
    color: COLORS,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("number-of-facilities-used")}
          name="facilitiesUsedEachMonthDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default FacilityMonth;
