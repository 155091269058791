import AppAntdConfigProvider from "providers/app-antd-config";
import AppPopupProvider from "providers/app-popup";
import AppAuthProvider from "./providers/app-auth";
import { HashRouter, useRoutes } from "react-router-dom";
import { APP_ROUTES } from "./routes";
import AppRoleMenuProvider from "providers/app-role-menu";
import AppLanguageProvider from "providers/app-language";
import AppNotification from "providers/app-notification";
import AppActionRoleProvider from "providers/action-role";
import AppActivitySidebarProvider from "providers/active-sidebar";
import AppResponsiveProvider from "providers/app-responesive";

const AppRoute = () => {
  const appRoute = useRoutes(APP_ROUTES);
  return appRoute;
};

function App() {
  return (
    <HashRouter>
      <AppResponsiveProvider>
        <AppLanguageProvider>
          <AppNotification>
            <AppAuthProvider>
              <AppRoleMenuProvider>
                <AppActionRoleProvider>
                  <AppAntdConfigProvider>
                    <AppPopupProvider>
                      <AppActivitySidebarProvider>
                        <AppRoute />
                      </AppActivitySidebarProvider>
                    </AppPopupProvider>
                  </AppAntdConfigProvider>
                </AppActionRoleProvider>
              </AppRoleMenuProvider>
            </AppAuthProvider>
          </AppNotification>
        </AppLanguageProvider>
      </AppResponsiveProvider>
    </HashRouter>
  );
}

export default App;
