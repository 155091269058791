import React from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectParcelStatus: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("status")}
      valueOption={{
        values: [
          { value: "RECEIVED", title: t("parcel-received") },
          { value: "NOT_RECEIVED", title: t("not-received-parcel") },
          { value: "OVER_TIME", title: t("parcel-over-time") },
          { value: "SEND_BACK", title: t("parcel-overdue") },
          { value: "CANCEL", title: t("cancel"), disabled: true },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
