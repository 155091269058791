import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { removeUuid } from "service/auth";
import styled from "styled-components";
import tw from "twin.macro";
import SiderMenuItem, { SiderMenuItemProps } from "./item";

export interface SiderMenuProps {
  menus: SiderMenuItemProps[];
  isPropertyPath: boolean;
}

// const Indicator = styled.div<{ top: number }>`
//   ${tw`absolute bg-primary transition-all m-auto rounded-xl`};
//   z-index: 1;
//   height: 20px;
//   width: 3px;
//   top: ${({ top }) => top}px;
//   right: 0px;
// `;

// const IndicatorLine = styled.div`
//   ${tw`absolute m-auto rounded-xl`};
//   background-color: #f0f0f0;
//   width: 3px;
//   right: 0px;
//   top: 24px;
//   bottom: 24px;
// `;

const SiderMenuWrapper = styled.div<{ $isPropertyPath: boolean }>`
  ${({ $isPropertyPath }) => ($isPropertyPath ? tw`pb-5 pt-14` : "pb-10")};
  height: 100%;
  overflow-y: auto;
`;

const GobackWrapper = styled.div`
  ${tw`absolute bg-gray p-4`};
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
  svg * {
    color: white;
  }
`;

const SiderMenu: React.FC<SiderMenuProps> = ({ menus, isPropertyPath }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { propertyId } = useParams<{ propertyId?: string }>();
  // const [top, setTop] = useState<number>(0);

  // useEffect(() => {
  //   const position = getPosition();
  //   setTop(position * 54 + 16);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname, menus]); //add check with menu

  // const getPosition = (): number => {
  //   const routes = matchRoutes(APP_ROUTES, location);
  //   if (!routes || !routes.length) return -1;

  //   const { route } = routes[routes.length - 1];

  //   if (!route.path) return -1;
  //   const split = route.path.split("/");

  //   if (split.length >= 3) {
  //     //active border left menu
  //     if (menus.findIndex((e) => e.to === route.path) && propertyId) {
  //       return menus.findIndex((e) => e.to.includes(split[3]));
  //     }
  //     return menus.findIndex((e) => e.to.includes(split[1]));
  //   }

  //   const index = menus.findIndex((e) => e.to === (route?.path || "/"));

  //   return index;
  // };

  return (
    <div className="relative" style={{ height: "calc(100vh - 71px)" }}>
      {isPropertyPath ? (
        <GobackWrapper className="flex items-center">
          <Button
            className="center"
            onClick={() => {
              removeUuid();
              navigate("/property", { replace: true });
            }}
            style={{
              width: 20,
              height: 20,
              backgroundColor: "#F8931D",
              borderColor: "#F8931D",
            }}
            icon={<LeftOutlined style={{ fontSize: 9 }} />}
          />
          <Typography.Text className="ml-2">{t("back")}</Typography.Text>
        </GobackWrapper>
      ) : null}
      <SiderMenuWrapper $isPropertyPath={isPropertyPath}>
        <Row>
          <Col span={24}>
            {menus.map((item) => {
              return <SiderMenuItem item={item} key={item.key} />;
            })}
          </Col>
        </Row>
      </SiderMenuWrapper>
    </div>
  );
};

export default SiderMenu;
