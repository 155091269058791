import { FC } from "react";
import tw from "twin.macro";
import { Space } from "antd";
import styled from "styled-components";
import { EmptyPage, ErrorPage } from "components";
import { HeaderOverview } from "./header";
import { TextItem } from "./text-item";
import { IResponse } from "config/axios/interface";
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { CInfiniteScroll } from "components/display/c-infinite-scroll";
import { useAppLanguage } from "providers/app-language";

export interface ITopBox {
  text?: string;
  title: string;
  nameDate: string;
  height?: number;
  data?: any[];
  query?: UseQueryResult<any[], Error>;
  mutate?: UseInfiniteQueryResult<IResponse<any[]>, Error>;
  translation?: boolean;
  keyTitle?: string;
  classNameWrapper?: string;
}

const WrapperList = styled.div`
  ${tw`bg-white rounded-app p-4`}
`;

export const TopBox: FC<ITopBox> = (props) => {
  const height = props.height || 300;
  const classNameWrapper = props.classNameWrapper;

  return (
    <WrapperList
      style={{ height: height }}
      className={`${classNameWrapper} w-full !p-0 `}
    >
      <div className="px-4 pt-4">
        <HeaderOverview title={props.title} name={props.nameDate} />
      </div>
      {props.data || props.query ? (
        <ContentData {...props} />
      ) : props.mutate ? (
        <ContentMutate {...props} />
      ) : (
        <div className="w-full overflow-auto !overflow-x-hidden px-4">
          <EmptyPage />
        </div>
      )}
    </WrapperList>
  );
};

const ContentData: FC<ITopBox> = ({
  text,
  data,
  query,
  keyTitle = "name",
  translation = false,
  ...props
}) => {
  const { translationObject } = useAppLanguage();

  const height = `${(props?.height || 300) - 92}px`;

  const valueList = data || query?.data || [];
  const emptyData = !valueList || valueList.length < 1;

  const findTitle = (value: any) => {
    if (translation) {
      const obj = translationObject({
        object: value,
        keys: [keyTitle],
      })?.[keyTitle];
      return { ...value, title: obj };
    }
    return { ...value, title: value[keyTitle] };
  };

  if (query && query.isError) {
    return (
      <div className="px-5 ">
        <ErrorPage error={query?.error} height={height} />
      </div>
    );
  }

  if (emptyData) {
    return <EmptyPage className="px-5" style={{ height: height }} />;
  }

  return (
    <div className="w-full overflow-auto !overflow-x-hidden px-4">
      <Space direction="vertical" size="large" className="w-full">
        {valueList?.map((item, index) => {
          const value = findTitle(item);
          return <TextItem {...value} key={index} text={text} />;
        })}
      </Space>
    </div>
  );
};

const ContentMutate: FC<ITopBox> = ({
  mutate,
  text,
  height = 300,
  keyTitle = "name",
  translation = false,
}) => {
  const { translationObject } = useAppLanguage();

  const findTitle = (value: any) => {
    if (translation) {
      const obj = translationObject({
        object: value,
        keys: [keyTitle],
      })?.[keyTitle];
      return { ...value, title: obj };
    }
    return { ...value, title: value[keyTitle] };
  };

  return (
    <CInfiniteScroll
      mutation={mutate}
      height={height - 80}
      style={{ overflow: "hidden", overflowY: "auto" }}
      loading={false}
      render={(value) => {
        return (
          <div className="w-full  px-4">
            <Space direction="vertical" size="large" className="w-full">
              {value?.map((item, index) => {
                const dataValue = findTitle(item);
                return <TextItem {...dataValue} key={index} text={text} />;
              })}
            </Space>
          </div>
        );
      }}
    />
  );
};
