import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import { IDashboardGraph, IDashboardParams } from "../../interface";

export const useGetDashboardVoting = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-voting", "count-meeting", params],
    async () => {
      const res = await axiosCentral.get(
        "dashboard/vote-meeting/count-meeting",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
