import { Layout } from "antd";
import React from "react";
import Content from "./content";
import Header from "./header";
import Sider from "./sider";

export interface AppLayoutProps {
  type: "property" | "main";
}

const AppLayout: React.FC<AppLayoutProps> = ({ type }) => {
  return (
    <Layout className='h-screen'>
      <Sider type={type} />
      <Layout>
        <Header />
        <Content />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
