import { Form } from "antd";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import dayjs from "config/dayjs";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { CustomizedDot } from "pages/property/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashBoardComplaintGraph } from "service/dashboard/central/recommrnt-feed-back";
import { IData } from "service/dashboard/interface";

const NumberComplaint = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const numberComplaintsDate = Form.useWatch("numberComplaintsDate");
  const date = findDate(numberComplaintsDate);

  const { data, isFetching, isError, error } = useGetDashBoardComplaintGraph({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("number-complaints")}
            name="numberComplaintsDate"
          />
          <div className="h-[300px]"></div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const day = dayjs(e.day).date().toString();
      return { name: day, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    CustomizedDot: CustomizedDot,
  };

  return (
    <WrapperBG>
      <HeaderOverview
        title={t("number-complaints")}
        name="numberComplaintsDate"
      />
      {isError ? (
        <ErrorDashboard error={error} height="300px" />
      ) : !data || (data && data.length <= 0) ? (
        <NoDataDashboard height="300px" />
      ) : (
        <RenderLineChart {...dataLine} />
      )}
    </WrapperBG>
  );
};

export default NumberComplaint;
