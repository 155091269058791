import { FC } from "react";
import { useScrollGetRecommendFeedbackSettings } from "service/property/recommend-feedback-setting";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export const CSelectComplaintType: FC<CSelectProps> = (props) => {
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetRecommendFeedbackSettings}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      queryParams={{ status: true }}
      translation
    />
  );
};
