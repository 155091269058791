import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectRepairServiceStatus: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("operation-status")}
      valueOption={{
        values: [
          {
            value: "WAITING_CONFIRM_APPOINTMENT",
            title: t("waiting-confirm-appointment"),
          },
          { value: "PENDING", title: t("pending") },
          { value: "PUT_OFF_APPOINTMENT", title: t("put-off-appointment") },
          { value: "COMPLETE", title: t("complete") },
          { value: "CANCEL", title: t("cancel") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
