import { LoadingOutlined } from "@ant-design/icons";
import React, { FC } from "react";

export interface CLoading {
  size?: number;
}

const CLoadingOutLined: FC<CLoading> = ({ size = 30 }) => {
  return (
    <LoadingOutlined className="!text-yellow" style={{ fontSize: size }} />
  );
};

export default CLoadingOutLined;
