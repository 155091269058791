import { UseQueryResult, useQuery} from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import { INotification, INotificationDetail } from "./inteface";

export const useGetNotifications = (): UseQueryResult<INotificationDetail[]> => {
  return useQuery(
    ["get-notification"],
    async () => {
      const res = await axiosCentral.get(`/notification/notification-history`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
  );
};