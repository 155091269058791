import { Wrapper } from ".";
import { Row, Col, Form } from "antd";
import { Action, CSelectPropertyMenus } from "components";
import { useTranslation } from "react-i18next";

const SearchMenus = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className="!pb-0">
      <Row gutter={[12, 12]}>
        <Col span={20}>
          <Form.Item label={t("menu")} name="menu">
            <CSelectPropertyMenus
              placeholder={t("menu")}
              multipleMode={true}
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Action searchType="menu" />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SearchMenus;
