import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectIdentityChanel: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("identity-verification-channel")}
      valueOption={{
        values: [
          { value: "email", label: t("email") },
          { value: "sms", label: t("sms") },
          { value: "by_juristic", label: t("by-juristic") },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
