import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";

export const CSelectSurveyStatus: FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const status = [
    {
      value: "PUBLISH",
      label: "publish",
    },
    {
      value: "DRAFT",
      label: "draft",
    },
    {
      value: "PENDING_APPROVE",
      label: "pending-approval",
    },
    {
      value: "DISAPPROVE",
      label: "disapproved",
    },
    {
      value: "SELECT_PROPERTY",
      label: "select-property",
    },
    { value: "APPROVED_SOME", title: "published-some-property" },
  ];

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("display-type")}
      valueOption={{
        values: status,
        valueKey: "value",
        labelKey: "label",
        translationI18n: true,
      }}
    />
  );
};
