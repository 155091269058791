import { useTranslation } from "react-i18next";
import { CSelectPagination } from "./pagination";
import { useScrollGetPostman } from "service/parcel-setting-worker";
import { IUserParams } from "service/administrator/interface";
import { CSelectProps } from ".";

export interface CSelectPostmanProps extends CSelectProps {
  params?: IUserParams;
}

export const CSelectPostman: React.FC<CSelectPostmanProps> = ({
  params,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder ? placeholder : t("person")}
      useQuery={useScrollGetPostman}
      valueKey="id"
      labelKey="first-last-name"
      searchKey="name"
      queryParams={{ ...params, isActive: true }}
    />
  );
};
