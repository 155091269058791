import { useOverview } from "providers/overview";
import { Form } from "antd";
import { TopBox } from "components";
import { useTranslation } from "react-i18next";
import { LoadingDashboard } from "pages/overview";
import { useGetDashboardRepairingCategory } from "service/dashboard/central/homecare";

const TypeBox = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const repairTypesDate = Form.useWatch("repairTypesDate");
  const date = findDate(repairTypesDate);

  const query = useGetDashboardRepairingCategory({ ...date, property });

  return (
    <LoadingDashboard loading={query.isFetching}>
      <TopBox
        query={query}
        nameDate="repairTypesDate"
        title={t("statistics-type-5-types")}
        translation
        keyTitle="title"
      />
    </LoadingDashboard>
  );
};

export default TypeBox;
