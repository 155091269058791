import { parsePhoneNumber, CountryCode } from "libphonenumber-js/max";

export const getUUID = (): string => {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
};

export const isPhoneNumber = (value: string, region?: CountryCode): boolean => {
  if (typeof value !== "string" || value.trim() !== value) {
    return false;
  }

  try {
    const phoneNumber = parsePhoneNumber(value, region);
    if (region && phoneNumber.country !== region) {
      return false;
    }

    return phoneNumber.isValid();
  } catch (error) {
    return false;
  }
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const numFormat = (n: number): string | undefined => {
  if (n < 1e3) return `${n}`;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
