import { Col, Row, Space, Typography } from "antd";
import { UserHolderImage } from "assets/images";
import { CAvatar, CInfiniteScroll } from "components";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setProperty, setUuid } from "service/auth";
import { useGetNotifications } from "service/notification";
import { INotificationDetail } from "service/notification/inteface";
import { IProperty } from "service/property/interface";
import styled from "styled-components";
import tw from "twin.macro";

const StyleDiv = styled.div<{ $active: boolean }>`
  ${tw`cursor-pointer`}
  background-color: ${({ $active }) => ($active ? "#ecf4ee" : "#ffffff")};
  &:hover,
  &:focus {
    background-color: #ecf4ee;
    border-color: #ecf4ee;
    color: #ffffff !important;
  }
  &:active {
    background-color: #ecf4ee;
    border-color: #ecf4ee;
  }
`;

const NotificationContent = () => {
 
  const navigate = useNavigate()
  const { data } = useGetNotifications()
  const onClick = (item: INotificationDetail) => {
    setProperty(item, `lpp-property-${item.propertyUnit?.property.id}`);
    setUuid(item.propertyUnit?.property?.uuid || "");
    item.isRead = true;
    navigate(`/property/${item.propertyUnit?.property?.id}`);
  };
  return (
    <div>
      <CInfiniteScroll
        height={"calc(100vh - 20vh)"}
        style={{ overflow: "hidden", overflowY: "auto" }}
        render={(value: any[]) => {
          return (
            <Space direction="vertical" className="!gap-0">
              {data?.map((item, i) => {
                if (item.propertyUnit) {
                  return (
                    <StyleDiv key={i} $active={!item.isRead}>
                      <Content item={item} onClick={() =>
                        onClick(item)
                      } />
                    </StyleDiv>
                  );
                }
              })}
            </Space>
          );
        }}
      />
    </div>
  );
};

interface IProps {
  item: INotificationDetail,
  onClick?: () => void;
}

const Content: FC<IProps> = ({ item, onClick }) => {
  // const currentTime = dayjs();
  // const createdAtTime = dayjs(item.createdAt);
  // const hoursDiff = currentTime.diff(createdAtTime, "hour");
  // let timeAgo = "";
  // if (hoursDiff < 24) {
  //   timeAgo = `ประมาณ ${hoursDiff} ชั่วโมงที่แล้ว`;
  // } else {
  //   const daysDiff = Math.floor(hoursDiff / 24);
  //   timeAgo = `ประมาณ ${daysDiff} วันที่แล้ว`;
  // }
  return (
    <Row className="px-4 py-2" onClick={onClick}>
      <Col span={4} className="flex flex-col justify-center">
        <CAvatar src={UserHolderImage} />
      </Col>
      <Col span={20}>
        <Typography.Paragraph ellipsis={{ rows: 2 }} className="!m-0">
          <span className="pr-2 text-[12px]"></span>
          <span className="text-[#535A6E] text-[12px]">
            {item.notiDetail.th}
          </span>
        </Typography.Paragraph>
        <div className="text-[#44955B] text-[10px]">
          {/* {timeAgo} */}
          {dayjs(item.createdAt).format("DD MMMM BBBB hh:mm:ss")}
        </div>
      </Col>
    </Row>
  );
};

export default NotificationContent;
