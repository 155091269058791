import React from "react";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "../..";
import { useTranslation } from "react-i18next";
import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import { useOverview } from "providers/overview";
import { Form } from "antd";
import { useGetPropertyDashboardMostVehicle } from "service/dashboard/property/vehicle";

const COLORS = ["#F8931D", "#D2D3D5", "#276347"];

const VehicleTop = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const vehicleTopDate = Form.useWatch("vehicleTopDate");
  const date = findDate(vehicleTopDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardMostVehicle({
      ...date,
    });

  const dataPie: IRenderPieChart = {
    data: data || [],
    dataKey: "count",
    dataName: "brand",
    color: COLORS,
    text: " ",
    height: 250,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("the-3-most-popular-brands-vehicle-brands")}
          name="vehicleTopDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default VehicleTop;
