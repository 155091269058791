import { useTranslation } from "react-i18next";
import { CRadio } from ".";
import { CRadioGroupProps } from "./radio-group";
import React from "react";

export const CRadioRepairArea: React.FC<CRadioGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const options = [
    { label: t("private"), value: "private" },
    { label: t("facility"), value: "facility" },
  ];
  return <CRadio.Group {...props} options={options} />;
};
