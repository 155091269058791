import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "../..";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { HeaderOverview, IRenderBarChart, RenderBarChart } from "components";
import { useGetPropertyDashboardTaskCategory } from "service/dashboard/property/task";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import { IData } from "service/dashboard/interface";

const WorkloadCard = () => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();
  const { findDate } = useOverview();

  const workloadDate = Form.useWatch("workloadDate");
  const date = findDate(workloadDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardTaskCategory({
      ...date,
    });

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("statistics-of-workload")}
            name="workloadDate"
          />
          <div className="h-[300px]"></div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const { title } = translationObject({ object: e, keys: ["title"] });
      return { name: title, count: e?.count || 0 };
    }) || [];

  const findNoCount = getData.some((e) => e.count > 0);

  const dataBar: IRenderBarChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
    color: "#80BC28",
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("statistics-of-workload")}
          name="workloadDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="300px" />
        ) : !data || data.length < 1 || !findNoCount ? (
          <NoDataDashboard height="300px" />
        ) : (
          <RenderBarChart {...dataBar} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default WorkloadCard;
