import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { throwResponse, axiosCentral } from "config/axios";
import { IPhoneCategory, IPhoneCategoryParams } from "./interface";
import { IRoleParams } from "service/role/interface";

export const useScrollGetPhoneCategories = (
  params?: IRoleParams
): UseInfiniteQueryResult<IResponse<IPhoneCategory[]>, Error> => {
  return useInfiniteQuery(
    ["phone-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/phone-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetPhoneCategories = (
  params?: IPhoneCategoryParams
): UseQueryResult<IResponse<IPhoneCategory[]>, Error> => {
  return useQuery(
    ["phone-category", "all", params],
    async () => {
      const res = await axiosCentral.get("/phone-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetPhoneCategory = (
  id?: number | string
): UseQueryResult<IPhoneCategory, Error> => {
  return useQuery(
    ["phone-category", "id", id],
    async () => {
      const res = await axiosCentral.get(`/phone-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostPhoneCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post(`/phone-category`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchPhoneCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(`/phone-category/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeletePhoneCategory = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/phone-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePhoneCategoryBulk = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axiosCentral.delete(`/phone-category/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
