import { CModal, CSelect, fireNotification } from "components";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { dataUrlToFile, fileToDataUrl, resizeImageFileSize } from "tools/file";
import { AcceptFileTypes } from "..";
import { useTranslation } from "react-i18next";

import ReactCrop, { Crop, PixelCrop, PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export interface FilePickerRef {
  click: () => void;
}

interface IImg {
  name: string;
  type: string;
  src: string;
}

interface FilePickerProps {
  accepts?: AcceptFileTypes[];
  onChange: (v: File) => void;
  resize?: boolean;
  cropImage?: boolean;
}

export const MAX_FILE_SIZE_BYTES = 10000000;
export const MAX_FILE_VIDEO_SIZE_BYTES = 30000000;

// const isLt10M = image.size / 1024 / 1024 < 10;

export const FilePicker = forwardRef<FilePickerRef, FilePickerProps>(
  (
    {
      accepts = [".jpeg", ".jpg", ".png"],
      resize = true,
      onChange,
      cropImage = true,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const [open, setOpen] = useState(false);

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [aspect, setAspect] = useState<number | undefined>(1);
    const [img, setImg] = useState<IImg>({ name: "", type: "", src: "" });
    const [crop, setCrop] = useState<Crop>({
      x: 0,
      y: 0,
      width: 30,
      height: 30,
      unit: "px",
    });

    useEffect(() => {
      setCrop({
        x: 0,
        y: 0,
        width: 30,
        height: 30,
        unit: "px",
      });
    }, [open]);

    useImperativeHandle(ref, () => ({
      click: () => {
        openPickFileDialog();
      },
    }));

    const toggle = () => {
      setOpen(!open);
    };

    const cancel = () => {
      toggle();
    };

    const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && files?.length > 0) {
        try {
          const image = files[0];
          if (
            image.type.includes("video") &&
            image.size > MAX_FILE_VIDEO_SIZE_BYTES
          ) {
            throw new Error(t("images-cannot-larger-30mb", { ns: "message" }));
          } else if (
            !image.type.includes("video") &&
            image.size > MAX_FILE_SIZE_BYTES
          ) {
            throw new Error(t("images-cannot-larger-10mb", { ns: "message" }));
          }
          const base64 = await fileToDataUrl(image);
          if (typeof base64 !== "string") {
            throw new Error(t("error-occurred"));
          }
          const type =
            image.type !== "image/png" &&
            image.type !== "image/jpeg" &&
            image.type !== "image/jpg" &&
            image.type !== "image/svg+xml";

          if (type || !cropImage) {
            if (!resize) {
              return onChange(image);
            }

            const resized = (await resizeImageFileSize({
              file: image,
              maxHeight: 500,
              maxWidth: 500,
            })) as File;

            return onChange(resized);
          }

          if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              setImg({
                name: image.name || "",
                type: image.type || "",
                src: reader.result?.toString() || "",
              })
            );
            reader.readAsDataURL(e.target.files[0]);
            toggle();
          }
        } catch (err: any) {
          fireNotification({
            type: "error",
            description: err?.message,
          });
        }
      }
    };

    const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
      e.currentTarget.value = "";
    };

    const openPickFileDialog = () => {
      inputRef.current?.click();
    };
    const onChangeCrop = (crop: PixelCrop) => {
      setCrop(crop);
    };

    const onComplete = async (crop: PixelCrop, _: PercentCrop) => {
      setCompletedCrop(crop);
    };

    const getCroppedImg = () => {
      if (!imgRef.current || !completedCrop)
        return { originFileObj: undefined, url: undefined };

      const canvas = document.createElement("canvas");
      const pixelRatio = window.devicePixelRatio;
      const scaleX = imgRef.current?.naturalWidth / imgRef.current?.width;
      const scaleY = imgRef.current?.naturalHeight / imgRef.current?.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) return { originFileObj: undefined, url: undefined };

      canvas.width = completedCrop.width * pixelRatio * scaleX;
      canvas.height = completedCrop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );

      const url = canvas.toDataURL(img.type);
      const originFileObj = dataUrlToFile(url, img.name);
      return { originFileObj, url };
    };

    const onSaveCrop = async () => {
      const image = getCroppedImg();
      if (!image || !image.originFileObj || !image.url) return;

      if (!resize) {
        onChange(image.originFileObj);
        return toggle();
      }

      const resized = (await resizeImageFileSize({
        file: image.originFileObj,
        maxHeight: 500,
        maxWidth: 500,
      })) as File;

      onChange(resized);
      return toggle();
    };

    return (
      <div>
        <input
          accept={accepts.join(",")}
          multiple={false}
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={onPicked}
          onClick={clearPreviousValue}
        />
        <CModal
          open={open}
          forceRender
          onCancel={cancel}
          // width={"40%"}
          closable={false}
          maskClosable={false}
        >
          <div>
            <CSelect
              className="mb-3"
              defaultValue={1 / 1}
              value={aspect}
              onChange={(value: number) => setAspect(value)}
              valueOption={{
                values: [
                  { value: 1 / 1, title: "1 / 1" },
                  { value: 4 / 3, title: "4 / 3" },
                ],
                valueKey: "value",
                labelKey: "title",
              }}
            />

            <ReactCrop
              crop={crop}
              onChange={onChangeCrop}
              onComplete={onComplete}
              aspect={aspect}
              keepSelection={true}
              // className="w-full"
            >
              <img ref={imgRef} alt="Crop me" src={img.src} />
            </ReactCrop>

            <div className="px-[60px]">
              <CModal.CModalHeader
                cancel={{
                  onClick: cancel,
                }}
                submit={{
                  onClick: onSaveCrop,
                }}
              />
            </div>
          </div>
        </CModal>
      </div>
    );
  }
);
