import { Col, Row, Space } from "antd";
import React from "react";
import BackOffice from "./content/backoffice";
import ListMenus from "./content/list-menus";
import Application from "./content/application";
import Group from "./content/group";
import styled from "styled-components";
import tw from "twin.macro";

export const SpaceStyle = styled(Space)`
  ${tw`h-[200px]`}
  .count {
    font-size: 24px;
    color: #44955b;
  }
  .people {
    font-size: 18px;
    color: #276347;
  }
  .title {
    color: #58595b;
  }
`;

const Statistics = () => {
  return (
    <div>
      <Row gutter={[14, 14]}>
        <Col md={24} lg={12} xl={12} xxl={6}>
          <BackOffice />
        </Col>
        <Col md={24} lg={12} xl={12} xxl={6}>
          <Application />
        </Col>
        <Col md={24} lg={24} xl={24} xxl={12}>
          <Group />
        </Col>
        <Col span={24}>
          <ListMenus />
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
