import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { numFormat } from "tools/string";

export interface IRenderLineChart {
  data: any[];
  dataKeyX?: string;
  dataKeyY: string;
  labelX?: string;
  labelY?: string;
  CustomizedDot?: (props: any) => ReactElement;
  height?: number;
}

export const RenderLineChart: FC<IRenderLineChart> = ({
  data,
  dataKeyX,
  dataKeyY,
  labelX,
  labelY,
  CustomizedDot,
  height = 300,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    //get size page width
    if (ref?.current === null) return;
    setWidth(ref?.current?.offsetWidth);
    const getWidth = () => {
      setWidth(ref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getWidth);
    return () => window.removeEventListener("resize", getWidth);
  }, []);

  const tickFormatter = (value: string, index: number) => {
    const number = numFormat(Number(value));
    if (!number) return "0";
    return number?.toString();
  };

  const CustomXAxisTick = (props: any) => {
    if (!dataKeyX) return <></>;
    const { x, y, payload } = props;
    const textAnchor = "middle";

    let tickValue = payload.value;
    if (tickValue.length >= 10) {
      tickValue = tickValue.slice(0, 12) + "...";
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor={textAnchor}>
          {tickValue}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-5 rounded-app">
          <p className="label">{`${label} : ${payload[0].value.toLocaleString()}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div ref={ref} className=" overflow-auto !overflow-x-hidden">
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" />
        <XAxis
          dataKey={dataKeyX}
          label={
            labelX ? (
              <Label value={labelX} offset={0} position="insideBottom" />
            ) : undefined
          }
          interval={0}
          tickSize={0}
          tickMargin={10}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          tickFormatter={tickFormatter}
          domain={[0, "auto"]}
          label={
            labelY
              ? { value: labelY, angle: -90, position: "center" }
              : undefined
          }
        />
        <Tooltip content={CustomTooltip} />
        <Line
          type="monotone"
          dataKey={dataKeyY}
          stroke="#7BB54E"
          activeDot={{
            fill: "#44955B",
            strokeWidth: 0,
            r: 6,
          }}
          fill="#7BB54E"
          dot={CustomizedDot ? <CustomizedDot /> : undefined}
        />
      </LineChart>
    </div>
  );
};
