import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosCentral } from "config/axios";
import { IResponse } from "config/axios/interface";
import { INewsCategory, INewsCategoryParams } from "./interface";

export const useGetCategorys = (
  params?: INewsCategoryParams,
): UseQueryResult<IResponse<INewsCategory[]>, Error> => {
  return useQuery(
    ["news-category", "all", params],
    async () => {
      const res = await axiosCentral.get("/news-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled },
  );
};

export const useScrollGetCategorys = (
  params?: INewsCategoryParams,
): UseInfiniteQueryResult<IResponse<INewsCategory[]>, Error> => {
  return useInfiniteQuery(
    ["news-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/news-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled },
  );
};

export const useGetCategory = (id?: number | string): UseQueryResult<INewsCategory, Error> => {
  return useQuery(
    ["news-category", "id", id],
    async () => {
      const res = await axiosCentral.get(`/news-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    },
  );
};

export const usePostCategory = (): UseMutationResult => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post("/news-category", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchCategory = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(`/news-category/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/news-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
