import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import { CSelect } from "components";

export const CSelectRoleResident: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={props.placeholder ? props.placeholder : t("role")}
      valueOption={{
        values: [
          { label: t("everyone"), value: "Everyone" },
          { label: t("owner"), value: "Owner" },
          { label: t("residents"), value: "Residents" },
          { label: t("tenants"), value: "Tenants" },
          { label: t("committee"), value: "Committee" },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
