import { notification } from "antd";
import { t } from "i18next";

type NotificationTypes = "success" | "info" | "warning" | "error";

interface NotificationProps {
  type: NotificationTypes;
  message?: string;
  menu?: string;
  description?: string;
}

export const fireNotification = ({ type, message, menu, description }: NotificationProps) => {
  const messages: { [K in NotificationTypes]: string } = {
    success: t("action-completed", { ns: "message" }),
    info: t("warning", { ns: "message" }),
    warning: t("warning", { ns: "message" }),
    error: t("error-occurred", { ns: "message" }),
  };
  const msg = message || messages[type];

  notification[type]({
    message: msg,
    duration: 3.5,
    className: "ronded-app",
    description: description,
  });
};
