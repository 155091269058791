import { FC } from "react";
import { useScrollGetPropertyUnits } from "service/property/property-unit";
import {
  IPropertyUnit,
  IPropertyUnitParams,
} from "service/property/property-unit/interface";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useTranslation } from "react-i18next";

export interface ISelectPropertyUnitProps
  extends CSelectProps,
    IPropertyUnitParams {
  onSelectCallback?: (v: IPropertyUnit) => void;
}

export const CSelectPropertyUnit: FC<ISelectPropertyUnitProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={props.placeholder ? props.placeholder : t("room-number")}
      useQuery={useScrollGetPropertyUnits}
      valueKey="id"
      labelKey="unitNumber"
      searchKey="unitNumber"
      queryParams={{
        isActive: true,
      }}
    />
  );
};
