import { Select } from "antd";
import styled from "styled-components";
import tw from "twin.macro";
import { CSelectProps } from ".";
import { useTranslation } from "react-i18next";
import { useAppLanguage } from "providers/app-language";

const StyledSelect = styled(Select)<{
  bgcolor?: number;
  $multipleMode?: boolean;
  $overview?: boolean;
}>`
  height: ${({ $multipleMode }) => ($multipleMode ? "100%" : "45px")};
  width: 100%;

  .ant-select-selector {
    ${tw`!rounded-app`}
    background-color: #ffffff !important;
    height: 100% !important;
    color: ${({ $overview }) =>
      $overview ? "#0958d9" : "rgba(0, 0, 0, 0.88)"} !important;
    // border-color: ${({ bgcolor }) => (bgcolor ? "white" : "")} !important;
    // padding-top: ${({ $multipleMode }) => ($multipleMode ? "4px" : "")};
    // padding-right: ${({ $multipleMode }) => ($multipleMode ? "10px" : "")};
    // padding-bottom: ${({ $multipleMode }) => ($multipleMode ? "0px" : "")};
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-overflow-item {
    // padding-right: ${({ $multipleMode }) => ($multipleMode ? "5px" : "")};
    // padding-bottom: ${({ $multipleMode }) => ($multipleMode ? "5px" : "")};
  }
`;

export const CSelect: React.FC<CSelectProps> = ({
  valueOption,
  options,
  bgcolor = true,
  multipleMode,
  overview = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const getOptions = () => {
    if (options && options.length) return options;
    if (!valueOption) return [];

    const {
      valueKey,
      labelKey,
      values,
      translation,
      translationI18n,
      notValueKey,
    } = valueOption;

    return values?.map((item) => {
      if (notValueKey) {
        // select object
        let label = item[labelKey];
        if (labelKey === "first-last-name") {
          label = `${item["firstName"]} ${item["lastName"]}`;
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });
          label = value?.[labelKey];
        }

        if (translationI18n) {
          label = t(item[labelKey]);
        }

        const value = JSON.stringify(item);
        return { ...item, value: value, label: label };
      } else {
        // select by valueKey
        if (labelKey === "first-last-name") {
          return {
            ...item,
            value: item[valueKey],
            label: `${item["firstName"]} ${item["lastName"]}`,
          };
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });

          return { ...item, value: item[valueKey], label: value?.[labelKey] };
        }

        if (translationI18n) {
          return { ...item, value: item[valueKey], label: t(item[labelKey]) };
        }

        return { ...item, value: item[valueKey], label: item[labelKey] };
      }
    });
  };

  const opt = getOptions();

  return (
    <StyledSelect
      {...(props as any)}
      options={opt}
      bgcolor={bgcolor ? 1 : 0}
      getPopupContainer={(e) => e}
      $multipleMode={multipleMode}
      mode={multipleMode ? "multiple" : undefined}
      $overview={overview}
    />
  );
};
