import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import {
  IDashboardGraph,
  IDashboardParams,
  IDashboardTitle,
  IDashboardUnitNumber,
} from "../../interface";
import {
  IDashboardHomeCareStatus,
  IDashboardHomeCarePending,
  IDashboardHomeCareSLA,
} from "./interface";

export const useGetDashboardRepairingByStatus = (
  params?: IDashboardParams
): UseQueryResult<IDashboardHomeCareStatus, Error> => {
  return useQuery(
    ["dashboard-home-care", "most-by-status", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/most-repairing-by-status",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingCategory = (
  params?: IDashboardParams
): UseQueryResult<IDashboardTitle[], Error> => {
  return useQuery(
    ["dashboard-home-care", "most-repairing-sub-category", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/most-repairing-sub-category",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingUnit = (
  params?: IDashboardParams
): UseQueryResult<IDashboardUnitNumber[], Error> => {
  return useQuery(
    ["dashboard-home-care", "most-unit", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/most-unit-request-repairing",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingMostCategory = (
  params?: IDashboardParams
): UseQueryResult<IDashboardTitle[], Error> => {
  return useQuery(
    ["dashboard-home-care", "most-repairing-category", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/most-repairing-category",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingPending = (
  params?: IDashboardParams
): UseQueryResult<IDashboardHomeCarePending, Error> => {
  return useQuery(
    ["dashboard-home-care", "count-list-repairing-working-today", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/count-list-repairing-working-today",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingSLA = (
  params?: IDashboardParams
): UseQueryResult<IDashboardHomeCareSLA, Error> => {
  return useQuery(
    ["dashboard-home-care", "get-about-over-sla", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/get-about-over-sla",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingMostStatus = (
  params?: IDashboardParams
): UseQueryResult<IDashboardHomeCareStatus, Error> => {
  return useQuery(
    ["dashboard-home-care", "most-repairing-by-status", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/most-repairing-by-status",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardRepairingGraphPerDay = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-home-care", "graph-avg-per-day", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/repairing/graph-avg-per-day",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
