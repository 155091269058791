import {
  OverviewIcon,
  NewsAnnouncementIcon,
  PhoneBookIcon,
  ResidentsIcon,
  GalleryIcon,
  ActivitiesIcon,
  ChatIcon,
  HouseCareServiceIcon,
  ParcelIcon,
  FacilityIcon,
  VehicleIcon,
  ComplaintIcon,
  UnitIcon,
  SaleAndRentIcon,
  VotingIcon,
  RoutineIcon,
  WorkSpaceIcon,
  PaymentIcon,
  SurveyIcon,
} from "assets/images/icon/menu";
import { SiderMenuItemProps } from "layouts/menu/item";

export const PROPERTY_MENUS: SiderMenuItemProps[] = [
  {
    key: "property.overview",
    title: "property.overview",
    to: "/property/:propertyId/overview",
    icon: OverviewIcon,
    code: "2000",
  },
  {
    key: "property.news-and-announcement",
    title: "property.news-and-announcement",
    to: "/property/:propertyId/news-and-announcement",
    icon: NewsAnnouncementIcon,
    code: "2100",
  },

  {
    key: "property.chat",
    title: "property.chat",
    to: "/property/:propertyId/chat",
    icon: ChatIcon,
    code: "2200",
  },
  {
    key: "property.house-care-service",
    title: "property.house-care-service",
    to: "/property/:propertyId/house-care-service",
    icon: HouseCareServiceIcon,
    code: "2300",
    children: [
      {
        key: "property.house-care-service.list",
        title: "property.house-care-service.list",
        to: "/property/:propertyId/house-care-service/list",
        code: "2301",
      },
      {
        key: "property.house-care-service.setting",
        title: "property.house-care-service.setting",
        to: "/property/:propertyId/house-care-service/setting",
        code: "2302",
      },
      {
        key: "property.house-care-service.calendar",
        title: "property.house-care-service.calendar",
        to: "/property/:propertyId/house-care-service/calendar",
        code: "2303",
      },
    ],
  },
  {
    key: "property.parcel",
    title: "property.parcel",
    to: "/property/:propertyId/parcel",
    icon: ParcelIcon,
    code: "2400",
    children: [
      {
        key: "property.parcel.list",
        title: "property.parcel.list",
        to: "/property/:propertyId/parcel/list",
        code: "2401",
      },
      {
        key: "property.parcel.setting",
        title: "property.parcel.setting",
        to: "/property/:propertyId/parcel/setting",
        code: "2402",
      },
    ],
  },
  {
    key: "property.facility",
    title: "property.facility",
    to: "/property/:propertyId/facility",
    icon: FacilityIcon,
    code: "2500",
    children: [
      {
        key: "property.facility.list",
        title: "property.facility.list",
        to: "/property/:propertyId/facility/list",
        code: "2502",
      },
      {
        key: "property.facility.booking",
        title: "property.facility.booking",
        to: "/property/:propertyId/facility/booking",
        code: "2501",
      },
      {
        key: "property.facility.calendar",
        title: "property.facility.calendar",
        to: "/property/:propertyId/facility/calendar",
        code: "2503",
      },
    ],
  },
  {
    key: "property.phone-book",
    title: "property.phone-book",
    to: "/property/:propertyId/phone-book",
    icon: PhoneBookIcon,
    code: "2600",
    children: [
      {
        key: "property.phone-book.list",
        title: "property.phone-book.list",
        to: "/property/:propertyId/phone-book/list",
        code: "2601",
      },
      {
        key: "property.phone-book.category-setting",
        title: "property.phone-book.category-setting",
        to: "/property/:propertyId/phone-book/category-setting",
        code: "2602",
      },
    ],
  },
  {
    key: "property.residents",
    title: "property.residents",
    to: "/property/:propertyId/residents",
    icon: ResidentsIcon,
    code: "2700",
  },
  {
    key: "property.vehicle",
    title: "property.vehicle",
    to: "/property/:propertyId/vehicle",
    icon: VehicleIcon,
    code: "2800",
  },
  {
    key: "property.complaint",
    title: "property.complaint",
    to: "/property/:propertyId/complaint",
    icon: ComplaintIcon,
    code: "2900",
    children: [
      {
        key: "property.complaint.list",
        title: "property.complaint.list",
        to: "/property/:propertyId/complaint/list",
        code: "2901",
      },
      {
        key: "property.complaint.setting",
        title: "property.complaint.setting",
        to: "/property/:propertyId/complaint/setting",
        code: "2902",
      },
    ],
  },

  {
    key: "property.unit",
    title: "property.unit",
    to: "/property/:propertyId/unit",
    icon: UnitIcon,
    code: "3000",
  },
  {
    key: "property.sell-and-rent",
    title: "property.sell-and-rent",
    to: "/property/:propertyId/sell-and-rent",
    icon: SaleAndRentIcon,
    code: "3100",
  },
  {
    key: "property.voting",
    title: "property.voting",
    to: "/property/:propertyId/voting",
    icon: VotingIcon,
    code: "3300",
  },
  {
    key: "property.work-manage",
    title: "property.work-manage",
    to: "/property/:propertyId/work-manage",
    icon: RoutineIcon,
    code: "5000",
    children: [
      {
        key: "property.routine",
        title: "property.routine",
        to: "/property/:propertyId/work-manage/routine",
        code: "5001",
      },
      {
        key: "property.unroutine",
        title: "property.unroutine",
        to: "/property/:propertyId/work-manage/unroutine",
        code: "5002",
      },
      {
        key: "property.work-calendar",
        title: "property.work-calendar",
        to: "/property/:propertyId/work-manage/calendar",
        code: "5003",
      },
      {
        key: "property.work-setting",
        title: "property.work-setting",
        to: "/property/:propertyId/work-manage/setting",
        code: "5004",
      },
    ],
  },
  {
    key: "property.work-space",
    title: "property.work-space",
    to: "/property/:propertyId/work-space",
    icon: WorkSpaceIcon,
    code: "3600",
    children: [
      {
        key: "property.work-space-list",
        title: "property.work-space-list",
        to: "/property/:propertyId/work-space/work-space-list",
        code: "3601",
      },
      {
        key: "property.request-list",
        title: "property.request",
        to: "/property/:propertyId/work-space/request",
        code: "3602",
      },
    ],
  },
  {
    key: "property.gallery",
    title: "property.gallery",
    to: "/property/:propertyId/gallery",
    icon: GalleryIcon,
    code: "3700",
  },
  {
    key: "property.activities",
    title: "property.activities",
    to: "/property/:propertyId/activities",
    icon: ActivitiesIcon,
    code: "3900",
  },
  {
    key: "property.payment",
    title: "property.payment",
    to: "/property/:propertyId/payment",
    icon: PaymentIcon,
    code: "4000",
  },
  {
    key: "property.survey",
    title: "property.survey",
    to: "/property/:propertyId/survey",
    icon: SurveyIcon,
    code: "4100",
  },
];
