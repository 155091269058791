import axios, { AxiosResponse } from "axios";
import { getToken, getUuid } from "service/auth";

export const AUTHEN_URL = process.env.REACT_APP_AUTHEN_API_URL || "";
export const CENTRAL_URL = process.env.REACT_APP_API_URL || "";
export const PROPERTY_URL = process.env.REACT_APP_PROPERTY_URL || "";
export const CHAT_URL = process.env.REACT_APP_CHAT_URL || "";
export const API_VERSION = process.env.REACT_APP_API_VERSION || "";
export const LONGDO_KEY = process.env.REACT_APP_LONGDO_API_KEY;
export const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL || "";
export const SITE_KEY = process.env.REACT_APP_SITE_KEY || "";
export const NOTIFICATION_SOCKET_URL = process.env.REACT_APP_NOTIFICATION_URL || ""


let axiosCentral = axios.create();
let axiosProperty = axios.create();
let axiosAuthen = axios.create();
let axiosChat = axios.create();

axiosAuthen.interceptors.request.use(
  (request) => {
    const token = getToken();
    request.baseURL = `${AUTHEN_URL}/api/${API_VERSION}`;
    request.headers["Authorization"] = `Bearer ${token}`;
    request.headers["Accept"] = `application/json`;
    request.validateStatus = (_) => true;
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosCentral.interceptors.request.use(
  (request) => {
    const token = getToken();
    request.baseURL = `${CENTRAL_URL}/api`;
    request.headers["Authorization"] = `Bearer ${token}`;
    request.headers["Accept"] = `application/json`;
    request.validateStatus = (_) => true;
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosProperty.interceptors.request.use(
  (request) => {
    const token = getToken();
    const uuid = getUuid();
    request.baseURL = `${PROPERTY_URL}/api/${API_VERSION}`;
    request.headers["Authorization"] = `Bearer ${token}`;
    request.headers["Accept"] = `application/json`;
    request.headers["x-property-uuid"] = uuid;
    request.validateStatus = (_) => true;
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosChat.interceptors.request.use(
  (request) => {
    const token = getToken();
    const uuid = getUuid();
    request.baseURL = `${CHAT_URL}/api/${API_VERSION}`;
    request.headers["Authorization"] = `Bearer ${token}`;
    request.headers["Accept"] = `application/json`;
    request.headers["x-property-uuid"] = uuid;
    request.validateStatus = (_) => true;
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosAuthen, axiosCentral, axiosProperty, axiosChat };

// axios.interceptors.request.use(
//   (request) => {
//     const token = getToken();
//     request.baseURL = `${baseUrl}/api`;
//     request.headers["Authorization"] = `Bearer ${token}`;
//     request.headers["Accept"] = `application/json`;
//     request.validateStatus = (_) => true;
//     return Promise.resolve(request);
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const throwResponse = (res: AxiosResponse) => {
  const { message } = res.data;
  if (!Array.isArray(message)) {
    throw new Error(message);
  }
  const text = message.reduce((result: string, item: string) => {
    return `${result}${item}\n`;
  }, "");
  throw new Error(text);
};

// export const axiosProperty = axios.create({
//   baseURL: `${propertyUrl}/api/${versionPropertyApi}`,
//   headers: { "x-property-uuid": "e2d9a704-5bc7-41a3-831e-7b316cecf99e" },
// });

export default axios;
