import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import { IDashboardParcelStatus, IDashboardParcelAverage } from "./interface";
import {
  IDashboardGraph,
  IDashboardName,
  IDashboardParams,
  IDashboardUnitNumber,
} from "../../interface";
import { IResponse } from "config/axios/interface";

export const useGetDashboardParcel = (
  params?: IDashboardParams
): UseQueryResult<IDashboardParcelStatus, Error> => {
  return useQuery(
    ["dashboard-parcel", "all", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-parcel-by-status",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardParcelAverage = (
  params?: IDashboardParams
): UseQueryResult<IDashboardParcelAverage, Error> => {
  return useQuery(
    ["dashboard-parcel", "average", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/interval-three-months",
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};
export const useGetDashboardParcelByStatus = (
  params?: IDashboardParams
): UseQueryResult<IDashboardParcelStatus, Error> => {
  return useQuery(
    ["dashboard-parcel", "most-parcel-by-status", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-parcel-by-status",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardParcelType = (
  params?: IDashboardParams
): UseQueryResult<IDashboardName[], Error> => {
  return useQuery(
    ["dashboard-parcel", "type", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-parcel-types",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardParcelUnit = (
  params?: IDashboardParams
): UseQueryResult<IDashboardUnitNumber[], Error> => {
  return useQuery(
    ["dashboard-parcel", "unit", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-unit-used-parcel",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

// scroll
export const useScrollGetDashboardParcelType = (
  params?: IDashboardParams
): UseInfiniteQueryResult<IResponse<IDashboardName[]>, Error> => {
  return useInfiniteQuery(
    ["dashboard-parcel", "type", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-parcel-types",
        {
          params: { ...params, ...pageParam },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useScrollGetDashboardParcelUnit = (
  params?: IDashboardParams
): UseInfiniteQueryResult<IResponse<IDashboardUnitNumber[]>, Error> => {
  return useInfiniteQuery(
    ["dashboard-parcel", "unit", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/most-unit-used-parcel",
        {
          params: { ...params, ...pageParam },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetDashboardParcelPerDay = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-parcel", "count-parcel-per-day", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/count-parcel-per-day",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetDashboardParcelAvgDate = (
  params?: IDashboardParams
): UseQueryResult<IDashboardGraph[], Error> => {
  return useQuery(
    ["dashboard-parcel", "graph-avg", params],
    async () => {
      const res = await axiosCentral.get(
        "/dashboard/post-parcel/graph-avg-post-parcel-date",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
