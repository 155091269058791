import React from "react";
import { useScrollGetCategorys } from "service/news-category";
import { INewsCategoryParams } from "service/news-category/interface";
import { usePropertyScrollGetCategorys } from "service/property/news-category";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export interface CSelectNewsCategoryProps
  extends CSelectProps,
    INewsCategoryParams {
  inProperty?: boolean;
}

export const CSelectNewsCategory: React.FC<CSelectNewsCategoryProps> = ({
  type,
  inProperty = false,
  ...props
}) => {
  const useQuery = inProperty
    ? usePropertyScrollGetCategorys
    : useScrollGetCategorys;
  return (
    <CSelectPagination
      {...props}
      useQuery={useQuery}
      valueKey="id"
      labelKey="name"
      searchKey="name"
      queryParams={{
        type: type,
      }}
    />
  );
};
