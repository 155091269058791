import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectVehicleColor: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      showSearch
      {...props}
      placeholder={t("color")}
      valueOption={{
        values: [
          { value: "Black", title: "black" },
          { value: "Blue", title: "blue" },
          { value: "Bright blue ", title: "bright-blue" },
          { value: "Bright green ", title: "bright-green" },
          { value: "Bright red ", title: "bright-red" },
          { value: "Brown", title: "brown" },
          { value: "Crimson", title: "crimson" },
          { value: "Dark blue", title: "dark-blue" },
          { value: "Dark brown ", title: "dark-brown" },
          { value: "Dark green", title: "dark-green" },
          { value: "Dark pink", title: "dark-pink" },
          { value: "Dark red", title: "dark-red" },
          { value: "Drab", title: "drab" },
          { value: "Gold", title: "gold" },
          { value: "Golden yellow", title: "golden-yellow" },
          { value: "Gray ", title: "gray" },
          { value: "Green", title: "green" },
          { value: "Khaki", title: "khaki" },
          { value: "Light blue", title: "light-blue" },
          { value: "Light green", title: "light-green" },
          { value: "Light purple", title: "light-purple" },
          { value: "Magenta", title: "magenta" },
          { value: "Olive green", title: "olive-green" },
          { value: "Orange", title: "orange" },
          { value: "Pink", title: "pink" },
          { value: "Purple", title: "purple" },
          { value: "Red", title: "red" },
          { value: "Silver", title: "silver" },
          { value: "Sky blue", title: "sky-blue" },
          { value: "Tawny", title: "tawny" },
          { value: "Ultramarine", title: "ultramarine" },
          { value: "Violet", title: "violet" },
          { value: "White", title: "white" },
          { value: "Yellow", title: "yellow" },
        ],
        valueKey: "value",
        labelKey: "title",
        translationI18n: true,
      }}
    />
  );
};
