import { useTranslation } from "react-i18next";
import { CSSProperties, FC } from "react";
import { CEmpty } from "components";
import { AimOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { CImage } from "components/input/c-image";
import { LoadingIcon } from "assets/images";

interface ErrorPageProps {
  error?: Error | null;
  height?: string | number;
}

export const ErrorPage: FC<ErrorPageProps> = ({ error, height = "100%" }) => {
  const { t } = useTranslation("message");
  return (
    <div
      className="w-full center flex-col text-center"
      style={{ flexDirection: "column", height: height }}
    >
      <span>{t("error-occurred")}</span>
      <span>{error?.message}</span>
    </div>
  );
};

interface EmptyPageProps {
  className?: string;
  style?: CSSProperties;
}

export const EmptyPage: FC<EmptyPageProps> = ({ className, style }) => {
  return (
    <div
      className={`w-full h-full center flex-col text-center ${className}`}
      style={style}
    >
      <CEmpty />
    </div>
  );
};

export interface PleaseSelectDataPageProps {
  height?: string | number;
  icon?: boolean;
}

export const PleaseSelectDataPage: React.FC<PleaseSelectDataPageProps> = ({
  height = "100%",
  icon = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="center flex-col" style={{ height }}>
      {icon ? (
        <AimOutlined
          className="!text-primary-dark"
          style={{ height: 45, width: 45, fontSize: 45, color: "#276347" }}
        />
      ) : null}
      <Typography.Text className="!mt-3 text-[18px]">
        {t("please-select-data", { ns: "message" })}
      </Typography.Text>
    </div>
  );
};

interface LoadingPageProps {
  className?: string;
  style?: CSSProperties;
}

export const LoadingPage: FC<LoadingPageProps> = ({ className, style }) => {
  return (
    <div className={`w-full h-full center ${className}`} style={style}>
      <CImage src={LoadingIcon} width="150px" height="150px" />
    </div>
  );
};
