import { Col, Row } from "antd";
import React from "react";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import { StatusBox } from "./content/status-box";
import VolumeComplaint from "./content/volume-complaint";
import NumberComplaint from "./content/number-complaint";

const Complaint = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.complaint", { ns: "menu" })}</Col>
        <StatusBox />
        <Col span={24}>
          <VolumeComplaint />
        </Col>
        <Col span={24}>
          <NumberComplaint />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Complaint;
