import { Col } from "antd";
import { Box } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetPropertyDashBoardComplaint } from "service/dashboard/property/recommrnt-feed-back";
import { LoadingDashboard } from "../..";

export const StatusBox = () => {
  const { t } = useTranslation();

  const { data, isFetching } = useGetPropertyDashBoardComplaint();

  return (
    <React.Fragment>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-complaints")}
            count={data?.all || 0}
            color="blue"
            textAfter={false}
          />
        </LoadingDashboard>
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-pending-complaints")}
            count={data?.pending || 0}
            color="red"
            textAfter={false}
          />
        </LoadingDashboard>
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-completed-complaints")}
            count={data?.success || 0}
            color="green"
            textAfter={false}
          />
        </LoadingDashboard>
      </Col>
    </React.Fragment>
  );
};
