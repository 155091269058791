export type ButtonActionThemeTypes = "green" | "orange";

interface ThemeOption {
  bg?: string;
  color?: string;
  border?: string;
  icon?: string;
  actionBg?: string;
  actionBorder?: string;
  actionColor?: string;
  actionIcon?: string;
}

export const BUTTON_ACTION_THEME: {
  [K in ButtonActionThemeTypes]: ThemeOption;
} = {
  green: {
    actionBg: "#276347",
    actionColor: "white",
    actionBorder: "#276347",
    actionIcon: "white",
    bg: "#E9EFEC ",
    border: "#E9EFEC ",
    color: "#276347",
    icon: "#276347",
  },
  orange: {
    actionBg: "#F8931D",
    actionColor: "white",
    actionBorder: "#F8931D",
    actionIcon: "white",
    bg: "#FEF0DE",
    border: "#FEF0DE",
    color: "#F8931D",
    icon: "#F8931D",
  },
};
