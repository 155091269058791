import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";

export const CSelectSurveyTaker: FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const status = [
    {
      value: "Everyone",
      label: "everyone",
    },
    {
      value: "Committee",
      label: "committee",
    },
    {
      value: "Owner",
      label: "owner",
    },
    {
      value: "Tenants",
      label: "tenants",
    },
    {
      value: "Residents",
      label: "residents",
    },
  ];

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("display-type")}
      valueOption={{
        values: status,
        valueKey: "value",
        labelKey: "label",
        translationI18n: true,
      }}
    />
  );
};
