import {
  IMediaObject,
  IMediaObjectParams,
  IMediaObjectResponse,
} from "./interface";
import { throwResponse, axiosCentral } from "config/axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";

export const useGetMediaObjects = (
  params?: IMediaObjectParams
): UseQueryResult<IMediaObjectResponse<IMediaObject[]>, Error> => {
  return useQuery(
    ["media-object", "all", params],
    async () => {
      const res = await axiosCentral.get("/media-object", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetMediaObject = (
  id?: string | number
): UseQueryResult<IMediaObject> => {
  return useQuery(
    ["media-object", "id", id],
    async () => {
      if (!id) return;
      const res = await axiosCentral.get(`/media-object/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const postMediaObject = async (
  imageFile: File
): Promise<IMediaObject> => {
  let formData = new FormData();
  formData.append("file", imageFile);
  const res = await axiosCentral.post<IResponse<IMediaObject>>(
    "/media-object",
    formData
  );
  if (res.status === 200) {
    const { data } = res.data;
    return data;
  }
  return throwResponse(res);
};

export const usePostMediaObject = (): UseMutationResult => {
  return useMutation(async ({ imageFile }: any) => {
    let formData = new FormData();
    formData.append("file", imageFile);
    const res = await axiosCentral.post("/media-object", formData);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostCentralMediaObject = (): UseMutationResult<
  IResponse<IMediaObject>
> => {
  return useMutation(async ({ imageFile }: any) => {
    let formData = new FormData();
    formData.append("file", imageFile);
    const res = await axiosCentral.post("/media-object", formData);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteMediaObject = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/media-object/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteMediaObjectBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosCentral.delete(`/media-object/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useCheckDeleteMediaObject = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosCentral.delete(`/media-object/bulk/is-used`, {
      data,
    });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};
