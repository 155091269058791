import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { ErrorDashboard, LoadingDashboard } from "../..";
import { useGetPropertyDashboardRepairingPending } from "service/dashboard/property/homecare";

const Waiting = () => {
  const { t } = useTranslation();

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardRepairingPending();

  return (
    <div className="!rounded-app">
      <LoadingDashboard loading={isFetching}>
        <Space className="flex justify-between bg-white px-4 h-[50px] rounded-t-app">
          <div>{t("pending")}</div>
          <div className="text-[20px]">
            {data?.propertyUnit || 0} {t("suite")}
          </div>
        </Space>
        {isError ? (
          <div className="bg-[#FFFFFF] center flex-col h-[300px] rounded-b-app">
            <ErrorDashboard error={error} />
          </div>
        ) : (
          <div>
            <div className="bg-[#FEEAEE] text-[#F52A53] center flex-col h-[100px]">
              <span>{t("beyond-the-appointed-day")}</span>
              <span className="text-[20px]">{data?.after || 0}</span>
            </div>
            <div className="bg-[#FFF4E8] text-[#F8931D] center flex-col h-[100px]">
              <span>{t("today-list")}</span>
              <span className="text-[20px]">{data?.today || 0}</span>
            </div>
            <div className="bg-[#EAF5FF] text-[#2061E7] center flex-col h-[100px] rounded-b-app">
              <span>{t("appointment-date-has-not-yet")}</span>
              <span className="text-[20px]">{data?.before || 0}</span>
            </div>
          </div>
        )}
      </LoadingDashboard>
    </div>
  );
};

export default Waiting;
