import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import { ITaskCategory, ITaskCategoryParams } from "./interface";
import { IResponseWork } from "config/axios/interface";

export const useGetTaskCategorys = (
  params?: ITaskCategoryParams
): UseQueryResult<IResponseWork<ITaskCategory[]>, Error> => {
  return useQuery(
    ["task-category", "all", params],
    async () => {
      const res = await axiosCentral.get("/tasks-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetTaskCategory = (
  params?: ITaskCategoryParams
): UseInfiniteQueryResult<IResponseWork<ITaskCategory[]>, Error> => {
  return useInfiniteQuery(
    ["task-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/tasks-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.mainId || params?.enabled }
  );
};

export const useGetTaskCategory = (
  params?: ITaskCategoryParams
): UseQueryResult<ITaskCategory, Error> => {
  return useQuery(
    ["task-category", "id", params],
    async () => {
      const { id, type } = params || {};
      const res = await axiosCentral.get(`/tasks-category/${id}?type=${type}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const usePostTaskCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post("/tasks-category", data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const usePatchTaskCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(`/tasks-category/${id}`, data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useDeleteTaskCategory = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.delete(`/tasks-category/${id}`, {
      data: { ...data },
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteTaskCategoryBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosCentral.delete(`/tasks-category/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
