import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IPropertyMediaObject,
  IPropertyMediaObjectParams,
  IPropertyMediaObjectResponse,
} from "./interface";
import { IMediaObject } from "service/media-object/interface";

export const useGetPropertyMediaObjects = (
  params?: IPropertyMediaObjectParams
): UseQueryResult<
  IPropertyMediaObjectResponse<IPropertyMediaObject[]>,
  Error
> => {
  return useQuery(
    ["property-media-object", "all", params],
    async () => {
      const res = await axiosProperty.get("/media-object", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetPropertyMediaObject = (
  id?: string
): UseQueryResult<IPropertyMediaObject> => {
  return useQuery(
    ["property-media-object", "id", id],
    async () => {
      if (!id) return;
      const res = await axiosProperty.get(`/media-object/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const postPropertyMediaObject = async (
  file: File | IMediaObject
): Promise<IPropertyMediaObject> => {
  if ("id" in file) return file;
  let formData = new FormData();
  formData.append("file", file);
  const res = await axiosProperty.post<IResponse<IPropertyMediaObject>>(
    "/media-object",
    formData
  );
  if (res.status === 200) {
    const { data } = res.data;
    return data;
  }
  return throwResponse(res);
};

export const postPropertyMultiMediaObject = async (
  files: (IMediaObject | File)[]
) => {
  let id: number[] = [];
  for (const file of files) {
    if ("id" in file) {
      id = [...id, file.id];
      continue;
    }
    const media = await postPropertyMediaObject(file);
    id = [...id, media.id];
  }
  return id;
};

export const usePostPropertyMediaObject = (): UseMutationResult<
  IResponse<IPropertyMediaObject>
> => {
  return useMutation(async ({ imageFile }: any) => {
    let formData = new FormData();
    formData.append("file", imageFile);
    const res = await axiosProperty.post("/media-object", formData);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyMediaObject = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/media-object/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePropertyMediaObjectBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/media-object/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useCheckDeletePropertyMediaObject = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/media-object/bulk/is-used`, {
      data,
    });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};
