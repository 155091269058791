import React from "react";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { usePropertyScrollGetEstateCategorys } from "service/property-category";

export interface CSelectPropertyCategory extends CSelectProps {
  queryParams?: Record<string, any>;
}

export const CSelectPropertyCategory : React.FC<CSelectPropertyCategory> = ({
  ...props
}) => {

  return (
    <CSelectPagination
      {...props}
      useQuery={usePropertyScrollGetEstateCategorys}
      valueKey="id"
      labelKey="name"
      searchKey="name"
    />
  );
};
