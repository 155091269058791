import { RouteObject } from "react-router-dom";
import AppLayout from "layouts";
import LoginPage from "pages/login";
import { lazy } from "@loadable/component";
import React from "react";
import AppRoleMenuProvider from "providers/app-role-menu";

const OverviewPage = lazy(() => import("pages/overview"));

const UserManagementPage = lazy(() => import("pages/user-management"));
const UserManagementCreatePage = lazy(
  () => import("pages/user-management/create")
);
const ActivitiesPage = lazy(() => import("pages/activities"));
const ActivitiesCreate = lazy(() => import("pages/activities/create"));

const NewsAndAnnouncementPage = lazy(
  () => import("pages/news-and-announcement")
);
const NewsAndAnnouncementCreatePage = lazy(
  () => import("pages/news-and-announcement/create")
);
const PropertyPage = lazy(() => import("pages/property"));
const DetailPropertyPage = lazy(
  () => import("pages/property/property-page/create-property")
);

const GalleryPage = lazy(() => import("pages/gallery"));
// const SettingPage = lazy(() => import("pages/setting"));

const SurveyPage = lazy(() => import("pages/survey"));
const SurveyCreatePage = lazy(() => import("pages/survey/create-and-edit"));

const PropertySurveyPage = lazy(() => import("pages/property/survey"));
const PropertySurveyCreatePage = lazy(
  () => import("pages/property/survey/create-and-edit")
);

const ResidentsPage = lazy(() => import("pages/residents"));
const CentralResidentsCreate = lazy(
  () => import("pages/residents/create-and-edit")
);

const SellAndRentPage = lazy(() => import("pages/sell-and-rent"));
const SellAndRentCreatePage = lazy(
  () => import("pages/sell-and-rent/create-and-edit")
);
const TermsSellAndRentCreate = lazy(
  () => import("pages/sell-and-rent/terms-and-condition")
);

const UnRoutinePage = lazy(() => import("pages/unroutine"));
const UnRoutineCreate = lazy(() => import("pages/unroutine/create"));
const WorkCalendarPage = lazy(() => import("pages/work-calendar"));

const ComplaintSettingPage = lazy(
  () => import("pages/complanint/complanint-setting")
);

const WorkSpacePage = lazy(() => import("pages/work-space"));
const WorkSpaceAssetCreate = lazy(
  () => import("pages/work-space/content-asset/create-and-edit")
);
const WorkSpaceNumberEditDetails = lazy(
  () =>
    import("pages/work-space/content-asset/create-and-edit/number/edit-detail")
);

const WorkSpaceSuperfluousCreate = lazy(
  () => import("pages/work-space/content-superfluous/create-and-edit")
);

const RequestPage = lazy(() => import("pages/request"));
const RequestCreate = lazy(() => import("pages/request/create-and-edit"));

//property menu
const PropertyOverviewPage = lazy(() => import("pages/property/overview"));
const PropertyNewsAndAnnouncementPage = lazy(
  () => import("pages/property/news-and-announcement")
);
const PropertyNewsAndAnnouncementCreatePage = lazy(
  () => import("pages/property/news-and-announcement/create")
);
const PropertyChatPage = lazy(() => import("pages/property/chat"));
const PropertyChatSettingPage = lazy(
  () => import("pages/property/chat/setting")
);
const PropertyHouseCareServicePage = lazy(
  () => import("pages/property/house-care-service")
);
const PropertyHouseCareServiceCreateAndEditPage = lazy(
  () => import("pages/property/house-care-service/create")
);
const PropertyHouseCareServiceSettingPage = lazy(
  () => import("pages/property/house-care-service-setting")
);
const PropertyHouseCareServiceCalendarPage = lazy(
  () => import("pages/property/house-care-service-calendar")
);
const PropertyParcelPage = lazy(() => import("pages/property/parcel"));
const PropertyParcelCreateAndEdit = lazy(
  () => import("pages/property/parcel/create")
);
const PropertyParcelSettingPage = lazy(
  () => import("pages/property/parcel-setting")
);

const PropertyFacilityListPage = lazy(
  () => import("pages/property/facility/list")
);
const PropertyFacilityListCreateAndEditPage = lazy(
  () => import("pages/property/facility/list/create-and-edit")
);

const PropertyFacilityBookingPage = lazy(
  () => import("pages/property/facility/booking")
);
const PropertyFacilityBookingCreateAndEditPage = lazy(
  () => import("pages/property/facility/booking/create-and-edit")
);

const PropertyFacilityCalendarPage = lazy(
  () => import("pages/property/facility/calendar")
);
const PropertyPhoneBookPage = lazy(
  () => import("pages/property/phone-book/list")
);
const PropertyPhoneCategoryPage = lazy(
  () => import("pages/property/phone-book/category-setting")
);
const PropertyResidentPage = lazy(() => import("pages/property/resident"));
const ResidentCreateAndEdit = lazy(
  () => import("pages/property/resident/create-and-edit")
);

const PropertyVehiclePage = lazy(() => import("pages/property/vehicle"));
const PropertyVehicleCreateAndEdit = lazy(
  () => import("pages/property/vehicle/create")
);

const PropertyComplaintPage = lazy(
  () => import("pages/property/complaint/complaint-list")
);
const PropertyComplaintCreatePage = lazy(
  () =>
    import("pages/property/complaint/complaint-list/content/create-and-edit")
);
const PropertyComplaintSettingPage = lazy(
  () => import("pages/property/complaint/complaint-setting")
);

const PropertyApartmentPage = lazy(() => import("pages/property/apartment"));
const ProPertyApartmentCreate = lazy(
  () => import("pages/property/apartment/create/index")
);

const PropertySellAndRentPage = lazy(
  () => import("pages/property/sell-and-rent")
);
const PropertySellAndRentCreate = lazy(
  () => import("pages/property/sell-and-rent/create-and-edit")
);
const PropertyTermsSellAndRentCreate = lazy(
  () => import("pages/property/sell-and-rent/terms-and-condition")
);

const PropertyVotingPage = lazy(() => import("pages/property/voting"));
const PropertyVotingCreateAndEditPage = lazy(
  () => import("pages/property/voting/create-and-edit")
);

const PropertyWorkSpacePage = lazy(() => import("pages/property/work-space"));

const PropertyGalleryPage = lazy(() => import("pages/property/gallery"));

const PropertyRequestPage = lazy(() => import("pages/property/request"));
const RequestWorkCreate = lazy(
  () => import("pages/property/request/create-and-edit")
);

const PropertyPaymentPage = lazy(() => import("pages/property/payment"));
const ProPertyPaymentCreate = lazy(
  () => import("pages/property/payment/create/")
);

const PhoneBookPage = lazy(() => import("pages/phone-book/list"));
const PhoneCategoryPage = lazy(
  () => import("pages/phone-book/category-setting")
);

const PropertyActivityPage = lazy(() => import("pages/property/activities"));
const PropertyActivityCreate = lazy(
  () => import("pages/property/activities/create")
);

const PropertyWorkRoutinePage = lazy(
  () => import("pages/property/work-routine")
);
const PropertyWorkRoutineCreate = lazy(
  () => import("pages/property/work-routine/create-and-edit")
);

const PropertyWorkSettingCategoryPage = lazy(
  () => import("pages/property/work-setting")
);

const UnRoutinePropertyPage = lazy(() => import("pages/property/unroutine"));
const UnRoutinePropertyCreate = lazy(
  () => import("pages/property/unroutine/create")
);

const Suggestion = lazy(() => import("pages/suggestion"));
const SiggestionCreate = lazy(() => import("pages/suggestion/content/create"));

const PropertyWorkCalendar = lazy(() => import("pages/property/work-calendar"));

const PropertyWorkSpaceCreate = lazy(
  () => import("pages/property/work-space/content-asset/create-and-edit")
);
const PropertyAmountEdit = lazy(
  () =>
    import(
      "pages/property/work-space/content-asset/create-and-edit/amount/edit"
    )
);
const PropertyWorkSpaceSuperfluousCreate = lazy(
  () => import("pages/property/work-space/content-superfluous/create-and-edit")
);
const RequestListEdit = lazy(
  () =>
    import(
      "pages/property/work-space/content-superfluous/create-and-edit/request-list/edit"
    )
);

//setting
const SettingRolePage = lazy(() => import("pages/setting/role"));
const MenuDisplayPage = lazy(() => import("pages/setting/menu-display"));
const SettingTermsAndConditionPage = lazy(
  () => import("pages/setting/terms-and-condition")
);
const SettingPDPAPage = lazy(() => import("pages/setting/pdpa"));
const SettingPrivacyPage = lazy(
  () => import("pages/setting/privacy-and-policy")
);

const WorkSettingCategory = lazy(() => import("pages/work-setting"));
export interface CRouteObject
  extends Omit<RouteObject, "element" | "children"> {
  element?: React.ComponentType;
  children?: CRouteObject[];
}

//ถ้า path มี children เพิ่ม ให้ไปเพิ่ม path ใน interface ด้วย

export const APP_ROUTES: RouteObject[] = [
  {
    path: "/property/:propertyId",
    // element: <CreatePropertyPage />,
    element: (
      <AppRoleMenuProvider>
        <AppLayout type="property" />
      </AppRoleMenuProvider>
    ),
    children: [
      {
        path: "/property/:propertyId",
        element: <DetailPropertyPage />,
      },
      {
        path: "/property/:propertyId/overview",
        element: <PropertyOverviewPage />,
      },
      {
        path: "/property/:propertyId/news-and-announcement",
        element: <PropertyNewsAndAnnouncementPage />,
      },
      {
        path: "/property/:propertyId/news-and-announcement/create",
        element: <PropertyNewsAndAnnouncementCreatePage />,
      },
      {
        path: "/property/:propertyId/news-and-announcement/:id",
        element: <PropertyNewsAndAnnouncementCreatePage />,
      },
      {
        path: "/property/:propertyId/chat",
        element: <PropertyChatPage />,
      },
      {
        path: "/property/:propertyId/chat/setting",
        element: <PropertyChatSettingPage />,
      },
      {
        path: "/property/:propertyId/house-care-service",
        // element: <PropertyHouseCareServicePage />,
        children: [
          {
            path: "/property/:propertyId/house-care-service/list",
            element: <PropertyHouseCareServicePage />,
          },
          {
            path: "/property/:propertyId/house-care-service/list/create",
            element: <PropertyHouseCareServiceCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/house-care-service/list/:id",
            element: <PropertyHouseCareServiceCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/house-care-service/setting",
            element: <PropertyHouseCareServiceSettingPage />,
          },
          {
            path: "/property/:propertyId/house-care-service/calendar",
            element: <PropertyHouseCareServiceCalendarPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/parcel",
        // element: <PropertyParcelPage />,
        children: [
          {
            path: "/property/:propertyId/parcel/list",
            element: <PropertyParcelPage />,
          },
          {
            path: "/property/:propertyId/parcel/list/create",
            element: <PropertyParcelCreateAndEdit />,
          },
          {
            path: "/property/:propertyId/parcel/list/:id",
            element: <PropertyParcelCreateAndEdit />,
          },
          {
            path: "/property/:propertyId/parcel/setting",
            element: <PropertyParcelSettingPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/facility",
        // element: <PropertyFacilityPage />,
        children: [
          {
            path: "/property/:propertyId/facility/list",
            element: <PropertyFacilityListPage />,
          },
          {
            path: "/property/:propertyId/facility/list/create",
            element: <PropertyFacilityListCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/facility/list/:id",
            element: <PropertyFacilityListCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/facility/booking",
            element: <PropertyFacilityBookingPage />,
          },
          {
            path: "/property/:propertyId/facility/booking/create",
            element: <PropertyFacilityBookingCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/facility/booking/:id",
            element: <PropertyFacilityBookingCreateAndEditPage />,
          },
          {
            path: "/property/:propertyId/facility/calendar",
            element: <PropertyFacilityCalendarPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/phone-book",
        // element: <PropertyPhoneBookPage />,
        children: [
          {
            path: "/property/:propertyId/phone-book/list",
            element: <PropertyPhoneBookPage />,
          },
          {
            path: "/property/:propertyId/phone-book/category-setting",
            element: <PropertyPhoneCategoryPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/residents",
        element: <PropertyResidentPage />,
      },
      {
        path: "/property/:propertyId/residents/create",
        element: <ResidentCreateAndEdit />,
      },
      {
        path: "/property/:propertyId/residents/:id",
        element: <ResidentCreateAndEdit />,
      },
      {
        path: "/property/:propertyId/vehicle",
        element: <PropertyVehiclePage />,
      },
      {
        path: "/property/:propertyId/vehicle/create",
        element: <PropertyVehicleCreateAndEdit />,
      },
      {
        path: "/property/:propertyId/vehicle/:id",
        element: <PropertyVehicleCreateAndEdit />,
      },
      {
        path: "/property/:propertyId/complaint",
        // element: <PropertyComplaintPage />,
        children: [
          {
            path: "/property/:propertyId/complaint/list",
            element: <PropertyComplaintPage />,
          },
          {
            path: "/property/:propertyId/complaint/list/create",
            element: <PropertyComplaintCreatePage />,
          },
          {
            path: "/property/:propertyId/complaint/list/:id",
            element: <PropertyComplaintCreatePage />,
          },
          {
            path: "/property/:propertyId/complaint/setting",
            element: <PropertyComplaintSettingPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/unit",
        element: <PropertyApartmentPage />,
      },
      {
        path: "/property/:propertyId/unit/create",
        element: <ProPertyApartmentCreate />,
      },
      {
        path: "/property/:propertyId/unit/:id",
        element: <ProPertyApartmentCreate />,
      },
      {
        path: "/property/:propertyId/sell-and-rent",
        element: <PropertySellAndRentPage />,
      },
      {
        path: "/property/:propertyId/sell-and-rent/create",
        element: <PropertySellAndRentCreate />,
      },
      {
        path: "/property/:propertyId/sell-and-rent/:id",
        element: <PropertySellAndRentCreate />,
      },
      {
        path: "/property/:propertyId/sell-and-rent/terms",
        element: <PropertyTermsSellAndRentCreate />,
      },
      {
        path: "/property/:propertyId/voting",
        element: <PropertyVotingPage />,
      },
      {
        path: "/property/:propertyId/voting/create",
        element: <PropertyVotingCreateAndEditPage />,
      },
      {
        path: "/property/:propertyId/voting/:id",
        element: <PropertyVotingCreateAndEditPage />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list",
        element: <PropertyWorkSpacePage />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/create",
        element: <PropertyWorkSpaceCreate />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/:id",
        element: <PropertyWorkSpaceCreate />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/amount/:id",
        element: <PropertyAmountEdit />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/superfluous/create",
        element: <PropertyWorkSpaceSuperfluousCreate />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/superfluous/:id",
        element: <PropertyWorkSpaceSuperfluousCreate />,
      },
      {
        path: "/property/:propertyId/work-space/work-space-list/superfluous/request/:id",
        element: <RequestListEdit />,
      },
      {
        path: "/property/:propertyId/gallery",
        element: <PropertyGalleryPage />,
      },
      {
        path: "/property/:propertyId/work-space/request",
        element: <PropertyRequestPage />,
      },
      {
        path: "/property/:propertyId/work-space/request/create",
        element: <RequestWorkCreate />,
      },
      {
        path: "/property/:propertyId/work-space/request/:id",
        element: <RequestWorkCreate />,
      },
      {
        path: "/property/:propertyId/activities",
        element: <PropertyActivityPage />,
      },
      {
        path: "/property/:propertyId/activities/create",
        element: <PropertyActivityCreate />,
      },
      {
        path: "/property/:propertyId/activities/:id",
        element: <PropertyActivityCreate />,
      },
      {
        path: "/property/:propertyId/payment",
        element: <PropertyPaymentPage />,
      },
      {
        path: "/property/:propertyId/payment/create",
        element: <ProPertyPaymentCreate />,
      },
      {
        path: "/property/:propertyId/payment/:id",
        element: <ProPertyPaymentCreate />,
      },
      {
        path: "/property/:propertyId/work-manage",
        children: [
          {
            path: "/property/:propertyId/work-manage/routine",
            element: <PropertyWorkRoutinePage />,
          },
          {
            path: "/property/:propertyId/work-manage/routine/create",
            element: <PropertyWorkRoutineCreate />,
          },
          {
            path: "/property/:propertyId/work-manage/routine/:id",
            element: <PropertyWorkRoutineCreate />,
          },
          {
            path: "/property/:propertyId/work-manage/unroutine",
            element: <UnRoutinePropertyPage />,
          },
          {
            path: "/property/:propertyId/work-manage/unroutine/create",
            element: <UnRoutinePropertyCreate />,
          },
          {
            path: "/property/:propertyId/work-manage/unroutine/:id",
            element: <UnRoutinePropertyCreate />,
          },
          {
            path: "/property/:propertyId/work-manage/calendar",
            element: <PropertyWorkCalendar />,
          },
          {
            path: "/property/:propertyId/work-manage/setting",
            element: <PropertyWorkSettingCategoryPage />,
          },
        ],
      },
      {
        path: "/property/:propertyId/survey",
        element: <PropertySurveyPage />,
      },
      {
        path: "/property/:propertyId/survey/create",
        element: <PropertySurveyCreatePage />,
      },
      {
        path: "/property/:propertyId/survey/:id",
        element: <PropertySurveyCreatePage />,
      },
    ],
  },
  {
    path: "/",
    element: <AppLayout type="main" />,
    children: [
      { path: "/", element: <OverviewPage /> },
      {
        path: "/property",
        element: <PropertyPage />,
      },
      {
        path: "/property/create",
        element: <DetailPropertyPage />,
      },
      {
        path: "/news-and-announcement",
        element: <NewsAndAnnouncementPage />,
      },
      {
        path: "/news-and-announcement/create",
        element: <NewsAndAnnouncementCreatePage />,
      },
      {
        path: "/news-and-announcement/:id",
        element: <NewsAndAnnouncementCreatePage />,
      },
      {
        path: "/activities",
        element: <ActivitiesPage />,
      },
      {
        path: "/activities/create",
        element: <ActivitiesCreate />,
      },
      {
        path: "/activities/:id",
        element: <ActivitiesCreate />,
      },
      {
        path: "/survey",
        element: <SurveyPage />,
      },
      {
        path: "/survey/create",
        element: <SurveyCreatePage />,
      },
      {
        path: "/survey/:id",
        element: <SurveyCreatePage />,
      },
      {
        path: "/user-management",
        element: <UserManagementPage />,
      },
      {
        path: "/user-management/create",
        element: <UserManagementCreatePage />,
      },
      {
        path: "/user-management/:id",
        element: <UserManagementCreatePage />,
      },
      {
        path: "/phone-book",
        children: [
          {
            path: "/phone-book/list",
            element: <PhoneBookPage />,
          },
          {
            path: "/phone-book/category-setting",
            element: <PhoneCategoryPage />,
          },
        ],
      },
      {
        path: "/gallery",
        element: <GalleryPage />,
      },
      {
        path: "/setting",
        // element: <SettingPage />,
        children: [
          { path: "/setting/role", element: <SettingRolePage /> },
          { path: "/setting/menu-display", element: <MenuDisplayPage /> },
          {
            path: "/setting/pdpa",
            element: <SettingPDPAPage />,
          },
          {
            path: "/setting/terms-and-condition",
            element: <SettingTermsAndConditionPage />,
          },
          {
            path: "/setting/privacy",
            element: <SettingPrivacyPage />,
          },
        ],
      },
      {
        path: "/residents",
        element: <ResidentsPage />,
      },
      {
        path: "/residents/create",
        element: <CentralResidentsCreate />,
      },
      {
        path: "/residents/:id",
        element: <CentralResidentsCreate />,
      },
      {
        path: "/sell-and-rent",
        element: <SellAndRentPage />,
      },
      {
        path: "/sell-and-rent/create",
        element: <SellAndRentCreatePage />,
      },
      {
        path: "/sell-and-rent/:id",
        element: <SellAndRentCreatePage />,
      },
      {
        path: "/sell-and-rent/terms",
        element: <TermsSellAndRentCreate />,
      },
      {
        path: "/work-manage",
        children: [
          {
            path: "/work-manage/unroutine",
            element: <UnRoutinePage />,
          },
          {
            path: "/work-manage/unroutine/create",
            element: <UnRoutineCreate />,
          },
          {
            path: "/work-manage/unroutine/:id",
            element: <UnRoutineCreate />,
          },
          {
            path: "/work-manage/work-calendar",
            element: <WorkCalendarPage />,
          },
          {
            path: "/work-manage/work-setting",
            element: <WorkSettingCategory />,
          },
        ],
      },
      {
        path: "/complaint",
        children: [
          {
            path: "/complaint/list",
            element: <Suggestion />,
          },
          {
            path: "/complaint/list/create",
            element: <SiggestionCreate />,
          },
          {
            path: "/complaint/list/:id",
            element: <SiggestionCreate />,
          },
          {
            path: "/complaint/setting",
            element: <ComplaintSettingPage />,
          },
        ],
      },
      {
        path: "/work-space",
        children: [
          {
            path: "/work-space/work-space-list",
            element: <WorkSpacePage />,
          },
          {
            path: "/work-space/work-space-list/create",
            element: <WorkSpaceAssetCreate />,
          },
          {
            path: "/work-space/work-space-list/:id",
            element: <WorkSpaceAssetCreate />,
          },
          {
            path: "/work-space/work-space-list/amount/:id",
            element: <WorkSpaceNumberEditDetails />,
          },
          {
            path: "/work-space/work-space-list/superfluous/create",
            element: <WorkSpaceSuperfluousCreate />,
          },
          {
            path: "/work-space/work-space-list/superfluous/:id",
            element: <WorkSpaceSuperfluousCreate />,
          },
          {
            path: "/work-space/request",
            element: <RequestPage />,
          },
          {
            path: "/work-space/request/create",
            element: <RequestCreate />,
          },
          {
            path: "/work-space/request/:id",
            element: <RequestCreate />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
];
