import React from "react";

export const SearchIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <clipPath id="clip-search">
        <path d="M0 0h50v50H0z" />
      </clipPath>
    </defs>
    <g data-name="search" clipPath="url(#clip-search)">
      <rect
        data-name="Rectangle 27183"
        width={50}
        height={50}
        rx={4}
        fill="#e6e7ea"
      />
      <g fill="#0a1430" data-name="vuesax/bold/filter-search">
        <path
          data-name="Vector"
          d="m35.309 32.518-1.058-1.057a5.308 5.308 0 1 0-1.507 1.507l1.057 1.057a1.086 1.086 0 0 0 1.53 0 1.087 1.087 0 0 0-.022-1.507Z"
        />
        <path
          data-name="Vector"
          d="M34.022 16.522v2.5a4.292 4.292 0 0 1-1.125 2.621l-.2.18a.6.6 0 0 1-.6.112 7.416 7.416 0 0 0-.675-.191 6.47 6.47 0 0 0-1.564-.18 7.033 7.033 0 0 0-7.031 7.031 6.927 6.927 0 0 0 1.012 3.622 6.74 6.74 0 0 0 2.2 2.261.472.472 0 0 1 .135.731 3.358 3.358 0 0 1-.236.18l-1.564 1.022a2.283 2.283 0 0 1-3.476-1.935v-6.018a4.387 4.387 0 0 0-.9-2.385l-4.264-4.545a3.815 3.815 0 0 1-1.012-2.272v-2.621a2.294 2.294 0 0 1 2.239-2.385h14.826a2.266 2.266 0 0 1 2.235 2.272Z"
        />
      </g>
    </g>
  </svg>
);

export const MessageIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="message-remove"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g fill="#939498">
        <path
          data-name="Vector"
          d="M8.966 16.686a4.437 4.437 0 0 0-3.341-1.5 4.5 4.5 0 0 0-4.5 4.5 4.411 4.411 0 0 0 .652 2.317 4.144 4.144 0 0 0 .855 1.024 4.411 4.411 0 0 0 2.993 1.159 4.466 4.466 0 0 0 3.847-2.183 4.411 4.411 0 0 0 .653-2.317 4.482 4.482 0 0 0-1.159-3Zm-1.541 4.777a.842.842 0 0 1-1.192 0l-.6-.6-.616.623a.842.842 0 0 1-1.192 0 .849.849 0 0 1 0-1.192l.619-.619-.6-.6a.843.843 0 0 1 1.196-1.189l.6.6.562-.562a.843.843 0 0 1 1.189 1.188l-.566.562.6.6a.849.849 0 0 1 0 1.189Z"
        />
        <path
          data-name="Vector"
          d="M19.405 2.734H8.718a5.048 5.048 0 0 0-5.343 5.343v5.017a.623.623 0 0 0 .787.574 6.269 6.269 0 0 1 1.462-.169 6.327 6.327 0 0 1 6.165 5.771.6.6 0 0 0 .586.564h.619l4.758 3.172a1.069 1.069 0 0 0 1.654-.9v-2.272a5.39 5.39 0 0 0 3.87-1.462 5.4 5.4 0 0 0 1.474-3.881V8.077a5.048 5.048 0 0 0-5.345-5.343Zm-1.6 9.427h-7.489a.804.804 0 0 1 0-1.609h7.492a.804.804 0 0 1 0 1.609Z"
        />
      </g>
    </svg>
  );
};

export const SmsIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="sms-star"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g fill="#939498">
        <path
          data-name="Vector"
          d="m21.913 3.172.315.641a1.407 1.407 0 0 0 .9.675l.427.067c1.282.214 1.586 1.159.664 2.092l-.394.394a1.424 1.424 0 0 0-.326 1.147l.056.236c.349 1.552-.472 2.149-1.822 1.339l-.292-.169a1.383 1.383 0 0 0-1.26 0l-.292.169c-1.362.822-2.183.215-1.821-1.338l.056-.236a1.424 1.424 0 0 0-.328-1.147l-.394-.4c-.922-.934-.619-1.879.664-2.092l.427-.067a1.4 1.4 0 0 0 .9-.675l.316-.647c.607-1.215 1.597-1.215 2.204.011Z"
        />
        <path
          data-name="Vector"
          d="M24.265 11.35a2.961 2.961 0 0 1-3.161 0 .632.632 0 0 0-.585 0 3.821 3.821 0 0 1-1.687.461 2.509 2.509 0 0 1-1.474-.461 2.879 2.879 0 0 1-.99-3 .643.643 0 0 0-.146-.529 3.335 3.335 0 0 1-.9-3.015c.112-.374-.124-.869-.507-.869h-6.94C4.5 3.937 2.25 5.624 2.25 9.562v7.875c0 3.937 2.25 5.625 5.625 5.625h11.249c3.375 0 5.625-1.687 5.625-5.625v-5.884c.001-.216-.304-.327-.484-.203Zm-8.133 2.25a4.343 4.343 0 0 1-5.265 0L7.35 10.788a.865.865 0 0 1-.135-1.192.841.841 0 0 1 1.181-.135l3.517 2.812a2.684 2.684 0 0 0 3.161 0 .849.849 0 0 1 1.057 1.327Z"
        />
      </g>
    </svg>
  );
};

export const ArchiveIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="archive"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <path
        d="M18.921 2.25H8.077a4.352 4.352 0 0 0-4.342 4.342v15.85c0 2.025 1.451 2.88 3.229 1.9l5.49-3.049a2.383 2.383 0 0 1 2.1 0l5.49 3.049c1.777.99 3.229.135 3.229-1.9V6.592a4.369 4.369 0 0 0-4.352-4.342Zm-2.036 8.718a9.987 9.987 0 0 1-3.386.6 9.987 9.987 0 0 1-3.386-.6.843.843 0 0 1-.506-1.08.86.86 0 0 1 1.091-.506 8.294 8.294 0 0 0 5.613 0 .843.843 0 0 1 .574 1.586Z"
        fill="#939498"
      />
    </svg>
  );
};

export const FileIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="archive"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <path
        data-name="Vector"
        d="M18.213 2.25H8.786c-4.095 0-6.536 2.441-6.536 6.536v9.416c0 4.105 2.441 6.548 6.536 6.548h9.416c4.095 0 6.536-2.441 6.536-6.536V8.786c.012-4.095-2.431-6.536-6.525-6.536Zm.2 13.893-2.5 2.5a3.087 3.087 0 1 1-4.363-4.368l1.586-1.586a.843.843 0 0 1 1.192 1.192l-1.578 1.587a1.388 1.388 0 0 0-.416.99 1.369 1.369 0 0 0 .416.99 1.405 1.405 0 0 0 1.991 0l2.5-2.5a3.66 3.66 0 1 0-5.182-5.172l-2.722 2.722a2.988 2.988 0 0 0 0 4.252.843.843 0 1 1-1.192 1.192 4.557 4.557 0 0 1-1.395-3.307 4.67 4.67 0 0 1 1.372-3.33l2.722-2.722a5.35 5.35 0 0 1 7.571 7.559Z"
        fill="#2061e7"
      />
    </svg>
  );
};

export const ImageIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="archive"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g fill="#2061e7">
        <path d="m2.906 21.384-.026.023a6.96 6.96 0 0 1-.574-2.25 6.909 6.909 0 0 0 .6 2.227Z" />
        <path
          data-name="Vector"
          d="M12.802 8.999a2.677 2.677 0 1 1-2.678-2.677 2.677 2.677 0 0 1 2.678 2.677Z"
        />
        <path
          data-name="Vector"
          d="M18.213 2.25H8.786c-4.095 0-6.536 2.441-6.536 6.536v9.427a7.563 7.563 0 0 0 .63 3.195c.97 2.137 3.037 3.342 5.906 3.342h9.427c4.095 0 6.536-2.441 6.536-6.536V8.786c.001-4.095-2.442-6.536-6.536-6.536Zm4.7 11.812a2.508 2.508 0 0 0-3.172 0l-4.68 4.016a2.508 2.508 0 0 1-3.172 0l-.382-.315a2.5 2.5 0 0 0-2.97-.157l-4.206 2.823a6 6 0 0 1-.394-2.216V8.786c0-3.172 1.676-4.848 4.848-4.848h9.427c3.172 0 4.848 1.676 4.848 4.848v5.4Z"
        />
      </g>
    </svg>
  );
};

export const SendIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="archive"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <path
        d="M18.156 3.33 7.998 6.705c-6.828 2.284-6.828 6.007 0 8.279l3.015 1 1 3.015c2.272 6.828 6.007 6.828 8.279 0l3.385-10.146c1.51-4.555-.965-7.042-5.521-5.523Zm.36 6.052-4.275 4.3a.842.842 0 0 1-1.192 0 .849.849 0 0 1 0-1.192l4.275-4.3a.844.844 0 0 1 1.192 1.194Z"
        fill="#fff"
        data-name="vuesax/bold/send-2"
      />
    </svg>
  );
};

export const SearchIconOutlined = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="search-normal"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g fill="#2061e7" data-name="vuesax/outline/search-normal">
        <path d="M12.936 24.467a11.53 11.53 0 1 1 11.53-11.53 11.546 11.546 0 0 1-11.53 11.53Zm0-21.373a9.843 9.843 0 1 0 9.843 9.843 9.854 9.854 0 0 0-9.843-9.844Z" />
        <path
          d="M24.748 25.591a.834.834 0 0 1-.6-.247l-2.246-2.25a.843.843 0 0 1 1.192-1.192l2.25 2.25a.849.849 0 0 1 0 1.192.834.834 0 0 1-.596.247Z"
          data-name="Vector"
        />
      </g>
    </svg>
  );
};

export const LinkIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="link"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g data-name="vuesax/bold/link-square">
        <path
          fill="#2061e7"
          d="M18.213 2.25H8.786c-4.095 0-6.536 2.441-6.536 6.536v9.416c0 4.105 2.441 6.548 6.536 6.548h9.416c4.095 0 6.536-2.441 6.536-6.536V8.786c.012-4.095-2.431-6.536-6.525-6.536ZM9.202 18.865h-.079a4.549 4.549 0 0 1-2.88-1.415 5.021 5.021 0 0 1 0-6.851L8.707 8.01a4.544 4.544 0 0 1 6.637 0 5.021 5.021 0 0 1 0 6.851l-1.226 1.289a.844.844 0 1 1-1.226-1.159l1.226-1.294a3.306 3.306 0 0 0 0-4.522 2.947 2.947 0 0 0-4.2 0l-2.46 2.592a3.306 3.306 0 0 0 0 4.522 2.885 2.885 0 0 0 1.822.9.845.845 0 0 1 .754.922.832.832 0 0 1-.832.754Zm11.548-2.452L18.291 19a4.544 4.544 0 0 1-6.637 0 5.021 5.021 0 0 1-.004-6.85l1.23-1.294a.844.844 0 1 1 1.226 1.159l-1.226 1.293a3.305 3.305 0 0 0 0 4.522 2.937 2.937 0 0 0 4.2 0l2.464-2.587a3.306 3.306 0 0 0 0-4.522 2.885 2.885 0 0 0-1.822-.9.845.845 0 0 1-.754-.922.831.831 0 0 1 .922-.754 4.605 4.605 0 0 1 2.88 1.417 5.032 5.032 0 0 1-.02 6.851Z"
          data-name="Vector"
        />
      </g>
    </svg>
  );
};

export const DocumentIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="document"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}
    >
      <g data-name="vuesax/bold/document-text">
        <path
          fill="#2061e7"
          d="M18 2.25H9c-3.937 0-5.625 2.25-5.625 5.625v11.25c0 3.376 1.688 5.625 5.625 5.625h9c3.938 0 5.625-2.25 5.625-5.625V7.875C23.626 4.5 21.938 2.25 18 2.25ZM9 13.782h4.5a.844.844 0 1 1 0 1.688H9a.844.844 0 1 1 0-1.688Zm9 6.188H9a.844.844 0 1 1 0-1.688h9a.844.844 0 0 1 0 1.688Zm2.813-9.563h-2.25a3.092 3.092 0 0 1-3.094-3.094v-2.25a.844.844 0 0 1 1.688 0v2.25a1.407 1.407 0 0 0 1.406 1.406h2.25a.844.844 0 1 1 0 1.688Z"
          data-name="Vector"
        />
      </g>
    </svg>
  );
};

export const BellIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31.951"
      height="35.418"
      viewBox="0 0 31.951 35.418"
      {...props}
    >
      <g fill="#fff">
        <path
          d="M4.425 26.558h23.1a4.426 4.426 0 0 0 4.162-5.933L27.478 8.991A12.28 12.28 0 0 0 15.648 0 12.277 12.277 0 0 0 3.664 9.614L.196 20.827a4.426 4.426 0 0 0 4.229 5.731Z"
          data-name="Path 70961"
        />
        <path
          d="M8.898 29.508a7.377 7.377 0 0 0 14.459 0Z"
          data-name="Path 70962"
        />
      </g>
    </svg>
  );
};

export const PhoneIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="52"
      height="43"
      viewBox="0 0 52 43"
      {...props}
    >
      <path
        fill="#fff"
        d="m21.771 28.422-1.064.01s-2.53.023-2.47-7.122c.06-7.144 2.591-7.166 2.591-7.166l.67-.008c1.652-.014 3.067-1.317 3.33-3.067l.535-3.574c.325-2.166-1.224-4.1-3.27-4.08l-3.668.031c-1.013.011-2.011.348-2.706 1.15-1.777 2.055-4.992 7.101-5.075 16.783-.086 10.267 4.33 15.248 6.398 17.013.655.56 1.484.775 2.33.766l3.319-.03c2.242-.018 3.867-2.347 3.236-4.637l-.981-3.563c-.416-1.501-1.703-2.519-3.175-2.506Z"
      />
      <ellipse cx={31.843} cy={8.889} fill="#fff" rx={2.344} ry={2.424} />
      <ellipse cx={31.843} cy={20.203} fill="#fff" rx={2.344} ry={2.424} />
      <ellipse cx={31.843} cy={31.517} fill="#fff" rx={2.344} ry={2.424} />
      <path
        stroke="#fff"
        d="M37.312 9.197h14.062M37.312 20.511h14.062M37.312 31.825h14.062"
      />
    </svg>
  );
};
