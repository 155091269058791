import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectComplaintStatus: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      valueOption={{
        values: [
          { value: "ACKNOWLEDGE", label: t("acknowledge") },
          { value: "PENDING", label: t("pending") },
          { value: "SUCCESS", label: t("complete") },
          { value: "CANCEL", label: t("cancel") },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
