import React, {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import io from "socket.io-client";
import {
  INotiContext,
  INotiState,
  TNotiAction,
  dataMenuPage,
} from "./interface";
import { NOTIFICATION_SOCKET_URL } from "config/axios";

const AppNotiContext = createContext<INotiContext>({
  sidebarNoti: dataMenuPage,
  tableNoti: dataMenuPage,
});

const AppNotification: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<INotiState, TNotiAction>>(
    notiReducer,
    {
      tableNoti: dataMenuPage,
      sidebarNoti: dataMenuPage,
      headerNoti: dataMenuPage,
    }
  );

  useEffect(() => {
    const socket = io(NOTIFICATION_SOCKET_URL.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on("sendMessage", setFromSocketNoti);

    return () => {
      socket.disconnect();
    };
  }, []);

  const setFromSocketNoti = (value: any) => {
    console.log("setFromSocketNoti", value);
    const { id, type } = value;

    if (type) {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: [type],
      });
    }
  };

  return (
    <AppNotiContext.Provider value={{ ...state, dispatchNoti: dispatch }}>
      {children}
    </AppNotiContext.Provider>
  );
};

export const useNotification = () => useContext(AppNotiContext);
export default AppNotification;

const notiReducer = (state: INotiState, payload: TNotiAction): INotiState => {
  const { action, type, id, key } = payload;

  for (const keyItem of key) {
    const { tableNoti, sidebarNoti } = state;
    if (action === "clear") {
      switch (type) {
        case "all":
          state = {
            ...state,
            tableNoti: { ...tableNoti, [keyItem]: [] },
            sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
          };
          break;
        case "sidebar":
          state = {
            ...state,
            sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
          };
          break;
        case "table":
          state = {
            ...state,
            tableNoti: { ...tableNoti, [keyItem]: [] },
          };
          break;
      }
      continue;
    }
    if (id) {
      switch (type) {
        case "all":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id].flat(),
            },
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id].flat(),
            },
          };
          break;
        case "sidebar":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id].flat(),
            },
          };
          break;
        case "table":
          state = {
            ...state,
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id].flat(),
            },
          };
          break;
      }
    }
  }

  return state;
};
