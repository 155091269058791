import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosCentral } from "config/axios";
import { IResponse, IResponseActive } from "config/axios/interface";
import { IUser, IUserParams } from "./interface";

export const useGetAdministrators = (
  params?: IUserParams
): UseQueryResult<IResponseActive<IUser[]>, Error> => {
  return useQuery(
    ["administrator", "all", params],
    async () => {
      const res = await axiosCentral.get("/administrator", { params: params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useScrollGetAdministrators = (
  params?: IUserParams
): UseInfiniteQueryResult<IResponse<IUser[]>, Error> => {
  return useInfiniteQuery(
    ["administrator", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/administrator", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useScrollGetAdministratorsPermission = (
  params?: IUserParams
): UseInfiniteQueryResult<IResponse<IUser[]>, Error> => {
  return useInfiniteQuery(
    ["administrator", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/administrator/without-permission", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetAdministrator = (
  id?: number | string
): UseQueryResult<IUser> => {
  return useQuery(
    ["administrator", "id", id],
    async () => {
      if (!id) return;
      const res = await axiosCentral.get(`/administrator/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!id }
  );
};

export const usePostAdministrator = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axiosCentral.post("/administrator", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchAdministrator = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const { profilePicture, checkProfile, ...rest } = data;
    if (!checkProfile) {
      const resP = await axiosCentral.patch(
        `/administrator/${id}/change-profile`,
        {
          profilePicture,
        }
      );

      if (resP.status !== 200) {
        throwResponse(resP);
      }
    }

    const res = await axiosCentral.patch(`/administrator/${id}`, { ...rest });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const usePatchChangeProfile = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosCentral.patch(
      `/administrator/${id}/change-profile`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteAdministrator = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/administrator/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteAdministrators = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data: any) => {
    const res = await axiosCentral.delete(`/administrator/bulk`, { data });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelAdministrators = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosCentral.post("/export-excel/administrator", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useTemplateAdministrators = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (params: any) => {
    const res = await axiosCentral.post(
      "/export-excel/template-administrator",
      params,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
