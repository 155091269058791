import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectRegisterStatus: React.FC<CSelectProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const data = [
    { label: "pending-approval", value: "PENDING_APPROVE" },
    { label: "approved-by-juristic", value: "APPROVE_BY_JURISTIC" },
    { label: "not-approved-by-juristic", value: "DISAPPROVE_BY_JURISTIC" },
    { label: "approved-by-referral-code", value: "APPROVE_BY_REFERENCE" },
  ];
  return (
    <CSelect
      valueOption={{
        values: data,
        labelKey: "label",
        valueKey: "value",
        translationI18n: true,
      }}
      placeholder={t("registration-status")}
      {...props}
    />
  );
};
