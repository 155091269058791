import { SwapRightOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CInputProps } from "./c-input";
import { CInputNumber } from "./input-number";
import { useWatch } from "antd/es/form/Form";

export const CInputRangeSelling: React.FC<CInputProps> = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row
        gutter={8}
        className="rounded-app"
        style={{ border: "1px solid #D2D3D5", height: "45px" }}
      >
        <Col span={11}>
          <Form.Item name="costSellStart">
            <CInputNumber
              placeholder={t("start-price")}
              min={0}
              bordered={false}
              style={{ height: "43px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Col>
        <Col span={2} className="pt-3">
          <SwapRightOutlined style={{ color: "#D2D3D5", fontSize: "16px" }} />
        </Col>
        <Col span={11}>
          <Form.Item name="costSellEnd" >
            <CInputNumber
              placeholder={t("end-price")}
              min={0}
              bordered={false}
              style={{ height: "43px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};
