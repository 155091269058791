import React from "react";
import { Wrapper } from "..";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import WorkCard from "./content/work-card";
import RemainingWorkloadCard from "./content/remaining-workload-card";
import WorkloadCard from "./content/workload-card";

const WorkPlan = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div>{t("work-manage", { ns: "menu" })}</div>
      <Row gutter={[16, 16]}>
        <WorkCard />
        <Col span={24}>
          <WorkloadCard />
        </Col>
        <Col span={24}>
          <RemainingWorkloadCard />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default WorkPlan;
