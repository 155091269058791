import { Form } from "antd";
import { HeaderOverview, IRenderPieChart, RenderPieChart } from "components";
import { WrapperBG } from "pages/overview";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import { useGetPropertyDashboardFacilityUnit } from "service/dashboard/property/facility";
import { LoadingDashboard, NoDataDashboard, ErrorDashboard } from "../..";

const COLORS = ["#276347", "#E5E5E5", "#F8931D", "#535A6E", "#8EC641"];

const FacilityTop = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const facilityTopDate = Form.useWatch("facilityTopDate");
  const date = findDate(facilityTopDate);

  const { data, isFetching, isError, error } =
    useGetPropertyDashboardFacilityUnit({
      ...date,
    });

  const dataPie: IRenderPieChart = {
    data: data || [],
    dataKey: "count",
    dataName: "unitNumber",
    color: COLORS,
  };

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("unit-top-5-facilities")}
          name="facilityTopDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <RenderPieChart {...dataPie} />
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default FacilityTop;
