import {
  CloseOutlined,
  DeleteFilled,
  EyeFilled,
  FileAddFilled,
  FilePdfFilled,
} from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { fireNotification } from "components/popup/notification";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "service/media-object/interface";
import styled from "styled-components";
import { fileToDataUrl } from "tools/file";
import tw from "twin.macro";
import { AcceptFileTypes, CImage } from "./c-image";
import { CModal } from "components/display/c-modal";
import { MAX_FILE_SIZE_BYTES } from "./c-image/profile/file-picker";
import { useAppActionRole } from "providers/action-role";

export interface CUploadListProps {
  accepts?: AcceptFileTypes[];
  onChange?: (v: (File | IMediaObject | { media: IMediaObject })[]) => void;
  value?: (File | IMediaObject | { media: IMediaObject })[];
  id?: string;
  disabled?: boolean;
  className?: string;
  maximumFileSize?: number;
  required?: boolean;
}

interface UploadBoxProps {
  type: "file" | "empty";
  file?: File | IMediaObject | { media: IMediaObject };
  accepts?: AcceptFileTypes[];
  onAdd: (v: File[] | IMediaObject[] | { media: IMediaObject }[]) => void;
  onRemove: (id?: number) => void;
  keyItem?: number;
  disabled?: boolean;
}

const CUploadListWrapper = styled.div`
  ${tw`p-5 rounded-app`};
  min-height: 1px;
`;

const UploadBoxWrapper = styled.div<{ $hover?: boolean; $empty?: boolean }>`
  ${tw`bg-white rounded-app`};
  width: 100px;
  height: 100px;
  cursor: ${({ $empty }) => ($empty ? "pointer" : "")};
  border: 1px dashed #d2d3d5;

  &:hover {
    #delete-mask {
      ${({ $hover }) => ($hover ? tw`visible opacity-100` : "")}
    }
    .ant-typography {
      color: white !important;
    }
  }
`;

const DeleteMaskWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  transition: visibility 0s, opacity 0.1s linear;
  background: rgba(0, 0, 0, 0.8);
  ${tw`invisible opacity-0 rounded-app`};
`;

export const CUploadList: FC<CUploadListProps> = ({
  accepts = [".jpeg", ".jpg", ".png"],
  id,
  onChange,
  value,
  disabled = false,
  maximumFileSize = 2,
  className,
  required = false,
}) => {
  const { t } = useTranslation();
  const { actionMenu } = useAppActionRole();

  const disable = !actionMenu?.action || disabled;

  const onRemove = (uuid?: number) => {
    const next = value?.filter((_, index) => {
      return index !== uuid;
    });
    onChange?.(next || []);
  };

  const onAdd = (
    files: File[] | IMediaObject[] | { media: IMediaObject }[]
  ) => {
    if (disable) return;
    const next = [...(value || []), ...files];
    onChange?.(next);
  };

  return (
    <CUploadListWrapper id={id} className={className ? className : undefined}>
      <Row className="mb-3">
        {required ? <span className="text-[#80bc28] pr-1"> * </span> : <></>}
        <Typography.Text>{t("select-img")}</Typography.Text>
        <Typography.Text className="px-2 text-[#1E70E9]">
          {t("maximum-file-size-10-MB", {
            ns: "message",
            size: maximumFileSize,
          })}
        </Typography.Text>
      </Row>
      <Row gutter={[12, 12]}>
        {value?.map((item, index) => {
          return (
            <Col key={index}>
              <UploadBox
                onRemove={onRemove}
                onAdd={onAdd}
                file={item}
                type="file"
                keyItem={index}
                disabled={disable}
              />
            </Col>
          );
        })}
        <Col>
          <UploadBox
            onRemove={onRemove}
            onAdd={onAdd}
            accepts={accepts}
            type="empty"
            disabled={disable}
          />
        </Col>
      </Row>
    </CUploadListWrapper>
  );
};

const UploadBox: FC<UploadBoxProps> = ({
  type,
  accepts,
  file,
  onRemove,
  onAdd,
  keyItem,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { actionMenu } = useAppActionRole();
  const ref = useRef<HTMLInputElement>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<IMediaObject>();

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    const fileArr = Object.values(files).map((e) => e);
    const fileArrCheckSize = fileArr.filter((e) => {
      if (e.size > MAX_FILE_SIZE_BYTES) {
        return fireNotification({
          type: "error",
          description: t("images-cannot-larger-10mb", { ns: "message" }),
        });
      }
      return e;
    });
    onAdd(fileArrCheckSize);
  };

  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openFilePickerDialog = () => {
    if (disabled) return;
    ref.current?.click();
  };

  const handlePreview = async (file: IMediaObject) => {
    setPreviewImage(file);
    return setPreviewOpen(!previewOpen);
  };

  const handleCancel = () => setPreviewOpen(!previewOpen);

  if (type === "empty" && accepts) {
    return (
      <UploadBoxWrapper
        className="center"
        onClick={openFilePickerDialog}
        $empty={type === "empty"}
      >
        <FileAddFilled style={{ fontSize: 20, color: "#A8A8A8" }} />
        <input
          accept={accepts.join(",")}
          multiple={true}
          type="file"
          ref={ref}
          style={{ display: "none" }}
          onChange={onFileSelect}
          onClick={onClick}
        />
      </UploadBoxWrapper>
    );
  }

  if (file) {
    const getFileDescription = () => {
      if ("url" in file) {
        return { key: keyItem, fileName: file.originalFilename };
      }
      if ("media" in file) {
        return { key: keyItem, fileName: file.media.originalFilename };
      }
      return { key: keyItem, fileName: file.name };
    };

    const { key, fileName } = getFileDescription();

    return (
      <UploadBoxWrapper $hover className="relative">
        <FilePreview file={file} />
        <DeleteMaskWrapper className="center" id="delete-mask">
          <Row justify="center" className="w-full">
            {"url" in file && (
              <>
                <div
                  className="p-2 mr-2 rounded-app center"
                  style={{ backgroundColor: "#80BC28" }}
                >
                  <EyeFilled
                    style={{ color: "#FFFFFF", fontSize: 16 }}
                    onClick={() => {
                      handlePreview(file);
                    }}
                  />
                </div>
              </>
            )}
            <div
              className="p-2 rounded-app center"
              style={{ backgroundColor: "#80BC28" }}
              hidden={!actionMenu?.action}
            >
              <DeleteFilled
                style={{ color: "#FFFFFF", fontSize: 16 }}
                onClick={() => {
                  onRemove(key);
                }}
              />
            </div>
          </Row>
        </DeleteMaskWrapper>
        <Typography.Text
          style={{
            fontSize: 12,
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
          className="px-1"
          ellipsis={{ tooltip: fileName }}
          type="secondary"
        >
          {fileName}
        </Typography.Text>
        <ModalPreviewImg
          visible={previewOpen}
          toggle={handleCancel}
          previewImage={previewImage}
        />
      </UploadBoxWrapper>
    );
  }

  return null;
};

const FilePreview: FC<{
  file: File | IMediaObject | { media: IMediaObject };
}> = ({ file }) => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if ("url" in file) {
      return setSrc(file.url);
    }
    if ("media" in file) {
      return setSrc(file.media?.url);
    }

    const convert = async () => {
      if (!(file instanceof File)) return;
      const base = await fileToDataUrl(file);
      if (typeof base !== "string") return;
      setSrc(base);
    };
    if ("type" in file && file?.type?.includes("image")) {
      convert();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  if ("url" in file) {
    if (file.filename.split(".").pop()?.toLocaleLowerCase() === "pdf") {
      return (
        <div className="h-full center">
          <FilePdfFilled style={{ fontSize: 20, color: "red" }} />
        </div>
      );
    }
    return <CImage src={src} placeholder={null} className="rounded-app" />;
  }

  if ("media" in file) {
    if (file.media?.filename.split(".").pop()?.toLocaleLowerCase() === "pdf") {
      return (
        <div className="h-full center">
          <FilePdfFilled style={{ fontSize: 20, color: "red" }} />
        </div>
      );
    }
    return <CImage src={src} placeholder={null} className="rounded-app" />;
  }

  if ("type" in file && file?.type?.includes("image")) {
    return <CImage src={src} placeholder={null} className="rounded-app" />;
  }

  if ("type" in file && file?.type?.includes("pdf")) {
    return (
      <div className="h-full center">
        <FilePdfFilled style={{ fontSize: 20, color: "red" }} />
      </div>
    );
  }

  return null;
};

const ModalPreviewImg = ({
  visible,
  toggle,
  previewImage,
}: {
  visible: boolean;
  toggle: () => void;
  previewImage?: IMediaObject;
}) => {
  const { t } = useTranslation();
  return (
    <CModal open={visible} onCancel={toggle} className="!p-0">
      <Row gutter={[16, 16]}>
        <Col span={20}>
          <Typography.Title level={5}>{t("image")}</Typography.Title>
        </Col>
        <Col span={4} className="!grid !justify-items-end">
          <CloseOutlined onClick={toggle} />
        </Col>
        <Col span={24}>
          <CImage src={previewImage?.url} />
        </Col>
      </Row>
    </CModal>
  );
};
