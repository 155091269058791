import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";

export const CSelectTypeNews: FC<CSelectProps> = ({ placeholder, ...props }) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("display-type")}
      valueOption={{
        values: [
          { value: "BANNER", title: t("banner") },
          { value: "MAIN", title: t("main-news") },
          { value: "SECONDARY", title: t("secondary-news") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
