import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import vehicle from "../../data/vehicle-brand.json";
import motorcycle from "../../data/motorcycle-brand.json";

export interface ISelectVehicleTypeProps extends CSelectProps {
  typeVehicle?: string;
}

export const CSelectVehicleBrand: FC<ISelectVehicleTypeProps> = ({
  typeVehicle,
  ...props
}) => {
  const { t } = useTranslation();
  const dataVehicle = vehicle;
  const dataMotorcycle = motorcycle;

  return (
    <CSelect
      showSearch
      placeholder={t("vehicle-brand")}
      {...props}
      valueOption={{
        values:
          typeVehicle === "MOTORCYCLE"
            ? dataMotorcycle
            : typeVehicle === "CAR"
            ? dataVehicle
            : dataVehicle,
        labelKey: "brand",
        valueKey: "brand",
      }}
    />
  );
};
