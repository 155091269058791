import { Col, Row } from "antd";
import React from "react";
import FacilityUsed from "./content/facility-used";
import { useTranslation } from "react-i18next";
import { Wrapper } from "..";
import FacilityMonth from "./content/facility-month";
import FacilityTop from "./content/facility-top-5";
import FacilityType from "./content/facility-type";

const Facility = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.facility", { ns: "menu" })}</Col>
        <Col md={24} lg={24} xl={12}>
          <FacilityUsed />
        </Col>
        <Col md={24} lg={24} xl={12}>
          <FacilityMonth />
        </Col>
        <Col span={24}>
          <FacilityTop />
        </Col>
        <Col span={24}>
          <FacilityType />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Facility;
