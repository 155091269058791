import { Select, SelectProps } from "antd";
import { useAppLanguage } from "providers/app-language";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { CSelectGender } from "./c-select-gender";
import { CSelectPagination, CSelectPaginationProps } from "./pagination";
import CSelectProvince from "./province";
import { CSelectRole } from "./select-role";
import { CSelectPhoneCategory } from "./c-select-phone-category";
import { CSelectPropertyPhoneCategory } from "./c-select-property-phone-category";
import {
  CSelectPropertyUnit,
  ISelectPropertyUnitProps,
} from "./select-property-unit";
import {
  CSelectVehicleBrand,
  ISelectVehicleTypeProps,
} from "./c-select-vehicle-brand";
import {
  CSelectVehicleModel,
  ISelectVehicleModelProps,
} from "./c-select-vehicle-model";
import { CSelectVehicleColor } from "./c-select-vehicle-color";
import { CSelectVehicleType } from "./c-select-vehicle-type";
import { CSelectVehicleSticker } from "./c-select-vehicle-sticker";
import { CSelectVehicleStatus } from "./c-select-vehicle-status";
import { CSelectTypeNews } from "./c-select-type-news";
import { CSelectStatusNews } from "./c-select-status-news";
import {
  CSelectNewsCategory,
  CSelectNewsCategoryProps,
} from "./c-select-news-category";
import { CSelectRepeat } from "./c-select-repeat";
import { CSelectProperty, CSelectPropertyProps } from "./c-select-property";
import { CSelectUser, CSelectUserProps } from "./c-select-user";
import { CSelectPropertyUnitWithUUID } from "./c-select-property-unit-with-uuid";
import { CSelectParcelStatus } from "./c-select-parcel-status";
import {
  CSelectParcelType,
  CSelectParcelTypeProps,
} from "./c-select-parcel-types";
import {
  CSelectParcelStorageZone,
  ISelectParcelStorageZone,
} from "./c-select-parcel-storage-zone";
import {
  CSelectDeliveryCompany,
  ISelectDeliveryCompany,
} from "./c-select-delivery-company";
import {
  CSelectParcelStoragePlace,
  ISelectParcelStorage,
} from "./c-select-parcel-storage-place";
import {
  CSelectPropertyUser,
  CSelectPropertyUserProps,
} from "./c-select-user-property";
import { CSelectVehicleParkingFree } from "./c-select-vehicle-parking-free";
import { CSelectBankCode } from "./c-select-bank-code";
import { CSelectTagsSign } from "./c-select-tags";
import { CSelectTags, CSelectTagsProps } from "./c-celect-tags";
import {
  CSelectAdminProperty,
  CSelectAdminPropertyProps,
} from "./c-select-admin-property";
import { CSelectMenu, CSelectMenuProps } from "./c-select-menu";
import {
  CSelectSettingSurvey,
  CSelectSettingSurveyProps,
} from "./c-select-setting-survey";
import { CSelectSurveyStatus } from "./c-select-survey-status";
import { CSelectSurveyTaker } from "./c-select-survey-taker";
import { CSelectMenuProperty } from "./c-select-survey-property-menu";
import { CSelectPropertyId } from "./c-select-property-id";
import { CSelectApprove } from "./c-select-approve";
import { CSelectPostman, CSelectPostmanProps } from "./c-select-postman";
import { CSelectMainCategory } from "./c-select-main-category";
import { CSelectSubCategory } from "./select-sub-category";
import { CSelectRoleResident } from "./select-role-resident";
import { CSelectMainMenuProps, CSelectMainMenus } from "./c-select-main-menu";
import { CSelectRepairServiceCategory } from "./c-select-repair-service-category";
import {
  CSelectRepairServiceSubCategory,
  CSelectRepairServiceSubCategoryProps,
} from "./c-select-repair-service-sub-category";
import {
  CSelectRepairServiceReferenceNumber,
  CSelectRepairServiceReferenceNumberProps,
} from "./c-select-repair-service-reference-number";
import { CSelectRepairServiceStatus } from "./c-select-repair-service-status";
import {
  CSelectPropertyMenuProps,
  CSelectPropertyMenus,
} from "./c-select-property-menu";
import { CSelectRegisterStatus } from "./status-approved-residents";
import { CSelectPropertyCategory } from "./c-select-property-category";

export interface CSelectOption {
  valueKey: string;
  labelKey: string | "first-last-name";
  values: any[];
  translation?: boolean;
  translationI18n?: boolean;
  notValueKey?: boolean;
}
export interface CSelectProps extends SelectProps {
  valueOption?: CSelectOption;
  render?: (v: any[]) => ReactNode;
  multipleMode?: boolean;
  bgcolor?: boolean;
  onSelectCallback?: (v: any) => void;
  overview?: boolean;
}

const StyledSelect = styled(Select)<{
  bgcolor?: number;
  $multipleMode?: boolean;
  $overview?: boolean;
}>`
  height: ${({ $multipleMode }) => ($multipleMode ? "100%" : "45px")};
  width: 100%;
  .ant-select-selector {
    ${tw`!rounded-app`}
    background-color: #ffffff !important;
    height: 100% !important;
    color: ${({ $overview }) =>
      $overview ? "#0958d9" : "rgba(0, 0, 0, 0.88)"} !important;
    // border-color: ${({ bgcolor }) => (bgcolor ? "white" : "")} !important;
    padding-top: ${({ $multipleMode }) => ($multipleMode ? "4px" : "")};
    padding-right: ${({ $multipleMode }) => ($multipleMode ? "10px" : "")};
    padding-bottom: ${({ $multipleMode }) => ($multipleMode ? "5px" : "")};
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-overflow-item {
    padding-right: ${({ $multipleMode }) => ($multipleMode ? "5px" : "")};
    padding-bottom: ${({ $multipleMode }) => ($multipleMode ? "5px" : "")};
  }
`;

type NotationTypes = {
  Province: React.FC<CSelectProps>;
  Pagination: React.FC<CSelectPaginationProps>;
  Role: React.FC<CSelectPaginationProps>;
  Gender: React.FC<CSelectProps>;
  PhoneCategory: React.FC<CSelectPaginationProps>;
  PropertyPhoneCategory: React.FC<CSelectPaginationProps>;
  PropertyUnit: React.FC<ISelectPropertyUnitProps>;
  VehicleBrand: React.FC<ISelectVehicleTypeProps>;
  VehicleModel: React.FC<ISelectVehicleModelProps>;
  VehicleColor: React.FC<CSelectProps>;
  VehicleSticker: React.FC<CSelectProps>;
  VehicleType: React.FC<CSelectProps>;
  VehicleStatus: React.FC<CSelectProps>;
  NewsType: React.FC<CSelectProps>;
  StatusNews: React.FC<CSelectProps>;
  NewsCategory: React.FC<CSelectNewsCategoryProps>;
  Repeat: React.FC<CSelectProps>;
  Property: React.FC<CSelectPropertyProps>;
  ActiveStatus: React.FC<CSelectProps>;
  User: React.FC<CSelectUserProps>;
  PropertyUnitWithUUID: React.FC<CSelectProps>;
  PropertyUser: React.FC<CSelectPropertyUserProps>;
  PropertyUnits: React.FC<CSelectProps>;
  ParcelStatus: React.FC<CSelectProps>;
  ParcelType: React.FC<CSelectParcelTypeProps>;
  ParcelStorageZone: React.FC<ISelectParcelStorageZone>;
  DeliveryCompany: React.FC<ISelectDeliveryCompany>;
  ParcelStoragePlace: React.FC<ISelectParcelStorage>;
  VehicleParkingFree: React.FC<CSelectProps>;
  BankCode: React.FC<CSelectProps>;
  TagsSign: React.FC<CSelectProps>;
  SelectTags: React.FC<CSelectTagsProps>;
  AdminProperty: React.FC<CSelectAdminPropertyProps>;
  Menu: React.FC<CSelectMenuProps>;
  SettingSurvey: React.FC<CSelectSettingSurveyProps>;
  SurveyStatus: React.FC<CSelectProps>;
  SurveyTaker: React.FC<CSelectProps>;
  MenuProperty: React.FC<CSelectMenuProps>;
  PropertyId: React.FC<CSelectPropertyProps>;
  ApproveStatus: React.FC<CSelectProps>;
  Postman: React.FC<CSelectPostmanProps>;
  MainCategoryWork: React.FC<CSelectProps>;
  SubCategoryWork: React.FC<CSelectProps>;
  RoleResident: React.FC<CSelectProps>;
  MainMenus: React.FC<CSelectMainMenuProps>;
  PropertyMenus: React.FC<CSelectPropertyMenuProps>;
  RepairServiceCategory: React.FC<CSelectProps>;
  RepairServiceSubCategory: React.FC<CSelectRepairServiceSubCategoryProps>;
  RepairServiceReferenceNumber: React.FC<CSelectRepairServiceReferenceNumberProps>;
  RepairServiceStatus: React.FC<SelectProps>;
  RegisterStatus: React.FC<SelectProps>;
  PropertyCategory:React.FC<SelectProps>;
};

export const CSelect: React.FC<CSelectProps> & NotationTypes = ({
  valueOption,
  options,
  bgcolor = true,
  multipleMode,
  onSelectCallback,
  onChange,
  overview = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const getOptions = () => {
    if (options && options.length) return options;
    if (!valueOption) return [];

    const {
      valueKey,
      labelKey,
      values,
      translation,
      translationI18n,
      notValueKey,
    } = valueOption;

    return values?.map((item) => {
      if (notValueKey) {
        // select object
        let label = item[labelKey];
        if (labelKey === "first-last-name") {
          label = `${item["firstName"]} ${item["lastName"]}`;
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });
          label = value?.[labelKey];
        }

        if (translationI18n) {
          label = t(item[labelKey]);
        }

        const value = JSON.stringify(item);
        return { ...item, value: value, label: label };
      } else {
        // select by valueKey
        if (labelKey === "first-last-name") {
          return {
            ...item,
            value: item[valueKey],
            label: `${item["firstName"]} ${item["lastName"]}`,
          };
        }

        if (translation) {
          const value = translationObject({ object: item, keys: [labelKey] });

          return { ...item, value: item[valueKey], label: value?.[labelKey] };
        }

        if (translationI18n) {
          return { ...item, value: item[valueKey], label: t(item[labelKey]) };
        }

        return { ...item, value: item[valueKey], label: item[labelKey] };
      }
    });
  };

  const opt = getOptions();

  return (
    <StyledSelect
      {...(props as any)}
      onChange={(value, option) => {
        const { values, valueKey } = valueOption || {};
        if (valueKey && onSelectCallback) {
          const find = values?.find((e) => e[valueKey] === value);
          if (find) {
            onSelectCallback(find);
          }
        }

        onChange?.(value, option as any);
      }}
      options={opt}
      bgcolor={bgcolor ? 1 : 0}
      getPopupContainer={(e) => e}
      $multipleMode={multipleMode}
      mode={multipleMode ? "multiple" : undefined}
      $overview={overview}
    />
  );
};

export const CSelectStatusActive: React.FC<CSelectProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const data = [
    { label: "active", value: true },
    { label: "in-active", value: false },
  ];
  return (
    <CSelect
      valueOption={{
        values: data,
        labelKey: "label",
        valueKey: "value",
        translationI18n: true,
      }}
      placeholder={t("status")}
      {...props}
    />
  );
};

export { CSelectPagination } from "./pagination";
export { CSelectRole } from "./select-role";
export { CSelectGender } from "./c-select-gender";
export { CSelectPhoneCategory } from "./c-select-phone-category";
export { CSelectPropertyPhoneCategory } from "./c-select-property-phone-category";
export { CSelectPropertyUnit } from "./select-property-unit";

CSelect.Province = CSelectProvince;
CSelect.Pagination = CSelectPagination;
CSelect.Role = CSelectRole;
CSelect.Gender = CSelectGender;
CSelect.PhoneCategory = CSelectPhoneCategory;
CSelect.PropertyPhoneCategory = CSelectPropertyPhoneCategory;
CSelect.PropertyUnit = CSelectPropertyUnit;
CSelect.VehicleBrand = CSelectVehicleBrand;
CSelect.VehicleColor = CSelectVehicleColor;
CSelect.VehicleModel = CSelectVehicleModel;
CSelect.VehicleSticker = CSelectVehicleSticker;
CSelect.VehicleStatus = CSelectVehicleStatus;
CSelect.VehicleType = CSelectVehicleType;
CSelect.NewsType = CSelectTypeNews;
CSelect.StatusNews = CSelectStatusNews;
CSelect.NewsCategory = CSelectNewsCategory;
CSelect.Repeat = CSelectRepeat;
CSelect.Property = CSelectProperty;
CSelect.ActiveStatus = CSelectStatusActive;
CSelect.User = CSelectUser;
CSelect.PropertyUnitWithUUID = CSelectPropertyUnitWithUUID;
CSelect.PropertyUser = CSelectPropertyUser;
CSelect.PropertyUnits = CSelectPropertyUnit;
CSelect.ParcelStatus = CSelectParcelStatus;
CSelect.ParcelType = CSelectParcelType;
CSelect.ParcelStorageZone = CSelectParcelStorageZone;
CSelect.DeliveryCompany = CSelectDeliveryCompany;
CSelect.ParcelStoragePlace = CSelectParcelStoragePlace;
CSelect.VehicleParkingFree = CSelectVehicleParkingFree;
CSelect.BankCode = CSelectBankCode;
CSelect.TagsSign = CSelectTagsSign;
CSelect.SelectTags = CSelectTags;
CSelect.AdminProperty = CSelectAdminProperty;
CSelect.Menu = CSelectMenu;
CSelect.SettingSurvey = CSelectSettingSurvey;
CSelect.SurveyStatus = CSelectSurveyStatus;
CSelect.SurveyTaker = CSelectSurveyTaker;
CSelect.MenuProperty = CSelectMenuProperty;
CSelect.PropertyId = CSelectPropertyId;
CSelect.ApproveStatus = CSelectApprove;
CSelect.Postman = CSelectPostman;
CSelect.MainCategoryWork = CSelectMainCategory;
CSelect.SubCategoryWork = CSelectSubCategory;
CSelect.RoleResident = CSelectRoleResident;
CSelect.MainMenus = CSelectMainMenus;
CSelect.PropertyMenus = CSelectPropertyMenus;
CSelect.RepairServiceCategory = CSelectRepairServiceCategory;
CSelect.RepairServiceSubCategory = CSelectRepairServiceSubCategory;
CSelect.RepairServiceReferenceNumber = CSelectRepairServiceReferenceNumber;
CSelect.RepairServiceStatus = CSelectRepairServiceStatus;
CSelect.RegisterStatus = CSelectRegisterStatus;
CSelect.PropertyCategory = CSelectPropertyCategory;
