import { Wrapper } from "pages/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { SpaceStyle } from "..";
import { HeaderOverview } from "components";

const Application = () => {
  const { t } = useTranslation();
  const count = (2485).toLocaleString();
  return (
    <Wrapper className="h-[300px]">
      <HeaderOverview
        title={t("number-of-user-using")}
        name="applicationDate"
      />
      <SpaceStyle className="center " direction="vertical">
        <div className="count">{count}</div>
        <div className="people">{t("people")}</div>
      </SpaceStyle>
    </Wrapper>
  );
};

export default Application;
