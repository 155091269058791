import { FC, ReactNode, createContext, useContext, useState } from "react";
import { IActionRoleProvider } from "./interface";
import { useAppAuth } from "providers/app-auth";
import { SiderMenuItemProps } from "layouts/menu/item";
import { getMenuCode } from "service/auth";

const ContextActionRole = createContext<IActionRoleProvider>(
  {} as IActionRoleProvider
);

const AppActionRoleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { userMe } = useAppAuth();
  const [menu, setMenu] = useState<SiderMenuItemProps>(); // รอถามพี่ป๊อก
  const code = getMenuCode();

  const sliceCode = (code?: string) => {
    return code?.slice(0, 2);
  };

  const getAction = () => {
    if (code === null) return undefined;
    const menuCode = sliceCode(code);
    return userMe?.role?.roleMenus.find((e) => {
      const { code } = e.menu || {};
      return sliceCode(code) === menuCode;
    });
  };
  const actionMenu = getAction();

  return (
    <ContextActionRole.Provider value={{ setMenu, actionMenu }}>
      {children}
    </ContextActionRole.Provider>
  );
};

export const useAppActionRole = () => useContext(ContextActionRole);
export default AppActionRoleProvider;
