import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IParcelSettingWorker, IParcelSettingWorkerType } from "./interface";

export const useGetPostmanParcel = (
  params?: IParcelSettingWorker
): UseQueryResult<IResponse<IParcelSettingWorkerType[]>, Error> => {
  return useQuery(
    ["postman-parcel", "all", params],
    async () => {
      const res = await axiosProperty.get("/postman-parcel", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetPostman = (
  params?: IParcelSettingWorker
): UseInfiniteQueryResult<IResponse<IParcelSettingWorkerType[]>, Error> => {
  return useInfiniteQuery(
    ["postman-parcel", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/postman-parcel", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetPostmanParcelById = (
  id?: string | number
): UseQueryResult<IParcelSettingWorkerType, Error> => {
  return useQuery(
    ["postman-parcel", "id", id],
    async () => {
      const res = await axiosProperty.get(`/postman-parcel/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostPostmanParcel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/postman-parcel", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchPostmanParcel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/postman-parcel/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePostmanParcel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/postman-parcel/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePostmanParcelBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete("/postman-parcel/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};



export const useExportExcelPropertyParcelPostMan = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/post-man", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};