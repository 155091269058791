import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IDashboardParams } from "../../interface";
import { axiosCentral, throwResponse } from "config/axios";
import { ISurvey } from "./interface";

export const useGetDashboardSurvey = (
  params?: IDashboardParams
): UseQueryResult<ISurvey[], Error> => {
  return useQuery(
    ["survey-dashboard", "count-on-menu", params],
    async () => {
      const res = await axiosCentral.get(
        "dashboard/satisfaction-survey/count-on-menu",
        { params }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
