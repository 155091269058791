import { Col, Row } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { TextItem } from "./text-item";

export interface IRenderPieChart {
  data: any[];
  dataKey: string;
  dataName: string;
  color: string[];
  height?: number;
  width?: number;
  text?: string;
}
export const RenderPieChart: FC<IRenderPieChart> = ({
  data,
  dataKey,
  dataName,
  color,
  height = 250,
  width = 250,
  text,
}) => {
  const ref = useRef<any>(null);
  const [widthSet, setWidth] = useState(0);

  useEffect(() => {
    //get size page width
    if (ref?.current === null) return;
    setWidth(ref?.current?.offsetWidth);
    const getWidth = () => {
      setWidth(ref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getWidth);
    return () => window.removeEventListener("resize", getWidth);
  }, [ref]);

  const getSpan = () => {
    let span = 8;

    if (widthSet < 1100 && widthSet > 750) {
      span = 12;
    }

    if (widthSet < 750) {
      span = 24;
    }

    return span;
  };

  const span = getSpan();

  const getSpanPie = () => {
    if (widthSet < 750 && widthSet > 400) {
      return { l: 12, r: 12 };
    }
    if (widthSet <= 400) {
      return { l: 24, r: 24 };
    }
    return { l: 8, r: 16 };
  };

  const spanPie = getSpanPie();

  return (
    <div className=" overflow-auto  " style={{ height: height }}>
      <Row ref={ref}>
        <Col span={spanPie.l} className="center">
          <PieChart width={width} height={height}>
            <Pie
              data={data}
              innerRadius={65}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={2}
              dataKey={dataKey}
              nameKey={dataName}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={color[index % color.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Col>
        <Col span={spanPie.r} className="center">
          <div className="w-full overflow-auto " style={{ height: height }}>
            <Row gutter={[12, 12]} className="w-full">
              {data.map((item, index) => {
                return (
                  <Col span={span} key={index}>
                    <TextItem
                      key={`text-${index}`}
                      colorDot={color[index % color.length]}
                      title={item[dataName]}
                      count={Number(item[dataKey])}
                      text={text}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
