import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectVehicleStatus: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("status")}
      valueOption={{
        values: [
          { value: "INACTIVE", title: t("in-active") },
          { value: "PENDING", title: t("pending-approval") },
          { value: "REJECT", title: t("disapproved") },
          { value: "APPROVE", title: t("approved") },
        ],
        valueKey: "value",
        labelKey: "title",
      }}
    />
  );
};
