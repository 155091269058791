export type ButtonThemeTypes =
  | "green"
  | "gray"
  | "orange"
  | "light-orange"
  | "dark-green"
  | "white"
  | "red"
  | "blue"
  | "light-purple"
  | "light-green"
  | "dark-blue"
  | "outline-green"
  | "outline-dark-green";

interface ThmemOption {
  bg?: string;
  color?: string;
  hoverBg?: string;
  border?: string;
  icon?: string;
}

export const BUTTON_THEME: { [K in ButtonThemeTypes]: ThmemOption } = {
  "light-green": {
    bg: "#EDF5EF",
    color: "black",
    border: "#EDF5EF",
  },
  green: {
    bg: "#7BB54E",
    color: "white",
    border: "#7BB54E",
    icon: "white",
    hoverBg: "#699941",
  },
  orange: {
    bg: "#F8931C",
    color: "white",
    border: "#F8931C",
    icon: "white",
    hoverBg: "#d67e19",
  },
  gray: {
    bg: "#E5E5E5",
    color: "black",
    border: "#E5E5E5",
    icon: "black",
    hoverBg: "#d1d1d1",
  },
  "light-orange": {
    bg: "#FEF0DE",
    color: "#F8931C",
    border: "#FEF0DE",
    icon: "#F8931C",
    hoverBg: "#f7dbb9",
  },
  "dark-green": {
    bg: "#276347",
    color: "white",
    border: "#276347",
    icon: "white",
    hoverBg: "#305646",
  },
  white: {
    bg: "white",
    color: "#2061E7",
    border: "white",
    icon: "#2061E7",
    hoverBg: "#d1d1d1",
  },
  blue: {
    bg: "#E7EFFE",
    color: "#2061E7",
    border: "#E7EFFE",
    icon: "#2061E7",
    hoverBg: "#B3C4E5",
  },
  red: {
    bg: "#C1061B",
    color: "white",
    border: "#C1061B",
    icon: "white",
    hoverBg: "#BB0015",
  },
  "light-purple": {
    bg: "#FBEDFF",
    color: "#D146FF",
    border: "#FBEDFF",
    icon: "#D146FF",
    hoverBg: "#f7ddff",
  },
  "dark-blue": {
    bg: "#2061E7",
    color: "#E7EFFE",
    border: "#2061E7",
    icon: "#E7EFFE",
    hoverBg: "#2B5DBD",
  },
  "outline-green": {
    bg: "#FFFFFF",
    color: "#7BB54E",
    border: "#E5E5E5",
    hoverBg: "#E5EBDE",
  },
  "outline-dark-green": {
    bg: "#E3E9E6",
    color: "#276347",
    border: "#E3E9E6",
    hoverBg: "#c6ceca",
  },
};
