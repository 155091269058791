import ActivitiesDashboard from "pages/overview/activities";
import Complaint from "pages/overview/complaint";
import Facility from "pages/overview/facility";
import HomeCare from "pages/overview/home-care";
import Juristic from "pages/overview/juristic";
import NewsAndAnnouncement from "pages/overview/news-and-announcement";
import ParCel from "pages/overview/parcel";
import Resident from "pages/overview/residents";
import SaleAndRent from "pages/overview/sale-and-rent";
import Survey from "pages/overview/survey";
import Vehicle from "pages/overview/vehicle";
import Voting from "pages/overview/voting";
import WorkPlan from "pages/overview/work-plan";
import WorkSpaceDashboard from "pages/overview/work-space";

export const CODE_MENU = [
  "2100",
  "2300",
  "2400",
  "2500",
  "0600",
  "2700",
  "2900",
  "4100",
  "5000",
  "2800",
  "3100",
  "3300",
  "3600",
  "3900",
];

export const DATA_PAGE_OVERVIEW: { [T: string]: JSX.Element } = {
  "5000": <WorkPlan />,
  "4100": <Survey />,
  "2100": <NewsAndAnnouncement />,
  "2300": <HomeCare />,
  "2400": <ParCel />,
  "2900": <Complaint />,
  "2500": <Facility />,
  "2700": <Resident />,
  "0600": <Juristic />,
  "2800": <Vehicle />,
  "3100": <SaleAndRent />,
  "3300": <Voting />,
  "3600": <WorkSpaceDashboard />,
  "3900": <ActivitiesDashboard />,
};
