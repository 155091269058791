import { Space, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

export interface IBoxText {
  title: string;
  count: number;
  color: { bg: string; colorText: string };
  text?: string;
}

const BoxStyle = styled.div<{ bg: string; color: string }>`
  ${tw`h-[150px] rounded-app`}
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};

  .ant-typography {
    color: ${({ color }) => color};
  }
`;

export const BoxText: FC<IBoxText> = ({ title, color, count, text }) => {
  const { t } = useTranslation();
  return (
    <BoxStyle bg={color.bg} color={color.colorText} className="center">
      <Space direction="vertical" className="center">
        <div>{title}</div>
        <Typography.Title level={3}>{count.toLocaleString()}</Typography.Title>
        <div>{text ? text : t("work")}</div>
      </Space>
    </BoxStyle>
  );
};
