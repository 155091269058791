import { Col } from "antd";
import dayjs from "config/dayjs";
import { LoadingDashboard } from "pages/overview";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useGetDashboardRepairingMostStatus,
  useGetDashboardRepairingSLA,
} from "service/dashboard/central/homecare";

const SLA = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { findDate, property } = useOverview();

  const now = dayjs();
  const date = findDate(now);

  const dataSLA = useGetDashboardRepairingSLA({ ...date, property });
  const dataStatus = useGetDashboardRepairingMostStatus({ ...date, property });

  const month = formatDate({ date: now, format: "month-full" });

  const avg = (dataSLA.data?.avg || 0).toLocaleString();
  const count = (dataSLA.data?.count || 0).toLocaleString();
  const waitingConfirmAppointment = (
    dataStatus.data?.waitingConfirmAppointment || 0
  ).toLocaleString();

  return (
    <React.Fragment>
      <Col md={24} lg={12} xl={12}>
        <LoadingDashboard loading={dataSLA.isFetching}>
          <div className="bg-[#E9F7E6] text-[#21AA00] h-[250px] center flex-col !rounded-app">
            <div className="bg-[#E9F7E6] text-[#21AA00]">
              {t("sla-in-months")} {month}
            </div>
            <div className="text-[24px] mt-5">{avg}%</div>
          </div>
        </LoadingDashboard>
      </Col>
      <Col md={24} lg={12} xl={12} className="h-[250px] !rounded-app">
        <LoadingDashboard loading={dataSLA.isFetching || dataStatus.isFetching}>
          <div className="bg-[#EAF5FF] text-[#2061E7] center h-[125px] flex-col rounded-t-app">
            <span>{t("waiting-for-appointment")}</span>
            <div className="text-[24px] mt-2">
              {waitingConfirmAppointment} {t("suite")}
            </div>
          </div>
          <div className="bg-white text-[#081221] center h-[125px] flex-col rounded-b-app">
            <span>{t("sla-number")}</span>
            <div className="text-[24px] mt-2">{count}</div>
          </div>
        </LoadingDashboard>
      </Col>
    </React.Fragment>
  );
};

export default SLA;
