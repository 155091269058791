import { Select } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { CSelectProps } from ".";
import { useGetProperties } from "service/property";
import { useAppLanguage } from "providers/app-language";
import React from "react";

const CSelectStyle = styled(Select)`
  height: 45px;
  width: 100%;
  input {
    font-family: FCIconic !important;
  }
  .ant-select-selector {
    height: 100% !important;
    ${tw`!rounded-app`};
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }

  .ant-select-selection-item {
    margin: auto;
    background: none !important;
    border: none !important;
    .ant-select-selection-item-remove {
      display: none !important;
    }
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
`;

export const CSelectPropertyWithSaleAndRent: FC<CSelectProps> = ({
  value,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [dataSelect, setDataSelect] = useState<string[]>(value || []);
  const { data, isFetching } = useGetProperties({ pagination: false });
  const { translationObject } = useAppLanguage();

  useEffect(() => {
    setDataSelect(value);
  }, [value]);

  return (
    <React.Fragment>
      <CSelectStyle
        getPopupContainer={(e) => e}
        loading={isFetching}
        // {...props}
        onChange={(values: any, options: any) => {
          if (options?.length === 0 || options?.length === 1) {
            setDataSelect(values);
            onChange?.(values?.[0], options);
          }
        }}
        placeholder={t("property-name")}
        mode="tags"
        value={dataSelect}
      >
        {data?.data.map((item) => {
          const { name } = translationObject({
            object: item,
            keys: ["name"],
          });

          return (
            <CSelectStyle.Option key={name} value={name}>
              {name}
            </CSelectStyle.Option>
          );
        })}
      </CSelectStyle>
    </React.Fragment>
  );
};
