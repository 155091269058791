import { HeaderOverview } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import { Form } from "antd";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useGetDashboardAppliancesCountMost } from "service/dashboard/central/appliances";

const MostUser = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { translationObject } = useAppLanguage();

  const workSpaceDate = Form.useWatch("workSpaceDate");
  const date = findDate(workSpaceDate);

  const { data, isFetching, isError, error } =
    useGetDashboardAppliancesCountMost({
      ...date,
      property,
    });

  const { name } = translationObject({ object: data?.[0], keys: ["name"] });

  return (
    <WrapperBG className="h-full">
      <LoadingDashboard loading={isFetching}>
        <HeaderOverview
          title={t("items-that-have-most-users")}
          name="workSpaceDate"
        />
        {isError ? (
          <ErrorDashboard error={error} height="250px" />
        ) : !data || data.length <= 0 ? (
          <NoDataDashboard height="250px" />
        ) : (
          <div className="h-[250px] center flex-col">
            <div className="text-[2.5rem] text-[#44955B]">{name}</div>
          </div>
        )}
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default MostUser;
