import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosAuthen } from "config/axios";
import { IUser } from "service/administrator/interface";
import { ILogin } from "./interface";
import { IProperty } from "service/property/interface";

export const useGetMe = (isPublic: boolean): UseQueryResult<IUser> => {
  return useQuery(
    ["get-me", isPublic],
    async () => {
      const res = await axiosAuthen.get("/auth/administrator/me");
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !isPublic }
  );
};

export const useMutationGetMe = (): UseMutationResult<IUser, Error> => {
  return useMutation(async () => {
    const res = await axiosAuthen.get("/administrator/me");
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useLogin = (): UseMutationResult<ILogin> => {
  return useMutation(async (data) => {
    const res = await axiosAuthen.post("/auth/web-login", data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const Logout = () => {
  removeToken();
  removeUuid();
  window.location.reload();
};

export const removeToken = () => {
  localStorage.removeItem("lpp-access-token");
};

export const setToken = (token: string) => {
  localStorage.setItem("lpp-access-token", token);
};

export const getToken = (): string | null => {
  return localStorage.getItem("lpp-access-token");
};

export const removeUuid = () => {
  localStorage.removeItem("lpp-uuid-property");
};

export const setUuid = (uuid: string) => {
  localStorage.setItem("lpp-uuid-property", uuid);
};

export const getUuid = (): string | null => {
  return localStorage.getItem("lpp-uuid-property");
};

export const removeMenuCode = () => {
  localStorage.removeItem("lpp-menu-code");
};

export const setMenuCode = (MenuCode: string) => {
  localStorage.setItem("lpp-menu-code", MenuCode);
};

export const getMenuCode = (): string | null => {
  return localStorage.getItem("lpp-menu-code");
};

export const removeProperty = (key: string) => {
  localStorage.removeItem(key);
};

export const setProperty = (value: IProperty, key: string) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getProperty = (key: string): IProperty | null => {
  const value = localStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  }
  return value;
};
