import React from "react";
import { ErrorDashboard, LoadingDashboard, Wrapper, WrapperBG } from "..";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { HeaderOverview } from "components";
import { BoxText } from "components/menu/overview";
import { useOverview } from "providers/overview";
import { useGetDashboardMDPC } from "service/dashboard/central/mdpc";

const SaleAndRent = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Row gutter={[14, 14]}>
        <Col span={24}>{t("property.sell-and-rent", { ns: "menu" })}</Col>
        <Col span={24}>
          <List />
        </Col>
      </Row>
    </Wrapper>
  );
};

const List = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const saleAndRentDate = Form.useWatch("saleAndRentDate");
  const date = findDate(saleAndRentDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardMDPC({
    ...date,
    property,
  });

  return (
    <WrapperBG>
      <LoadingDashboard loading={isFetching}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <HeaderOverview
              formatDate="year"
              title={t("type-of-consignment-for-sell-rent")}
              name="saleAndRentDate"
            />
          </Col>
          {isError ? (
            <ErrorDashboard error={error} height="150px" />
          ) : (
            [
              <Col sm={24} xl={8}>
                <BoxText
                  title={t("sell")}
                  text={t("list")}
                  count={data?.sale || 0}
                  color={{
                    bg: "#EAF5FF",
                    colorText: "#2061E7",
                  }}
                />
              </Col>,
              <Col sm={24} xl={8}>
                <BoxText
                  title={t("rent")}
                  text={t("list")}
                  count={data?.rent || 0}
                  color={{
                    bg: "#FEEAEE",
                    colorText: "#F52A53",
                  }}
                />
              </Col>,
              <Col sm={24} xl={8}>
                <BoxText
                  title={t("sell-and-rent")}
                  text={t("list")}
                  count={data?.saleRent || 0}
                  color={{
                    bg: "#E9F7E6",
                    colorText: "#21AA00",
                  }}
                />
              </Col>,
            ]
          )}
        </Row>
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default SaleAndRent;
