import { Col, Row } from "antd";
import { LoadingDashboard, WrapperBG } from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboardParcelAverage } from "service/dashboard/central/parcel";

const AveragePieces = () => {
  const { t } = useTranslation();
  const { property } = useOverview();

  const { data, isFetching } = useGetDashboardParcelAverage({ property });

  const count = (data?.average || 0).toLocaleString();
  const max = (data?.max || 0).toLocaleString();
  const min = (data?.min || 0).toLocaleString();

  return (
    <WrapperBG className="h-[300px]">
      <LoadingDashboard loading={isFetching}>
        <Row className="w-full h-full">
          <Col span={24} className="text-[#6C6C6C] h-[60px]">
            {t("average-per-day-past-3-months")}
          </Col>
          <Col span={24} className="h-[180px] center flex-col">
            <div className="text-[2.5rem] text-[#44955B]">{count}</div>
            <div className="text-[1.5rem] text-[#276347]">{t("pieces")}</div>
          </Col>
          <Col span={24} className="h-[40px]">
            <span className="text-[#F65555] mr-1">
              {`${t("maximum")} ${max} ${t("pieces")} < `}
            </span>
            <span className="text-[#07BC31]">
              {` > ${t("minimum")} ${min} ${t("pieces")}`}
            </span>
          </Col>
        </Row>
      </LoadingDashboard>
    </WrapperBG>
  );
};

export default AveragePieces;
