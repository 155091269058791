import { useScrollGetTaskCategory } from "service/tasks-category";
import { CSelectPagination } from "./pagination";
import { useParams } from "react-router-dom";
import { useScrollGetPropertyTaskCategory } from "service/property/tasks-category";
import { CSelectProps } from ".";
export interface CSelectSubCategoryProps extends CSelectProps {
  mainId?: string | number;
  valueKey?: string;
  queryParams?: Record<string, any>;
}

export const CSelectSubCategory: React.FC<CSelectSubCategoryProps> = ({
  mainId,
  valueKey = "id",
  queryParams,
  ...props
}) => {
  const { propertyId } = useParams();

  return (
    <CSelectPagination
      {...props}
      useQuery={
        !propertyId
          ? useScrollGetTaskCategory
          : useScrollGetPropertyTaskCategory
      }
      valueKey={valueKey}
      labelKey="title"
      searchKey="title"
      translation={true}
      queryParams={{
        ...queryParams,
        type: "SUB",
        status: true,
        mainId: mainId,
      }}
    />
  );
};
