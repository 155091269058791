import React from "react";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  Wrapper,
  WrapperBG,
} from "..";
import { useTranslation } from "react-i18next";
import { HeaderOverview, IRenderLineChart, RenderLineChart } from "components";
import { Form } from "antd";
import { IData } from "service/dashboard/interface";
import { useOverview } from "providers/overview";
import { useAppLanguage } from "providers/app-language";
import { useGetDashboardResident } from "service/dashboard/central/resident";

const Resident = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { findDate, property } = useOverview();

  const residentsDate = Form.useWatch("residentsDate");
  const date = findDate(residentsDate, "year");

  const { data, isFetching, isError, error } = useGetDashboardResident({
    ...date,
    property,
  });

  if (isFetching) {
    return (
      <Wrapper>
        <LoadingDashboard loading={isFetching}>
          <div>{t("property.residents", { ns: "menu" })}</div>
          <WrapperBG className="mt-2">
            <HeaderOverview
              title={t("administrators-added-to-system")}
              name="residentsDate"
              formatDate="year"
            />
            <div className="h-[300px]"></div>
          </WrapperBG>
        </LoadingDashboard>
      </Wrapper>
    );
  }

  const getData: IData[] =
    data?.map((e) => {
      const month = formatDate({ date: e.month, format: "month" });
      return { name: month, count: e?.count || 0 };
    }) || [];

  const dataLine: IRenderLineChart = {
    data: getData,
    dataKeyX: "name",
    dataKeyY: "count",
  };

  return (
    <Wrapper>
      <div>{t("property.residents", { ns: "menu" })}</div>
      <WrapperBG className="mt-2">
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("residents-added-to-system")}
            name="residentsDate"
            formatDate="year"
          />
          {isError ? (
            <ErrorDashboard error={error} height="300px" />
          ) : !data || data.length <= 0 ? (
            <NoDataDashboard height="300px" />
          ) : (
            <RenderLineChart {...dataLine} />
          )}
        </LoadingDashboard>
      </WrapperBG>
    </Wrapper>
  );
};

export default Resident;
