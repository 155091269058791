import React from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetFacilityLists } from "service/facility-list";
import { IFacilityListParams } from "service/facility-list/interface";

export interface ISelectFacilityItem extends CSelectProps {
  params?: IFacilityListParams;
}

export const CSelectFacilityItem: React.FC<ISelectFacilityItem> = ({
  params,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder || t("facility-list")}
      useQuery={useScrollGetFacilityLists}
      valueKey="id"
      translation
      labelKey="name"
      searchKey="name"
      queryParams={{ ...params }}
    />
  );
};
