import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetProperties } from "service/property";
import { IPropertyParams } from "service/property/interface";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export interface CSelectPropertyProps extends CSelectProps, IPropertyParams {
  notValueKey?: boolean;
  valueKey?: string;
  overview?: boolean;
}

export const CSelectProperty: React.FC<CSelectPropertyProps> = ({
  placeholder,
  notValueKey = false,
  valueKey,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder ? placeholder : t("property", { ns: "menu" })}
      useQuery={useScrollGetProperties}
      valueKey={valueKey ? valueKey : "uuid"}
      labelKey="name"
      searchKey="name"
      translation
      notValueKey={notValueKey}
    />
  );
};
