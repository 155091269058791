import { DeleteFilled } from "@ant-design/icons";
import { UseQueryResult } from "@tanstack/react-query";
import {
  Col,
  Row,
  TableColumnsType,
  TableColumnType,
  TableProps,
  Typography,
} from "antd";
import { GetComponentProps } from "rc-table/lib/interface";
import { TableRowSelection } from "antd/es/table/interface";
import { IResponse } from "config/axios/interface";
import { MenuTypes } from "menus/interface";
import { useAppPageContainer } from "providers/app-page-container";
import { useAppPopup } from "providers/app-popup";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { StyledTable } from "./styled-table";
import styled from "styled-components";
import tw from "twin.macro";
import { IPagination, PaginationPage } from "./pagination";
import { MenuPath } from "routes/interface";
import { useAppActionRole } from "providers/action-role";

export const TabStyle = styled.div<{ $active: boolean }>`
  ${tw`rounded-app`}
  cursor: pointer;
  background-color: ${({ $active }) => ($active ? "#44955B" : "#44955B1A")};
  .ant-typography {
    color: ${({ $active }) => ($active ? "white" : "#44955B")};
  }
  height: 35px;
  width: 145px;
  padding: 20px;
`;

export const StyleDiv = styled.div`
  ${tw`rounded-app flex justify-center items-center`}
  height: 40px;
  width: 40px;
  background-color: #e0e1e4;
  color: black !important;
  &:hover,
  &:focus {
    background-color: #c1061b;
    border-color: #c1061b;
    color: #ffffff !important;
  }
  &:active {
    background-color: #c1061b;
    border-color: #c1061b;
  }
`;

interface CTableProps extends Omit<TableProps<any>, "title" | "rowKey"> {
  withWrapper?: boolean;
  wrapperClassName?: string;
  withSelectAble?: boolean;
  rowKey?: string;
  title: MenuTypes | string;
  pathRow?: MenuPath;
  useQueryResult?: UseQueryResult<IResponse<any[]>>;
  onRowClick?: boolean;
  manageOptions?: {
    onDelete?: boolean | ((rc: any) => void);
    onEdit?: ((rc: any) => void) | false;
    onLastItemInPage?: () => void;
  };
  extraWrapper?: IExtraWrapper[];
  pagination?: IPagination;
  withManage?: boolean;
  notFetch?: boolean;
}

export interface IExtraWrapper {
  label: string;
  active: boolean;
  onClick: () => void;
}

export const CTable: React.FC<CTableProps> = ({
  withWrapper = true,
  wrapperClassName = "",
  manageOptions,
  title,
  pathRow,
  pagination,
  rowKey = "id",
  useQueryResult,
  dataSource,
  columns,
  loading,
  rowSelection,
  withSelectAble = true,
  onRowClick = true,
  extraWrapper,
  withManage = true,
  notFetch = false,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { actionMenu } = useAppActionRole();

  const { confirmDelete } = useAppPopup();
  const { selectRowsKey, setSelectRowsKey, onRecordDelete } =
    useAppPageContainer();

  useEffect(() => {
    setSelectRowsKey?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPagination = (
    _pagination?: IPagination
  ): IPagination | undefined => {
    if (useQueryResult && pagination) {
      const { count, page, limit, pageCount } = useQueryResult.data || {};
      return {
        total: count || 0,
        current: page || 1,
        pageSize: limit || 10,
        size: "small",
        showSizeChanger: true,
        pageCount: pageCount || 1,
        ..._pagination,
      };
    }
    if (_pagination) {
      return {
        size: "small",
        showSizeChanger: true,
        ..._pagination,
      };
    }
  };

  const initialData = (_pagination?: IPagination) => {
    return (dataSource || []).filter((item, index) => {
      if ((_pagination?.current || 1) > 1) {
        const start =
          (_pagination?.pageSize || 10) * ((_pagination?.current || 1) - 1) + 1;
        const end = (_pagination?.pageSize || 10) * (_pagination?.current || 1);
        return index + 1 >= start && index + 1 <= end;
      }
      return index + 1 <= (_pagination?.pageSize || 10);
    });
  };

  const _onDelete = async (rc: any) => {
    const { onDelete, onLastItemInPage } = manageOptions || {};
    const confirm = await confirmDelete();
    if (!confirm) return;
    if (typeof onDelete === "function") {
      return onDelete(rc);
    }
    if (onDelete) {
      const dataLength = notFetch
        ? _dataSourceNotFetch?.length
        : _dataSource?.length;
      return onRecordDelete(rc[rowKey], onLastItemInPage, dataLength);
    }
  };

  const getColumn = (): TableColumnsType<any> => {
    const index: TableColumnType<any> = {
      title: "#",
      align: "center",
      width: 80,
      render: (_, __, index: number) => {
        if (!pagination) return index + 1;
        return (
          ((_pagination?.current || 1) - 1) * (pagination?.pageSize || 10) +
          (index + 1)
        );
      },
    };
    if (!columns) return [index];

    if (actionMenu?.action && withManage) {
      const manage: TableColumnType<any> = {
        title: t("manage"),
        width: 100,
        align: "center",
        onCell: (record, rowIndex) => {
          return {
            onClick: (ev) => {
              ev.stopPropagation();
            },
          };
        },
        render: (_, rc) => {
          return (
            <Row gutter={24} className="center">
              <Col hidden={manageOptions?.onDelete === false}>
                <StyleDiv onClick={() => _onDelete(rc)}>
                  <DeleteFilled
                    className="m-auto block "
                    style={{ fontSize: 20 }}
                  />
                </StyleDiv>
              </Col>
            </Row>
          );
        },
      };

      return [index, ...columns, manage];
    }
    return [index, ...columns];
  };

  const _dataSource = useQueryResult?.data?.data || dataSource || [];
  const _columns = getColumn();
  const _pagination = getPagination(pagination);
  const _loading = useQueryResult?.isFetching || loading;
  const _dataSourceNotFetch = initialData(pagination);
  const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
    setSelectRowsKey(selectedRowKeys);
  };

  const _rowSelection: TableRowSelection<object> = {
    onChange: onSelectedRowKeysChange,
    selectedRowKeys: selectRowsKey,
    columnWidth: "50px",
  };

  const onRow: GetComponentProps<object> = (rc: any) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        if (manageOptions?.onEdit === false) return;
        if (manageOptions?.onEdit) return manageOptions.onEdit(rc);
        if (!onRowClick) return;
        if (propertyId) {
          return navigate(`/property/${propertyId}/${pathRow}/${rc[rowKey]}`);
        }
        return navigate(`/${pathRow}/${rc[rowKey]}`);
      },
    };
  };

  const table = (
    <StyledTable
      {...props}
      rowKey={rowKey}
      loading={_loading}
      columns={_columns}
      dataSource={notFetch ? _dataSourceNotFetch : _dataSource}
      pagination={false}
      rowSelection={
        withSelectAble && actionMenu?.action
          ? rowSelection
            ? rowSelection
            : _rowSelection
          : undefined
      }
      onRow={onRow}
      rowClassName={onRowClick ? "cursor-pointer" : ""}
      className={className}
    />
  );

  if (withWrapper) {
    return (
      <div className={wrapperClassName}>
        <Row className="!mb-4" gutter={[6, 0]}>
          <Col className="center mr-5 h-full mt-2">
            <Typography.Title level={5}>
              {t(title, { ns: "menu" })}
            </Typography.Title>
          </Col>
          {extraWrapper ? (
            <Row gutter={[0, 8]}>
              {extraWrapper.map((item, index) => {
                return (
                  <TabStyle
                    $active={item.active}
                    className="center mr-2"
                    onClick={item.onClick}
                  >
                    <Typography.Text ellipsis={{ tooltip: item.label }}>
                      {item.label}
                    </Typography.Text>
                  </TabStyle>
                );
              })}
            </Row>
          ) : null}
        </Row>

        <div>
          {table} {pagination ? <PaginationPage {..._pagination} /> : null}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {table} {pagination ? <PaginationPage {..._pagination} /> : null}
    </React.Fragment>
  );
};
