import { Input, Popover } from "antd";
import React from "react";
import { BlockPicker } from "react-color";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

export interface CInputColorProps {
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
}

const InputColor = styled(Input)<{ $bgcolor?: string }>`
  ${tw`!rounded-app`}
  // border-color: ${({ $bgcolor }) => ($bgcolor ? "white" : "")}!important;
height: 45px;
  .ant-input-group-addon {
    ${tw`p-0`}
    height: 45px;
    border: 1px solid ${({ $bgcolor }) => ($bgcolor ? $bgcolor : "#d9d9d9")};
    background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : "#ffffff")};
    .ant-input-group-wrapper-status-error {
      color: #ff4d4f;
      border-color: #ff4d4f !important;
    }
  }

  .ant-input {
    height: 45px;
  }
`;

export const CInputColor: React.FC<CInputColorProps> = ({
  onChange,
  value,
  placeholder,
}) => {
  const { t } = useTranslation();
  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      content={
        <BlockPicker
          onChange={(e) => onChange?.(e?.hex)}
          color={value}
          triangle="hide"
        />
      }
    >
      <InputColor
        addonAfter={<div style={{ width: 50 }}></div>}
        className="!m-0 !p-0"
        value={value}
        $bgcolor={value}
        placeholder={placeholder ? placeholder : t("color")}
      />
    </Popover>
  );
};
