import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse, axiosProperty } from "config/axios";
import { IResponse } from "config/axios/interface";
import { INewsCategory, INewsCategoryParams } from "./interface";

export const usePropertyGetCategorys = (
  params?: INewsCategoryParams
): UseQueryResult<IResponse<INewsCategory[]>, Error> => {
  return useQuery(
    ["news-category", "all", params],
    async () => {
      const res = await axiosProperty.get("/news-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyScrollGetCategorys = (
  params?: INewsCategoryParams
): UseInfiniteQueryResult<IResponse<INewsCategory[]>, Error> => {
  return useInfiniteQuery(
    ["news-category", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/news-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const usePropertyGetCategory = (
  id?: number | string
): UseQueryResult<INewsCategory, Error> => {
  return useQuery(
    ["news-category", "id", id],
    async () => {
      const res = await axiosProperty.get(`/news-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePropertyPostCategory = (): UseMutationResult => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post("/news-category", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyPatchCategory = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/news-category/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePropertyDeleteCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/news-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
