import { Form, Space } from "antd";
import {
  HeaderOverview,
  IRenderStackedBarChart,
  RenderStackedBarChart,
} from "components";
import {
  ErrorDashboard,
  LoadingDashboard,
  NoDataDashboard,
  WrapperBG,
} from "pages/overview";
import { useAppLanguage } from "providers/app-language";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import { useGetDashBoardComplaintGraphSettingType } from "service/dashboard/central/recommrnt-feed-back";

const dataKeyY = [
  { color: "#F52A53", key: "cancel" },
  { color: "#8EC641", key: "success" },
  { color: "#939498", key: "acknowledge" },
  { color: "#F8931D", key: "pending" },
];

const VolumeComplaint = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();
  const { translationObject } = useAppLanguage();

  const volumeComplaintsDate = Form.useWatch("volumeComplaintsDate");
  const date = findDate(volumeComplaintsDate);

  const { data, isFetching, isError, error } =
    useGetDashBoardComplaintGraphSettingType({
      ...date,
      property,
    });

  const getName =
    data?.map((item) => {
      const { name } = translationObject({ object: item, keys: ["name"] });
      return { ...item, name: name };
    }) || [];

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-5 rounded-app w-full shadow-lg">
          <p className="label">{label}</p>
          {payload.map((item: any) => {
            return (
              <div className={`text-[${item.color}]`}>
                {t(item.name) + " : " + item.value}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  if (isFetching) {
    return (
      <WrapperBG>
        <LoadingDashboard loading={isFetching}>
          <HeaderOverview
            title={t("volume-complaints")}
            name="volumeComplaintsDate"
          />
          <div className="h-[300px]"></div>
          <div className="px-5 mt-3">
            <Space size="large">
              <span>{t("status")} : </span>
              <DotColor color="#F8931D" text={t("pending")} />
              <DotColor color="#939498" text={t("acknowledge")} />
              <DotColor color="#8EC641" text={t("success")} />
              <DotColor color="#F52A53" text={t("cancel")} />
            </Space>
          </div>
        </LoadingDashboard>
      </WrapperBG>
    );
  }

  const findNoCount = getName.some((e) => {
    return e.cancel + e.acknowledge + e.pending + e.success > 0;
  });

  const dataStackedBar: IRenderStackedBarChart = {
    data: getName,
    dataKeyX: "name",
    dataKeyY: dataKeyY,
    Content: CustomTooltip,
  };

  return (
    <WrapperBG>
      <HeaderOverview
        title={t("volume-complaints")}
        name="volumeComplaintsDate"
      />
      {isError ? (
        <ErrorDashboard error={error} height="300px" />
      ) : !data || data.length <= 0 || !findNoCount ? (
        <NoDataDashboard height="300px" />
      ) : (
        <RenderStackedBarChart {...dataStackedBar} />
      )}
      <div className="px-5 mt-3">
        <Space size="large">
          <span>{t("status")} : </span>
          <DotColor color="#F8931D" text={t("pending")} />
          <DotColor color="#939498" text={t("acknowledge")} />
          <DotColor color="#8EC641" text={t("success")} />
          <DotColor color="#F52A53" text={t("cancel")} />
        </Space>
      </div>
    </WrapperBG>
  );
};

const DotColor = ({ color, text }: { color: string; text: string }) => {
  return (
    <div className="flex flex-row center">
      <div
        className="w-[10px] h-[10px] rounded-full mr-1"
        style={{ backgroundColor: color }}
      ></div>
      <span>{text}</span>
    </div>
  );
};

export default VolumeComplaint;
