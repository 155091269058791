import React, { FC } from "react";
export interface ISvgDot {
  cx: number;
  cy: number;
  color: string;
}

export const SvgDot: FC<ISvgDot> = ({ cx, cy, color }) => {
  return (
    <svg x={cx - 10} y={cy - 10} width={20} height={20} viewBox="0 0 100 100">
      <circle cx={50} cy={50} r={30} fill={color} />
    </svg>
  );
};
