import { Button, ButtonProps } from "antd";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ButtonThemeTypes, BUTTON_THEME as theme } from "./theme";

export const StyledCButton = styled(Button)<{
  color: ButtonThemeTypes;
  $fullWidth: boolean;
  $sizeFor: "text" | "icon";
  $display: boolean;
}>`
  //remove splash effect
  div {
    ${({ $display }) => ($display ? "display:none" : "")};
  }
  ${tw`flex justify-center items-center`};
  width: ${({ $sizeFor }) => ($sizeFor === "icon" ? "40px" : "")};
  ${({ $fullWidth }) => ($fullWidth ? tw`w-full` : "")};
  color: ${({ color }) => theme[color].color} !important;
  background-color: ${({ color }) => theme[color].bg} !important;
  border-color: ${({ color }) =>
    theme[color].border || theme[color].bg} !important;

  span {
    color: ${({ color }) => theme[color].color} !important;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ color }) => theme[color].color} !important;
    background-color: ${({ color }) =>
      theme[color].hoverBg || theme[color].bg} !important;
    border-color: ${({ color }) =>
      theme[color].hoverBg || theme[color].bg} !important;
  }

  &:disabled {
    color: ${({ color }) => theme[color].color} !important;
    background-color: ${({ color }) => theme[color].bg} !important;
    border-color: ${({ color }) =>
      theme[color].border || theme[color].bg} !important;
  }
`;

export interface CButtonProps extends ButtonProps {
  color?: ButtonThemeTypes;
  fullWidth?: boolean;
  sizeFor?: "text" | "icon";
  className?: string;
  display?: boolean;
}

export const CButton: React.FC<CButtonProps> = ({
  color = "green",
  fullWidth = false,
  sizeFor = "text",
  display = true,
  className,
  ...props
}) => {
  return (
    <StyledCButton
      $sizeFor={sizeFor}
      $fullWidth={fullWidth}
      color={color}
      {...props}
      className={className}
      $display={display}
    />
  );
};

// export default CButton;
