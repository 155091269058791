import { useScrollGetRoles } from "service/role";
import { CSelectPagination } from "./pagination";

export const CSelectRole = ({ ...props }) => {
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetRoles}
      valueKey='id'
      labelKey='title'
      searchKey='title'
      translation
      queryParams={{
        isActive: true,
      }}
    />
  );
};
