import { Dayjs } from "dayjs";
import { FC } from "react";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, {
  PickerProps,
} from "antd/es/date-picker/generatePicker";
import { useTranslation } from "react-i18next";

let myConfigTH = Object.assign({}, dayjsGenerateConfig);

myConfigTH.getYear = (params) => {
  return params.year() + 543;
};

myConfigTH.setYear = (date, year) => {
  return date.year(year - 543);
};

const myConfigEN = dayjsGenerateConfig;

export const CDate = generatePicker<Dayjs>(dayjsGenerateConfig);

type CDatePickerProps = PickerProps<Dayjs> & {
  //
};

export const CDatePickerWithYear: FC<CDatePickerProps> = (props) => {
  const language = useTranslation().i18n.language as "en" | "th";
  const CDateComponent = generatePicker<Dayjs>(
    language === "en" ? myConfigEN : myConfigTH
  );

  let format: { en: string; th: string } = {
    en: "YYYY",
    th: "BBBB",
  };

  return (
    <CDateComponent
    
      {...props}
      picker="year"
      format={format[language]}
      getPopupContainer={(e) => e}
      style={{ width: "100%", height: "45px" }}
    />
  );
};
