import { Avatar, AvatarProps } from "antd";
import { AvatarHolderImage } from "assets/images";
import { CImage } from "components/input/c-image";
import styled from "styled-components";

const CAvatarStyle = styled(Avatar)`
  background: white;
`;

export const CAvatar = ({
  src,
  icon,
  className = "",
  ...props
}: AvatarProps) => {
  return (
    <CAvatarStyle
      {...props}
      src={src}
      icon={
        icon ? (
          icon
        ) : (
          <CImage
            src={AvatarHolderImage}
            rounded={false}
            style={{ borderColor: "white" }}
          />
        )
      }
      className={`!inline-flex items-center justify-center ${className}`}
    />
  );
};
