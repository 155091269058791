import { Form } from "antd";
import { ITextItem, TopBox } from "components";
import { LoadingDashboard } from "pages/overview";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import { useGetDashboardParcel } from "service/dashboard/central/parcel";

const AllParcelsItem = () => {
  const { t } = useTranslation();
  const { findDate, property } = useOverview();

  const allStatusDate = Form.useWatch("allStatusDate");
  const date = findDate(allStatusDate);

  const { data, isFetching } = useGetDashboardParcel({ ...date, property });

  const AllStatus: ITextItem[] = [
    { title: t("parcel-all"), count: data?.all || 0, colorCount: "#2061E7" },
    {
      title: t("parcel-received"),
      count: data?.received || 0,
      colorCount: "#66BC07",
    },
    {
      title: t("parcel-waiting-received"),
      count: data?.notReceived || 0,
      colorCount: "#F65555",
    },
    {
      title: t("parcel-send-back"),
      count: data?.sendBack || 0,
      colorCount: "#58595B",
    },
    {
      title: t("average-parcel-receiving"),
      count: data?.overTime || 0,
      colorCount: "#58595B",
    },
  ];

  return (
    <LoadingDashboard loading={isFetching}>
      <TopBox
        data={AllStatus}
        keyTitle="title"
        nameDate="allStatusDate"
        title={t("all-items")}
      />
    </LoadingDashboard>
  );
};

export default AllParcelsItem;
