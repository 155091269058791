import { useScrollGetTaskCategory } from "service/tasks-category";
import { CSelectPagination } from "./pagination";
import { useParams } from "react-router-dom";
import { useScrollGetPropertyTaskCategory } from "service/property/tasks-category";
import { CSelectProps } from ".";

export interface CSelectSubMenuProps extends CSelectProps {
  queryParams?: Record<string, any>;
  valueKey?: string;
}

export const CSelectMainCategory: React.FC<CSelectSubMenuProps> = ({
  queryParams,
  valueKey = "id",
  ...props
}) => {
  const { propertyId } = useParams();

  return (
    <CSelectPagination
      {...props}
      useQuery={
        !propertyId
          ? useScrollGetTaskCategory
          : useScrollGetPropertyTaskCategory
      }
      valueKey={valueKey}
      labelKey="title"
      searchKey="title"
      translation={true}
      queryParams={{
        type: "MAIN",
        status: true,
        ...queryParams,
      }}
    />
  );
};
