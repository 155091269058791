import { Row, Col, Form, Input, Typography } from "antd";
import { BGWeb, LogoImage } from "assets/images";
import { CButton, fireNotification } from "components";
import { CImage } from "components/input/c-image";
import { SITE_KEY } from "config/axios";
import { SiderMenuItemProps } from "layouts/menu/item";
import { MAIN_MENUS } from "menus/main";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setMenuCode, setToken, useLogin } from "service/auth";
import { IRoleMenu } from "service/role-menu/interface";
import styled from "styled-components";
import tw from "twin.macro";

const PageBackground = styled.div`
  height: 100vh !important;
  width: 100vw !important;
  overflow: auto;
  overflow-y: auto;
`;

const StyleInput = styled(Input)`
  height: 60px;
  background-color: white !important;
  border: 1px solid #b1b3b8;
  border-radius: 10px;
  &:focus {
    border: 1px solid #80bc28;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #80bc28;
  }
`;

const StyleInputPassword = styled(Input.Password)`
  height: 60px;
  background-color: white;
  border: 1px solid #b1b3b8;
  color: #d8dadf;
  border-radius: 10px;
  &:focus {
    border: 1px solid #80bc28;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #80bc28;
  }
  .ant-input-password-icon {
    color: white;
    cursor: pointer;
    transition: all 0.3s;
  }
`;

const StyleButton = styled(CButton)`
  height: 60px;
  border-radius: 10px;
  font-size: 18px;
`;

const BackgroundImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: url(${BGWeb});
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: bottom 0px right 0px;
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tokenRecaptcha, setTokenRecaptcha] = useState<string | null>();

  const { mutate, isLoading } = useLogin();

  const onLogin = (values: any) => {
    mutate(
      { email: values.loginEmail, password: values.loginPassword },
      {
        onSuccess: (data) => {
          setToken(data.accessToken);
          const main = getViewAbleMenu(
            MAIN_MENUS,
            data?.data?.role?.roleMenus || []
          );
          // navigate("/", { replace: true });
          navigate(main?.[0]?.to || "/", { replace: true });
          setMenuCode(main?.[0]?.code);
        },
        onError: ({ message }: any) => {
          fireNotification({ type: "error", message: message });
        },
      }
    );
  };

  const getViewAbleMenu = (
    base: SiderMenuItemProps[],
    roleMenus: IRoleMenu[]
  ): SiderMenuItemProps[] => {
    return base
      .map((item) => {
        return item;
      })
      .filter((item) => {
        const find = roleMenus.find((e) => e.menu?.code === item.code);
        const view =
          find?.viewable || (item.children?.length || 0) > 0 || false;
        return view;
      })
      .map((item) => {
        const find = roleMenus.find((e) => e.menu?.code === item.code);
        return { ...item, slug: find?.slug };
      });
  };

  const onChange = (value?: string | null) => {
    console.log("Captcha value:", value);
    setTokenRecaptcha(value);
  };

  return (
    <PageBackground>
      <Row className="!h-full w-full">
        <Col span={18} className="!bg-[#E5E5E5] !h-full w-full relative">
          <BackgroundImg />
          <div className="pt-10 pl-10">
            <CImage height={100} width="auto" src={LogoImage} rounded={false} />
            <Typography.Paragraph
              style={{ width: "40%", fontSize: 16 }}
              type="secondary"
            >
              The most completed property and facility management company with
              expertise in affordable & quality
            </Typography.Paragraph>
            <div>
              <Row>
                <DotOrange width="20px" />
                <DotOrange />
                <DotOrange />
              </Row>
            </div>
          </div>
        </Col>
        <Col span={6} className="center">
          <Layout>
            <Form className="w-full" layout="vertical" onFinish={onLogin}>
              <div className="center my-10 text-[#276347] text-[20px] font-bold ">
                {t("login")}
              </div>
              <Form.Item
                name="loginEmail"
                rules={[{ type: "email", required: true }]}
              >
                <StyleInput
                  placeholder={`${t("email")}`}
                  readOnly={isLoading}
                />
              </Form.Item>
              <Form.Item name="loginPassword" rules={[{ required: true }]}>
                <StyleInputPassword
                  placeholder={`${t("password")}`}
                  readOnly={isLoading}
                />
              </Form.Item>
              <ReCAPTCHA sitekey={SITE_KEY} onChange={onChange} />
              <Form.Item>
                <StyleButton
                  color="dark-green"
                  className="w-full my-5"
                  loading={isLoading}
                  htmlType="submit"
                  disabled={!tokenRecaptcha}
                >
                  {t("login")}
                </StyleButton>
              </Form.Item>
              <div className=" center text-[#707070]">
                {t("forget-the-password")}
              </div>
            </Form>
          </Layout>
        </Col>
      </Row>
    </PageBackground>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row className="h-full w-full">
      <Col xs={1} md={2} lg={3} xxl={4}></Col>
      <Col xs={22} md={20} lg={18} xxl={16} className="center">
        {children}
      </Col>
      <Col xs={1} md={2} lg={3} xxl={4}></Col>
    </Row>
  );
};

const DotOrange = styled.div<{ width?: string }>`
  ${tw`mr-1`}
  height: 6px;
  width: ${({ width }) => (width ? width : "6px")};
  background-color: #f8931d;
  border-radius: 10px;
`;

export default LoginPage;
