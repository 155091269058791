import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps, CSelect } from ".";
import { MAIN_MENUS } from "menus/main";
import { PROPERTY_MENUS } from "menus/property";

export interface CSelectMenuProps extends CSelectProps {
  property?: boolean;
}

export const CSelectMenu: FC<CSelectMenuProps> = ({
  property,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const mainMenu = MAIN_MENUS.map((e) => {
    return { code: e.code, title: t(e.title, { ns: "menu" }) };
  });
  const propertyMenu = PROPERTY_MENUS.map((e) => {
    const title = t(e.title, { ns: "menu" }) + ` (${t("property-menu")})`;
    const translate = property ? t(e.title, { ns: "menu" }) : title;
    return {
      code: e.code,
      title: translate,
    };
  });

  const menus = !property ? [...mainMenu, ...propertyMenu] : propertyMenu;

  return (
    <CSelect
      {...props}
      placeholder={placeholder ? placeholder : t("display-type")}
      valueOption={{
        values: menus,
        valueKey: "code",
        labelKey: "title",
      }}
    />
  );
};
