import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IRecommendSetting, IRecommendSettingParams } from "./interface";

export const useScrollGetRecommendFeedbackSettings = (
  params?: IRecommendSettingParams
): UseInfiniteQueryResult<IResponse<IRecommendSetting[]>, Error> => {
  return useInfiniteQuery(
    ["RecommendFeedbackSetting", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/recommend-feedback-setting", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetRecommendFeedbackSettings = (
  params?: IRecommendSettingParams
): UseQueryResult<IResponse<IRecommendSetting[]>> => {
  return useQuery(
    ["RecommendFeedbackSetting", "all", params],
    async () => {
      const res = await axiosProperty.get("/recommend-feedback-setting", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetRecommendFeedbackSetting = (
  id?: number | string
): UseQueryResult<IRecommendSetting, Error> => {
  return useQuery(
    ["RecommendFeedbackSetting", "id", id],
    async () => {
      const res = await axiosProperty.get(`/recommend-feedback-setting/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostRecommendFeedbackSetting = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/recommend-feedback-setting`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchRecommendFeedbackSetting = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(
      `/recommend-feedback-setting/${id}`,
      data
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteRecommendFeedbackSetting = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/recommend-feedback-setting/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteRecommendFeedbackSettingBulk = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/recommend-feedback-setting/bulk`, {
      data,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelPropertyARecommendFeedbackSetting =
  (): UseMutationResult<Blob> => {
    return useMutation(async (data) => {
      const res = await axiosProperty.post(
        "/export-excel/recomment-feedback-setting",
        data,
        {
          responseType: "blob",
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };

export const useExcelImportPropertyARecommendFeedbackSetting =
  (): UseMutationResult<unknown, Error> => {
    return useMutation(async (params: any) => {
      const res = await axiosProperty.post(
        "/import-excel/feedback-setting/",
        params,
        {
          responseType: "blob",
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };

export const useExcelTemplatePropertyARecommendFeedbackSetting =
  (): UseMutationResult<unknown, Error> => {
    return useMutation(async ({ data }: any) => {
      const res = await axiosProperty.post(
        "/export-excel/template-feedback-setting/",
        data,
        {
          responseType: "blob",
        }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };
