import { Layout } from "antd";
import { AppLayoutProps } from "layouts";
import SiderMenu from "layouts/menu";
import { useAppRoleMenu } from "providers/app-role-menu";
import React from "react";
import SiderLogo from "./sider-logo";

const Sider: React.FC<AppLayoutProps> = ({ type }) => {
  const { mainMenu, propertyMenu } = useAppRoleMenu();

  return (
    <Layout.Sider className='!bg-gray' width={280}>
      <SiderLogo />
      <SiderMenu
        isPropertyPath={type === "property"}
        menus={type === "property" ? propertyMenu : mainMenu}
      />
    </Layout.Sider>
  );
};

export default Sider;
