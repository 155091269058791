import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetPropertyUnits } from "service/property/property-unit";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export const CSelectPropertyUnitWithUUID: React.FC<CSelectProps> = ({
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder ? placeholder : t("property", { ns: "menu" })}
      useQuery={useScrollGetPropertyUnits}
      valueKey="uuid"
      labelKey="houseNumber"
      searchKey="houseNumber"
    />
  );
};
