import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse, axiosCentral } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IProperty, IPropertyParams } from "./interface";

export const useGetProperties = (
  params?: IPropertyParams
): UseQueryResult<IResponse<IProperty[]>, Error> => {
  return useQuery(
    ["property", "all", params],
    async () => {
      const res = await axiosCentral.get("/property", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useScrollGetProperties = (
  params?: IPropertyParams
): UseInfiniteQueryResult<IResponse<IProperty[]>, Error> => {
  return useInfiniteQuery(
    ["property", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosCentral.get("/property", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetProperty = (
  id?: string | number
): UseQueryResult<IProperty, Error> => {
  return useQuery(
    ["property", "info"],
    async () => {
      const res = await axiosProperty.get(`/property/info`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!id }
  );
};

export const usePostProperty = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosCentral.post("/property", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchProperty = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/property`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteProperty = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosCentral.delete(`/property/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostExcelProperty = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data: any) => {
    const res = await axiosProperty.post(
      `export-excel/property`,
      { data },
      { responseType: "blob" }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelProperty = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosCentral.post("/import-excel/property", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
