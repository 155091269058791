import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, {
  PickerProps,
} from "antd/es/date-picker/generatePicker";

let myConfigTH = Object.assign({}, dayjsGenerateConfig);
const myConfigEN = dayjsGenerateConfig;

myConfigTH.getYear = (date) => {
  return Number(date.format("BBBB"));
};

myConfigTH.setYear = (date, year) => {
  return date.year(year - 543);
};

myConfigTH.locale.format = (
  locale: string,
  date: dayjs.Dayjs,
  format: string
) => {
  const language = localStorage.getItem("@lpp-language") as "en" | "th";
  const convertFormatTh = format.replace("YYYY", "BBBB");
  const convertFormatEn = format.replace("YYYY", "YYYY");

  const formatLang = language === "en" ? convertFormatEn : convertFormatTh;
  const lang = language === "en" ? "en" : "th";

  return date.locale(lang).format(formatLang);
};

const CDateLang = () => {
  const language = localStorage.getItem("@lpp-language");
  if (language === "en") {
    return generatePicker<Dayjs>(myConfigEN);
  }
  return generatePicker<Dayjs>(myConfigTH);
};

export const CDate = CDateLang();

export type CDatePickerProps = PickerProps<Dayjs> & {
  showTime?: boolean;
  formatDate?: string;
  notShowDate?: boolean;
};

export const CDatePicker: FC<CDatePickerProps> = ({
  formatDate,
  notShowDate,
  ...props
}) => {
  const language = localStorage.getItem("@lpp-language") as "en" | "th";
  const CDateComponent = generatePicker<Dayjs>(
    language === "en" ? myConfigEN : myConfigTH
  );

  let format: { en: string; th: string } = {
    en:
      formatDate === "year"
        ? "YYYY"
        : props?.showTime
        ? "DD MMM YYYY HH:mm"
        : notShowDate
        ? "MMM YYYY"
        : "DD MMM YYYY",
    th:
      formatDate === "year"
        ? "BBBB"
        : props?.showTime
        ? "DD MMM BBBB HH:mm"
        : notShowDate
        ? "MMM BBBB"
        : "DD MMM BBBB",
  };

  return (
    <CDateComponent
      {...props}
      format={format[language]}
      getPopupContainer={(e) => e}
      style={{ width: "100%", height: "45px" }}
    />
  );
};
