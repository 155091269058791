import { IFacilityList, IFacilityListParams } from "./interface";
import { IResponse } from "config/axios/interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  // useInfiniteQuery,
  // UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import {
  postPropertyMediaObject,
  postPropertyMultiMediaObject,
} from "service/property/media-object";
import { IMediaObject } from "service/media-object/interface";

export const useGetFacilityLists = (
  params?: IFacilityListParams
): UseQueryResult<IResponse<IFacilityList[]>, Error> => {
  return useQuery(
    ["facilityList", "all", params],
    async () => {
      const res = await axiosProperty.get("/facility", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
      keepPreviousData: true,
    }
  );
};

export const useScrollGetFacilityLists = (
  params?: IFacilityListParams
): UseInfiniteQueryResult<IResponse<IFacilityList[]>, Error> => {
  return useInfiniteQuery(
    ["facilityList", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/facility", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetFacilityList = (
  id?: string | number
): UseQueryResult<IFacilityList, Error> => {
  return useQuery(
    ["facilityList", "only", id],
    async () => {
      const res = await axiosProperty.get(`/facility/${id}`);
      if (res.status === 200) {
        const { facilityStoreImage } = res.data?.data || {};
        let _temp: IMediaObject[] = [];
        for (const img of facilityStoreImage || []) {
          if (img?.image) {
            _temp = [..._temp, img.image];
          }
        }
        return { ...res.data.data, facilityStoreImage: _temp };
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostFacilityList = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ coverPicture, image = [], ...data }: any) => {
    if (typeof coverPicture === "object") {
      const mediaObject = await postPropertyMediaObject(coverPicture);
      data = { ...data, coverPicture: mediaObject.id };
    }
    const imageList = await postPropertyMultiMediaObject(image);
    data = { ...data, image: imageList };
    const res = await axiosProperty.post("/facility", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchFacilityList = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ coverPicture, image = [], id, ...data }: any) => {
    if (typeof coverPicture === "object" && coverPicture) {
      const mediaObject = await postPropertyMediaObject(coverPicture);
      data = { ...data, coverPicture: mediaObject.id };
    }
    const imageList = await postPropertyMultiMediaObject(image);
    data = { ...data, image: imageList };
    const res = await axiosProperty.patch(`/facility/${id}`, data);
    if (res.status === 200) {
      return res.data?.data;
    }
    throwResponse(res);
  });
};

export const useDeleteFacilityList = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/facility/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteFacilityListBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete("/facility/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostExcelFacilityList = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post(`/export-excel/facility`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelPropertyFacility = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/facility", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelImportPropertyFacility = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/facility/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyFacility = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post(
      "/export-excel/template-facility/",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
