import React, { FC, useEffect, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { numFormat } from "tools/string";

interface IDataKeyY {
  key: string;
  color?: string;
}

export interface IRenderStackedBarChart {
  data: any[];
  dataKeyX: string;
  dataKeyY: IDataKeyY[];
  labelX?: string;
  labelY?: string;
  height?: number;
  Content?: ({ active, payload, label }: any) => JSX.Element | null;
}

export const RenderStackedBarChart: FC<IRenderStackedBarChart> = ({
  data,
  dataKeyX,
  dataKeyY,
  labelX,
  labelY,
  height = 300,
  Content = undefined,
}) => {
  const ref = useRef<any>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    //get size page width
    if (ref?.current === null) return;
    setWidth(ref?.current?.offsetWidth);
    const getWidth = () => {
      setWidth(ref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getWidth);
    return () => window.removeEventListener("resize", getWidth);
  }, []);

  const tickFormatter = (value: string, index: number) => {
    const number = numFormat(Number(value));
    if (!number) return "0";
    return number?.toString();
  };

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const textAnchor = "middle";

    let tickValue = payload.value;
    if (tickValue.length >= 10) {
      tickValue = tickValue.slice(0, 12) + "...";
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor={textAnchor}>
          {tickValue}
        </text>
      </g>
    );
  };

  return (
    <div ref={ref} className=" overflow-auto !overflow-x-hidden">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 0" vertical={false} />
        <XAxis
          dataKey={dataKeyX}
          label={
            labelX ? (
              <Label value={labelX} offset={0} position="insideBottom" />
            ) : undefined
          }
          interval={0}
          tickSize={0}
          tickMargin={10}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          tickFormatter={tickFormatter}
          domain={[0, "dataMax"]}
          label={
            labelY
              ? { value: labelY, angle: -90, position: "center" }
              : undefined
          }
        />
        <Tooltip content={Content} />
        {dataKeyY.map((y, index) => {
          return (
            <Bar
              dataKey={y.key}
              stackId="a"
              fill={y.color}
              barSize={50}
              key={index}
            >
              {data.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={y.color} />;
              })}
            </Bar>
          );
        })}
      </BarChart>
    </div>
  );
};
