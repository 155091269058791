import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetAdministrators } from "service/administrator";
import { IUserParams } from "service/administrator/interface";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";

export interface CSelectUserProps extends CSelectProps, IUserParams {
  valueKey?: string;
}

export const CSelectUser: React.FC<CSelectUserProps> = ({
  enabled,
  placeholder,
  valueKey = "id",
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      placeholder={placeholder ? placeholder : t("person")}
      useQuery={useScrollGetAdministrators}
      valueKey={valueKey}
      labelKey="first-last-name"
      searchKey="name"
      queryParams={{
        enabled,
        isActive: true,
      }}
      {...props}
    />
  );
};
