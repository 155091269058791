import { CloseOutlined } from "@ant-design/icons";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Col, Drawer, DrawerProps, FormInstance, Row, Typography } from "antd";
import { CButton } from "components/button/c-button";
import { fireNotification } from "components/popup/notification";
import { useAppActionRole } from "providers/action-role";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

interface CDrawerProps extends DrawerProps {
  children?: ReactNode;
  onSave?: (values: any) => void;
  onCancel: () => void;
  loading?: boolean;
  form?: FormInstance;
  resetFormAfterClose?: boolean;
  mutation?: {
    mutate: UseMutationResult;
    invalidateQueries?: string[];
    onBeforeSubmit?: (v: any) => any;
    onAfterSubmit?: (v: any) => void;
  };
  hide?: {
    cancel?: boolean;
    save?: boolean;
  };
  className?: string;
}

const StyledDrawer = styled(Drawer)<{
  $action?: boolean;
  $divider?: boolean;
}>`
  font-family: Kanit;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .c-title {
    ${tw`text-[#276347] text-[16px] pl-5`}
  }
  .c-header {
    ${tw`h-[55px] flex items-center bg-[#F7F7F7] text-[#276347]`}
    .anticon {
      ${tw`text-[15px]`}
    }
  }
  .c-body {
    padding: 24px;
    height: ${({ $action }) =>
      $action ? "calc(100vh - 137px)" : "calc(100vh - 57px)"};
    overflow-y: auto;
  }
  .c-footer {
    height: ${({ $divider }) => ($divider ? "80px" : "0px")};
    border-top: ${({ $divider }) =>
      $divider ? "1px solid #00000029" : "1px solid #ffffff"};
  }
`;

const CDrawer: FC<CDrawerProps> = (props) => {
  const {
    form,
    title,
    onSave,
    onCancel,
    loading,
    children,
    mutation,
    className,
    width = "40vw",
    resetFormAfterClose = true,
    hide,
    ...rest
  } = props;
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { actionMenu } = useAppActionRole();

  const action = !hide?.save && !hide?.cancel;

  const _onSave = async () => {
    const { mutate, onBeforeSubmit, invalidateQueries, onAfterSubmit } =
      mutation || {};
    let values = await form?.validateFields();
    if (onSave) {
      return onSave(values);
    }
    if (onBeforeSubmit) {
      values = onBeforeSubmit(values);
    }
    mutate?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        onCancel();
        invalidateQueries?.forEach((k) => qClient.invalidateQueries([k]));
        onAfterSubmit?.(values);
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
        });
      },
    });
  };

  return (
    <StyledDrawer
      {...rest}
      forceRender
      width={width}
      closeIcon={null}
      $action={action}
      $divider={action}
      afterOpenChange={(e) => {
        if (!e && resetFormAfterClose) {
          form?.resetFields();
        }
        rest.afterOpenChange?.(e);
      }}
    >
      <Row className="c-header">
        <Col span={22}>
          <Typography.Title className="c-title">{title}</Typography.Title>
        </Col>
        <Col span={2} className="flex justify-end pr-5">
          <CloseOutlined className="cursor-pointer" onClick={onCancel} />
        </Col>
      </Row>
      <Row className={`c-body ${className}`}>{children}</Row>
      <Row
        gutter={20}
        align="middle"
        justify="center"
        hidden={!action}
        className="c-footer !m-0"
      >
        <Col span={6}>
          <CButton
            fullWidth
            color="gray"
            onClick={onCancel}
            style={{ height: 50 }}
          >
            {t("cancel")}
          </CButton>
        </Col>
        <Col span={6} hidden={!actionMenu?.action}>
          <CButton
            fullWidth
            loading={loading}
            onClick={_onSave}
            style={{ height: 50 }}
          >
            {t("save")}
          </CButton>
        </Col>
      </Row>
    </StyledDrawer>
  );
};

export default CDrawer;
