import { Pagination, TablePaginationConfig } from "antd";
import { useAppPageContainer } from "providers/app-page-container";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const StylePagination = styled(Pagination)`
  ${tw`flex justify-end py-5`}
  :where(.css-dev-only-do-not-override-sxw3l).ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #276347;
    border-color: #276347;
  }
  :where(.css-dev-only-do-not-override-sxw3l).ant-pagination
    .ant-pagination-item-active
    a {
    color: #ffffff;
  }
`;

export interface IPagination extends TablePaginationConfig {
  onChange?: (page: number, pageSize: number) => void;
  pageCount?: number;
}

export const PaginationPage: React.FC<IPagination> = ({
  onChange,
  ..._pagination
}) => {
  const { t } = useTranslation("message");
  const { current, pageCount } = _pagination;
  const { setSelectRowsKey } = useAppPageContainer();
  return (
    <StylePagination
      showTotal={(total, range) => {
        return (
          <div className="text-[13px] text-[#276347]">{`${t("show-items")} ${
            range[0]
          } ${t("to")} ${range[1]} ${t("out-of")} ${total} ${t("items")} ${t(
            "page"
          )} ${current} / ${pageCount}`}</div>
        );
      }}
      showSizeChanger
      size="small"
      onChange={(page: number, pageSize: number) => {
        setSelectRowsKey?.([]);
        onChange?.(page, pageSize);
      }}
      {..._pagination}
    />
  );
};
