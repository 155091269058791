import { Form, Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CInput, CInputProps } from "./c-input";
import { Flag } from "assets/images";
import { isPhoneNumber } from "tools/string";

const StyleInput = styled.div`
  width: 40px;
`;

export interface CInputPhoneNumberProps extends CInputProps {
  value?: any;
  onChange?: any;
  name: string;
  label?: string;
}

export const CInputPhoneNumber: React.FC<CInputPhoneNumberProps> = ({
  name,
  label,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label ? label : t("phone-number")}
      rules={[
        { required: true },
        {
          message: t("phone-number", { ns: "form" }),
          validator: (rule, value) => {
            if (value) {
              const phone = isPhoneNumber(value, "TH");
              if (value.length > 10 || !phone) {
                return Promise.reject(
                  new Error(t("phone-number", { ns: "form" }))
                );
              } else if (value.length === 10) {
                return Promise.resolve();
              }
            }
            return Promise.reject();
          },
        },
      ]}
    >
      <CInput
        {...props}
        phoneNumber
        placeholder={label ? label : t("phone-number")}
        addonBefore={
          <StyleInput className="center !bg-[#44955b]">
            <Image src={Flag} height={15} preview={false} />
          </StyleInput>
        }
      />
    </Form.Item>
  );
};
