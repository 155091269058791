import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import vehicle from "../../data/vehicle-brand.json";
import motorcycle from "../../data/motorcycle-brand.json";

export interface ISelectVehicleModelProps extends CSelectProps {
  brand?: string;
  typeVehicle?: string;
}

export const CSelectVehicleModel: FC<ISelectVehicleModelProps> = ({
  brand,
  typeVehicle,
  ...props
}) => {
  const { t } = useTranslation();
  const dataFilterModel = vehicle.find((val) => val.brand === brand)?.models;
  const dataMapKey = dataFilterModel?.map((v) => ({ models: v })) || [];

  const dataFilterModelType =
    motorcycle.find((val) => val.brand === brand)?.models || [];
  const dataMapKeyTypeVehicle =
    dataFilterModelType?.map((v) => ({
      models: v,
    })) || [];

  return (
    <CSelect
      showSearch
      placeholder={t("vehicle-model")}
      {...props}
      valueOption={{
        values:
          typeVehicle === "MOTORCYCLE"
            ? dataMapKeyTypeVehicle
            : typeVehicle === "CAR"
            ? dataMapKey
            : [],
        labelKey: "models",
        valueKey: "models",
      }}
    />
  );
};
