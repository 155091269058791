import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelect, CSelectProps } from ".";

export const CSelectStatusSellAndRent: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      {...props}
      placeholder={t("status")}
      valueOption={{
        values: [
          { value: "approve", label: t("approve") },
          { value: "pending", label: t("pending-approval") },
          { value: "cancel", label: t("cancel") },
          { value: "sale", label: t("on-sell") },
          { value: "rent", label: t("on-rent") },
          { value: "sale-rent", label: t("on-sell-and-rent") },
          { value: "sales-completed", label: t("sales-completed") },
          { value: "rent-completed", label: t("rent-completed") },
          { value: "sale-rent-completed", label: t("sale-rent-completed") },
        ],
        valueKey: "value",
        labelKey: "label",
      }}
    />
  );
};
