import { throwResponse, axiosProperty } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IParcelSettingStoragePlace,
  IParcelSettingStoragePlaceParams,
} from "./interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";

export const useGetParcelSettingStoragePlaces = (
  params?: IParcelSettingStoragePlaceParams
): UseQueryResult<IResponse<IParcelSettingStoragePlace[]>, Error> => {
  return useQuery(
    ["parcelSettingStoragePlace", "all", params],
    async () => {
      const res = await axiosProperty.get("/parcel-location", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetParcelSettingStoragePlaces = (
  params?: IParcelSettingStoragePlaceParams
): UseInfiniteQueryResult<IResponse<IParcelSettingStoragePlace[]>, Error> => {
  return useInfiniteQuery(
    ["parcelSettingStoragePlace", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/parcel-location", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetParcelSettingStoragePlace = (
  id?: string | number
): UseQueryResult<IParcelSettingStoragePlace, Error> => {
  return useQuery(
    ["parcelSettingStoragePlace", "all", id],
    async () => {
      const res = await axiosProperty.get(`/parcel-location/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostParcelSettingStoragePlace = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/parcel-location", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchParcelSettingStoragePlace = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/parcel-location/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingStoragePlace = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/parcel-location/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingStoragePlaceBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete("/parcel-location/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};




export const useExportExcelPropertyParcelLocation = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/parcel-storage-location", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelImportPropertyParcelLocation = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/parcel-location/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyParcelLocation = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post("/export-excel/template-parcel-location/", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
