import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IRepairCategoryCount,
  IRepairServiceApproveAppointment,
  IRepairServiceCategory,
  IRepairServiceCategoryParams,
  IRepairServiceEndJob,
  IRepairServiceSubCategory,
  IRepairServiceSubCategoryParams,
} from "./interface";

// category ******************************************************

export const useGetRepairServiceCategoryCount = (): UseQueryResult<
  IResponse<IRepairCategoryCount>,
  Error
> => {
  return useQuery(
    ["repairServiceCategoryCount", "count"],
    async () => {
      const res = await axiosProperty.get("/repairing-setting/count-category");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetRepairServiceCategories = (
  params?: IRepairServiceCategoryParams
): UseQueryResult<IResponse<IRepairServiceCategory[]>, Error> => {
  return useQuery(
    ["repairServiceCategory", "all", params],
    async () => {
      const res = await axiosProperty.get("/repairing-category", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useScrollGetRepairServiceCategories = (
  params?: IRepairServiceCategoryParams
): UseInfiniteQueryResult<IResponse<IRepairServiceCategory[]>, Error> => {
  return useInfiniteQuery(
    ["repairServiceCategory", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/repairing-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetRepairServiceCategory = (
  id?: number | string
): UseQueryResult<IRepairServiceCategory, Error> => {
  return useQuery(
    ["repairService", "id", id],
    async () => {
      const res = await axiosProperty.get(`/repairing-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostRepairServiceCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/repairing-category`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchRepairServiceCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/repairing-category/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteRepairServiceCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/repairing-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteRepairServiceCategoryBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/repairing-category/bulk`, {
      data,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

// sub category **************************************************

export const useGetRepairServiceSubCategories = (
  params?: IRepairServiceSubCategoryParams
): UseQueryResult<IResponse<IRepairServiceSubCategory[]>, Error> => {
  return useQuery(
    ["repairServiceSubCategory", "all", params],
    async () => {
      const res = await axiosProperty.get("/repairing-sub-category", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useScrollGetRepairServiceSubCategories = (
  params?: IRepairServiceSubCategoryParams
): UseInfiniteQueryResult<IResponse<IRepairServiceSubCategory[]>, Error> => {
  return useInfiniteQuery(
    ["repairServiceSubCategory", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/repairing-sub-category", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetRepairServiceSubCategory = (
  id?: number | string
): UseQueryResult<IRepairServiceSubCategory, Error> => {
  return useQuery(
    ["repairServiceSubCategory", "id", id],
    async () => {
      const res = await axiosProperty.get(`/repairing-sub-category/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostRepairServiceSubCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/repairing-sub-category`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchRepairServiceSubCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(
      `/repairing-sub-category/${id}`,
      data
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteRepairServiceSubCategory = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/repairing-sub-category/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteRepairServiceSubCategoryBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete(`/repairing-sub-category/bulk`, {
      data,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

// setting ********************************************************

export const useGetRepairServiceApproveAppointment = (): UseQueryResult<
  IResponse<IRepairServiceApproveAppointment[]>,
  Error
> => {
  return useQuery(
    ["repairServiceApproveAppointment", "all"],
    async () => {
      const res = await axiosProperty.get(
        "/repairing-setting/approve-appointment"
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostRepairServiceApproveAppointment = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data: any) => {
    console.log(data);
    const res = await axiosProperty.post(
      `/repairing-setting/approve-appointment`,
      data
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetRepairServiceEndJob = (): UseQueryResult<
  IResponse<IRepairServiceEndJob>,
  Error
> => {
  return useQuery(
    ["repairServiceEndJob", "all"],
    async () => {
      const res = await axiosProperty.get("/repairing-setting/end-job");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostRepairServiceEndJob = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axiosProperty.post(`/repairing-setting/end-job`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};


export const useExcelImportPropertyRepairServiceSetting = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/repair-category/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyRepairServiceSetting = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post("/export-excel/template-repairing-category/", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};


export const useExcelImportPropertyRepairServiceSettingSub = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/repair-sub-category/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyRepairServiceSettingSub = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post("/export-excel/template-repairing-sub-category/", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
