import { Form } from "antd";
import { TopBox } from "components";
import { useOverview } from "providers/overview";
import { useTranslation } from "react-i18next";
import { useScrollGetPropertyDashboardParcelType } from "service/dashboard/property/parcel";
import { LoadingDashboard } from "../..";

const ParcelType = () => {
  const { t } = useTranslation();
  const { findDate } = useOverview();

  const typeParcelDate = Form.useWatch("typeParcelDate");
  const date = findDate(typeParcelDate);

  const mutate = useScrollGetPropertyDashboardParcelType({ ...date });

  return (
    <LoadingDashboard loading={mutate.isFetching}>
      <TopBox
        translation
        mutate={mutate}
        keyTitle="name"
        nameDate="typeParcelDate"
        title={t("each-type-of-parcel")}
      />
    </LoadingDashboard>
  );
};

export default ParcelType;
