import { throwResponse, axiosProperty } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IParcelSettingCompany,
  IParcelSettingCompanyParams,
} from "./interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";

export const useGetParcelSettingCompanys = (
  params?: IParcelSettingCompanyParams
): UseQueryResult<IResponse<IParcelSettingCompany[]>, Error> => {
  return useQuery(
    ["parcelSettingCompany", "all", params],
    async () => {
      const res = await axiosProperty.get("/delivery-company", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetParcelSettingCompanies = (
  params?: IParcelSettingCompanyParams
): UseInfiniteQueryResult<IResponse<IParcelSettingCompany[]>, Error> => {
  return useInfiniteQuery(
    ["parcelSettingCompany", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/delivery-company", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }

      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetParcelSettingCompany = (
  id?: string | number
): UseQueryResult<IParcelSettingCompany, Error> => {
  return useQuery(
    ["parcelSettingCompany", "only", id],
    async () => {
      const res = await axiosProperty.get(`/delivery-company/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostParcelSettingCompany = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/delivery-company", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchParcelSettingCompany = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/delivery-company/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingCompany = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/delivery-company/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingCompanyBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete("/delivery-company/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelPropertyParcelCompany = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/parcel-company", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelImportPropertyParcelCompany = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/delivery-company/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyParcelCompany = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post("/export-excel/template-delivery-company/", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
