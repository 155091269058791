export const OverviewIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-overview">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="overview" clipPath="url(#a)">
        <g fill="#67696e" data-name="overview">
          <path d="M6.414 9.856H4.58a3.1 3.1 0 0 1-3.438-3.438V4.584A3.1 3.1 0 0 1 4.58 1.146h1.834a3.1 3.1 0 0 1 3.438 3.438v1.834a3.1 3.1 0 0 1-3.438 3.438ZM4.58 2.521c-1.449 0-2.063.614-2.063 2.063v1.834c0 1.449.614 2.063 2.063 2.063h1.834c1.449 0 2.063-.614 2.063-2.063V4.584c0-1.449-.614-2.063-2.063-2.063Z" />
          <path
            data-name="Vector"
            d="M17.416 9.856h-1.834a3.1 3.1 0 0 1-3.438-3.438V4.584a3.1 3.1 0 0 1 3.438-3.438h1.834a3.1 3.1 0 0 1 3.438 3.438v1.834a3.1 3.1 0 0 1-3.438 3.438Zm-1.834-7.335c-1.449 0-2.063.614-2.063 2.063v1.834c0 1.449.614 2.063 2.063 2.063h1.834c1.449 0 2.063-.614 2.063-2.063V4.584c0-1.449-.614-2.063-2.063-2.063ZM17.416 20.858h-1.834a3.1 3.1 0 0 1-3.438-3.438v-1.834a3.1 3.1 0 0 1 3.438-3.438h1.834a3.1 3.1 0 0 1 3.438 3.438v1.834a3.1 3.1 0 0 1-3.438 3.438Zm-1.834-7.335c-1.449 0-2.063.614-2.063 2.063v1.834c0 1.449.614 2.063 2.063 2.063h1.834c1.449 0 2.063-.614 2.063-2.063v-1.834c0-1.449-.614-2.063-2.063-2.063ZM6.414 20.858H4.58a3.1 3.1 0 0 1-3.438-3.438v-1.834a3.1 3.1 0 0 1 3.438-3.438h1.834a3.1 3.1 0 0 1 3.438 3.438v1.834a3.1 3.1 0 0 1-3.438 3.438ZM4.58 13.523c-1.449 0-2.063.614-2.063 2.063v1.834c0 1.449.614 2.063 2.063 2.063h1.834c1.449 0 2.063-.614 2.063-2.063v-1.834c0-1.449-.614-2.063-2.063-2.063Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const PropertyIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-property">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        fill="#67696e"
        data-name="vuesax/outline/home-2"
        clipPath="url(#clip-property)"
      >
        <path d="M16.31 20.854H5.693a4.555 4.555 0 0 1-4.547-4.556V9.504a5.044 5.044 0 0 1 1.76-3.585l4.94-3.855a4.766 4.766 0 0 1 5.4-.124l5.666 3.97a5.009 5.009 0 0 1 1.944 3.722v6.675a4.553 4.553 0 0 1-4.546 4.547ZM8.692 3.15 3.746 7.001a3.726 3.726 0 0 0-1.229 2.5v6.794a3.181 3.181 0 0 0 3.172 3.181H16.31a3.173 3.173 0 0 0 3.172-3.172V9.632a3.638 3.638 0 0 0-1.356-2.594L12.46 3.064a3.413 3.413 0 0 0-3.768.086Z" />
        <path
          data-name="Vector"
          d="M11.002 17.187a.693.693 0 0 1-.688-.688v-2.75a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v2.75a.693.693 0 0 1-.688.688Z"
        />
      </g>
    </svg>
  );
};

export const NewsAnnouncementIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-news_and_announcement">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        data-name="news and announcement"
        clipPath="url(#l(#clip-news_and_announcement)"
      >
        <g fill="#67696e" data-name="vuesax/outline/note-text">
          <path d="M7.331 5.272a.693.693 0 0 1-.688-.688v-2.75a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v2.75a.693.693 0 0 1-.688.688Z" />
          <path
            data-name="Vector"
            d="M14.666 5.272a.693.693 0 0 1-.688-.688v-2.75a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v2.75a.693.693 0 0 1-.688.688Z"
          />
          <path
            data-name="Vector"
            d="M14.665 20.858H7.331c-3.347 0-5.272-1.926-5.272-5.272V7.793c0-3.347 1.925-5.272 5.272-5.272h7.335c3.346 0 5.272 1.925 5.272 5.272v7.793c-.001 3.346-1.926 5.272-5.273 5.272ZM7.331 3.896c-2.622 0-3.9 1.274-3.9 3.9v7.793c0 2.622 1.274 3.9 3.9 3.9h7.335c2.622 0 3.9-1.274 3.9-3.9V7.793c0-2.622-1.274-3.9-3.9-3.9Z"
          />
          <path
            data-name="Vector"
            d="M14.665 10.773H7.331a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h7.334a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687ZM10.998 15.357H7.331a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h3.667a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const ActivitiesIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-activities">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="activities" clipPath="url(#clip-activities">
        <g fill="#67696e" data-name="vuesax/outline/graph">
          <path d="M16.796 11.69h-3.044c-2.283 0-3.438-1.293-3.438-3.851V5.208A3.645 3.645 0 0 1 11.396 2.2c.818-.605 1.989-.65 3.718-.146a7.248 7.248 0 0 1 4.836 4.831c.5 1.724.458 2.906-.147 3.713a3.624 3.624 0 0 1-3.007 1.092Zm-3.7-8.609a1.423 1.423 0 0 0-.862.22c-.358.266-.532.889-.532 1.907v2.64c0 2.054.788 2.475 2.063 2.475h3.04c1.009 0 1.632-.174 1.907-.532.321-.422.293-1.265-.064-2.5a5.935 5.935 0 0 0-3.9-3.9 5.822 5.822 0 0 0-1.656-.31Z" />
          <path
            data-name="Vector"
            d="M10.146 20.858a9.021 9.021 0 0 1-1.793-17.86.7.7 0 0 1 .816.532.7.7 0 0 1-.532.816 7.65 7.65 0 1 0 9.04 9 .687.687 0 0 1 1.348.266 9.032 9.032 0 0 1-8.879 7.246Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const SurveyIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-survey">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        fill="#67696e"
        data-name="vuesax/outline/task-square"
        clipPath="url(#clip-survey)"
      >
        <path d="M16.154 8.82h-4.812a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h4.812a.688.688 0 0 1 0 1.375Z" />
        <path
          data-name="Vector"
          d="M6.528 9.517a.68.68 0 0 1-.486-.2l-.687-.69a.687.687 0 1 1 .972-.972l.2.2 1.578-1.574a.687.687 0 0 1 .972.972L7.014 9.315a.687.687 0 0 1-.486.202ZM16.154 15.237h-4.812a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h4.812a.688.688 0 0 1 0 1.375ZM6.528 15.935a.68.68 0 0 1-.486-.2l-.687-.69a.687.687 0 0 1 .972-.972l.2.2 1.578-1.574a.687.687 0 0 1 .972.972l-2.063 2.062a.687.687 0 0 1-.486.202Z"
        />
        <path
          data-name="Vector"
          d="M13.752 20.858h-5.5c-4.979 0-7.106-2.127-7.106-7.106v-5.5c0-4.979 2.127-7.106 7.105-7.106h5.5c4.978 0 7.105 2.127 7.105 7.105v5.5c.002 4.98-2.125 7.107-7.104 7.107ZM8.251 2.521c-4.227 0-5.73 1.5-5.73 5.73v5.5c0 4.227 1.5 5.73 5.73 5.73h5.5c4.227 0 5.73-1.5 5.73-5.73v-5.5c0-4.227-1.5-5.73-5.73-5.73Z"
        />
      </g>
    </svg>
  );
};

export const UserManagementIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-user_management">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        fill="#67696e"
        data-name="vuesax/outline/profile-2user"
        clipPath="url(#clip-user_management"
      >
        <path d="M8.398 10.654h-.073a.5.5 0 0 0-.165 0 4.717 4.717 0 1 1 .266 0Zm-.147-8.133a3.38 3.38 0 0 0-.128 6.757 1.526 1.526 0 0 1 .293 0 3.381 3.381 0 0 0-.165-6.757Z" />
        <path
          data-name="Vector"
          d="M15.164 10.773a.254.254 0 0 1-.084-.009.726.726 0 0 1-.8-.605.668.668 0 0 1 .568-.761c.11-.009.229-.009.33-.009a2.523 2.523 0 0 0-.133-5.043.679.679 0 0 1-.688-.678.693.693 0 0 1 .688-.688 3.905 3.905 0 0 1 3.9 3.9 3.888 3.888 0 0 1-3.75 3.9ZM8.408 20.675a9.056 9.056 0 0 1-4.97-1.372 3.934 3.934 0 0 1-1.971-3.258 3.975 3.975 0 0 1 1.971-3.274 9.707 9.707 0 0 1 9.939 0 3.956 3.956 0 0 1 1.971 3.255 3.975 3.975 0 0 1-1.971 3.277 9.078 9.078 0 0 1-4.969 1.372Zm-4.209-6.748a2.636 2.636 0 0 0-1.357 2.127 2.63 2.63 0 0 0 1.357 2.108 8.285 8.285 0 0 0 8.417 0 2.636 2.636 0 0 0 1.357-2.127 2.63 2.63 0 0 0-1.357-2.109 8.328 8.328 0 0 0-8.417.001ZM16.815 19.025a.677.677 0 0 1-.669-.55.7.7 0 0 1 .532-.816 3.714 3.714 0 0 0 1.522-.669 1.794 1.794 0 0 0 .807-1.412 1.778 1.778 0 0 0-.8-1.4 3.839 3.839 0 0 0-1.5-.669.692.692 0 1 1 .3-1.352 5.117 5.117 0 0 1 2.035.917 3.153 3.153 0 0 1 1.339 2.5 3.193 3.193 0 0 1-1.349 2.515 4.966 4.966 0 0 1-2.072.917.429.429 0 0 1-.145.019Z"
        />
      </g>
    </svg>
  );
};

export const ResidentsIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-residents">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="2 residents" clipPath="url(#clip-residents)">
        <g data-name="vuesax/outline/3dcube">
          <g data-name="3dcube" fill="#67696e">
            <path d="M11.002 11.231a2.529 2.529 0 0 1-1.174-.293L3.86 7.719a1.74 1.74 0 0 1 0-3.026L9.829 1.48a2.459 2.459 0 0 1 2.338 0l5.969 3.218a1.74 1.74 0 0 1 0 3.026l-5.969 3.213a2.363 2.363 0 0 1-1.165.294Zm0-8.682a1.086 1.086 0 0 0-.523.138L4.52 5.895a.343.343 0 0 0-.165.312.323.323 0 0 0 .165.3l5.969 3.22a1.092 1.092 0 0 0 1.036 0l5.969-3.218a.361.361 0 0 0 .165-.3.323.323 0 0 0-.165-.3l-5.969-3.223a1.086 1.086 0 0 0-.523-.138Z" />
            <path
              data-name="Vector"
              d="M8.967 20.831a1.722 1.722 0 0 1-.761-.183l-5.56-2.769a2.722 2.722 0 0 1-1.5-2.439v-5.248a1.7 1.7 0 0 1 2.466-1.518l5.556 2.768a2.712 2.712 0 0 1 1.5 2.439v5.244a1.692 1.692 0 0 1-.807 1.449 1.672 1.672 0 0 1-.894.257ZM2.851 9.865a.4.4 0 0 0-.174.046.328.328 0 0 0-.156.284v5.245a1.347 1.347 0 0 0 .743 1.21l5.547 2.778a.339.339 0 0 0 .321-.009.328.328 0 0 0 .156-.284v-5.243a1.347 1.347 0 0 0-.742-1.212L2.998 9.902a.314.314 0 0 0-.147-.037ZM13.037 20.83a1.672 1.672 0 0 1-.9-.257 1.692 1.692 0 0 1-.805-1.448v-5.243a2.722 2.722 0 0 1 1.5-2.44l5.55-2.778a1.7 1.7 0 0 1 2.467 1.518v5.248a2.722 2.722 0 0 1-1.5 2.439l-5.551 2.778a1.606 1.606 0 0 1-.761.183Zm6.116-10.965a.314.314 0 0 0-.147.037l-5.547 2.78a1.337 1.337 0 0 0-.743 1.21v5.244a.328.328 0 0 0 .156.284.315.315 0 0 0 .321.009l5.547-2.78a1.347 1.347 0 0 0 .743-1.21v-5.244a.328.328 0 0 0-.156-.284.4.4 0 0 0-.174-.046Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PhoneBookIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-call-calling">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        fill="#67696e"
        data-name="vuesax/outline/call-calling"
        clipPath="url(#clip-call-calling)"
      >
        <path d="M15.996 20.854a8.386 8.386 0 0 1-3.254-.724 17.211 17.211 0 0 1-3.291-1.884 28.613 28.613 0 0 1-3.089-2.631 27.386 27.386 0 0 1-2.631-3.08 17.107 17.107 0 0 1-1.87-3.267 8.487 8.487 0 0 1-.715-3.273 5.524 5.524 0 0 1 .376-2.026 4.9 4.9 0 0 1 1.224-1.778 3.329 3.329 0 0 1 2.378-1.045 2.479 2.479 0 0 1 1.036.229 2.1 2.1 0 0 1 .88.742l2.127 3a3.551 3.551 0 0 1 .44.779 2.059 2.059 0 0 1 .183.816 1.945 1.945 0 0 1-.293 1.008 3.667 3.667 0 0 1-.614.779l-.624.647a.447.447 0 0 0 .027.064 7.049 7.049 0 0 0 .752 1.017c.449.513.871.981 1.293 1.412.541.532.99.953 1.412 1.3a5.97 5.97 0 0 0 1.063.761l-.018.046.669-.66a3.424 3.424 0 0 1 .825-.632 1.935 1.935 0 0 1 1.788-.1 4.173 4.173 0 0 1 .77.431l3.043 2.161a1.97 1.97 0 0 1 .733.871 2.653 2.653 0 0 1 .2.99 3.057 3.057 0 0 1-.293 1.293 4.269 4.269 0 0 1-.733 1.1 4.7 4.7 0 0 1-1.751 1.256 5.412 5.412 0 0 1-2.043.398ZM5.124 2.521a2.009 2.009 0 0 0-1.421.66 3.517 3.517 0 0 0-.9 1.293 4.011 4.011 0 0 0-.284 1.522 7.067 7.067 0 0 0 .607 2.731 15.3 15.3 0 0 0 1.72 2.988A25.361 25.361 0 0 0 7.33 14.63a25.81 25.81 0 0 0 2.924 2.493 14.894 14.894 0 0 0 3.016 1.733 5.467 5.467 0 0 0 4.244.321 3.384 3.384 0 0 0 1.256-.907 2.973 2.973 0 0 0 .513-.77 1.624 1.624 0 0 0 .165-.706 1.094 1.094 0 0 0-.1-.458.694.694 0 0 0-.257-.275l-3.043-2.163a2.628 2.628 0 0 0-.5-.284.482.482 0 0 0-.6.028 2.09 2.09 0 0 0-.532.412l-.7.688a1.306 1.306 0 0 1-1.329.275l-.241-.112a7.814 7.814 0 0 1-1.3-.926c-.44-.376-.917-.816-1.494-1.384-.449-.458-.9-.944-1.366-1.485a7.821 7.821 0 0 1-.94-1.292l-.107-.272a1.711 1.711 0 0 1-.073-.458 1.176 1.176 0 0 1 .348-.853l.688-.715a2.654 2.654 0 0 0 .412-.513.588.588 0 0 0 .1-.312.852.852 0 0 0-.068-.296 2.63 2.63 0 0 0-.3-.486L5.922 2.906a.845.845 0 0 0-.339-.284 1.148 1.148 0 0 0-.459-.101Zm7.663 11.238-.147.623.248-.642a.113.113 0 0 0-.1.019Z" />
        <path
          data-name="Vector"
          d="M16.963 8.937a.693.693 0 0 1-.692-.683 3.058 3.058 0 0 0-.88-1.6 2.478 2.478 0 0 0-1.64-.925.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688 3.748 3.748 0 0 1 2.64 1.347 4.24 4.24 0 0 1 1.255 2.553.693.693 0 0 1-.683.683ZM20.163 8.938a.693.693 0 0 1-.683-.692 5.731 5.731 0 0 0-5.729-5.725.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688 7.116 7.116 0 0 1 7.1 7.1.693.693 0 0 1-.688.692Z"
        />
      </g>
    </svg>
  );
};

export const GalleryIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-gallery">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        fill="#67696e"
        data-name="vuesax/outline/gallery"
        clipPath="url(#clip-gallery)"
      >
        <path d="M13.746 20.854h-5.5c-4.978 0-7.1-2.127-7.1-7.1V8.246c0-4.973 2.127-7.1 7.1-7.1h5.5c4.977 0 7.1 2.127 7.1 7.1v5.5c.008 4.982-2.118 7.108-7.1 7.108Zm-5.5-18.333c-4.226 0-5.729 1.5-5.729 5.729v5.5c0 4.226 1.5 5.729 5.729 5.729h5.5c4.226 0 5.729-1.5 5.729-5.729V8.246c0-4.226-1.5-5.729-5.729-5.729Z" />
        <path
          data-name="Vector"
          d="M8.25 9.855a2.521 2.521 0 1 1 2.521-2.521A2.519 2.519 0 0 1 8.25 9.855Zm0-3.667a1.146 1.146 0 1 0 1.146 1.146A1.146 1.146 0 0 0 8.25 6.188ZM2.448 18.058a.684.684 0 0 1-.376-1.256l4.519-3.034a2.739 2.739 0 0 1 3.254.174l.3.266a1.351 1.351 0 0 0 1.687 0l3.816-3.272a2.737 2.737 0 0 1 3.483 0l1.494 1.283a.69.69 0 1 1-.9 1.045l-1.492-1.283a1.364 1.364 0 0 0-1.7 0l-3.809 3.272a2.737 2.737 0 0 1-3.483 0l-.3-.266a1.363 1.363 0 0 0-1.589-.077l-4.519 3.038a.745.745 0 0 1-.385.11Z"
        />
      </g>
    </svg>
  );
};

export const SettingIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-setting">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g fill="#67696e" clipPath="url(#clip-setting)">
        <path d="M11.002 14.44a3.438 3.438 0 1 1 3.438-3.438 3.439 3.439 0 0 1-3.438 3.438Zm0-5.5a2.063 2.063 0 1 0 2.062 2.062 2.066 2.066 0 0 0-2.062-2.063Z" />
        <path
          data-name="Vector"
          d="M13.946 20.344a2.493 2.493 0 0 1-.578-.073 2.251 2.251 0 0 1-1.348-1.018l-.11-.183c-.541-.935-1.284-.935-1.824 0l-.1.174a2.2 2.2 0 0 1-1.35 1.027 2.146 2.146 0 0 1-1.678-.229l-1.577-.908a2.432 2.432 0 0 1-.9-3.319 1.227 1.227 0 0 0 .186-1.154 1.222 1.222 0 0 0-1.091-.422 2.434 2.434 0 0 1-2.43-2.431v-1.613a2.434 2.434 0 0 1 2.43-2.434 1.222 1.222 0 0 0 1.091-.422 1.2 1.2 0 0 0-.183-1.155 2.44 2.44 0 0 1-.238-1.839 2.365 2.365 0 0 1 1.136-1.476l1.586-.908a2.221 2.221 0 0 1 3.026.8l.11.183c.541.935 1.284.935 1.824 0l.1-.174a2.213 2.213 0 0 1 3.035-.8l1.577.908a2.432 2.432 0 0 1 .9 3.319 1.227 1.227 0 0 0-.183 1.155 1.222 1.222 0 0 0 1.091.422 2.434 2.434 0 0 1 2.43 2.43v1.614a2.434 2.434 0 0 1-2.43 2.43 1.222 1.222 0 0 0-1.091.422 1.2 1.2 0 0 0 .183 1.155 2.4 2.4 0 0 1 .238 1.843 2.365 2.365 0 0 1-1.137 1.476l-1.586.908a2.281 2.281 0 0 1-1.109.292Zm-2.944-3.392a2.5 2.5 0 0 1 2.1 1.421l.1.174a.841.841 0 0 0 1.137.3l1.586-.917a1.06 1.06 0 0 0 .5-.642 1.048 1.048 0 0 0-.1-.8 2.517 2.517 0 0 1-.183-2.53 2.5 2.5 0 0 1 2.283-1.109 1.049 1.049 0 0 0 1.054-1.054v-1.61a1.054 1.054 0 0 0-1.06-1.054 2.5 2.5 0 0 1-2.283-1.109 2.517 2.517 0 0 1 .183-2.53 1.048 1.048 0 0 0 .1-.8 1.072 1.072 0 0 0-.486-.642l-1.587-.906a.844.844 0 0 0-1.155.3l-.1.174a2.5 2.5 0 0 1-2.1 1.421 2.5 2.5 0 0 1-2.1-1.421l-.1-.183a.842.842 0 0 0-1.137-.293l-1.585.919a1.06 1.06 0 0 0-.5.642 1.048 1.048 0 0 0 .1.8 2.517 2.517 0 0 1 .183 2.53A2.5 2.5 0 0 1 3.576 9.14a1.049 1.049 0 0 0-1.055 1.055v1.614a1.054 1.054 0 0 0 1.055 1.052 2.5 2.5 0 0 1 2.283 1.109 2.517 2.517 0 0 1-.183 2.53 1.048 1.048 0 0 0-.1.8 1.072 1.072 0 0 0 .486.642l1.586.908a.811.811 0 0 0 .633.092.87.87 0 0 0 .523-.394l.1-.174a2.516 2.516 0 0 1 2.098-1.422Z"
        />
      </g>
    </svg>
  );
};

export const ChatIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-message">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="message" clipPath="url(#clip-message)">
        <g fill="#67696e" data-name="vuesax/outline/message">
          <path d="M10.998 20.913a2.077 2.077 0 0 1-1.65-.88l-1.376-1.834a.433.433 0 0 0-.183-.092h-.458c-3.824 0-6.189-1.036-6.189-6.188V7.335c0-4.053 2.136-6.189 6.189-6.189h7.335c4.052 0 6.189 2.136 6.189 6.189v4.584c0 4.052-2.136 6.189-6.189 6.189h-.458a.239.239 0 0 0-.183.092l-1.375 1.834a2.077 2.077 0 0 1-1.652.879ZM7.331 2.521c-3.282 0-4.813 1.531-4.813 4.813v4.584c0 4.144 1.421 4.813 4.813 4.813h.458a1.72 1.72 0 0 1 1.284.642l1.375 1.834a.637.637 0 0 0 1.1 0l1.375-1.834a1.607 1.607 0 0 1 1.284-.642h.458c3.282 0 4.813-1.531 4.813-4.813V7.335c0-3.282-1.531-4.813-4.813-4.813Z" />
          <path
            data-name="Vector"
            d="M10.998 11.002a.917.917 0 1 1 .917-.917.914.914 0 0 1-.917.917ZM14.665 11.002a.917.917 0 1 1 .917-.917.914.914 0 0 1-.917.917ZM7.331 11.002a.917.917 0 1 1 .917-.917.914.914 0 0 1-.917.917Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const HouseCareServiceIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-house_care_service">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g
        data-name="house_care_service"
        clipPath="url(#clip-house_care_service)"
      >
        <g fill="#67696e" data-name="vuesax/outline/setting">
          <path d="M10.998 20.749a3.479 3.479 0 0 1-1.724-.422L4.232 17.42c-2.054-1.385-2.182-1.596-2.182-3.771v-5.3c0-2.17.119-2.381 2.136-3.747l5.079-2.934a3.732 3.732 0 0 1 3.438 0l5.061 2.916c2.054 1.384 2.186 1.595 2.186 3.765v5.29c0 2.173-.119 2.384-2.136 3.75l-5.079 2.934a3.539 3.539 0 0 1-1.737.426Zm0-18.117a2.1 2.1 0 0 0-1.027.229L4.929 5.776C3.434 6.784 3.434 6.784 3.434 8.349v5.29c0 1.568 0 1.568 1.531 2.6l5.006 2.888a2.392 2.392 0 0 0 2.063 0l5.043-2.916c1.485-1.009 1.485-1.009 1.485-2.576V8.349c0-1.568 0-1.568-1.531-2.6L12.025 2.86a2.1 2.1 0 0 0-1.027-.229Z" />
          <path
            data-name="Vector"
            d="M10.998 14.44a3.438 3.438 0 1 1 3.438-3.438 3.439 3.439 0 0 1-3.438 3.438Zm0-5.5a2.063 2.063 0 1 0 2.062 2.062 2.066 2.066 0 0 0-2.062-2.063Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const ParcelIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-parcel">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="parcel" clipPath="url(#clip-parcel)">
        <g fill="#67696e" data-name="vuesax/outline/box">
          <path d="M11.002 12.194a.684.684 0 0 1-.348-.092L2.558 7.418a.7.7 0 0 1-.247-.945.689.689 0 0 1 .944-.247l7.747 4.483 7.7-4.456a.7.7 0 0 1 .946.248.7.7 0 0 1-.248.944l-8.04 4.658a.862.862 0 0 1-.358.091Z" />
          <path
            data-name="Vector"
            d="M11.002 20.5a.693.693 0 0 1-.688-.691v-8.312a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v8.312a.693.693 0 0 1-.688.691Z"
          />
          <path
            data-name="Vector"
            d="M11.004 20.858a4.71 4.71 0 0 1-2.237-.523l-4.9-2.723a4.99 4.99 0 0 1-2.363-4.025v-5.18a5 5 0 0 1 2.365-4.025l4.896-2.713a5.049 5.049 0 0 1 4.474 0l4.9 2.723a4.99 4.99 0 0 1 2.365 4.024v5.18a5 5 0 0 1-2.365 4.025l-4.9 2.714a4.71 4.71 0 0 1-2.235.523Zm0-18.337a3.379 3.379 0 0 0-1.568.348l-4.9 2.723a3.669 3.669 0 0 0-1.657 2.815v5.18a3.7 3.7 0 0 0 1.659 2.824l4.9 2.723a3.68 3.68 0 0 0 3.136 0l4.9-2.723a3.685 3.685 0 0 0 1.659-2.824v-5.18a3.7 3.7 0 0 0-1.659-2.824l-4.9-2.723a3.5 3.5 0 0 0-1.57-.339Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const FacilityIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-facility">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="facility" clipPath="url(#clip-facility)">
        <g fill="#67696e" data-name="vuesax/outline/reserve">
          <path d="M17.393 20.857H4.557c-3.438 0-3.438-1.907-3.438-3.438v-.921a1.612 1.612 0 0 1 1.6-1.6h16.5a1.612 1.612 0 0 1 1.6 1.6v.917c.012 1.535.012 3.442-3.426 3.442ZM2.719 16.273a.227.227 0 0 0-.229.229v.917c0 1.5 0 2.063 2.063 2.063h12.84c2.063 0 2.063-.559 2.063-2.063v-.921a.227.227 0 0 0-.229-.229Z" />
          <path
            data-name="Vector"
            d="M18.996 16.274h-16a.693.693 0 0 1-.686-.687v-3.668A8.052 8.052 0 0 1 8.645 4.08a7.86 7.86 0 0 1 1.687-.183h1.33a7.882 7.882 0 0 1 1.7.183 8.059 8.059 0 0 1 6.326 7.839v3.668a.687.687 0 0 1-.692.687ZM3.685 14.897H18.31v-2.978a6.685 6.685 0 0 0-5.244-6.5 6.387 6.387 0 0 0-1.4-.147h-1.334a6.363 6.363 0 0 0-1.394.147 6.678 6.678 0 0 0-5.253 6.5Z"
          />
          <path
            data-name="Vector"
            d="M8.792 5.437a.685.685 0 0 1-.66-.5 2.984 2.984 0 0 1 2.87-3.791 2.984 2.984 0 0 1 2.87 3.787.674.674 0 0 1-.807.486 6.387 6.387 0 0 0-1.4-.147h-1.333a6.363 6.363 0 0 0-1.394.147.713.713 0 0 1-.146.018Zm1.54-1.541h1.33a7.881 7.881 0 0 1 .935.055 1.6 1.6 0 0 0-3.191 0 7.842 7.842 0 0 1 .926-.055ZM13.753 10.773H8.252a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h5.5a.693.693 0 0 1 .688.688.693.693 0 0 1-.687.687Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const VehicleIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-vehicle">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="vehicle" clipPath="url(#clip-vehicle)">
        <g fill="#67696e" data-name="vuesax/outline/smart-car">
          <path d="M14.211 13.299h-11a.665.665 0 0 1-.532-.257.686.686 0 0 1-.138-.578l.783-3.717a2.677 2.677 0 0 1 2.975-2.448h4.832a2.677 2.677 0 0 1 2.98 2.448l.779 3.713a.69.69 0 0 1-.679.839Zm-10.158-1.38h9.306l-.605-2.888c-.193-.926-.422-1.357-1.632-1.357H6.29c-1.21 0-1.439.431-1.632 1.357Z" />
          <path
            data-name="Vector"
            d="M14.183 20.858H12.89a1.481 1.481 0 0 1-1.623-1.174l-.138-.413c-.165-.468-.165-.468-.651-.468H6.955c-.5 0-.5.018-.651.468l-.138.413a1.505 1.505 0 0 1-1.628 1.183H3.245a2.1 2.1 0 0 1-1.558-.687 2.049 2.049 0 0 1-.523-1.568l.385-4.193c.119-1.3.541-2.5 2.778-2.5h8.774c2.237 0 2.659 1.2 2.778 2.5l.385 4.19a2.061 2.061 0 0 1-.523 1.568 2.129 2.129 0 0 1-1.558.681Zm-1.528-1.394a1.624 1.624 0 0 0 .238.018h1.293a.709.709 0 0 0 .541-.238.667.667 0 0 0 .174-.513l-.385-4.19c-.083-.862-.119-1.256-1.412-1.256H4.327c-1.293 0-1.329.394-1.412 1.256l-.385 4.19a.655.655 0 0 0 .174.513.709.709 0 0 0 .541.238h1.293a.861.861 0 0 0 .266-.028c0-.009.037-.119.064-.2l.138-.413a1.764 1.764 0 0 1 1.962-1.412h3.52a1.747 1.747 0 0 1 1.953 1.4l.147.422a.573.573 0 0 0 .067.213ZM3.209 11.231h-.687a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h.688a.693.693 0 0 1 .688.688.693.693 0 0 1-.689.687ZM14.898 11.231h-.687a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h.688a.693.693 0 0 1 .688.688.693.693 0 0 1-.689.687Z"
          />
          <path
            data-name="Vector"
            d="M6.647 16.044H4.585a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h2.062a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687ZM12.835 16.044h-2.062a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h2.062a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687ZM17.154 8.7a.736.736 0 0 1-.275-.055.7.7 0 0 1-.358-.907 1.6 1.6 0 0 0-.248-1.449 1.564 1.564 0 0 0-1.252-.788h-.012a.691.691 0 0 1-.688-.676.693.693 0 0 1 .678-.7 2.888 2.888 0 0 1 2.412 1.394 2.95 2.95 0 0 1 .367 2.76.662.662 0 0 1-.624.421Z"
          />
          <path
            data-name="Vector"
            d="M20.006 9.608a.635.635 0 0 1-.183-.027.683.683 0 0 1-.477-.843 4.932 4.932 0 0 0-.78-3.981 4.943 4.943 0 0 0-3.393-2.236.684.684 0 1 1 .166-1.357 6.331 6.331 0 0 1 4.364 2.842 6.345 6.345 0 0 1 .963 5.116.691.691 0 0 1-.66.486Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const ComplaintIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-complaint">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="complaint" clipPath="url(#clip-complaint)">
        <g fill="#67696e" data-name="vuesax/outline/message-2">
          <path d="M14.21 10.314H7.793a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h6.417a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687Z" />
          <path
            data-name="Vector"
            d="M14.669 20.463a1.629 1.629 0 0 1-.889-.266l-3.906-2.6H6.418a4.99 4.99 0 0 1-5.272-5.275v-5.5a4.99 4.99 0 0 1 5.272-5.273h9.168a4.99 4.99 0 0 1 5.272 5.272v5.5a4.944 4.944 0 0 1-4.584 5.235v1.3a1.585 1.585 0 0 1-.843 1.412 1.617 1.617 0 0 1-.762.195ZM6.418 2.915a3.653 3.653 0 0 0-3.9 3.9v5.5a3.653 3.653 0 0 0 3.9 3.9h3.667a.659.659 0 0 1 .385.119l4.08 2.714a.231.231 0 0 0 .358-.193v-1.958a.693.693 0 0 1 .688-.688 3.653 3.653 0 0 0 3.9-3.9v-5.5a3.653 3.653 0 0 0-3.9-3.9Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const UnitIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-unit">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="unit" clipPath="url(#clip-unit)">
        <g data-name="vuesax/outline/3dcube">
          <g data-name="3dcube" fill="#67696e">
            <path d="M11.002 11.231a2.529 2.529 0 0 1-1.174-.293L3.86 7.719a1.74 1.74 0 0 1 0-3.026L9.829 1.48a2.459 2.459 0 0 1 2.338 0l5.969 3.218a1.74 1.74 0 0 1 0 3.026l-5.969 3.213a2.363 2.363 0 0 1-1.165.294Zm0-8.682a1.086 1.086 0 0 0-.523.138L4.52 5.895a.343.343 0 0 0-.165.312.323.323 0 0 0 .165.3l5.969 3.22a1.092 1.092 0 0 0 1.036 0l5.969-3.218a.361.361 0 0 0 .165-.3.323.323 0 0 0-.165-.3l-5.969-3.223a1.086 1.086 0 0 0-.523-.138Z" />
            <path
              data-name="Vector"
              d="M8.967 20.831a1.722 1.722 0 0 1-.761-.183l-5.56-2.769a2.722 2.722 0 0 1-1.5-2.439v-5.248a1.7 1.7 0 0 1 2.466-1.518l5.556 2.768a2.712 2.712 0 0 1 1.5 2.439v5.244a1.692 1.692 0 0 1-.807 1.449 1.672 1.672 0 0 1-.894.257ZM2.851 9.865a.4.4 0 0 0-.174.046.328.328 0 0 0-.156.284v5.245a1.347 1.347 0 0 0 .743 1.21l5.547 2.778a.339.339 0 0 0 .321-.009.328.328 0 0 0 .156-.284v-5.243a1.347 1.347 0 0 0-.742-1.212L2.998 9.902a.314.314 0 0 0-.147-.037ZM13.037 20.83a1.672 1.672 0 0 1-.9-.257 1.692 1.692 0 0 1-.805-1.448v-5.243a2.722 2.722 0 0 1 1.5-2.44l5.55-2.778a1.7 1.7 0 0 1 2.467 1.518v5.248a2.722 2.722 0 0 1-1.5 2.439l-5.551 2.778a1.606 1.606 0 0 1-.761.183Zm6.116-10.965a.314.314 0 0 0-.147.037l-5.547 2.78a1.337 1.337 0 0 0-.743 1.21v5.244a.328.328 0 0 0 .156.284.315.315 0 0 0 .321.009l5.547-2.78a1.347 1.347 0 0 0 .743-1.21v-5.244a.328.328 0 0 0-.156-.284.4.4 0 0 0-.174-.046Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SaleAndRentIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-sale_and_rent">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="sale and rent" clipPath="url(#clip-sale_and_rent)">
        <g fill="#67696e" data-name="vuesax/outline/tag">
          <path d="M11.066 19.652a5.054 5.054 0 0 1-3.576-1.476l-4.153-4.154a5.087 5.087 0 0 1-1.476-3.823l.22-4.584a4.224 4.224 0 0 1 4.034-4.034l4.584-.224a5.122 5.122 0 0 1 3.824 1.48l4.153 4.153a5.074 5.074 0 0 1 0 7.16l-4.025 4.025a5.075 5.075 0 0 1-3.585 1.477Zm-6.757-6.61 4.153 4.153a3.674 3.674 0 0 0 5.208 0l4.025-4.025a3.674 3.674 0 0 0 0-5.208l-4.153-4.153a3.672 3.672 0 0 0-2.778-1.073l-4.585.221A2.836 2.836 0 0 0 3.447 5.67l-.22 4.587a3.721 3.721 0 0 0 1.082 2.784Z" />
          <path
            data-name="Vector"
            d="M8.71 11.189a2.98 2.98 0 1 1 2.98-2.98 2.985 2.985 0 0 1-2.98 2.98Zm0-4.584a1.6 1.6 0 1 0 1.6 1.6 1.612 1.612 0 0 0-1.6-1.6Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const CalendarIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-calendar">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="calendar" clipPath="url(#clip-calendar)">
        <g fill="#67696e" data-name="vuesax/outline/calendar-2">
          <path d="M7.334 5.271a.693.693 0 0 1-.688-.687v-2.75a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v2.75a.693.693 0 0 1-.688.687Z" />
          <path
            data-name="Vector"
            d="M14.667 5.271a.693.693 0 0 1-.688-.687v-2.75a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v2.75a.693.693 0 0 1-.688.687ZM18.792 9.02H3.209a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h15.583a.693.693 0 0 1 .688.688.693.693 0 0 1-.688.687Z"
          />
          <path
            data-name="Vector"
            d="M14.663 20.854H7.334c-3.346 0-5.271-1.925-5.271-5.27V7.792c0-3.346 1.925-5.271 5.271-5.271h7.329c3.346 0 5.271 1.925 5.271 5.271v7.792c.004 3.345-1.921 5.27-5.271 5.27ZM7.334 3.896c-2.622 0-3.9 1.274-3.9 3.9v7.792c0 2.622 1.274 3.9 3.9 3.9h7.329c2.622 0 3.9-1.274 3.9-3.9V7.792c0-2.622-1.274-3.9-3.9-3.9Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const VotingIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-voting">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="voting" clipPath="url(#clip-voting)">
        <g fill="#67696e" data-name="vuesax/outline/chart-2">
          <path d="M13.748 20.854h-5.5c-4.979 0-7.106-2.127-7.106-7.106v-5.5c0-4.979 2.127-7.106 7.105-7.106h5.5c4.978 0 7.105 2.127 7.105 7.105v5.5c.002 4.98-2.125 7.107-7.104 7.107ZM8.247 2.517c-4.227 0-5.73 1.5-5.73 5.73v5.5c0 4.227 1.5 5.73 5.73 5.73h5.5c4.227 0 5.73-1.5 5.73-5.73v-5.5c0-4.227-1.5-5.73-5.73-5.73Z" />
          <path
            data-name="Vector"
            d="M14.207 17.645a2.52 2.52 0 0 1-2.521-2.521V6.872a2.522 2.522 0 0 1 5.043 0v8.251a2.52 2.52 0 0 1-2.522 2.522Zm0-11.919a1.146 1.146 0 0 0-1.146 1.146v8.251a1.146 1.146 0 1 0 2.292 0V6.872a1.146 1.146 0 0 0-1.146-1.146ZM7.789 17.645a2.52 2.52 0 0 1-2.521-2.521v-3.209a2.522 2.522 0 0 1 5.043 0v3.209a2.52 2.52 0 0 1-2.522 2.521Zm0-6.876a1.146 1.146 0 0 0-1.146 1.146v3.209a1.146 1.146 0 1 0 2.292 0v-3.209a1.146 1.146 0 0 0-1.146-1.146Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const RoutineIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-routine">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="routine" clipPath="url(#clip-routine)">
        <g fill="#67696e" data-name="vuesax/outline/folder-open">
          <path d="M16.766 20.854H5.233c-3.116 0-3.281-1.714-3.418-3.098l-.367-4.593a3.476 3.476 0 0 1 .743-2.493 3.428 3.428 0 0 1 2.676-1.274h12.266a3.425 3.425 0 0 1 2.63 1.228l.156.211a3.38 3.38 0 0 1 .632 2.338l-.367 4.574c-.137 1.393-.302 3.107-3.418 3.107ZM4.867 10.771a2.04 2.04 0 0 0-1.586.752l-.064.064a2.06 2.06 0 0 0-.394 1.457l.367 4.593c.128 1.338.183 1.843 2.044 1.843h11.532c1.87 0 1.916-.5 2.044-1.852l.367-4.592a2 2 0 0 0-.458-1.5l-.092-.11a2.042 2.042 0 0 0-1.5-.651Z" />
          <path
            data-name="Vector"
            d="M18.792 11.201a.693.693 0 0 1-.688-.687V8.873c0-2.732-.477-3.208-3.208-3.208h-2.337a1.88 1.88 0 0 1-1.788-.892L9.589 3.208a1.213 1.213 0 0 0-1.32-.66H7.104c-2.732 0-3.208.477-3.208 3.208v4.717a.693.693 0 0 1-.687.688.693.693 0 0 1-.688-.688V5.756c0-3.51 1.073-4.583 4.583-4.583h1.174a2.5 2.5 0 0 1 2.42 1.21l1.173 1.558c.248.33.266.348.7.348h2.337c3.511 0 4.583 1.073 4.583 4.583v1.642a.707.707 0 0 1-.699.687ZM13.357 16.271H8.645a.693.693 0 0 1-.688-.687.693.693 0 0 1 .688-.688h4.712a.688.688 0 0 1 0 1.375Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const UnRoutineIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-unroutine">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="unroutine" clipPath="url(#clip-unroutine)">
        <g fill="#67696e" data-name="vuesax/outline/folder-cross">
          <path d="M12.659 15.107a.68.68 0 0 1-.486-.2L8.929 11.66a.687.687 0 0 1 .971-.971l3.245 3.244a.692.692 0 0 1 0 .972.72.72 0 0 1-.486.202Z" />
          <path
            data-name="Vector"
            d="M9.377 15.144a.68.68 0 0 1-.486-.2.692.692 0 0 1 0-.972l3.245-3.246a.687.687 0 0 1 .972.972l-3.245 3.244a.7.7 0 0 1-.486.202Z"
          />
          <path
            data-name="Vector"
            d="M15.584 20.854H6.417c-4.043 0-5.271-1.228-5.271-5.27V6.417c0-4.043 1.228-5.271 5.271-5.271h1.375a2.835 2.835 0 0 1 2.754 1.375l1.375 1.833c.3.4.348.458.917.458h2.75c4.042 0 5.271 1.228 5.271 5.271v5.5c-.005 4.043-1.233 5.271-5.275 5.271ZM6.417 2.521c-3.273 0-3.9.623-3.9 3.9v9.167c0 3.273.623 3.9 3.9 3.9h9.167c3.273 0 3.9-.623 3.9-3.9v-5.5c0-3.272-.623-3.9-3.9-3.9h-2.75a2.1 2.1 0 0 1-2.017-1.009L9.446 3.346a1.535 1.535 0 0 0-1.65-.825Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const WorkSpaceIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-work_space">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="work_space" clipPath="url(#clip-work_space)">
        <g fill="#67696e" data-name="vuesax/outline/path">
          <path d="M15.565 12.1a.7.7 0 0 1-.486-.2l-4.951-4.961a.68.68 0 0 1-.092-.853l1.55-2.447a2.478 2.478 0 0 1 1.842-1.183 3.027 3.027 0 0 1 2.42.935l2.78 2.778a2.971 2.971 0 0 1 .889 2.429 2.463 2.463 0 0 1-1.138 1.846l-2.451 1.546a.661.661 0 0 1-.363.11Zm-4.079-5.739 4.18 4.183 1.989-1.258a1.105 1.105 0 0 0 .5-.834 1.612 1.612 0 0 0-.495-1.3l-2.774-2.78a1.589 1.589 0 0 0-1.3-.541 1.106 1.106 0 0 0-.834.55Z" />
          <path
            data-name="Vector"
            d="M5.399 19.562a2.943 2.943 0 0 1-2.1-.834 2.964 2.964 0 0 1-.807-2.521l.9-7.636c.275-2.31 1.292-3.153 3.667-3.016l3.593.211a.684.684 0 0 1-.085 1.366l-3.593-.2c-1.632-.1-2.035.22-2.218 1.806l-.9 7.627a1.648 1.648 0 0 0 .411 1.382 1.648 1.648 0 0 0 1.393.412l7.627-.9c1.6-.193 1.962-.614 1.806-2.191l-.22-3.621a.684.684 0 0 1 1.366-.082l.211 3.593c.22 2.283-.678 3.382-3.016 3.667l-7.627.9a2.032 2.032 0 0 1-.408.037Z"
          />
          <path
            data-name="Vector"
            d="M4.226 18.489a.68.68 0 0 1-.486-.2.692.692 0 0 1 0-.972l2.787-2.787a.687.687 0 0 1 .972.972l-2.787 2.786a.68.68 0 0 1-.486.201Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const RequestIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-request">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="request" clipPath="url(#clip-request)">
        <g fill="#67696e" data-name="vuesax/outline/directbox-receive">
          <path d="M11.001 8.021a.691.691 0 0 1-.266-.055.679.679 0 0 1-.422-.632v-5.5a.693.693 0 0 1 .688-.688.693.693 0 0 1 .688.688v3.84l.66-.66a.687.687 0 0 1 .972.972l-1.835 1.833a.7.7 0 0 1-.485.202Z" />
          <path
            data-name="Vector"
            d="M11 8.021a.68.68 0 0 1-.486-.206L8.681 5.986a.687.687 0 0 1 .971-.971l1.829 1.833a.692.692 0 0 1 0 .967.68.68 0 0 1-.481.206ZM14.663 20.855H7.334c-5.271 0-5.271-2.796-5.271-5.271v-.917c0-2.044 0-4.354 4.354-4.354a2.853 2.853 0 0 1 2.063.688.744.744 0 0 1 .091.082l.935.99a2.119 2.119 0 0 0 3.007 0l.935-.99A.826.826 0 0 1 13.54 11a2.814 2.814 0 0 1 2.062-.687c4.354 0 4.354 2.31 4.354 4.354v.917c-.018 3.502-1.787 5.271-5.293 5.271Zm-8.246-9.167c-2.979 0-2.979.935-2.979 2.979v.917c0 2.512 0 3.9 3.9 3.9h7.325c2.732 0 3.9-1.164 3.9-3.9v-.917c0-2.044 0-2.979-2.979-2.979a1.5 1.5 0 0 0-1.192.376l-.889.944a3.436 3.436 0 0 1-5 0l-.889-.944a1.5 1.5 0 0 0-1.197-.376Z"
          />
          <path
            data-name="Vector"
            d="M4.584 11.688A.693.693 0 0 1 3.896 11V7.334c0-1.779 0-3.988 3.373-4.312A.692.692 0 0 1 7.396 4.4c-2.127.192-2.127 1.054-2.127 2.933V11a.693.693 0 0 1-.685.688ZM17.417 11.687a.693.693 0 0 1-.687-.688V7.333c0-1.879 0-2.741-2.127-2.943a.688.688 0 0 1-.618-.751.677.677 0 0 1 .755-.619c3.373.321 3.373 2.53 3.373 4.308V11a.707.707 0 0 1-.696.687Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const PaymentIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="clip-payment">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
      <g data-name="payment" clipPath="url(#clip-payment)">
        <g fill="#67696e" data-name="vuesax/outline/wallet">
          <path d="M15.584 20.854H6.417a4.989 4.989 0 0 1-5.271-5.271V11a4.945 4.945 0 0 1 4.446-5.207 5.9 5.9 0 0 1 .825-.064h9.167a5.217 5.217 0 0 1 .8.055A4.941 4.941 0 0 1 20.857 11v4.583a4.989 4.989 0 0 1-5.273 5.271ZM6.417 7.104a4.917 4.917 0 0 0-.642.046A3.6 3.6 0 0 0 2.521 11v4.583a3.652 3.652 0 0 0 3.9 3.9h9.167a3.652 3.652 0 0 0 3.9-3.9V11a3.6 3.6 0 0 0-3.291-3.859 3.507 3.507 0 0 0-.605-.037Z" />
          <path
            data-name="Vector"
            d="M5.675 7.159a.691.691 0 0 1-.559-.284.674.674 0 0 1-.055-.715 3.293 3.293 0 0 1 .66-.889L8.7 2.282a3.923 3.923 0 0 1 5.518 0l1.6 1.623a3.806 3.806 0 0 1 1.137 2.521.689.689 0 0 1-.229.55.682.682 0 0 1-.568.165 3.905 3.905 0 0 0-.577-.037H6.417a4.917 4.917 0 0 0-.642.044.344.344 0 0 1-.1.011Zm1.531-1.43h8.213a2.441 2.441 0 0 0-.568-.852l-1.614-1.629a2.557 2.557 0 0 0-3.575 0ZM20.167 15.813h-2.75a2.521 2.521 0 1 1 0-5.042h2.75a.693.693 0 0 1 .688.688.693.693 0 0 1-.687.688h-2.751a1.146 1.146 0 1 0 0 2.292h2.75a.688.688 0 0 1 0 1.375Z"
          />
        </g>
      </g>
    </svg>
  );
};
