import { firebaseApp } from "config/firebase/firebase";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Logout, useGetMe } from "service/auth";
import { IAppAuthContext } from "./interface";
import { getFirestore } from "firebase/firestore";
import { LoadingPage } from "components";

const Context = React.createContext<IAppAuthContext>({} as IAppAuthContext);

const AppAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const isPublic = pathname === "/login";
  const { isLoading, data, isError } = useGetMe(isPublic);

  if (isLoading && !isPublic) {
    return (
      <div className="h-screen w-screen center">
        <LoadingPage />
      </div>
    );
  }
  if (isError && !isPublic) {
    return <Navigate to="/login" replace />;
  }
  if (data && !data?.role?.isActive) {
    Logout();
    return <Navigate to="/login" replace />;
  }

  const db = getFirestore(firebaseApp);

  return (
    <Context.Provider
      value={{ userMe: data, role: data?.role, firebaseApp, db }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppAuth = () => React.useContext(Context);
export default AppAuthProvider;
