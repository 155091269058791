import { Col } from "antd";
import { Box } from "components";
import { LoadingDashboard } from "pages/overview";
import { useOverview } from "providers/overview";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetDashBoardComplaint } from "service/dashboard/central/recommrnt-feed-back";

export const StatusBox = () => {
  const { t } = useTranslation();
  const { property } = useOverview();

  const { data, isFetching } = useGetDashBoardComplaint({ property });

  return (
    <React.Fragment>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-complaints")}
            count={data?.all || 0}
            color="blue"
            textAfter={isFetching}
          />
        </LoadingDashboard>
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-pending-complaints")}
            count={data?.pending || 0}
            color="red"
            textAfter={isFetching}
          />
        </LoadingDashboard>
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LoadingDashboard loading={isFetching}>
          <Box
            title={t("number-completed-complaints")}
            count={data?.success || 0}
            color="green"
            textAfter={isFetching}
          />
        </LoadingDashboard>
      </Col>
    </React.Fragment>
  );
};
