import dayjs, { Dayjs } from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";

dayjs.extend(buddhistEra);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(utc);

export const getClientTimeZone = () => {
  return dayjs.tz.guess();
};

export default dayjs;
export { Dayjs };
