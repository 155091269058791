import React from "react";
import { IPropertyParams } from "service/property/interface";
import { CSelectProps } from ".";
import { CSelectPagination } from "./pagination";
import { useScrollGetSettingSurveys } from "service/setting-survey";

export interface CSelectSettingSurveyProps
  extends CSelectProps,
    IPropertyParams {
  notValueKey?: boolean;
}

export const CSelectSettingSurvey: React.FC<CSelectSettingSurveyProps> = ({
  placeholder,
  ...props
}) => {
  return (
    <CSelectPagination
      {...props}
      placeholder={placeholder}
      useQuery={useScrollGetSettingSurveys}
      valueKey="id"
      labelKey="title"
      searchKey="title"
      translation
    />
  );
};
