import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { axiosProperty, throwResponse } from "config/axios";
import {
  IDashboardName,
  IDashboardParams,
  IDashboardUnitNumber,
} from "../../interface";
import { IDashboardFacilityType } from "./interface";

export const useGetPropertyDashboardFacility = (
  params?: IDashboardParams
): UseQueryResult<IDashboardName[], Error> => {
  return useQuery(
    ["dashboard-property-facility", "most-facility", params],
    async () => {
      const res = await axiosProperty.get("/dashboard/facility/most-facility", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetPropertyDashboardFacilityType = (
  params?: IDashboardParams
): UseQueryResult<IDashboardName[], Error> => {
  return useQuery(
    ["dashboard-property-facility", "type", params],
    async () => {
      const res = await axiosProperty.get("/dashboard/facility/most-facility", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetPropertyDashboardFacilityUnit = (
  params?: IDashboardParams
): UseQueryResult<IDashboardUnitNumber[], Error> => {
  return useQuery(
    ["dashboard-property-facility", "most-unit-used-facility", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/facility/most-unit-used-facility",
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};

export const useGetPropertyDashboardFacilityMonth = (
  params?: IDashboardParams
): UseQueryResult<IDashboardFacilityType, Error> => {
  return useQuery(
    ["dashboard-property-facility", "most-facility-month", params],
    async () => {
      const res = await axiosProperty.get(
        "/dashboard/facility/count-facility-booking-type",
        {
          params,
        }
      );
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
    }
  );
};
