import { FC, ReactNode, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  isTablet,
  isMobile,
  isAndroid,
  isIOS,
  isMobileSafari,
  isSamsungBrowser,
} from "react-device-detect";

const Context = createContext<{}>({} as {});

const AppResponsiveProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  const mobileOrTablet = isMobile || isTablet;
  const OS = isIOS || isAndroid;
  const browser = isMobileSafari || isSamsungBrowser;

  if (mobileOrTablet || OS || browser) {
    return (
      <div className="w-[100vw] h-[100vh] bg-[#F8F8F8] flex justify-center items-center">
        <div>{t("not-supported-on-mobile", { ns: "message" })}</div>
      </div>
    );
  }

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

export const useAppLoading = () => useContext(Context);
export default AppResponsiveProvider;
