import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";

export const CSelectVehicleParkingFree: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
    placeholder={t("parking-fee")}
    valueOption={{
      values: [
        { value: true, title: t("use-privileges-of-parking") },
        { value: false, title: t("use-not-privileges-of-parking") },
      ],
      valueKey: "value",
      labelKey: "title",
    }}
    {...props}
    />
  );
};
