import { CSelect } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import bank from "components/data/bank-code.json";

export const CSelectBankCode: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const data = bank;
  return (
    <CSelect
      {...props}
      placeholder={t("bank-name")}
      valueOption={{
        values: data,
        valueKey: "id",
        labelKey: "name",
        translation: true,
      }}
    />
  );
};
