import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import dayjs from "dayjs";
import { FC } from "react";
import styled from "styled-components";
import { CDate } from "../c-date/c-date-picker";

export const StyledRangePicker = styled(CDate.RangePicker)`
  height: 45px;
  width: 100%;
  // border: 1px solid #fff;
  .anticon-swap-right {
    margin: auto;
    display: block;
  }
`;

export const CRangePicker: FC<RangePickerProps<dayjs.Dayjs>> = (props) => {
  return <StyledRangePicker {...props} getPopupContainer={(e) => e} />;
};
