import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { axiosCentral, throwResponse } from "config/axios";
import { IDashboardName, IDashboardParams } from "../../interface";

export const useGetDashboardAppliancesCount = (
  params?: IDashboardParams
): UseQueryResult<IDashboardName[], Error> => {
  return useQuery(
    ["dashboard-appliances", "count-used", params],
    async () => {
      const res = await axiosCentral.get("/dashboard/appliances/count-used", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetDashboardAppliancesCountMost = (
  params?: IDashboardParams
): UseQueryResult<IDashboardName[], Error> => {
  return useQuery(
    ["dashboard-appliances", "count-used-most", params],
    async () => {
      const res = await axiosCentral.get("/dashboard/appliances/count-used", {
        params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};
