import { Space, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { COLOR_THEME, ColorThemeTypes } from "./theme";

export interface IBox {
  title: string;
  count: number;
  color: ColorThemeTypes;
  text?: string;
  textAfter?: boolean;
}

const BoxStyle = styled.div<{ bg: string; color: string }>`
  ${tw`h-[150px] rounded-app`}
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};

  .ant-typography {
    color: ${({ color }) => color};
  }
`;

export const Box: FC<IBox> = ({
  title,
  color,
  count,
  text,
  textAfter = true,
}) => {
  const { t } = useTranslation();
  const theme = COLOR_THEME[color];
  return (
    <BoxStyle bg={theme.bg} color={theme.colorText} className="center">
      <Space direction="vertical" className="center">
        <div>{title}</div>
        <Typography.Title level={3}>{count.toLocaleString()}</Typography.Title>
        <div hidden={!textAfter}>{text ? text : t("work")}</div>
      </Space>
    </BoxStyle>
  );
};
