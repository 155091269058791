import { throwResponse, axiosProperty } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IParcelSettingStorageZone,
  IParcelSettingStorageZoneParams,
} from "./interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";

export const useGetParcelSettingStorageZones = (
  params?: IParcelSettingStorageZoneParams
): UseQueryResult<IResponse<IParcelSettingStorageZone[]>, Error> => {
  return useQuery(
    ["parcelSettingStorageZone", "all", params],
    async () => {
      const res = await axiosProperty.get("/parcel-zone", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: params?.enabled,
    }
  );
};

export const useScrollGetParcelSettingStorageZones = (
  params?: IParcelSettingStorageZoneParams
): UseInfiniteQueryResult<IResponse<IParcelSettingStorageZone[]>, Error> => {
  return useInfiniteQuery(
    ["parcelSettingStorageZone", "scroll", params],
    async ({ pageParam }) => {
      const res = await axiosProperty.get("/parcel-zone", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetParcelSettingStorageZone = (
  id?: string | number
): UseQueryResult<IParcelSettingStorageZone, Error> => {
  return useQuery(
    ["parcelSettingStorageZone", "all", id],
    async () => {
      const res = await axiosProperty.get(`/parcel-zone/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};

export const usePostParcelSettingStorageZone = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/parcel-zone", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchParcelSettingStorageZone = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axiosProperty.patch(`/parcel-zone/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingStorageZone = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axiosProperty.delete(`/parcel-zone/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParcelSettingStorageZoneBulk = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.delete("/parcel-zone/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};


export const useExportExcelPropertyParcelZone = (): UseMutationResult<Blob> => {
  return useMutation(async (data) => {
    const res = await axiosProperty.post("/export-excel/parcel-storage-zone", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};


export const useExcelImportPropertyParcelZone = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (params: any) => {
    const res = await axiosProperty.post("/import-excel/parcel-zone/", params, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExcelTemplatePropertyParcelZone = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ data }: any) => {
    const res = await axiosProperty.post("/export-excel/template-parcel-zone/", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};